import * as React from 'react';
import { ALaCarteLogo } from '../assets/Assets';
import { FieldCheckbox2 } from './form/FieldCheckbox';
import { FieldTextInput } from './form/FieldTextInput';
import { FormContainer } from './form/FormContainer';
import { SquareButton } from './Button/SquareButton';
import { Strings } from '../../../core/src/locale/Strings';
import {
  TLoginFormParams,
  useLoginFormHandler,
} from '../lib/forms/LoginForm';
import styled from 'styled-components';
import { ELocale } from '../../../core/src/locale/Locale';

export type TLoginParams = {
  email: string;
  password: string;
};

type TLoginViewProps = TLoginFormParams & {
  locale: ELocale;
};

export const LoginView = ({
  locale,
  ...loginViewProps
}: TLoginViewProps) => {
  const formHandler = useLoginFormHandler(loginViewProps);
  return (
    <Root>
      <ALaCarteLogo/>
      <Content>
        <StyledFormContainer
          onEnterPress={formHandler.onSubmit}
          error={formHandler.formError}>
          <StyledLoginInput
            type="email"
            value={formHandler.fields.email.value}
            onChange={formHandler.fields.email.setValue}
            error={formHandler.fields.email.errorStr}
            placeholder={Strings.hLoginEmailPhd()(locale)}
          />
          <StyledPasswordInput
            type="password"
            value={formHandler.fields.password.value}
            onChange={formHandler.fields.password.setValue}
            error={formHandler.fields.password.errorStr}
            placeholder="Password"
          />
          <FieldCheckbox2
            label={Strings.hLoginRememberCb()(locale)}
            value={formHandler.fields.rememberMe.value}
            onChange={formHandler.fields.rememberMe.setValue}
          />
          <SquareButton
            title={Strings.hLoginCmd()(locale)}
            onClick={formHandler.onSubmit}
            loading={formHandler.loading}
            disabled={formHandler.submitDisabled}
          />
        </StyledFormContainer>
      </Content>
    </Root>
  );
};

const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.scaler.moderateVerticalScale(120, 1.5)}px;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  max-width: 315px;
  width: 100%;
`;

const StyledFormContainer = styled(FormContainer)`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  max-width: 315px;
  width: 100%;
`;

const StyledLoginInput = styled(FieldTextInput)`

`;

const StyledPasswordInput = styled(FieldTextInput)`
  margin-top: 15px;
`;
