import * as React from 'react';
import * as _ from 'lodash';
import { FIELD_ID } from '../../../core/src/db/DbDefs';
import { Footer } from '../components/Footer';
import { FullScreenLoadingView } from '../components/fullScreenViews/FullScreenLoadingView';
import { Hist } from '../lib/navigation/History';
import { HostNavbarMain } from '../components/navbars/HostNavbarMain';
import { HostRoomGrid } from '../components/hostHome/HostRoomGrid';
import {
  MaxHeightDiv,
  MaxScreenDiv,
} from '../components/MaxHeightDiv';
import { Routes } from '../config/Routes';
import { Strings } from '../../../core/src/locale/Strings';
import { THostId } from '../../../core/src/models/db/host/HostTypes';
import {
  TPropCheck,
  withPropCheckMiddleware,
} from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import { TRoomId } from '../../../core/src/models/db/room/RoomTypes';
import { TScreenNavigationProps } from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import {
  TTable,
  TTableId,
} from '../../../core/src/models/db/table/TableTypes';
import { TableBuilder } from '../../../core/src/models/db/table/TableBuilder';
import { connect } from 'react-redux';
import styled from 'styled-components';

export type TScreenHostHomeNavParams = {
  hostId: THostId;
};

export type TScreenHostHomeProps = TScreenNavigationProps<TScreenHostHomeNavParams> & {
  //
};

type TScreenHostHomeBaseProps = TScreenHostHomeProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TScreenHomeBaseState = {
  //
};

class ScreenHostHomeBase extends React.Component<TPropCheck<typeof ScreenHostHomeBase>, TScreenHomeBaseState> {
  static checkProps(props: TScreenHostHomeBaseProps) {
    const {
      locale,
      hostData,
      hostPublicSubscription,
    } = props;

    if (hostData == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    if (hostPublicSubscription == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    return {
      ...props,
      hostPublicSubscription,
      hostData,
    };
  }

  onTableClick = async (tableData: TTable) => {
    const { hostData, locale } = this.props;
    Hist.push(Routes.ScreenHostGuestSessionOverview, {
      hostId: hostData[FIELD_ID],
      tableId: tableData[FIELD_ID],
      navContext: { goBackText: Strings.hBackToHostOverview()(locale) },
    });
  };

  onInternalNoteChange = async (table: TTable, note: string) => {
    await TableBuilder.fromItem(table).updateInternalNote(note);
  };

  onChangeTableRoom = async (tableId: TTableId, roomId: TRoomId) => {
    const { hostData } = this.props;
    const table = _.get(hostData.tables, tableId);
    if (!table) {
      return;
    }

    await TableBuilder.fromItem(table).updateRoomId(roomId);
  };

  render() {
    const {
      locale,
      hostData,
      hostGuestSessions,
      hostPublicSubscription,
    } = this.props;
    return (
      <Root>
        <HostNavbarMain
          locale={locale}
        />
        <ScrollableContent>
          <Content>
            <HostRoomGrid
              locale={locale}
              host={hostData}
              hostGuestSessions={hostGuestSessions}
              hostPublicSubscription={hostPublicSubscription}
              onTableClick={this.onTableClick}
              onInternalNoteChange={this.onInternalNoteChange}
              onChangeTableRoom={this.onChangeTableRoom}
            />
          </Content>
        </ScrollableContent>
        <Footer
          locale={locale}
        />
      </Root>
    );
  }
}

function mapStateToProps(state: TState) {
  return {
    locale: state.app.locale,
    hostData: state.host.hostData,
    hostGuestSessions: state.host.hostGuestSessions,
    hostPublicSubscription: state.host.hostPublicSubscription,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export const ScreenHostHome = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(ScreenHostHomeBase));

const Root = styled(MaxScreenDiv)`
`;

const ScrollableContent = styled(MaxHeightDiv)`
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;
