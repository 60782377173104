import * as React from 'react';
import { ErrorHandler } from '../../../../core/src/lib/error/ErrorHandler';
import { SnackbarOptions } from '../../lib/snackbars/SnackbarOptions';
import { Snackbars } from '../../lib/snackbars/Snackbars';
import { TState } from '../../redux/Store';
import { connect } from 'react-redux';

type TGlobalErrorBoundaryProps = {
  children: React.ReactNode;
};

type TGlobalErrorBoundaryBaseProps = ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TGlobalErrorBoundaryBaseState = {
};

class GlobalErrorBoundaryBase extends React.Component<TGlobalErrorBoundaryBaseProps, TGlobalErrorBoundaryBaseState> {
  componentDidCatch(error, info) {
    const { locale } = this.props;
    const handledError = ErrorHandler.handle(error);
    const resolvedError = ErrorHandler.resolve(handledError.knownError, {});
    Snackbars.enqueueSnackbar({
      content: resolvedError.message(locale),
      options: SnackbarOptions.globalError(),
    });
  }

  render() {
    const { children } = this.props;
    return (
      <>
        {children}
      </>
    );
  }
}

function mapStateToProps(state: TState, ownProps: TGlobalErrorBoundaryProps) {
  return { locale: state.app.locale };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export const GlobalErrorBoundary = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalErrorBoundaryBase);
