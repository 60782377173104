import { PackageSecrets } from './PackageSecrets';
import { Process } from '../../../core/src/config/Process';
import { ELOCALE_GLOBAL_DEFAULT } from '../../../core/src/locale/Locale';

export const Config = {
  appName: '@alacarte/cli-host-web',
  defaultLocale: ELOCALE_GLOBAL_DEFAULT,

  // Process and secrets
  env: Process.env.NODE_ENV,
  isCI: Process.env.CI,
  STRIPE_PREMIUM_CHECKOUT_PRICE_ID: 'price_1JpVXhDanvafi4hCglEorWJG', // PackageSecrets.val.STRIPE_PREMIUM_CHECKOUT_PRICE_ID,
  firebase: {
    apiKey: PackageSecrets.val.FIREBASE_API_KEY,
    authDomain: PackageSecrets.val.FIREBASE_AUTH_DOMAIN,
    databaseURL: PackageSecrets.val.FIREBASE_DB_URL,
    projectId: PackageSecrets.val.FIREBASE_PROJECT_ID,
    storageBucket: PackageSecrets.val.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: PackageSecrets.val.FIREBASE_MESSAGING_SENDER_ID,
    appId: PackageSecrets.val.FIREBASE_APP_ID,
    measurementId: PackageSecrets.val.FIREBASE_MEASUREMENT_ID,
  },
};

