import * as React from 'react';
import { DbRes } from '../../../../core/src/db/DbResources';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../../core/src/db/DbDefs';
import { IconButton } from '../Button/IconButton';
import { Strings } from '../../../../core/src/locale/Strings';
import { TMObjDataCreator } from '../../../../core/src/lib/model/ModelTypes';
import {
  TMenu,
  TMenuId,
} from '../../../../core/src/models/db/menu/MenuTypes';
import { dbItemLocalizableGetLocalizedFieldWithDefault } from '../../../../core/src/db/DbLib';
import { useEnterKeyDown } from '../../../../lib-react/src/hooks/useKeyDownListener';
import { useEventValueCallback } from '../../../../lib-react/src/hooks/useEventValueCallback';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

type TMenuBuilderNewItemProps<T extends TMObjDataCreator<typeof DbRes.Menu>> = {
  locale: ELocale;
  menuData: T;
  menuLocale: ELocale;
  onMenuNameChange: (menu: T, newName: string) => void;
  onMenuNameBlur?: (menu: T) => void;
  onItemSelect?: (menuId: TMenuId) => void;
};

export function MenuBuilderNewItem<T extends TMObjDataCreator<typeof DbRes.Menu>>({
  locale,
  menuData,
  menuLocale,
  onMenuNameChange,
  onMenuNameBlur,
  onItemSelect,
}: TMenuBuilderNewItemProps<T>) {
  const menuName = dbItemLocalizableGetLocalizedFieldWithDefault<TMenu, 'name'>(menuData, FIELD_NAME, menuLocale);

  const setMenuName = React.useCallback((newValue: string) => {
    return onMenuNameChange(menuData, newValue);
  }, [menuData]);

  const onMenuItemClick = onItemSelect && useItemCallback(menuData[FIELD_ID], onItemSelect);

  const onNameInputSave = React.useCallback(() => {
    onMenuNameBlur && onMenuNameBlur(menuData);
  }, [onMenuNameBlur, menuData]);

  const onKeyDown = useEnterKeyDown(onNameInputSave);
  const onNameChange = useEventValueCallback(setMenuName);

  return (
    <Root
      isClickable={!!onMenuItemClick}
      onClick={onMenuItemClick}
      isValid={true}
    >
      <InnerContainer>
        <SingleIconContainer>
          <IconButton>
          </IconButton>
        </SingleIconContainer>
        <TextContainer>
          <Input
            value={menuName}
            onChange={onNameChange}
            placeholder={Strings.hMenuCreateCat()(locale)}
            onKeyPress={onKeyDown}
          />
        </TextContainer>
      </InnerContainer>
    </Root>
  );
}

type TRootProps = {
  isClickable: boolean;
  isValid: boolean;
};

const Root = styled.div<TRootProps>`
  // flex: 0 0 109px;
  height: 109px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  background-color: ${({ theme }) => theme.t.lightGray4};
  border 1px solid ${({ theme, isValid }) => (isValid ? theme.t.col.lightGray2 : theme.t.col.red)};
`;

const InnerContainer = styled.div`
  align-items: center;
  display: grid;
  flex: 1;
  grid-template-columns: 30px 1fr 40px;
  height: 100%;
`;

const TextContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const SingleIconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const Input = styled.input`
  background: none;
  border: none;
  color: ${({ theme }) => theme.t.col.accentDark};
  flex: 1;
  font-size: 13px;
  font-weight: 600;
  &::placeholder {
    color: ${({ theme }) => theme.t.col.accentLight};
    font-size: 13px;
  }
  line-height: 22px;
  outline: 0;
  outline: none;
  width: 100%;
  width: 100%;
`;

