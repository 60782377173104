import { EFullScreenModal } from './FullScreenModal';
import { TDb } from '../../../core/src/db/Db';
import { TFirebaseUser } from '../../../core/src/FirebaseApi';
import {
  THost,
  THostId,
} from '../../../core/src/models/db/host/HostTypes';
import { THostMessage } from '../../../core/src/models/db/hostMessage/HostMessageTypes';
import { createAction } from '../../../lib-react/src/redux/redux';
import { THostPublicSubscription } from '../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import { ELocale } from '../../../core/src/locale/Locale';

export const actions = {
  actionTypeAppSetUser: createAction('app/setUser')<{
    user?: TFirebaseUser;
  }>(),
  actionTypeAppSetLocale: createAction('app/setLocale')<{
    locale: ELocale;
  }>(),
  actionTypeAppSetLockScreen: createAction('app/setLockScreen')<{
    lockScreen: boolean;
  }>(),
  actionTypeAppSetShowSplashScreen: createAction('app/setShowSplashScreen')<{
    showSplashScreen: boolean;
  }>(),
  actionTypeGuestSessionSetSetupComplete: createAction('guestSession/setSetupComplete')<{
    setupComplete: boolean;
  }>(),
  actionTypeAppSetHostId: createAction('host/setHostId')<{
    hostId?: THostId;
  }>(),
  actionTypeHostSetHost: createAction('host/setHost')<{
    hostData?: THost;
  }>(),
  actionTypeHostSetHostPublicSubscription: createAction('host/setHostPublicSubscription')<{
    hostPublicSubscription?: THostPublicSubscription;
  }>(),
  actionTypeHostSetGuestSessions: createAction('host/setGuestSessions')<{
    hostGuestSessions?: TDb['guestSessions'][THostId];
  }>(),
  actionTypeHostMessagesAddMessages: createAction('hostMessages/setMessages')<{
    hostMessages: THostMessage[];
  }>(),
  actionTypeFullScreenModalSet: createAction('fullScreenModal/set')<{
    type: EFullScreenModal | undefined;
    params: any;
  }>(),
  actionTypeResetToInitialState: createAction('sync/reset')(),
};
