import * as _ from 'lodash';
import { EPrintAdapter } from '../../../../core/src/printer/PrintAdapters';
import { EStationType, TStationUpdate } from '../../../../core/src/models/db/station/StationTypes';
import { FIELD_EDITABLE, FIELD_NAME, FIELD_SORTABLE_INDEX } from '../../../../core/src/db/DbDefs';
import { Host } from '../../../../core/src/models/db/host/Host';
import { THost, THostDataChange, TRoomToTableCount } from '../../../../core/src/models/db/host/HostTypes';
import { Validator } from '../../../../core/src/lib/error/Validators';
import { dbItemSortableNextIndex } from '../../../../core/src/db/DbLib';
import { useFormArrayField } from '../../../../lib-react/src/hooks/form/useFormArrayField';
import { useFormHandler } from '../../../../lib-react/src/hooks/form/useFormHandler';
import { useFormNumberField } from '../../../../lib-react/src/hooks/form/useFormNumberField';
import { useFormStringField } from '../../../../lib-react/src/hooks/form/useFormStringField';
import { validateMenuItem } from '../../../../core/src/models/validators/validateMenuItem';
import { validateHost } from '../../../../core/src/models/validators/validateHost';
import { validateRoomTableCount } from '../../../../core/src/models/validators/validateRoom';
import { validateStationUpdater } from '../../../../core/src/models/validators/validateStation';
import { THostCustomizationDataChange } from '../../../../core/src/models/dbUpdaters/hostCustomizationUpdate';

export type TProfileForm = THostDataChange & THostCustomizationDataChange & {
  //
};

export type TProfileFormParams = {
  hostData: THost;
  onSubmit: (data: TProfileForm) => Promise<any>;
};

export function useProfileFormHandler({
  hostData,
  onSubmit,
}: TProfileFormParams) {
  const logoImg = hostData.logoImg;
  const coverImg = hostData.coverImg;
  return useFormHandler({
    onSubmit: (params, context) => {
      return onSubmit(params);
    },
    fields: {
      name: useFormStringField({
        value: hostData[FIELD_NAME],
        required: true,
        valueToError: validateHost.name,
      }),

      currency: useFormStringField({
        value: hostData.currency ?? 'EUR' as const,
        required: false,
        // @ts-expect-error only applies to strings, currency is an enum
        valueToError: validateHost.currency,
      }),

      description: useFormStringField({
        value: hostData.description,
        required: true,
        valueToError: validateHost.description,
      }),

      rooms: useFormArrayField<TRoomToTableCount>({
        value: Host.getRoomToTableCount(hostData),
        valueToError: Validator.applyToArr(validateRoomTableCount),
        newItem: {
          room: {
            [FIELD_NAME]: '',
            [FIELD_SORTABLE_INDEX]: dbItemSortableNextIndex(_.values(hostData.rooms)),
          },
          tableCount: 0,
        },
      }),

      tableCount: useFormNumberField({
        value: _.size(hostData.tables),
        required: true,
        min: 1,
        max: 100,
      }),

      stations: useFormArrayField<TStationUpdate>({
        value: _.values(hostData.stations),
        required: true,
        valueToError: Validator.applyToArr(validateStationUpdater),
        newItem: {
          [FIELD_EDITABLE]: true,
          name: '',
          type: EStationType.SECONDARY,
          adapter: {
            name: EPrintAdapter.Network,
            params: { address: '0.0.0.0' },
          },
        },
      }),

      logoImg: useFormStringField({
        value: logoImg,
        syncingActive: true,
        required: true,
      }),

      coverImg: useFormStringField({
        value: coverImg,
        syncingActive: true,
        required: true,
      }),

      primaryColor: useFormStringField({
        value: '#000000', // ,hostData.primaryColor,
        required: false,
      }),

      secondaryColor: useFormStringField({
        value: '#000000', // hostData.secondaryColor,
        required: false,
      }),

      denomination: useFormStringField({
        value: _.get(hostData, 'denomination', ''),
        required: true,
      }),

      address: useFormStringField({
        value: _.get(hostData, 'address', ''),
        required: true,
      }),

      phone: useFormStringField({
        value: _.get(hostData, 'phone', ''),
        required: true,
      }),

      vatId: useFormStringField({
        value: _.get(hostData, 'vatId', ''),
        required: true,
      }),

      placeId: useFormStringField({
        value: _.get(hostData, 'placeId', ''),
        required: false,
      }),

      coordinates: useFormStringField({
        value: _.get(hostData, 'coordinates', ''),
        required: false,
      }),
    },
  });
}
