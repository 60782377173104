import * as React from 'react';
import { TTag } from '../../../core/src/models/Tag';
import { useCancelableAsyncEffect } from '../../../lib-react/src/hooks/useCancelableAsyncEffect';
import { Log } from '../config/Instance';
import { ReactComponent as FrozenFullIcon } from '../assets/tags/frozen-full.svg';
import { ReactComponent as FrozenEmptyIcon } from '../assets/tags/frozen-empty.svg';
import { ReactComponent as SpicyFullIcon } from '../assets/tags/spicy-full.svg';
import { ReactComponent as SpicyEmptyIcon } from '../assets/tags/spicy-empty.svg';
import { ReactComponent as VeganFullIcon } from '../assets/tags/vegan-full.svg';
import { ReactComponent as VeganEmptyIcon } from '../assets/tags/vegan-empty.svg';
import { ReactComponent as VegetarianFullIcon } from '../assets/tags/vegetarian-full.svg';
import { ReactComponent as VegetarianEmptyIcon } from '../assets/tags/vegetarian-empty.svg';
import { ReactComponent as CaffeineFreeFullIcon } from '../assets/tags/caffeinefree-full.svg';
import { ReactComponent as CaffeineFreeEmptyIcon } from '../assets/tags/caffeinefree-empty.svg';
import { ReactComponent as DairyFreeFullIcon } from '../assets/tags/dairyfree-full.svg';
import { ReactComponent as DairyFreeEmptyIcon } from '../assets/tags/dairyfree-empty.svg';
import { ReactComponent as GlutenFreeFullIcon } from '../assets/tags/glutenfree-full.svg';
import { ReactComponent as GlutenFreeEmptyIcon } from '../assets/tags/glutenfree-empty.svg';
import { ReactComponent as NewFullIcon } from '../assets/tags/new-full.svg';
import { ReactComponent as NewEmptyIcon } from '../assets/tags/new-empty.svg';
import { ReactComponent as NutsFullIcon } from '../assets/tags/nuts-full.svg';
import { ReactComponent as NutsEmptyIcon } from '../assets/tags/nuts-empty.svg';
import { ReactComponent as RecommendedFullIcon } from '../assets/tags/recomended-full.svg';
import { ReactComponent as RecommendedEmptyIcon } from '../assets/tags/recomended-empty.svg';
import { ReactComponent as SesameFullIcon } from '../assets/tags/sesame-full.svg';
import { ReactComponent as SesameEmptyIcon } from '../assets/tags/sesame-empty.svg';
import styled from 'styled-components';

const FilledTagToSvgMap = {
  '#frozen': FrozenFullIcon,
  '#spicy': SpicyFullIcon,
  '#vegan': VeganFullIcon,
  '#vegetarian': VegetarianFullIcon,
  '#caffeinefree':CaffeineFreeFullIcon,
  '#dairyfree':DairyFreeFullIcon,
  '#glutenfree':GlutenFreeFullIcon,
  '#new':NewFullIcon,
  '#nutsfree':NutsFullIcon,
  '#recommended':RecommendedFullIcon,
  '#sesamefree':SesameFullIcon,
};

const UnfilledTagToSvgMap = {
  '#frozen': FrozenEmptyIcon,
  '#spicy': SpicyEmptyIcon,
  '#vegan': VeganEmptyIcon,
  '#vegetarian': VegetarianEmptyIcon,
  '#caffeinefree':CaffeineFreeEmptyIcon,
  '#dairyfree':DairyFreeEmptyIcon,
  '#glutenfree':GlutenFreeEmptyIcon,
  '#new': NewEmptyIcon,
  '#nutsfree':NutsEmptyIcon,
  '#recommended':RecommendedEmptyIcon,
  '#sesamefree':SesameEmptyIcon,
};

type TMenuItemTagProps = {
  tag: string;
  getTagAsSvgUrl: (tag: TTag) => Promise<string>;
  className?: string;
  onClick?: (tag: string) => void;
  filled?: boolean;
};

export const MenuItemTag = ({
  tag,
  getTagAsSvgUrl,
  className,
  onClick,
  filled = true,
}: TMenuItemTagProps) => {
  const localMap = filled
    ? FilledTagToSvgMap
    : UnfilledTagToSvgMap;

  const LocalTag = localMap[`${tag}`.toLowerCase()];

  const onTagClick = React.useCallback(() => {
    onClick && onClick(tag);
  }, [onClick, tag]);

  return (
    <Root
      onClick={onClick ? onTagClick : undefined}
      className={className}>
      {LocalTag
        ? (
          <LocalTag/>
        ) : (
          <MenuItemTagRemote
            tag={tag}
            getTagAsSvgUrl={getTagAsSvgUrl}
          />
        )}
    </Root>
  );
};

const MenuItemTagRemote = ({
  tag,
  getTagAsSvgUrl,
}: Pick<TMenuItemTagProps, 'tag' | 'getTagAsSvgUrl'>) => {
  const [tagUrl, setTagUrl] = React.useState<string | undefined>();

  useCancelableAsyncEffect(async () => {
    try {
      const url = await getTagAsSvgUrl(tag);
      setTagUrl(url);
    } catch (e) {
      Log.e('MenuItemTag', `getTagAsSvgUrl fail, ${e.message}`);
    }
  }, [tag, getTagAsSvgUrl]);

  if (!tagUrl) {
    return (
      <div>.</div>
    );
  }

  return (
    <img
      alt=""
      src={tagUrl}
    />
  );
};

const Root = styled.div`
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  display: flex;
  height: 52px;
  justify-content: center;
  width: 52px;
`;
