import * as React from 'react';
import { IconButton } from '../Button/IconButton';
import { ReactComponent as LabelHelp } from '../../assets/label-help-icon.svg';
import styled from 'styled-components';

export type TFormLabelProps = {
  children: React.ReactNode;
  caption?: string;
  label?: string;
  onClickHelp?: () => void;
  className?: string;
  isRequired?: boolean;
};

export const FormLabel = React.memo(({
  children,
  caption,
  label,
  onClickHelp,
  className,
  isRequired = false,
}: TFormLabelProps) => {
  const labelWithRequired = isRequired
    ? `* ${label}`
    : label;
  return (
    <Root className={className}>
      <LabelContainer>
        {labelWithRequired}
        {onClickHelp && (
          <span>
            <IconButton onClick={onClickHelp}>
              <LabelHelp/>
            </IconButton>
          </span>
        )}
      </LabelContainer>
      {children}
      <CaptionText>{caption}</CaptionText>
    </Root>
  );
});

const Root = styled.div`
`;

const CaptionText = styled.span`
  color: ${({ theme }) => theme.t.col.accentLight};
  display: block;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  margin-top: 5px;
  text-transform: none;
`;

const LabelContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 14px;
  text-transform: uppercase;
  span {
    margin-left: 13px;
  }
`;
