import * as React from 'react';
import { FieldStationInputInputField } from './FieldStationInputInputField';
import { TStation } from '../../../../../core/src/models/db/station/StationTypes';
import styled from 'styled-components';

export type TFieldStationInputAdapterUSBParamsProps = {
  station: Partial<TStation>;
  onChange: (path: string, value: string) => void;
  onItemSave: () => void;
  saveOnBlur?: boolean;
};

export function FieldStationInputAdapterUSBParams({
  station,
  onChange,
  onItemSave,
  saveOnBlur = true,
}: TFieldStationInputAdapterUSBParamsProps) {
  return (
    <Root>
      <VidFieldStationInputInputField
        station={station}
        valuePathInStation={'adapter.params.vid'}
        placeholder={'vid'}
        onChange={onChange}
        onItemSave={onItemSave}
        saveOnBlur={saveOnBlur}
      />
      <PidFieldStationInputInputField
        station={station}
        valuePathInStation={'adapter.params.pid'}
        placeholder={'pid'}
        onChange={onChange}
        onItemSave={onItemSave}
        saveOnBlur={saveOnBlur}
      />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  & > *:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.t.col.accentLight};
  }
`;

const VidFieldStationInputInputField = styled(FieldStationInputInputField)`
  flex: 0.5;
  padding: 10px;
`;

const PidFieldStationInputInputField = styled(FieldStationInputInputField)`
  flex: 0.5;
  padding: 10px;
`;
