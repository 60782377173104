import * as _ from 'lodash';
import {
  EMessageSender,
  THostMessage,
} from '../../../../core/src/models/db/hostMessage/HostMessageTypes';
import {
  ESnackbarVariant,
  Snackbars,
  TSnackbar,
} from '../../lib/snackbars/Snackbars';
import {
  FIELD_CREATED_ON,
  FIELD_ID,
} from '../../../../core/src/db/DbDefs';
import { Log } from '../../config/Instance';
import { actions } from '../Actions';
import { getType } from 'typesafe-actions';
import { nowMs } from '../../../../core/src/lib/HelperFunctions';
import { takeEvery } from 'redux-saga/effects';

function hostMessageToSnackbar(hostMessage: THostMessage): TSnackbar {
  return {
    content: hostMessage.text,
    options: {
      key: hostMessage[FIELD_ID],
      persist: false,
      variant: ESnackbarVariant.default,
      action: (key: string) => null,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
  };
}

function hostMessageToSnackbarFilter(hostMessage: THostMessage): boolean {
  // Only notify of messages that were sent in the past 30 seconds (realtime)
  if (hostMessage[FIELD_CREATED_ON] < nowMs() - 30 * 1000) {
    return false;
  }

  // Don't show messages that come from the host
  if (hostMessage.sender == EMessageSender.HOST) {
    return false;
  }

  // Display the message
  return true;
}

export function* sagaHostMessageNotifications() {
  Log.v('sagaHostMessageNotifications', 'sagaHostMessageNotifications', 'Started');
  yield takeEvery(
    [
      getType(actions.actionTypeHostMessagesAddMessages),
    ],
    (params: ReturnType<typeof actions.actionTypeHostMessagesAddMessages>) => {
      const messages = _.get(params.payload, 'hostMessages', [] as THostMessage[]);

      messages
        .filter(hostMessageToSnackbarFilter)
        .map(hostMessageToSnackbar)
        .map((snackbar) => {
          return Snackbars.enqueueSnackbar(snackbar);
        });
    },
  );
}
