const defaultOrderBatchingTTLMs: number = 2 * 60 * 1000;

function iterationN(
  fibArrMs: number[],
  items: number[],
  itemIndex: number,
): number {
  if (itemIndex < 0) {
    return 0;
  }

  const currentN = items[itemIndex];
  if (itemIndex > fibArrMs.length - 1) {
    return iterationN(fibArrMs, items, itemIndex - 1);
  }

  const fibCurrent = fibArrMs[itemIndex];

  if (itemIndex == 0) {
    return currentN + fibCurrent;
  }

  return iterationN(fibArrMs, items, itemIndex - 1) + fibCurrent;
}

export function calculateBatching(
  timestamps: number[],
  batchingTTLMs: number = defaultOrderBatchingTTLMs,
): number {
  if (timestamps.length <= 0) {
    throw new Error([
      'TimestampBatcher',
      'calculateBatching',
      `Don't calculate with no timestamps`,
    ].join(', '));
  }

  const sorted = timestamps.sort();
  const sortedNormalized = sorted.map((s) => s - sorted[sorted.length - 1]);
  return sorted[sorted.length - 1] + iterationN(batchingArr(batchingTTLMs), sortedNormalized, sortedNormalized.length - 1);
}

function batchingArr(start: number) {
  const data = [start, start / 2] as number[];

  while (data[data.length - 1] > 0) {
    const i = data.length;
    const n = data[i - 1];
    const s = data[i - 2];

    const current = s - n > 0
      ? s - n
      : 0;

    data.push(current);
  }

  return data;
}
