import * as React from 'react';
import * as _ from 'lodash';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import styled from 'styled-components';
import { CurrenciesList, TCurrencyCode } from '../../../../core/src/locale/Currencies';

type TProps = {
  visible: boolean;
  bindedSearch: string;
  onSelect: (suggestionCode: TCurrencyCode) => void;
};

export const ProfileCurrencySuggestionList = ({ visible, bindedSearch, onSelect }: TProps) => {
  const suggestions = React.useMemo(() => {
    return CurrenciesList.filter((item) => {
      return `${item.name_plural} ${item.name} ${item.code} ${item.symbol}`.toLowerCase().includes(bindedSearch.toLowerCase())
    });
  }, [bindedSearch]);
  return (
    <Root visible={visible && suggestions.length > 0}>
      {suggestions.map((suggestion, index) => (
        <SuggestionItem
          key={_.get(suggestion, 'placeId', index)}
          suggestion={suggestion}
          onSelect={onSelect}
        />
      ))}
    </Root>
  );
};

type TSuggestionProps = {
  suggestion: (typeof CurrenciesList)[number];
  onSelect: (suggestionCode: TCurrencyCode) => void;
};

const SuggestionItem = ({ suggestion, onSelect }: TSuggestionProps) => {
  const onClick = useItemCallback(suggestion, async (data) => {
    return onSelect(data.code);
  });
  return (
    <Suggestion onClick={onClick}>
      <SuggestionHeader>
        <SuggestionName>{suggestion.name}</SuggestionName>
        <SuggestionPlaceId>{suggestion.symbol}</SuggestionPlaceId>
      </SuggestionHeader>
      <SuggestionAddress>{suggestion.code}</SuggestionAddress>
    </Suggestion>
  );
};

const Root = styled.div<{ visible: boolean }>`
  background-color: white;
  border-bottom: 0.5px solid ${({ theme }) => theme.t.col.accentLight};
  border-left: 0.5px solid ${({ theme }) => theme.t.col.accentLight};
  border-right: 0.5px solid ${({ theme }) => theme.t.col.accentLight};
  opacity: ${({ visible }) => (visible ? '1' : '0')};

  & > *:not(:last-child) {
    border-bottom: 0.5px solid ${({ theme }) => theme.t.col.accentLight};
  }
`;

const Suggestion = styled.div`
  cursor: pointer;
  padding: 8px 12px;
`;

const SuggestionHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const SuggestionName = styled.div`
  color: ${({ theme }) => theme.t.col.black};
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 500;
`;

const SuggestionPlaceId = styled.div`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-family: Nunito Sans;
  font-size: 8px;
  text-align: right;
`;

const SuggestionAddress = styled.div`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-family: Nunito Sans;
  font-size: 12px;
  text-align: left;
  width: 100%;
`;
