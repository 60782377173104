import * as React from 'react';
import { ChangeEvent } from 'react';
import { randomString } from '../../../core/src/lib/HelperFunctions';
import { Utils } from '../../../core/src/lib/Utils';
import { Log } from '../config/Instance';
import { useCancelableAsyncCallback } from '../../../lib-react/src/hooks/useCancelableAsyncCallback';
import { useSyncedDataRef } from '../../../lib-react/src/hooks/useSyncedDataRef';
import styled from 'styled-components';
import { Firebase } from '../lib/Firebase';

type TUploadMetadata = {
  referralPhn?: string;
  referralPhu?: string;
}

export function useSingleFileUploadInput(inputAccept: string, onChange: (url: string) => void) {
  const onChangeRef = useSyncedDataRef(onChange);
  const [loadingArrayBuffer, setLoadingArrayBuffer] = React.useState(false);

  const inputRef = React.createRef<HTMLInputElement>();

  const onTriggerUpload = React.useCallback(() => {
    if (inputRef.current == null) {
      Log.e('useSingleFileUploadInput', 'useSingleFileUploadInput', 'Upload ref was null');
      return;
    }
    inputRef.current.click();
  }, []);

  const uploadAndSetArrayBuffer = useCancelableAsyncCallback(async (arrayBuffer: ArrayBuffer, image: TUploadMetadata = {}) => {
    setLoadingArrayBuffer(true);
    try {
      const ref = Firebase.storageRef(`public/${randomString(10)}`);
      await ref.put(arrayBuffer, {
        customMetadata: {
          referralPhn: image.referralPhn ?? '',
          referralPhu: image.referralPhu ?? '',
        }
      });

      const url = await ref.getDownloadURL();
      const _url = new URL(url);
      image.referralPhn && _url.searchParams.append('referralPhn', image.referralPhn);
      image.referralPhu && _url.searchParams.append('referralPhu', Utils.encodeURIComponent(image.referralPhu));
      onChangeRef.current(`${_url}`);

    } catch (e) {
      // Not fatal
    } finally {
      setLoadingArrayBuffer(false);
    }
  }, [setLoadingArrayBuffer]);

  const onInputChange = useCancelableAsyncCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    setLoadingArrayBuffer(true);
    try {
      const file = event.target && event.target.files && event.target.files.item(0);
      if (!file) {
        Log.v('useSingleFileUploadInput', 'useSingleFileUploadInput', 'Uploaded file(0) was null');
        return;
      }

      const arrayBuffer = await file.arrayBuffer();
      await uploadAndSetArrayBuffer(arrayBuffer);

    } catch (e) {
      // Not fatal
    } finally {
      setLoadingArrayBuffer(false);
    }
  }, [setLoadingArrayBuffer, uploadAndSetArrayBuffer]);

  const onInputChangeRef = useSyncedDataRef(onInputChange);
  const input = React.useMemo(() => (
    <StyledInput
      ref={inputRef}
      type="file"
      accept={inputAccept}
      onChange={(...args: any) => {
        args[0].persist();
        // Keep this lazy as .current might change
        return onInputChangeRef.current(...args);
      }}
    />
  ), []);

  return {
    input,
    onTriggerUpload,
    setLoadingArrayBuffer,
    isLoading: loadingArrayBuffer,
    uploadAndSetArrayBuffer,
  };
}

const StyledInput = styled.input`
  display: none;
`;
