import * as _ from 'lodash';
import {
  TObjList,
  UUID_VERSION_GLOBAL_DEFAULT,
} from '../../db/DbDefs';
import { ErrorHandler } from './ErrorHandler';
import { KnownError } from './KnownError';
import {
  TKnownError,
  TResolvedError,
  TResolvedValidationError,
  TValidationError,
  TValidator,
} from './ErrorTypes';
import {
  enumToArray,
  isNotEmpty,
  isNotNull,
  pushAll,
} from '../HelperFunctions';
import validatorLib from 'validator';
import { ELocale } from '../../locale/Locale';
import {LanguageCodes, TLanguage} from '../../locale/Languages';

type TValidatorParamsStrOfLen = {
  min?: number;
  max?: number;
};

type TValidatorParamsNumInRange = {
  min?: number;
  max?: number;
};

type TValidatorParamsArrInRange = {
  min?: number;
  max?: number;
};

type TValidatorParamsValidator<T> = {
  validator: TValidator<T>;
};

type TValidatorParamsOneOf<T> = {
  allowedValues: T[];
};

type TValidatorParamsPathedObj = Partial<{
  [path: string]: TValidator<any>;
}>;

export class ValidatorBase {
  use(validator: TValidator<any> | undefined) {
    return (val: any, context: any) => {
      if (!validator) {
        throw new Error(['Validators', 'use', 'Supplied empty validator to use'].join(', '));
      }

      const res = validator(val);
      return res.length <= 0;
    };
  }

  make<KE extends TKnownError<MA>, MA = any>(knownError: KE, args?: MA): TResolvedError<TValidationError<MA>, MA> {
    const validationError = {
      ...knownError,
      trace: {},
    };

    return ErrorHandler.resolve(validationError as any, args);
  }

  readonly expectSet = <T>(value: T) => {
    if (!isNotNull(value)) {
      return [
        this.make(KnownError.validationFieldNotSet),
      ];
    }
    return [];
  };

  readonly expectNotEmpty = <T>(value: T) => {
    if (isNotEmpty(value)) {
      return [
        this.make(KnownError.validationInvalidLength),
      ];
    }
    return [];
  };

  readonly expectUUID = <T>(value: T) => {
    if (!validatorLib.isUUID(value as any, UUID_VERSION_GLOBAL_DEFAULT)) {
      return [
        this.make(KnownError.validationInvalidUUID),
      ];
    }
    return [];
  };

  readonly expectStr = <T>(value: T) => {
    if (!_.isString(value)) {
      return [
        this.make(KnownError.validationInvalidType),
      ];
    }
    return [];
  };

  readonly expectNum = <T>(value: T) => {
    if (!_.isNumber(value)) {
      return [
        this.make(KnownError.validationInvalidType),
      ];
    }
    return [];
  };

  readonly expectBool = <T>(value: T) => {
    if (!_.isBoolean(value)) {
      return [
        this.make(KnownError.validationInvalidType),
      ];
    }
    return [];
  };

  readonly expectEmail = <T>(value: T) => {
    if (!validatorLib.isEmail(value as any)) {
      return [
        this.make(KnownError.validationInvalidEmail),
      ];
    }
    return [];
  };

  readonly expectUrl = <T>(value: T) => {
    if (!validatorLib.isURL(value as any)) {
      return [
        this.make(KnownError.validationInvalidUrl),
      ];
    }
    return [];
  };

  readonly expectStrOfLen = <T>({
    min,
    max,
  }: TValidatorParamsStrOfLen) => {
    return (_value: T) => {
      const value = _value as any;
      if ((min && value.length < min) || (max && value.length > max)) {
        return [
          this.make(KnownError.validationInvalidLength, {
            minLen: `${min}`,
            maxLen: `${max}`,
          }),
        ];
      }
      return [];
    };
  };

  readonly expectArrayRange = <T>({
    min,
    max,
  }: TValidatorParamsArrInRange) => {
    return (_value: T[]) => {
      const value = _value as any;
      if ((min && _.size(value) < min) || (max && _.size(value) > max)) {
        return [
          this.make(KnownError.validationInvalidLength, {
            minLen: `${min}`,
            maxLen: `${max}`,
          }),
        ];
      }
      return [];
    };
  };

  readonly expectValidator = <T>({ validator }: TValidatorParamsValidator<T>) => {
    return (value: T) => {
      if (validator(value).length > 0) {
        return [
          this.make(KnownError.validationInvalidType),
        ];
      }
      return [];
    };
  };

  readonly expectOneValueOf = <T>({ allowedValues }: TValidatorParamsOneOf<T>) => {
    return (value: T) => {
      if (!allowedValues.includes(value)) {
        return [
          this.make(KnownError.validationInvalidType),
        ];
      }
      return [];
    };
  };

  readonly expectNumInRange = <T>({
    min,
    max,
  }: TValidatorParamsNumInRange) => {
    return (_value: T) => {
      const value = _value as any;
      if ((min && value < min) || (max && value > max)) {
        return [
          this.make(KnownError.validationInvalidNumRange, {
            minLen: `${min}`,
            maxLen: `${max}`,
          }),
        ];
      }
      return [];
    };
  };

  readonly expectPathedObj = <T>(pathedObj: TValidatorParamsPathedObj) => {
    return (value: T) => {
      const validationError = this.make(KnownError.validationInvalidType);

      _.forOwn(pathedObj, (validator, path) => {
        if (!validator) {
          return;
        }

        const res = validator(_.get(value, path));
        if (_.size(res) > 0) {
          validationError.knownError.trace[path] = res;
        }
      });

      if (_.size(validationError.knownError.trace) > 0) {
        return [validationError];
      }

      return [];
    };
  };

  private readonly supportedLocales = enumToArray<ELocale>(ELocale);

  readonly expectSupportedLocale = this.expectOneValueOf<ELocale>({ allowedValues: this.supportedLocales });
  readonly expectLanguage = this.expectOneValueOf<TLanguage>({ allowedValues: LanguageCodes });

  /**
   * Helpers
   */

  readonly applyAll = <T>(validators: TValidator<T>[]) => {
    return (value: T) => {
      return validators.reduce((acc, validator) => {
        return acc.concat(validator(value));
      }, [] as TResolvedValidationError<any>[]);
    };
  };

  readonly applyOne = <T>(validators: TValidator<T>[]) => {
    return (value: T) => {
      const finalRes = [] as TResolvedValidationError<any>[];
      for (let i = 0; i < validators.length; i++) {
        const res = validators[i](value);

        // At least one validator passed
        if (res.length <= 0) {
          return [];
        }

        pushAll(finalRes, res);
      }

      return finalRes;
    };
  };

  readonly applyToArr = <T>(validator: TValidator<T>) => {
    return (values: T[]) => {
      return values.reduce((acc, value) => {
        return acc.concat(validator(value));
      }, [] as TResolvedValidationError<any>[]);
    };
  };

  readonly applyToObjListAsArr = <T>(validator: TValidator<T>) => {
    return (objList: TObjList<any, T>) => {
      return _.keys(objList).reduce((acc, key) => {
        const value = objList[key];
        return acc.concat(validator(value));
      }, [] as TResolvedValidationError<any>[]);
    };
  };
}

export const Validator = new ValidatorBase();
