import { HostBuilder } from '../../../../core/src/models/db/host/HostBuilder';
import { Log } from '../../config/Instance';
import { actions } from '../Actions';
import {
  all,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { sagaFetchArchivedHostMessages } from './sagaFetchArchivedHostMessages';
import { sagaCheckBatchingStatus } from './sagaCheckBatchingStatus';
import { sagaHostMessageNotifications } from './sagaHostMessageNotifications';
import { sagaHostMessageSuggestions } from './sagaHostMessageSuggestions';
import { sagaSpawnAndKeepAlive } from '../../../../lib-react/src/sagas/sagaSpawnAndKeepAlive';
import { sagaSubscribeToGuestSessions } from './sagaSubscribeToGuestSessions';
import { sagaSubscribeToHost } from './sagaSubscribeToHost';
import { sagaSubscribeToHostMessages } from './sagaSubscribeToHostMessages';

export const sagasHostRootSubscriptionKey = 'sagasHostRoot';

export function* sagasHostRoot() {
  yield all([
    yield fork(onHostIdChangeSaga),
  ]);
}

function* onHostIdChangeSaga() {
  yield takeLatest(
    [
      getType(actions.actionTypeAppSetHostId),
    ],
    function* (params: ReturnType<typeof actions.actionTypeAppSetHostId>) {
      const { hostId } = params.payload;
      if (hostId == null) {
        yield put(actions.actionTypeHostSetHost({ hostData: undefined }));
        return;
      }

      const hostExists = yield HostBuilder.dataExistsById({ hostId });
      if (!hostExists) {
        Log.v('sagasHostRoot', 'onHostIdChangeSaga', `host doesn't exist ${hostId}`);
        return;
      }

      const hostData = yield HostBuilder.remoteDataFromPath({ hostId });
      if (!hostData) {
        Log.wtf('sagasHostRoot', 'onHostIdChangeSaga', `host doesn't exist ${hostId}`);
        return;
      }

      // Subscribe to related items
      yield all([
        yield fork(sagaSubscribeToHost, { hostId }),
        yield fork(sagaSubscribeToGuestSessions, { hostId }),
        yield fork(sagaSubscribeToHostMessages, { hostData }),
        yield fork(sagaFetchArchivedHostMessages, { hostId }),
        yield fork(sagaHostMessageSuggestions),
        yield fork(sagaHostMessageNotifications),
        yield sagaSpawnAndKeepAlive('sagaGuestSessionsCheckBatchingStatus', sagaCheckBatchingStatus),
      ]);
    },
  );
}

