import * as React from 'react';
import { FullScreenModals } from './components/modals/FullScreenModals';
import { GlobalErrorBoundary } from './components/errors/GlobalErrorBoundary';
import { Hist } from './lib/navigation/History';
import { MaxScreenDiv } from './components/MaxHeightDiv';
import { Persistor, StoreProvider, TState } from './redux/Store';
import { Re2direct } from './components/router/Re2direct';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { routeDef2ToRoutes, Routes } from './config/Routes';
import { SnackbarProvider } from './lib/snackbars/SnackbarsRefListener';
import { StyledComponentsTheme } from './lib-components/theme/StyledComponentsTheme';
import { Theme } from './config/Theme';
import { useSelector } from 'react-redux';
import { BrowserContextsProvider } from '../../lib-react/src/contexts-browser/BrowserContextsProvider';
import { useSingleSignOnCheck } from '../../lib-react/src/contexts-browser/useSingleSignOnCheck';
import { useContinueUrls } from '../../lib-react/src/contexts-browser/useContinueUrls';

export type TChildrenProps = {
  children: React.ReactNode;
};

const HistoryListener = ({ children }: TChildrenProps) => {
  const history = useHistory();
  React.useEffect(() => {
    Hist.setParams(history);
  }, [history]);
  return (
    <>
      {children}
    </>
  );
};

const AuthenticatorSwitch = () => {
  const user = useSelector((state: TState) => state.app.user);

  useSingleSignOnCheck({
    onRedirectToAuthScreen(): void {
      // todo, redirect to auth screen
      // navigatorRef.current.routeReplace({
      //   route: RoutesLanding.Login.name,
      //   hash: RoutesLanding.Login.hashes.top,
      //   search: { redirectUrl: window.location.href },
      // });
    },
  });
  useContinueUrls({});

  return (
    <MaxScreenDiv>
      <FullScreenModals/>
      <Switch>

        {/* All screen routes */}
        {routeDef2ToRoutes([
          Routes.ScreenHelpAllergens,
        ])}

        {/* Authenticated routes */}
        {/* Redirect to host overview whenever we're logged in */}
        {user && (
          <>
            {routeDef2ToRoutes([
              Routes.NavigatorHost,
            ])}
            <Route exact path="/">
              <Re2direct
                route={Routes.NavigatorHost}
                params={{ hostId: user.uid }}
              />
            </Route>
          </>
        )}

        {/* Unauthenticated routes */}
        {/* Redirect to login whenever we're logged out */}
        {!user && (
          <>
            {routeDef2ToRoutes([
              Routes.ScreenLogin,
            ])}
            <Route path="/hosts/*">
              {!user && (
                <Re2direct
                  route={Routes.ScreenLogin}
                  params={{}}
                />
              )}
            </Route>
          </>
        )}

        {/* Redirect to 404 on no-match */}
        <Route exact path="*">
          {user && (
            <Re2direct
              route={Routes.ScreenLogin}
              params={{ hostId: user.uid }}
            />
          )}
        </Route>
      </Switch>
    </MaxScreenDiv>
  );
};

export const App = () => (
  <BrowserContextsProvider window={window}>
    <StoreProvider>
      <StyledComponentsTheme.Provider initialTheme={Theme}>
        <SnackbarProvider>
          <GlobalErrorBoundary>
            <Persistor loadingComp={null}>
              <div className="App">
                <Router>
                  <HistoryListener>
                    <AuthenticatorSwitch/>
                  </HistoryListener>
                </Router>
              </div>
            </Persistor>
          </GlobalErrorBoundary>
        </SnackbarProvider>
      </StyledComponentsTheme.Provider>
    </StoreProvider>
  </BrowserContextsProvider>
);

