import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import styled from 'styled-components';

export type TPopoverTopWithClickAwayProps = {
  componentId: string;
  popoverOpen: boolean;
  closePopover: () => void;
  openPopover: () => void;
  popoverContent: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  placement?: 'top' | 'bottom';
};

export function PopoverWithClickAway({
  componentId,
  popoverOpen,
  closePopover,
  openPopover,
  popoverContent,
  children,
  className,
  placement = 'top',
}: TPopoverTopWithClickAwayProps) {
  const onPopoverOpen = React.useCallback((event) => {
    event.stopPropagation();
    openPopover();
  }, [openPopover]);
  return (
    <OverlayTrigger
      placement={placement}
      show={popoverOpen}
      overlay={(
        <Popover
          id={componentId}>
          <ClickAwayListener
            onClickAway={closePopover}>
            <PopoverContent>
              {popoverContent}
            </PopoverContent>
          </ClickAwayListener>
        </Popover>
      )}>
      <Root
        onClick={onPopoverOpen}
        className={className}>
        {children}
      </Root>
    </OverlayTrigger>
  );
}

const Root = styled.div`
  cursor: pointer;
`;

const PopoverContent = styled.div`
`;
