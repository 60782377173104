import {
  ESnackbarVariant,
  TSnackbar,
} from './Snackbars';

const defaultProps = {
  persist: false,
  autoHideDuration: 3500,
};

type TSnackbarOptionsGlobalErrorParams = {
  //
};

class SnackbarOptionsBase {
  globalError(params: TSnackbarOptionsGlobalErrorParams = {}): TSnackbar['options'] {
    return {
      ...defaultProps,
      variant: ESnackbarVariant.error,
      preventDuplicate: true,
    };
  }

  globalSuccess(params: TSnackbarOptionsGlobalErrorParams = {}): TSnackbar['options'] {
    return {
      ...defaultProps,
      variant: ESnackbarVariant.success,
      preventDuplicate: false,
      persist: false,
      autoHideDuration: 3500,
    };
  }
}

export const SnackbarOptions = new SnackbarOptionsBase();
