import * as React from 'react';
import styled from 'styled-components';
import PacmanLoader from "react-spinners/PacmanLoader";
import { Theme } from "../../config/Theme";

type TSquareButtonProps = {
  title: React.ReactNode | string;
  backgroundColor?: string;
  borderColor?: string;
  onClick: () => void;
  textColor?: string;
  bold?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  disabled?: boolean;
};

export const SquareButton = ({
  title,
  borderColor,
  onClick,
  className,
  bold = true,
  type = 'button',
  loading = false,
  disabled = false,
}: TSquareButtonProps) => {
  return (
    <StyledButton
      className={className}
      type={type}
      onClick={onClick}
      borderColor={borderColor}
      disabled={disabled}
      bold={bold}
    >
      {loading && (
        <StyledLoaderCont>
          <PacmanLoader
            loading={true}
            color={Theme.col.white}
            size={10}
            speedMultiplier={1.5}
          />
        </StyledLoaderCont>
      )}
      {!loading && (
        <>
          {title}
        </>
      )}
    </StyledButton>
  );
};

type TStyledButton = {
  borderColor?: string;
  bold?: boolean;
  style?: any;
  disabled?: boolean;
};

const StyledButton = styled.button<TStyledButton>`
  background-color: ${({ theme }) => theme.t.col.red};
  border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : '0')};
  color: ${({ theme }) => theme.t.col.white};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
  font-size: 11px;
  font-weight: ${({ bold }) => (bold ? '600' : undefined)};
  height: 50px;
  letter-spacing: 0.5px;
  opacity: ${({ disabled }) => (!disabled ? '1' : '0.7')};
  outline: 0;
  text-transform: uppercase;
  width: 100%;
`;

const StyledLoaderCont = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
