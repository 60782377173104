import * as React from 'react';
import { useSyncedDataRef } from '../../../lib-react/src/hooks/useSyncedDataRef';

export function useKeyListener(key: string, cb: () => void) {
  const onKeyPressRef = useSyncedDataRef(cb);

  const onKeyPress = React.useCallback((event: KeyboardEvent) => {
    event.key === key && onKeyPressRef.current();
  }, []);

  React.useEffect(() => {
    document.addEventListener('keypress', onKeyPress);
    return () => {
      document.removeEventListener('keypress', onKeyPress);
    };
  }, []);
}

export function useEnterKeyListener(cb: () => void) {
  return useKeyListener('Enter', cb);
}
