import {
  all,
  fork,
} from 'redux-saga/effects';
import { sagasHostRoot } from './sagas/sagasHostRoot';
import { sagaForkAndKeepAlive } from '../../../lib-react/src/sagas/sagaForkAndKeepAlive';
import { sagaVerifyUserSignIn } from './sagas/sagaVerifyUserSignIn';

export function* rootSaga() {
  yield all([
    yield fork(sagasHostRoot),
    yield sagaForkAndKeepAlive('sagaVerifyUserSignIn', sagaVerifyUserSignIn),
  ]);
}
