import { URLToolbox } from '../../../define/url-toolbox/URLToolbox';
import { addIfDefined2 } from '../../../HelperFunctions';

export const URLParamsContinueURL = {
  in: {
    redirectUrl: 'redirectUrl',
    continueUrl: 'continueUrl',
  } as {
    redirectUrl?: string | undefined;
    continueUrl?: string | undefined;
  },
  out: {
    redirectUrl: 'redirectUrl',
    continueUrl: 'continueUrl',
  } as {
    redirectUrl?: string | undefined;
    continueUrl?: string | undefined;
  },
  map(p: any) {
    return { ...addIfDefined2('redirectUrl', p.redirectUrl, () => URLToolbox.safeEncode(p.redirectUrl)) };
  },
  invert(p: any) {
    return { ...addIfDefined2('redirectUrl', p.redirectUrl, () => URLToolbox.safeDecode(p.redirectUrl)) };
  },
};
