import * as React from 'react';
import styled from 'styled-components';

export type TNavbarLinkBaseProps = {
  title: React.ReactNode;
  onClick: () => void;
  className?: string;
  underline?: React.ReactNode;
};

export const NavbarLinkBase = ({
  title,
  onClick,
  className,
  underline,
}: TNavbarLinkBaseProps) => {
  return (
    <Container className={className}>
      <StyledLink onClick={onClick}>
        {title}
      </StyledLink>
      {underline}
    </Container>
  );
};

const underlineMarginTop = 8;
const underlineHeight = 1;
const marginTop = underlineMarginTop + underlineHeight;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: ${marginTop}px;
  min-width: 75px;
  padding: 0 10px;
  text-align: center;
`;

const StyledLink = styled.span`
  color: inherit;
  cursor: pointer;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const Underline = styled.div`
  height: ${underlineHeight}px;
  margin-top: ${underlineMarginTop}px;
  width: 10px;
`;
