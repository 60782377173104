import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';
import { Process } from '../../../core/src/config/Process';
import { Strings } from '../../../core/src/locale/Strings';
import { useBindDrawableContentOffset } from '../hooks/useBindDrawableContentOffset';
import { ELocale } from '../../../core/src/locale/Locale';

export type TFooterProps = {
  locale: ELocale;
  onHelpClick?: () => void;
};

export const Footer = ({ locale, onHelpClick }: TFooterProps) => {
  const currentYear = new Date().getFullYear();
  useBindDrawableContentOffset(footerHeight);
  return (
    <Root>
      <Content>
        <div>
          A service by <SpanBold>{Strings.appName()(locale).toLowerCase()}</SpanBold> &copy; {currentYear}
          {!_.isEmpty(Process.env.COMMIT_HASH) ? ` (${Process.env.COMMIT_HASH})` : ''}
        </div>
        <div>
          <FooterLink onClick={onHelpClick}>
            {Strings.hHelpMsg()(locale)}
          </FooterLink>
        </div>
      </Content>
    </Root>
  );
};

export const footerHeight = 50;

const Root = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const Content = styled.div`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.t.col.lightGray2};
  color: ${({ theme }) => theme.t.col.accentLight};
  display: flex;

  font-size: 11px;
  font-weight: normal;
  height: ${footerHeight}px;
  justify-content: space-between;

  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
`;

const SpanBold = styled.span`
  color: ${({ theme }) => theme.t.col.black};
  font-weight: bold;
`;

const FooterLink = styled.span`
  color: ${({ theme }) => theme.t.col.accentLight};
  text-decoration: underline;
`;
