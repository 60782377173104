import * as React from 'react';
import { Col, Row, } from 'react-bootstrap';
import { EIngredientType } from '../../../../core/src/models/db/ingredient/IngredientTypes';
import { FluidContainer } from '../bootstrap/FluidContainer';
import { Hairline } from '../Hairline';
import {
  LabeledFieldCurrencyInput,
  LabeledFieldDualOptionInput,
  LabeledFieldHTMLArea,
  LabeledFieldImageFileInput,
  LabeledFieldMenuItemTagInput,
  LabeledFieldTextInput,
} from '../form/LabeledFields';
import { MenuItemEditAllergens } from '../form/duplexInputListResources/MenuItemEditAllergens';
import { MenuItemEditAssignedStation } from './MenuItemEditAssignedStation';
import { MenuItemEditIngredients } from '../form/duplexInputListResources/MenuItemEditIngredients';
import { SquareButton } from '../Button/SquareButton';
import { Strings } from '../../../../core/src/locale/Strings';
import { TMenuItemEditFormParams, useMenuItemEditFormHandler, } from '../../lib/forms/MenuItemEditForm';
import { colMd } from '../bootstrap/Bootstrap';
import { formatWithLocale } from '../../lib/Format';
import styled from 'styled-components';
import { ESubscriptionFeatureFlags } from '../../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';
import {
  THostPublicSubscription
} from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { FeatureFlagOverlay } from '../featureFlagOverlay/FeatureFlagOverlay';
import { ELocale } from '../../../../core/src/locale/Locale';
import { DEFAULT_CURRENCY } from '../../../../core/src/locale/Currencies';

export type TMenuItemEditViewProps = TMenuItemEditFormParams & {
  hostPublicSubscription: THostPublicSubscription;
  hostData: THost;
  locale: ELocale;
  onHelpClick: () => void;
};

export const MenuItemEditView = (props: TMenuItemEditViewProps) => {
  const {
    locale,
    language,
    hostData,
    hostPublicSubscription,
    onHelpClick,
  } = props;
  const formHandler = useMenuItemEditFormHandler(props);

  const withImagePicker = React.useMemo(() => ({
    initialSearch: formHandler.fields.name.value
  }), [formHandler.fields.name.value]);
  return (
    <Root>
      <FluidContainer>
        <StyledRow>
          <StyledCol md={12} lg={6}>
            <LabeledFieldTextInput
              isRequired
              value={formHandler.fields.name.value}
              onChange={formHandler.fields.name.setValue}
              error={formHandler.fields.name.errorStr}
              label={formatWithLocale(Strings.hItemName(), locale, language)}
              placeholder={Strings.hItemNamePhd()(locale)}
            />
          </StyledCol>
          <StyledCol md={12} lg={6}>
            <LabeledFieldCurrencyInput
              locale={locale}
              currency={hostData.currency ?? DEFAULT_CURRENCY}
              isRequired
              value={formHandler.fields.price.valueStr}
              onChange={formHandler.fields.price.setValue}
              error={formHandler.fields.price.errorStr}
              placeholder={`${formHandler.fields.price.initialValue}`}
              label={Strings.hItemPrice()(locale)}
            />
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol md={12} lg={6}>
            <LabeledFieldHTMLArea
              isRequired={formHandler.fields.description.isRequired}
              label={formatWithLocale(Strings.hMenuDescription(), locale, language)}
              value={formHandler.fields.description.value}
              onChange={formHandler.fields.description.setValue}
              error={formHandler.fields.description.errorStr}
              placeholder={Strings.hItemInfoPhd()(locale)}
            />
          </StyledCol>
          <StyledCol md={12} lg={6}>
            <FeatureFlagOverlay
              locale={locale}
              uniqueId={`ff-MenuItemEditView-AssignedStation`}
              hostPublicSubscription={hostPublicSubscription}
              blockCursor
              requiredFeatureList={[
                ESubscriptionFeatureFlags.guestOrders,
              ]}
            >
              <MenuItemEditAssignedStation
                label={Strings.hItemStation()(locale)}
                hostData={hostData}
                {...formHandler.fields.stationId}
              />
            </FeatureFlagOverlay>
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol md={12} lg={6}>
            <LabeledFieldImageFileInput
              locale={locale}
              value={formHandler.fields.itemImg.value}
              onChange={formHandler.fields.itemImg.setValue}
              placeholder={Strings.hProfileLogoPhd()(locale)}
              label={Strings.hItemImg()(locale)}
              caption={Strings.hProfileCoverImgHint()(locale)}
              withImagePicker={withImagePicker}
            />
          </StyledCol>
          <StyledCol md={12} lg={6}>
            <LabeledFieldDualOptionInput
              label={Strings.hItemShareOpt()(locale)}
              value={formHandler.fields.shareable.value}
              onChange={formHandler.fields.shareable.setValue}
              leftTitle={Strings.hItemYesBtn()(locale)}
              rightTitle={Strings.hItemNoBtn()(locale)}
              leftValue={true}
              rightValue={false}
            />
          </StyledCol>
        </StyledRow>
        <StyledHairline/>
        <StyledRow>
          <StyledCol md={12} lg={6}>
            <MenuItemEditAllergens
              locale={locale}
              label={Strings.hItemAllergens()(locale)}
              onHelpClick={onHelpClick}
              {...formHandler.fields.allergens}
            />
          </StyledCol>
          <StyledCol md={12} lg={6}>
            <LabeledFieldMenuItemTagInput
              label={Strings.hItemTags()(locale)}
              value={formHandler.fields.tags.value}
              onChange={formHandler.fields.tags.setValue}
            />
          </StyledCol>
        </StyledRow>
        <StyledHairline/>
        <StyledRow>
          <StyledCol md={12} lg={6}>
            <MenuItemEditIngredients
              locale={locale}
              hostCurrency={hostData.currency ?? DEFAULT_CURRENCY}
              label={formatWithLocale(Strings.hItemRemOpt(), locale, language)}
              newItemMainPlaceholder={Strings.hItemOptPhd()(locale)}
              ingredientType={EIngredientType.REMOVABLE}
              ingredientDefaultLocale={props.menuItemData.defaultLocale}
              {...formHandler.fields.extraRemovables}
            />
          </StyledCol>
          <StyledCol md={12} lg={6}>
            <MenuItemEditIngredients
              locale={locale}
              hostCurrency={hostData.currency ?? DEFAULT_CURRENCY}
              label={formatWithLocale(Strings.hItemAddOpt(), locale, language)}
              newItemMainPlaceholder={Strings.hItemOptPhd()(locale)}
              ingredientType={EIngredientType.ADDABLE}
              ingredientDefaultLocale={props.menuItemData.defaultLocale}
              {...formHandler.fields.extraAddables}
            />
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol md={12} lg={6}>
            <MenuItemEditIngredients
              locale={locale}
              hostCurrency={hostData.currency ?? DEFAULT_CURRENCY}
              label={formatWithLocale(Strings.hItemXtraOpt(), locale, language)}
              newItemMainPlaceholder={Strings.hItemXtraOptPhd()(locale)}
              ingredientType={EIngredientType.OPTION}
              ingredientDefaultLocale={props.menuItemData.defaultLocale}
              {...formHandler.fields.extraOptions}
            />
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <SaveSquareButton
              title={Strings.hItemSaveCmd()(locale)}
              onClick={formHandler.onSubmit}
              disabled={formHandler.submitDisabled}
              loading={formHandler.loading}
            />
          </StyledCol>
        </StyledRow>
      </FluidContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${colMd}px;
  width: 100%;
`;

const StyledRow = styled(Row)`
`;

const StyledCol = styled(Col)`
  margin-top: 30px;
`;

const StyledHairline = styled(Hairline)`
  margin-bottom: 10px;
  margin-top: 40px;
`;

const SaveSquareButton = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.red};
  color: ${({ theme }) => theme.t.col.white};
  margin-top: 10px;
  width: 100%;
`;
