import * as React from 'react';
import * as _ from 'lodash';
import {
  TUseFormField,
  useFormField,
} from './useFormField';

function isNumber(value: any) {
  // Number('') === 0
  if (value === '' || value == null) {
    return false;
  }

  return !Number.isNaN(parseFloat(value));
}

function isValidOrClamped(_value: string | undefined, _min?: number, _max?: number) {
  if (!isNumber(_value)) {
    return false;
  }

  const min = isNumber(_min) ? parseFloat(_min as any) : -Infinity;
  const max = isNumber(_max) ? parseFloat(_max as any) : Infinity;
  return _.clamp(parseFloat(_value as string), min, max);
}

export function normalizeNum(def: number, value: string, _min?: number, _max?: number) {
  const falseOrClamped = isValidOrClamped(value, _min, _max);
  const text = falseOrClamped === false ? '' : `${falseOrClamped}`;
  return {
    text,
    num: isNumber(text) ? parseFloat(text) : def,
  };
}

export type TUseFormNumberField = Partial<TUseFormField<number>> & {
  min?: number;
  max?: number;
};

export function useFormNumberField(params: TUseFormNumberField = {}) {
  const initialNumber = params.value != null
    ? params.value
    : 0;

  const {
    value,
    setValue,
    ...formField
  } = useFormField<string>({
    value: `${initialNumber}`,
    validate: (val: string, context: any): boolean => {
      const { num } = normalizeNum(initialNumber, val, params.min, params.max);
      if (params.validate) {
        return params.validate(num, context);
      }
      return true;
    },

    valueToError: (val: string, context: any) => {
      const { num } = normalizeNum(initialNumber, val, params.min, params.max);
      if (params.valueToError) {
        return params.valueToError(num, context);
      }
      return undefined;
    },

    required: (val: string, context: any) => {
      const { num } = normalizeNum(initialNumber, val, params.min, params.max);
      if (_.isFunction(params.required)) {
        return params.required(num, context);
      }

      if (params.required) {
        return params.required;
      }

      return false;
    },

    onValueChange(val: string): void | Promise<void> {
      if (_.isFunction(params.onValueChange)) {
        const parsed = parseFloat(val);
        const isNum = !Number.isNaN(parsed);
        if (isNum) {
          return params.onValueChange(parsed);
        }
      }
      return undefined;
    },

    checkForErrorOnRequired: params.checkForErrorOnRequired,
    resetOnError: params.resetOnError,
    syncingActive: params.syncingActive,
    throttleSyncMs: params.throttleSyncMs,
  });

  const handleChange = React.useCallback((val: string) => {
    const { text } = normalizeNum(initialNumber, val, params.min, params.max);
    setValue(text);
  }, [setValue, initialNumber, params.min, params.max]);

  const { num } = normalizeNum(initialNumber, value, params.min, params.max);
  return {
    ...formField,
    value: num,
    valueStr: value,
    setValue: handleChange,
  };
}
