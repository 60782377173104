import * as React from 'react';
import * as _ from 'lodash';
import { EFullScreenModal, TFullScreenModalProps, } from '../../redux/FullScreenModal';
import { FullScreenModal } from './FullScreenModal';
import { ELocale } from '../../../../core/src/locale/Locale';
import { ELANGUAGE_GLOBAL_DEFAULT, Languages, TLanguage } from '../../../../core/src/locale/Languages';
import { menuLocalizedFields, TMenu, TMenuId } from '../../../../core/src/models/db/menu/MenuTypes';
import { menuItemLocalizedFields } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { Menu } from '../../../../core/src/models/db/menu/Menu';
import { HostBuilder } from '../../../../core/src/models/db/host/HostBuilder';
import { FIELD_PATH } from '../../../../core/src/db/DbDefs';
import { THost, THostId } from '../../../../core/src/models/db/host/HostTypes';
import { useToggle } from '../../../../lib-react/src/hooks/useToggle';
import { useSelector } from "react-redux";
import { TState } from "../../redux/Store";
import { Log } from "../../config/Instance";
import styled from "styled-components";
import { AppAlacarteApiAccess } from "../../lib/api/AppAlacarteApiAccess";
import { Snackbars } from "../../lib/snackbars/Snackbars";
import { SnackbarOptions } from "../../lib/snackbars/SnackbarOptions";
import PacmanLoader from "react-spinners/PacmanLoader";
import { Theme } from "../../config/Theme";
import { Strings } from '../../../../core/src/locale/Strings';

export function useData(hostId: THostId, menuId: TMenuId) {
  const hostData = useSelector((state: TState) => state.host.hostData) as THost;
  const menuData = Menu.findMenuById(hostData.menu, menuId) as TMenu;
  return {
    hostData,
    menuData,
  }
}

function langStr(lang?: TLanguage) {
  const l = Languages[lang || ELANGUAGE_GLOBAL_DEFAULT];
  return l.language;
}

function langStrWithEmoji(lang?: TLanguage) {
  const l = Languages[lang || ELANGUAGE_GLOBAL_DEFAULT];
  return [
    l.language,
    l.emoji,
  ].join(' ');
}

type TProps = TFullScreenModalProps[EFullScreenModal.menuTranslator] & {
  locale: ELocale;
  onDismissClick: () => void;
  addedLanguages: string[]
};


export const MenuTranslatorModal = ({
                                      locale,
                                      hostId,
                                      menuId,
                                      addedLanguages,
                                      onDismissClick,
                                    }: TProps) => {
  const { menuData, hostData } = useData(hostId, menuId);
  const sourceLanguage = menuData.defaultLocale;

  const [loading, , setLoadingTrue, setLoadingFalse] = useToggle(false);

  const onTranslate = React.useCallback(async () => {
    setLoadingTrue();
    try {
      function mapMenu(language: TLanguage, menu: TMenu) {
        const mappedMenu = {
          ...['description'].reduce((acc, field) => {
            acc[`${menu[FIELD_PATH]}/${language}/${field}`] = menu[field];
            return acc;
          }, {}),

          ...Menu.menuItemsInMenu(menu, hostData).reduce((acc, menuItem) => {
            ['description'].forEach((field) => {
              acc[`${menuItem[FIELD_PATH]}/${language}/${field}`] = menuItem[field];
            })
            return acc;
          }, {}),

          ...Object.values(menu?.subMenus ?? {}).reduce((acc, subMenu) => {
            Object.assign(acc, mapMenu(language, subMenu));
            return acc;
          }, {}),
        };

        const onlyNotEmpty = _.pickBy(mappedMenu, (strToTranslate) => {
          return !_.isEmpty(strToTranslate);
        })

        const rebaseKeys = _.mapKeys(onlyNotEmpty, (value, key) => {
          return key.replace(hostData[FIELD_PATH], '');
        });

        return rebaseKeys as Record<string, string>;
      }

      const targetLanguage = addedLanguages[0];
      const data = mapMenu(targetLanguage, menuData);

      // Map data
      const pathsByIndex = {} as Record<number, string>;
      const valuesByIndex = [] as string[];
      let index = 0;
      for (const path in data) {
        pathsByIndex[index] = path;
        valuesByIndex.push(data[path])
        index++;
      }

      const { translations } = await AppAlacarteApiAccess.userTranslateText.request({
        body: {
          contents: valuesByIndex,
          mimeType: 'text/plain',
          sourceLanguageCode: sourceLanguage,
          targetLanguageCode: targetLanguage
        },
      });

      // Reapply translations
      const updates = _.cloneDeep(data);
      for (let i = 0; i < translations.length; i++) {
        updates[pathsByIndex[i]] = translations[i].translatedText;
      }

      await HostBuilder
        .ref(HostBuilder.getPathBuilderParamsFromDataPath(hostData))
        .update(updates);

      onDismissClick();

      Snackbars.enqueueSnackbar({
        content: Strings.menuTranslatedSuccess()(locale),
        options: { ...SnackbarOptions.globalSuccess({}) },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      });
    } catch (e) {
      Log.e(
        'MenuTranslatorModal',
        'onTranslate',
        `failed with ${e?.message}`,
      )

      Snackbars.enqueueSnackbar({
        content: Strings.menuTranslatedFailure()(locale),
        options: { ...SnackbarOptions.globalError({}) },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      });
    } finally {
      setLoadingFalse();
    }
  }, [menuData, hostData, sourceLanguage, setLoadingTrue]);


  const addedLanguagesStr = addedLanguages.map(langStr).join(', ');
  const defaultLanguageStr = langStrWithEmoji(sourceLanguage);
  return (
    <FullScreenModal
      title={Strings.menuInfoContent({addedLanguagesStr})(locale)}
      bodyNode={(
        <BodyNode>
          <ParagraphText>
            {loading ? (
              <ParagraphText>
                {Strings.menuInfoLoading()(locale)}
              </ParagraphText>
            ) : (
              <ParagraphText>
                {Strings.menuInfoContentQuestion()(locale)}
              </ParagraphText>
            )}
          </ParagraphText>
          <ContentNode>
            {loading ? (
              <Loader>
                <PacmanLoader
                  loading={true}
                  color={Theme.col.red}
                  size={14}
                  speedMultiplier={1.5}
                />
              </Loader>
            ) : (
              <DefaultLang>{defaultLanguageStr}</DefaultLang>
            )}
          </ContentNode>
        </BodyNode>
      )}
      leftButtonText={Strings.menuInfoTranslationConfirm()(locale)}
      leftButtonOnClick={!loading ? onTranslate : _.noop}
      rightButtonText={Strings.menuInfoTranslationCancel()(locale)}
      rightButtonOnClick={!loading ? onDismissClick : _.noop}
      onDismissClick={!loading ? onDismissClick : _.noop}
    />
  );
};

const BodyNode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
`;

const ContentNode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 54px;
  margin-bottom: 16px;
`;

const Loader = styled.div`
  margin-top: 12px;
`;

const DefaultLang = styled.div`
  font-weight: bold;
  font-size: 22px;
`;

const ParagraphText = styled.div`
  color: ${({ theme }) => theme.t.col.lightDark};
  font-size: 15px;
  line-height: 20px;
  text-align: center;
`;
