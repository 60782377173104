import * as _ from 'lodash';

export class RouteParser {
  static routeSplit(baseRoute: string) {
    return _.trim(baseRoute, '/').split('/');
  }

  static baseRouteToOrderedParamNames(baseRoute: string) {
    return _.trim(baseRoute, '/')
      .split('/')
      .filter((pathSection) => pathSection.startsWith(':'))
      .map((pathSection) => pathSection.substring(1));
  }

  static baseRouteToRegexp(baseRoute: string) {
    const split = RouteParser.routeSplit(baseRoute);
    const routeRegexpStr = split.reduce((acc, pathSection) => {
      return pathSection.startsWith(':')
        ? `${acc}\/([^/]+)`
        : `${acc}\/${pathSection}`;
    }, '');
    return new RegExp(routeRegexpStr);
  }

  static buildIsRouteSelected<Params>(baseRoute: string) {
    const routeToParams = RouteParser.buildRouteToParams(baseRoute);
    return (currentLocation: string) => {
      const currentParams = routeToParams(currentLocation);
      return currentParams != null;
    };
  }

  static buildParamsToRoute<Params>(baseRoute: string) {
    const split = RouteParser.routeSplit(baseRoute);
    return (params: Params) => {
      return split.reduce((acc, pathSection) => {
        const subSection = pathSection.startsWith(':')
          ? params[pathSection.replace(/[:?]/g, '')]
          : pathSection;
        return subSection != null
          ? `${acc}/${subSection}`
          : acc;
      }, '');
    };
  }

  static buildRouteToParams<Params>(baseRoute: string) {
    const baseRouteRegexp = RouteParser.baseRouteToRegexp(baseRoute);
    const baseRouteOrderedParamNames = RouteParser.baseRouteToOrderedParamNames(baseRoute);
    return (route: string) => {
      const match = baseRouteRegexp.exec(route);
      if (!match) {
        return null;
      }

      // First item of match is the full string
      match.shift();

      // The route should have the same length
      if (RouteParser.routeSplit(route).length !== RouteParser.routeSplit(baseRoute).length) {
        return null;
      }

      try {
        const paramNames = [...baseRouteOrderedParamNames];
        return match.reduce((params, nextParamName) => {
          params[paramNames[0]] = nextParamName;
          paramNames.shift();
          return params;
        }, {} as Params);
      } catch (e) {
        // Route matched but still wasn't correct
        return null;
      }
    };
  }
}

