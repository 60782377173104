import * as React from 'react';
import * as _ from 'lodash';
import {
  TPlaceDetails,
  useGetPlaceInfo,
} from '../maps/useGetPlaceInfo';
import {
  TPlaceSuggestion,
  usePlacesSuggestions,
} from '../maps/usePlacesSuggestions';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import styled from 'styled-components';

export type TPlaceResult = TPlaceSuggestion & TPlaceDetails;

type TProfileViewPlaceSuggestionList = {
  visible: boolean;
  bindedSearch: string;
  onSelect: (suggestion: TPlaceResult) => void;
};

export const ProfileViewPlaceSuggestionList = ({ visible, bindedSearch, onSelect }: TProfileViewPlaceSuggestionList) => {
  const suggestions = usePlacesSuggestions({ bindedSearch });
  return (
    <Root visible={visible && suggestions.length > 0}>
      {suggestions.map((suggestion, index) => (
        <SuggestionItem
          key={_.get(suggestion, 'placeId', index)}
          suggestion={suggestion}
          onSelect={onSelect}
        />
      ))}
    </Root>
  );
};

type TSuggestionProps = {
  suggestion: TPlaceSuggestion;
  onSelect: (suggestion: TPlaceResult) => void;
};

const SuggestionItem = ({ suggestion, onSelect }: TSuggestionProps) => {
  const getPlaceDetails = useGetPlaceInfo();
  const onClick = useItemCallback(suggestion, async (data) => {
    const details = await getPlaceDetails(data.placeId);
    return onSelect({
      ...data,
      number: undefined,
      coordinates: undefined,
      ...(details || {}),
    });
  });
  return (
    <Suggestion onClick={onClick}>
      <SuggestionHeader>
        <SuggestionName>{suggestion.name}</SuggestionName>
        <SuggestionPlaceId>{suggestion.placeId}</SuggestionPlaceId>
      </SuggestionHeader>
      <SuggestionAddress>{suggestion.address}</SuggestionAddress>
    </Suggestion>
  );
};

const Root = styled.div<{visible: boolean}>`
  background-color: white;
  border-bottom: 0.5px solid ${({ theme }) => theme.t.col.accentLight};
  border-left: 0.5px solid ${({ theme }) => theme.t.col.accentLight};
  border-right: 0.5px solid ${({ theme }) => theme.t.col.accentLight};
  opacity: ${({ visible }) => (visible ? '1' : '0')};

  & > *:not(:last-child) {
    border-bottom: 0.5px solid ${({ theme }) => theme.t.col.accentLight};
  }
`;

const Suggestion = styled.div`
  cursor: pointer;
  padding: 8px 12px;
`;

const SuggestionHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const SuggestionName = styled.div`
  color: ${({ theme }) => theme.t.col.black};
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 500;
`;

const SuggestionPlaceId = styled.div`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-family: Nunito Sans;
  font-size: 8px;
  text-align: right;
`;

const SuggestionAddress = styled.div`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-family: Nunito Sans;
  font-size: 12px;
  text-align: left;
  width: 100%;
`;
