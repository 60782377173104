import * as React from 'react';
import * as _ from 'lodash';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../core/src/db/DbDefs';
import { FullScreenErrorView } from '../components/fullScreenViews/FullScreenErrorView';
import { FullScreenLoadingView } from '../components/fullScreenViews/FullScreenLoadingView';
import { Hist } from '../lib/navigation/History';
import {
  MaxHeightDiv,
  MaxScreenDiv,
} from '../components/MaxHeightDiv';
import { MenuItemEditView } from '../components/menuItemEdit/MenuItemEditView';
import { Routes } from '../config/Routes';
import { SecondaryNavbar } from '../components/navbars/SecondaryNavbar';
import { Strings } from '../../../core/src/locale/Strings';
import { THostId } from '../../../core/src/models/db/host/HostTypes';
import { TMenuItemId } from '../../../core/src/models/db/menuItem/MenuItemTypes';
import { TMenuItemEditForm } from '../lib/forms/MenuItemEditForm';
import {
  TPropCheck,
  withPropCheckMiddleware,
} from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import {
  getNavParam,
  TScreenNavigationProps,
} from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import { actionMenuItemUpdate } from '../redux/actions/MenuItem';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import { connect } from 'react-redux';
import { formatWithLocale } from '../lib/Format';
import { localize } from '../../../core/src/lib/formatters/LocaleLib';
import styled from 'styled-components';
import { ELocale } from '../../../core/src/locale/Locale';
import { TLanguage } from '../../../core/src/locale/Languages';

export type TScreenHostMenuItemEditorNavParams = {
  hostId: THostId;
  menuItemId: TMenuItemId;
  viewLocale: ELocale;
};

export type TScreenHostMenuItemEditorProps = TScreenNavigationProps<TScreenHostMenuItemEditorNavParams> & {
  //
};

type TScreenHostMenuItemEditorBaseProps = TScreenHostMenuItemEditorProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TScreenHostMenuItemEditorBaseState = {
  //
};

class ScreenHostMenuItemEditorBase extends React.Component<TPropCheck<typeof ScreenHostMenuItemEditorBase>, TScreenHostMenuItemEditorBaseState> {
  static checkProps(props: TScreenHostMenuItemEditorBaseProps) {
    const {
      locale,
      hostData,
      hostPublicSubscription,
      menuItem,
    } = props;

    if (hostData == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    if (hostPublicSubscription == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    if (menuItem == null) {
      const hostId = hostData[FIELD_ID];
      return () => (
        <FullScreenErrorView
          locale={locale}
          message={Strings.gMenuItemNonExistentMsg()(locale)}
          actionText={Strings.hBackToMenuMain()(locale)}
          actionOnClick={() => Hist.replace(Routes.ScreenHostMenuMain, { hostId })}
        />
      );
    }

    return {
      ...props,
      hostData,
      hostPublicSubscription,
      menuItem,
    };
  }

  private onMenuItemSave = async (update: TMenuItemEditForm) => {
    const {
      menuItemUpdate,
      menuItem,
      language,
    } = this.props;
    await menuItemUpdate({
      menuItem,
      update,
      language,
    });
    Hist.goBack();
  };

  private onHelpClick = async () => {
    const { locale } = this.props;
    Hist.push(Routes.ScreenHelpAllergens, { navContext: { goBackText: Strings.hBackToMenuItemEdit({ hMenuItemName: this.getScreenName() })(locale) } });
  };

  render() {
    const {
      locale,
      hostData,
      hostPublicSubscription,
      language,
      menuItem,
    } = this.props;
    return (
      <Root>
        {this.renderSecondaryNavbar()}
        <ScrollableContent>
          <Content>
            <MenuItemEditView
              locale={locale}
              hostData={hostData}
              hostPublicSubscription={hostPublicSubscription}
              menuItemData={menuItem}
              language={language}
              onSubmit={this.onMenuItemSave}
              onHelpClick={this.onHelpClick}
            />
          </Content>
        </ScrollableContent>
      </Root>
    );
  }

  private renderSecondaryNavbar = () => {
    const {
      locale,
      hostData,
    } = this.props;
    return (
      <SecondaryNavbar
        locale={locale}
        hostId={hostData[FIELD_ID]}
        name={this.getScreenName()}
      />
    );
  };

  private getScreenName = () => {
    const { locale, menuItem, language } = this.props;
    const menuItemName = localize(menuItem, FIELD_NAME, language);
    return formatWithLocale(menuItemName, locale, language);
  };
}

function mapStateToProps(state: TState, ownProps: TScreenHostMenuItemEditorProps) {
  const menuItemId = getNavParam(ownProps, 'menuItemId');
  const viewLocale = getNavParam(ownProps, 'viewLocale');
  const hostData = state.host.hostData;
  return {
    menuItemId,
    locale: state.app.locale,
    hostData,
    hostPublicSubscription: state.host.hostPublicSubscription,
    language: viewLocale as TLanguage,
    menuItem: hostData && _.get(hostData, `items.${menuItemId}`),
  };
}

function mapDispatchToProps(dispatch) {
  return { menuItemUpdate: bindActionCreators(actionMenuItemUpdate, dispatch) };
}

export const ScreenHostMenuItemEditor = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(ScreenHostMenuItemEditorBase));

const Root = styled(MaxScreenDiv)`
`;

const ScrollableContent = styled(MaxHeightDiv)`
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 42px;
`;

