import { CIInterface } from '../../config/CIInterface';
import { FIELD_NAME } from '../../db/DbDefs';
import { TRoom } from '../db/room/RoomTypes';
import { TRoomToTableCount } from '../db/host/HostTypes';
import { Validator } from '../../lib/error/Validators';
import { buildObjValidator } from '../../lib/error/lib/buildObjValidator';

const validateRoomName = Validator.applyAll<string>([
  Validator.expectStrOfLen({
    min: CIInterface.validateRoomNameMinLen,
    max: CIInterface.validateRoomNameMaxLen,
  }),
]);

export const validateRoom = buildObjValidator<TRoom>({ [FIELD_NAME]: validateRoomName });

export const validateRoomTableCount = buildObjValidator<TRoomToTableCount>({
  room: validateRoom,
  tableCount: Validator.expectNumInRange({
    min: 0,
    max: 100,
  }),
});
