import * as React from 'react';
import * as _ from 'lodash';
import { GuestSession } from '../../../../core/src/models/db/guestSession/GuestSession';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { TOrderItem, TOrderItemId } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { dbItemCreatedOnSort, dbItemIdableMap } from '../../../../core/src/db/DbLib';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';

export enum EOrderItemStatus {
  NOT_SELECTED = 0,
  SELECTED = 1,
}

export type TOrderItemIdsToStatus = TOrderItemIds<EOrderItemStatus>;

type TOrderItemIds<T = any> = {
  [orderItemId: string]: T;
};

function toggle(status?: EOrderItemStatus): EOrderItemStatus {
  if (status == null) {
    return EOrderItemStatus.NOT_SELECTED;
  }

  return status === EOrderItemStatus.SELECTED
    ? EOrderItemStatus.NOT_SELECTED
    : EOrderItemStatus.SELECTED;
}

function set(ids: TOrderItemIds, status: EOrderItemStatus) {
  return _.mapValues(ids, () => status);
}

function setSelected(ids: TOrderItemIds, selected: boolean) {
  const selectedState = selected ? EOrderItemStatus.SELECTED : EOrderItemStatus.NOT_SELECTED;
  return _.mapValues(ids, () => selectedState);
}

function select(ids: TOrderItemIds) {
  return set(ids, EOrderItemStatus.SELECTED);
}

function filter(ids: TOrderItemIdsToStatus, statuses: EOrderItemStatus[]) {
  return _.chain(ids)
    .map((status, key) => ({
      key,
      status,
    }))
    .filter(({ status }) => statuses.includes(status))
    .reduce((acc, { key, status }) => _.set(acc, key, status), {})
    .value();
}

function filterSelectable(ids: TOrderItemIdsToStatus) {
  return filter(ids, [EOrderItemStatus.SELECTED, EOrderItemStatus.NOT_SELECTED]);
}

function filterSelected(ids: TOrderItemIdsToStatus) {
  return filter(ids, [EOrderItemStatus.SELECTED]);
}

function countSelected(ids: TOrderItemIdsToStatus) {
  return _.size(filter(ids, [EOrderItemStatus.SELECTED]));
}

function buildOrderItemIds(orderItems: TOrderItem[]) {
  return _.chain(orderItems)
    .sort(dbItemCreatedOnSort)
    .map(dbItemIdableMap)
    .keyBy(_.identity)
    .mapValues(() => EOrderItemStatus.NOT_SELECTED)
    .value();
}

export type TUseOrderItemStatusManagerParams = {
  guestSessionData: Pick<TGuestSession, 'orders'>;
  onOrderItemsResetClick: () => void;
};

export function useOrderItemStatusManager({
  guestSessionData,
  onOrderItemsResetClick,
}: TUseOrderItemStatusManagerParams) {
  const orderItems = GuestSession.getAllConfirmedOrderItems(guestSessionData);
  const orderItemIds = buildOrderItemIds(orderItems);
  const [ids, setIds] = React.useState(select(orderItemIds));

  // Sync orderItemIds with state ids since they can change
  const setIdsRef = useSyncedDataRef(setIds);
  const orderItemIdsRef = useSyncedDataRef(orderItemIds);
  React.useEffect(() => {
    setIdsRef.current((stateIds) => {
      const orderItemIdsVal = orderItemIdsRef.current;

      // select new ids by default
      const newIds = _.chain(orderItemIdsVal)
        .keys()
        .filter((orderItemId) => stateIds[orderItemId] === undefined)
        .keyBy()
        .value();

      return {
        ...orderItemIdsVal,
        ...stateIds,
        ...select(newIds),
      };
    });
  }, [_.size(orderItemIds)]);

  const onOrderItemStatusToggle = React.useCallback((id: TOrderItemId) => {
    setIds((stateIds) => ({
      ...stateIds,
      [id]: toggle(stateIds[id]),
    }));
  }, [setIds]);

  const onOrderItemSelectedStatusSetAll = React.useCallback((selected: boolean) => {
    setIds((stateIds) => ({
      ...stateIds,
      ...setSelected(filterSelectable(stateIds), selected),
    }));
  }, [setIds]);

  const onOrderItemSelectedStatusResetAll = React.useCallback(async () => {
    onOrderItemsResetClick();
    setIds((stateIds) => ({
      ...stateIds,
      ...setSelected(stateIds, true),
    }));
  }, [setIds, onOrderItemsResetClick]);

  return {
    guestSessionOrderItems: orderItems,
    orderItemIdsToStatus: ids,
    orderItemSelectedIds: Object.keys(filterSelected(ids)),
    atLeastOneSelected: countSelected(ids) > 0,
    onOrderItemStatusToggle,
    onOrderItemSelectedStatusSetAll,
    onOrderItemSelectedStatusResetAll,
  };
}
