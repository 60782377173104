import * as React from 'react';
import { EIngredientType, TIngredientUpdate, } from '../../../../../core/src/models/db/ingredient/IngredientTypes';
import { FIELD_NAME } from '../../../../../core/src/db/DbDefs';
import { LabeledFieldInputList } from '../LabeledFields';
import { RemoveIcon } from '../../../assets/Assets';
import { TFieldInputListItem } from '../duplexInputList/FieldInputList';
import { buildSecondaryItemInputCurrencyRenderer } from '../duplexInputList/buildSecondaryItemInputCurrencyRenderer';
import { useMenuItemEditFormHandler } from '../../../lib/forms/MenuItemEditForm';
import { ELocale } from '../../../../../core/src/locale/Locale';
import { TLanguage } from '../../../../../core/src/locale/Languages';
import { TCurrencyCode } from '../../../../../core/src/locale/Currencies';

function mapIngredientToListItem(value: TIngredientUpdate): TFieldInputListItem {
  return {
    mainText: value[FIELD_NAME] || '',
    secondaryText: `${value.price}`,
    actionable: true,
    hasSecondary: true,
  };
}

export type TMenuItemEditIngredientsProps = ReturnType<typeof useMenuItemEditFormHandler>['fields']['extraAddables'] & {
  locale: ELocale;
  hostCurrency: TCurrencyCode;
  ingredientType: EIngredientType;
  ingredientDefaultLocale: TLanguage;
  label: string;
  newItemMainPlaceholder: string;
};

export const MenuItemEditIngredients = (props: TMenuItemEditIngredientsProps) => {
  const mapListItemToIngredient = React.useCallback((listItem: TFieldInputListItem): TIngredientUpdate => {
    return {
      [FIELD_NAME]: listItem.mainText,
      price: parseFloat(`${listItem.secondaryText}`),
      type: props.ingredientType,
      defaultLocale: props.ingredientDefaultLocale,
    };
  }, [props.ingredientType, props.ingredientDefaultLocale]);

  const removeIcon = React.useMemo(() => <RemoveIcon/>, []);
  return (
    <LabeledFieldInputList<TIngredientUpdate>
      label={props.label}
      value={props.value}
      onChange={props.setValue}
      mapListItemToValue={mapListItemToIngredient}
      mapValueToListItem={mapIngredientToListItem}
      newItem={props.newItem.value}
      newItemSet={props.newItem.setValue}
      newItemReset={props.newItem.reset}
      newItemMainPlaceholder={props.newItemMainPlaceholder}
      newItemSecondaryPlaceholder={'0.00'}
      listItemAction={removeIcon}
      renderSecondaryInput={buildSecondaryItemInputCurrencyRenderer({
        locale: props.locale,
        currency: props.hostCurrency
      })}
    />
  );
};
