import * as React from 'react';
import * as _ from 'lodash';

export type TPropCheck<Component extends TWithStaticCheckProps<any, any>> =
  Exclude<ReturnType<Component['checkProps']>, (previousFrame: React.ReactNode) => React.ReactNode>;

type TWithStaticCheckProps<BaseProps extends any, CheckedProps> = {
  checkProps: (props: BaseProps) => CheckedProps | (() => React.ReactNode);
};

export function withPropCheckMiddleware<BaseProps extends any, CheckedProps>(Component: React.ComponentType<CheckedProps> & TWithStaticCheckProps<BaseProps, CheckedProps>) {
  return class WithPropCheckClass extends React.Component<BaseProps> {
    render = () => {
      const res = Component.checkProps(this.props);
      if (_.isFunction(res)) {
        return (
          <>
            {res()}
          </>
        );
      }

      return (
        <Component
          {...this.props}
          {...res}
        />
      );
    };
  };
}
