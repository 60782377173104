import * as React from 'react';
import * as _ from 'lodash';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../../core/src/db/DbDefs';
import {
  GuestSessionOverviewSearchResult,
  TSearchResult,
} from './GuestSessionOverviewSearchResult';
import { TMenuItem } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { colLg } from '../bootstrap/Bootstrap';
import styled from 'styled-components';
import { FixedSizeList } from 'react-window';

function mapStringToSearchResult(searchString: string, value: string): TSearchResult[] | null {
  const match = searchString.toUpperCase();
  if (!value.toUpperCase().includes(match)) {
    return null;
  }

  const caseInsensitiveSplit = value.split(new RegExp(`(${match})`, 'gi'));
  return caseInsensitiveSplit.map((fragment) => ({
    text: fragment,
    isMatch: fragment.toUpperCase() === match,
  }));
}

type TGuestSessionOverviewSearchResultsProps = {
  menuItems: TMenuItem[];
  onMenuItemClick: (menuItem: TMenuItem) => any;
  searchString?: string;
  className?: string;
};

export const GuestSessionOverviewSearchResults = ({
  menuItems,
  onMenuItemClick,
  searchString = '',
  className,
}: TGuestSessionOverviewSearchResultsProps) => {
  const results = menuItems.sort((a, b) => {
    if (_.isEmpty(a[FIELD_NAME])) {
      return 1;
    }
    if (_.isEmpty(b[FIELD_NAME])) {
      return -1;
    }
    return a[FIELD_NAME].localeCompare(b[FIELD_NAME]);
  }).reduce((acc, menuItem) => {
    const res = mapStringToSearchResult(searchString, menuItem[FIELD_NAME]);
    res && acc.push({
      searchResult: res,
      item: menuItem,
    });
    return acc;
  }, [] as { searchResult: TSearchResult[]; item: TMenuItem }[]);

  const Row = ({ index, style }) => {
    const item = results[index];
    return (
      <GuestSessionOverviewSearchResultContainer style={style}>
        <GuestSessionOverviewSearchResult
          key={item[FIELD_ID]}
          onClick={onMenuItemClick}
          {...item}
        />
      </GuestSessionOverviewSearchResultContainer>
    );
  };

  return (
    <Root className={className}>
      <ResultsColumnContainer
        itemSize={60}
        height={60 * 6}
        itemCount={results.length}
        width={colLg}
      >
        {Row}
      </ResultsColumnContainer>
    </Root>
  );
};

// Can't import from GuestSessionOverviewSearchbar before initialization
const searchBarTotalHeight = 60 + 40 + 5;

const Root = styled.div`
  background-color: ${({ theme }) => theme.t.col.white};
  display: flex;
  position: absolute;
  top: ${searchBarTotalHeight}px;
  width: ${colLg}px;
`;

const ResultsColumnContainer = styled(FixedSizeList)`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 60vh;
  overflow: scroll;

  ::-webkit-scrollbar {
    -ms-overflow-style: none;
    display: none;
  }
`;

const GuestSessionOverviewSearchResultContainer = styled.div`
  border-bottom: ${({ theme }) => theme.t.col.lightGray2};
  border-bottom-width: 1px;
`;
