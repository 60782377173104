import * as React from 'react';
import { Footer } from '../Footer';
import {
  MaxHeightDiv,
  MaxScreenDiv,
} from '../MaxHeightDiv';
import { NavbarFrame } from '../navbars/NavbarFrame';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

export type TFullScreenServiceFrameProps = {
  locale: ELocale;
  title?: string;
  content?: React.ReactNode;
};

export const FullScreenServiceFrame = ({
  locale,
  title,
  content,
}: TFullScreenServiceFrameProps) => {
  return (
    <Root>
      <NavbarFrame
        title={title || ''}
      />
      <ContentContainer>
        {content}
      </ContentContainer>
      <Footer
        locale={locale}
      />
    </Root>
  );
};

const Root = styled(MaxScreenDiv)`

`;

const ContentContainer = styled(MaxHeightDiv)`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.scaler.verticalScale(56)}px 0;
`;
