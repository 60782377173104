import { DbRes } from '../../../db/DbResources';
import { TMObj } from '../../../lib/model/ModelTypes';

export enum EMessageSender {
  HOST = 'HOST',
  GUEST = 'GUEST',
  SYSTEM = 'SYSTEM',
}

export type THostMessageId = string;
export type THostMessage = TMObj<typeof DbRes.HostMessage> & {
  text: string;
  sender: EMessageSender;
  delayMs: number;
  tableId?: string;
};
