import { RouteParser } from './RouteParser';

export type TRouteDef = {
  name: string;
  routerProps: {
    path: string;
    [key: string]: any;
  };
};

export type TBuiltRouteDef<Params> = TRouteDef & {
  isRouteSelected: (currentLocation: string) => boolean;
  routeToParams: (route: string) => Params;
  paramsToRoute: (params: Params) => string;
};

export function buildRoute<Params>(route: TRouteDef): TBuiltRouteDef<Params> {
  const isRouteSelected = RouteParser.buildIsRouteSelected(route.routerProps.path);
  const routeToParams = RouteParser.buildRouteToParams(route.routerProps.path);
  const paramsToRoute = RouteParser.buildParamsToRoute(route.routerProps.path);
  return {
    ...route,
    isRouteSelected: isRouteSelected as any,
    routeToParams: routeToParams as any,
    paramsToRoute: paramsToRoute as any,
  };
}

