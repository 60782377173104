import * as React from 'react';
import { FieldStationInputInputField } from './FieldStationInputInputField';
import { TStation } from '../../../../../core/src/models/db/station/StationTypes';
import styled from 'styled-components';

export type TFieldStationInputAdapterSerialParamsProps = {
  station: Partial<TStation>;
  onChange: (path: string, value: string) => void;
  onItemSave: () => void;
  saveOnBlur?: boolean;
};

export function FieldStationInputAdapterSerialParams({
  station,
  onChange,
  onItemSave,
  saveOnBlur = true,
}: TFieldStationInputAdapterSerialParamsProps) {
  return (
    <Root>
      <PortFieldStationInputInputField
        station={station}
        valuePathInStation={'adapter.params.port'}
        placeholder={'Porta'}
        onChange={onChange}
        onItemSave={onItemSave}
        saveOnBlur={saveOnBlur}
      />
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
`;

const PortFieldStationInputInputField = styled(FieldStationInputInputField)`
  padding: 10px;
`;
