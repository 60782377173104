import * as React from 'react';
import { URLToolbox } from '../../../core/src/lib/define/url-toolbox/URLToolbox';
import { URLParamsSingleSignOn } from '../../../core/src/lib/apis/routes/common/URLParamsSingleSignOn';
import { useSyncedDataRef } from '../hooks/useSyncedDataRef';
import * as _ from 'lodash';
import { FirebaseApi } from '../../../core/src/FirebaseApi';
import { FirebaseUserContext } from '../contexts/FirebaseUserContext';
import { URLStateContext } from './URLStateContext';

type TUseSingleSignOnCheckParams = {
  onRedirectToAuthScreen: () => void;
};

/**
 * If expectAuthEmail is set then we check that against the current logged in email
 * if user is not logged in or logged in with a different email then logout
 * -
 * If signInLink url parameter is set then use signInWithEmailLink to sign in (after steps above)
 */
export function useSingleSignOnCheck(params: TUseSingleSignOnCheckParams) {
  const { url } = React.useContext(URLStateContext);
  const { user } = React.useContext(FirebaseUserContext);
  const decoded = URLToolbox.deconstructSearchParams(URLParamsSingleSignOn, url);

  // react to expectAuthEmail being set
  const currentAuthEmail = user && user.email;
  const propsRef = useSyncedDataRef(params);
  React.useEffect(() => {
    (async () => {
      if (_.isEmpty(decoded.expectAuthEmail)) {
        // Nothing to do
        return;
      }

      if (decoded.expectAuthEmail == currentAuthEmail) {
        // All good
        return;
      }

      // decoded.expectAuthEmail != currentAuthEmail
      await FirebaseApi.instance.auth.signOut();
      propsRef.current.onRedirectToAuthScreen && propsRef.current.onRedirectToAuthScreen();
    })();
  }, [propsRef, decoded.expectAuthEmail, currentAuthEmail]);

  // react to signInLink being set
  React.useEffect(() => {
    if (_.isEmpty(decoded.expectAuthEmail) || _.isEmpty(decoded.signInLink)) {
      return;
    }

    (async () => {
      await FirebaseApi.instance.auth.signInWithEmailLink(
        decoded.expectAuthEmail as string,
        decoded.signInLink as string,
      );
    })();
  }, [decoded.expectAuthEmail, decoded.signInLink]);
}
