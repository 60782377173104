import * as React from 'react';
import { MenuItem } from '../../../../core/src/models/db/menuItem/MenuItem';
import { MenuItemTag } from '../MenuItemTag';
import { TTag } from '../../../../core/src/models/Tag';
import {
  fpPush,
  fpRemove,
} from '../../../../core/src/lib/HelperFunctions';
import styled from 'styled-components';

export type TFieldMenuItemTagInputProps = {
  value: TTag[];
  onChange: (tags: TTag[]) => void;
  className?: string;
};

const FieldMenuItemTagInputBase = ({
  value,
  onChange,
  className,
}: TFieldMenuItemTagInputProps) => {
  const onTagClick = React.useCallback((tag: string) => {
    const idxOfTag = value.indexOf(tag);
    idxOfTag > -1
      ? onChange(fpRemove(value, idxOfTag))
      : onChange(fpPush(value, tag));
  }, [value, onChange]);

  return (
    <Root className={className}>
      {MenuItem.tags.map((tag) => (
        <MenuItemTag
          key={tag}
          tag={tag}
          filled={value.includes(tag)}
          onClick={onTagClick}
          getTagAsSvgUrl={MenuItem.getTagAsSvgUrl}
        />
      ))}
    </Root>
  );
};

export const FieldMenuItemTagInput = React.memo(FieldMenuItemTagInputBase) as typeof FieldMenuItemTagInputBase;

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;
