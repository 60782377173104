import { ArchivedGuestSessionBuilder } from '../../../../core/src/models/db/guestSession/ArchivedGuestSessionBuilder';
import { Strings } from '../../../../core/src/locale/Strings';
import { TArchivedMObj } from '../../../../core/src/models/db/archivedMObj/ArchivedMObjTypes';
import {
  TDispatch,
  TThunkAction,
} from '../../../../lib-react/src/redux/redux';
import { TGetState } from '../Store';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { TMenuItem } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import {
  TOrderItem,
  TOrderItemId,
} from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { TRKGuestSession } from '../../../../core/src/db/DbResources';
import { actionRunWithUndoSnackbar } from './ActionHelpers';
import { dispatchWithErrorHandler } from '../ReduxErrorHandlers';
import { parseIntOrDefault } from '../../../../core/src/lib/HelperFunctions';
import { GuestSessionBuilder } from '../../../../core/src/models/db/guestSession/GuestSessionBuilder';
import { OrderItemBuilder } from '../../../../core/src/models/db/orderItem/OrderItemBuilder';

type TActionMenuItemOnMenuItemSaveParams = {
  guestSession: TGuestSession;
  menuItem: TMenuItem;
};

export const actionAddMenuItemToGuestSessionAsHost = dispatchWithErrorHandler(({
  guestSession,
  menuItem,
}: TActionMenuItemOnMenuItemSaveParams): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await GuestSessionBuilder.fromItem(guestSession)
      .addMenuItemToGuestSessionAsHost(menuItem);
  };
});

type TActionGuestSessionCloseParams = {
  guestSessionData: TGuestSession;
};

export const actionGuestSessionClose = dispatchWithErrorHandler(({ guestSessionData }: TActionGuestSessionCloseParams): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    return dispatch(actionRunWithUndoSnackbar({
      msg: Strings.hOrderClosedMsg(),

      run: () => {
        return GuestSessionBuilder.fromItem(guestSessionData)
          .closeAndArchive();
      },

      undo: (archivedItem: TArchivedMObj<TRKGuestSession>) => {
        return ArchivedGuestSessionBuilder.fromItem(archivedItem)
          .unArchive();
      },
    }));
  };
});

type TActionOrderItemsSetToPaid = {
  guestSessionData: TGuestSession;
  orderItemIds: TOrderItemId[];
};

export const actionOrderItemsSetToPaid = dispatchWithErrorHandler(({
  guestSessionData,
  orderItemIds,
}: TActionOrderItemsSetToPaid): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await GuestSessionBuilder.fromItem(guestSessionData)
      .setOrderItemsToPaidById(orderItemIds);
  };
});

type TActionOrderItemDelete = {
  orderItem: TOrderItem;
};

export const actionOrderItemDelete = dispatchWithErrorHandler(({ orderItem }: TActionOrderItemDelete): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await OrderItemBuilder.fromItem(orderItem)
      .deleteOrderItem();
  };
});

type TActionOrderItemsResetToUnPaid = {
  guestSessionData: TGuestSession;
};

export const actionOrderItemsResetToUnPaid = dispatchWithErrorHandler(({ guestSessionData }: TActionOrderItemsResetToUnPaid): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await GuestSessionBuilder.fromItem(guestSessionData)
      .setAllOrderItemsToUnPaid();
  };
});

type TActionGuestSessionSetOpen = {
  guestSessionData: TGuestSession;
};

export const actionGuestSessionSetOpen = dispatchWithErrorHandler(({ guestSessionData }: TActionGuestSessionSetOpen): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await GuestSessionBuilder.fromItem(guestSessionData)
      .open();
  };
});

export const actionSetGuestSessionCount = dispatchWithErrorHandler((
  guestSession: TGuestSession,
  newGuestCount: string,
): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await GuestSessionBuilder.fromItem(guestSession)
      .setGuestCount(parseIntOrDefault(newGuestCount, guestSession.numberOfGuests));
  };
});
