import * as React from 'react';
import {
  NavbarLinkBase,
  TNavbarLinkBaseProps,
  Underline,
} from './NavbarLinkBase';
import styled from 'styled-components';

export type TAlertNavbarLinkProps = Omit<TNavbarLinkBaseProps, 'underline'> & TSelectedUnderlineProps & {
  //
};

export const AlertNavbarLink = ({
  selected,
  ...navbarLinkProps
}: TAlertNavbarLinkProps) => {
  return (
    <StyledNavbarLinkBase
      {...navbarLinkProps}
      underline={(
        <AlertUnderline
          selected={selected}
        />
      )}
    />
  );
};

const StyledNavbarLinkBase = styled(NavbarLinkBase)`
  color: ${({ theme }) => theme.t.col.red};
`;

type TSelectedUnderlineProps = {
  selected?: boolean;
};

const AlertUnderline = styled(Underline)<TSelectedUnderlineProps>`
  background-color: ${({ theme, selected }) => (selected ? theme.t.col.red : undefined)};
`;
