import * as React from 'react';
import { PlusIcon } from '../../assets/Assets';
import { Strings } from '../../../../core/src/locale/Strings';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

type TMenuEditorCreateNewProps = {
  locale: ELocale;
  onClick: () => Promise<any>;
};

export const MenuEditorCreateNew = ({
  locale,
  onClick,
}: TMenuEditorCreateNewProps) => {
  return (
    <Root onClick={onClick}>
      <PlusIcon/>
      <CreateNewText>
        {Strings.hMenuItemCreateCmd()(locale)}
      </CreateNewText>
    </Root>
  );
};

const Root = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.t.col.white};
  border: 1px solid ${({ theme }) => theme.t.col.accentDark};
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  padding: 8px 20px;
`;

const CreateNewText = styled.span`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 15px;
  margin-left: 10px;
  text-transform: uppercase;
`;
