import * as React from 'react';
import { ReactComponent as CheckmarkEmpty } from '../../assets/checkmark-empty.svg';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import styled from 'styled-components';

export type TSearchResult = {
  text: string;
  isMatch: boolean;
};

type TGuestSessionOverviewSearchResultProps<T> = {
  searchResult: TSearchResult[];
  item: T;
  onClick: (item: T) => void;
  className?: string;
};

export function GuestSessionOverviewSearchResult<T>({
  searchResult,
  item,
  onClick,
  className,
}: TGuestSessionOverviewSearchResultProps<T>) {
  const onItemClick = useItemCallback(item, onClick);
  return (
    <Root
      className={className}
      onClick={onItemClick}>
      <ResultsItemText>
        {searchResult.map(({ text, isMatch }, index) => (
          <TextSpan key={`${index}-${text}`}>
            {isMatch && <MatchTextSpan>{text}</MatchTextSpan>}
            {!isMatch && <>{text}</>}
          </TextSpan>
        ))}
      </ResultsItemText>
      <CheckmarkEmpty/>
    </Root>
  );
}

const Root = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.white};
  cursor: pointer;;
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
`;

const ResultsItemText = styled.span`
  flex: 1;
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
  padding: 0 20px;
  text-align: left;
`;

const TextSpan = styled.span`
`;

const MatchTextSpan = styled.span`
  font-weight: 600;
`;
