import * as _ from 'lodash';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { Log } from '../../config/Instance';
import { Refs } from '../../../../core/src/db/DbRefs';
import { SafeRef } from '../../../../core/src/lib/model/SafeRef';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { actionTypeHostMessagesAddMessages } from '../HostMessages';
import {
  all,
  call,
  put,
} from 'redux-saga/effects';
import { sagaBindRefChildAddedByKey } from '../../../../lib-react/src/sagas/sagaBindRefByKey';

export function* sagaSubscribeToHostMessages({ hostData }: { hostData: THost }) {
  const hostId = hostData[FIELD_ID];
  Log.v('sagaSubscribeToHostMessages', 'sagaSubscribeToHostMessages', `subscribing to ${hostId}`);

  const stationSubscriptions = _.values(hostData.stations)
    .map((station) => {
      return createSagaSubscribeToHostMessagesByRef({
        ref: Refs.hostStationMessages({
          hostId,
          stationId: station[FIELD_ID],
        }),
      });
    })
    .map((effect) => call(effect));

  yield all(stationSubscriptions);
}

export function createSagaSubscribeToHostMessagesByRef({ ref }: { ref: SafeRef<any, any> }) {
  return function* () {
    const subscribeToPath = ref.path();
    Log.v('sagaSubscribeToHostMessages', 'createSagaSubscribeToHostMessagesByRef', `subscribing to ${subscribeToPath}`);

    yield sagaBindRefChildAddedByKey(`sagaSubscribeToHostMessages ${subscribeToPath}`, ref, function* (hostMessage) {
      yield put(actionTypeHostMessagesAddMessages({ hostMessages: [hostMessage] }));
    });
  };
}
