import * as React from 'react';
import styled from 'styled-components';

import { ReactComponent as BackCaret } from '../../assets/back-caret.svg';
import { Hist } from '../../lib/navigation/History';
import { Routes } from '../../config/Routes';
import { Strings } from '../../../../core/src/locale/Strings';
import { hexToRgbaString } from '../../../../core/src/lib/Color';
import { useBindDrawableContentOffset } from '../../hooks/useBindDrawableContentOffset';
import { useNavigateBack } from '../../lib/navigation/Navigation';
import { ELocale } from '../../../../core/src/locale/Locale';

type TSecondaryNavbar = {
  locale: ELocale;
  name: string;
  hostId?: string;
  rightActionButton?: React.ReactNode;
};

export const SecondaryNavbar = ({
  locale,
  name,
  hostId,
  rightActionButton,
}: TSecondaryNavbar) => {
  useBindDrawableContentOffset(secondaryNavbarHeight);

  const realGoBack = useNavigateBack();
  const previousNavContext = Hist.getPreviousNavContext();

  const { defaultGoBackText, defaultGoBack } = React.useMemo(() => ({
    defaultGoBackText: Strings.hBackGeneric()(locale),
    defaultGoBack: hostId && (() => Hist.replace(Routes.ScreenHostHome, { hostId })),
  }), [hostId, locale]);

  const [goBack, goBackText] = previousNavContext
    ? [realGoBack, previousNavContext.goBackText]
    : [defaultGoBack, defaultGoBackText];

  return (
    <Root2>
      <Root>
        <LeftContainer>
          {goBack && (
            <StyledLink onClick={goBack}>
              <StyledCaret/>
              {goBackText}
            </StyledLink>
          )}
        </LeftContainer>
        <CenterContainer>
          <TableNameLinkContainer>
            {name}
          </TableNameLinkContainer>
        </CenterContainer>
        <RightContainer>
          {rightActionButton}
        </RightContainer>
      </Root>
    </Root2>
  );
};

const flexTotal = 1;
const flexLeftRightContainers = 0.25;
const flexCenterContainer = flexTotal - (flexLeftRightContainers * 2);
export const secondaryNavbarHeight = 45;

const Root2 = styled.div`
  background-color: ${({ theme }) => theme.t.col.white};
  border-bottom: 1px solid ${({ theme }) => hexToRgbaString(theme.t.col.accentDark, 0.2)};
  display: flex;
  height: ${secondaryNavbarHeight}px;
  width: 100vw;
`;

const Root = styled.div`
  align-items: center;
  display: flex;
  flex: ${flexTotal};
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 10px;
`;

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

const LeftContainer = styled(FlexContainer)`
  flex: ${flexLeftRightContainers};
  justify-content: flex-start;
`;

const CenterContainer = styled(FlexContainer)`
  flex: ${flexCenterContainer};
  justify-content: center;
`;

const RightContainer = styled(FlexContainer)`
  flex: ${flexLeftRightContainers};
  justify-content: flex-end;
`;

const TableNameLinkContainer = styled.div`
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.7px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
`;

const StyledLink = styled.span`
  align-items: center;
  color: ${({ theme }) => theme.t.col.accentLight};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.1px;
`;

const StyledCaret = styled(BackCaret)`
  margin-right: 15px;
`;
