import * as React from 'react';
import { LoginView } from '../components/LoginView';
import { MaxScreenDiv } from '../components/MaxHeightDiv';
import { Re2direct } from '../components/router/Re2direct';
import { Routes } from '../config/Routes';
import {
  TPropCheck,
  withPropCheckMiddleware,
} from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import { TScreenNavigationProps } from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import { actionAppSignInWithEmailAndPassword } from '../redux/App';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import { connect } from 'react-redux';
import styled from 'styled-components';

export type TScreenLoginNavParams = {
  //
};

export type TScreenLoginProps = TScreenNavigationProps<TScreenLoginNavParams> & {
  //
};

type TScreenHomeBaseProps = TScreenLoginProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TScreenHomeBaseState = {
  //
};

class ScreenLoginBase extends React.Component<TPropCheck<typeof ScreenLoginBase>, TScreenHomeBaseState> {
  static checkProps(props: TScreenHomeBaseProps) {
    const { user } = props;

    if (user != null) {
      return () => (
        <Re2direct
          route={Routes.ScreenHostHome}
          params={{ hostId: user.uid }}
        />
      );
    }

    return props;
  }

  render() {
    const {
      locale,
      appSignInWithEmailAndPassword,
    } = this.props;
    return (
      <Root>
        <LoginView
          locale={locale}
          onSubmit={appSignInWithEmailAndPassword}
        />
      </Root>
    );
  }
}

function mapStateToProps(state: TState) {
  return {
    locale: state.app.locale,
    user: state.app.user,
  };
}

function mapDispatchToProps(dispatch) {
  return { appSignInWithEmailAndPassword: bindActionCreators(actionAppSignInWithEmailAndPassword, dispatch) };
}

export const ScreenLogin = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(ScreenLoginBase));

const Root = styled(MaxScreenDiv)`
`;
