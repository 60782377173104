import * as React from 'react';
import {
  BouncingLogo,
  bouncingLogoTotalHeight,
} from '../BouncingLogo';
import { FullScreenServiceFrame } from './FullScreenServiceFrame';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

export type TFullScreenLoadingViewProps = {
  locale: ELocale;
  title?: string;
  infoText?: string;
};

export const FullScreenLoadingView = ({
  locale,
  title,
  infoText,
}: TFullScreenLoadingViewProps) => {
  return (
    <FullScreenServiceFrame
      locale={locale}
      title={title}
      content={(
        <ContentRoot>
          <BouncingLogoContainer>
            <BouncingLogo/>
          </BouncingLogoContainer>
          <LoadingH1>
            {infoText}
          </LoadingH1>
        </ContentRoot>
      )}
    />
  );
};

const ContentRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const BouncingLogoContainer = styled.div`
  height: ${bouncingLogoTotalHeight}px;
`;

const LoadingH1 = styled.span`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
`;
