import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Dt } from '../../../../core/src/lib/formatters/Dt';
import { FieldTextInput } from '../form/FieldTextInput';
import { FluidContainer } from '../bootstrap/FluidContainer';
import { FormLabel } from '../form/FormLabel';
import { SquareButton } from '../Button/SquareButton';
import { Strings } from '../../../../core/src/locale/Strings';
import { TSettingsFormParams, useSettingsFormHandler } from '../../lib/forms/SettingsForm';
import { colMd } from '../bootstrap/Bootstrap';
import styled from 'styled-components';
import { THostPublicSubscription } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import { RoutableLanding } from '../../../../core/src/lib/apis/routes/RoutableLanding';
import { ELocale, localeToLang } from '../../../../core/src/locale/Locale';

export type TSettingsViewProps = TSettingsFormParams & {
  locale: ELocale;
  hostPublicSubscription: THostPublicSubscription;
  initialUserEmail: string;
};

export const SettingsView = (props: TSettingsViewProps) => {
  const {
    locale,
    hostPublicSubscription,
    initialUserEmail,
  } = props;
  const formHandler = useSettingsFormHandler(props);
  const landingProfileUrl = RoutableLanding.Profile.url({
    pathParams: { locale: localeToLang(locale) },
    params: {},
  });
  return (
    <Root>
      <ScreenTitleText>{Strings.hSettings()(locale)}</ScreenTitleText>
      <FluidContainer>
        <StyledRow>
          <StyledCol md={12}>
            <FormLabel label={Strings.hSettingsMail()(locale)}>
              <StyledFieldTextInput
                readOnly
                type="email"
                value={initialUserEmail}
                placeholder={initialUserEmail}
              />
              <StyledFieldTextInput
                type="email"
                value={formHandler.fields.newEmail.value}
                onChange={formHandler.fields.newEmail.setValue}
                error={formHandler.fields.newEmail.errorStr}
                placeholder={Strings.hSettingsNewMailPhd1()(locale)}
              />
              <StyledFieldTextInput
                type="email"
                value={formHandler.fields.confirmNewEmail.value}
                onChange={formHandler.fields.confirmNewEmail.setValue}
                error={formHandler.fields.confirmNewEmail.errorStr}
                placeholder={Strings.hSettingsNewMailPhd2()(locale)}
                showErrorOnlyBlur={false}
              />
            </FormLabel>
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol md={12}>
            <FormLabel label={Strings.hSettingsPsw()(locale)}>
              <StyledFieldTextInput
                type="password"
                value={formHandler.fields.newPassword.value}
                onChange={formHandler.fields.newPassword.setValue}
                error={formHandler.fields.newPassword.errorStr}
                placeholder={Strings.hSettingsNewPswPhd1()(locale)}
              />
              <StyledFieldTextInput
                type="password"
                value={formHandler.fields.confirmNewPassword.value}
                onChange={formHandler.fields.confirmNewPassword.setValue}
                error={formHandler.fields.confirmNewPassword.errorStr}
                placeholder={Strings.hSettingsNewPswPhd2()(locale)}
                showErrorOnlyBlur={false}
              />
              <StyledFieldTextInput
                type="password"
                value={formHandler.fields.currentPassword.value}
                onChange={formHandler.fields.currentPassword.setValue}
                error={formHandler.fields.currentPassword.errorStr}
                placeholder={Strings.hSettingsOldPswPhd()(locale)}
                showErrorOnlyBlur={false}
              />
            </FormLabel>
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <SaveSquareButton
              title={Strings.hSettingsSaveCmd()(locale)}
              onClick={formHandler.onSubmit}
              loading={formHandler.loading}
              disabled={formHandler.submitDisabled}
            />
          </StyledCol>
        </StyledRow>
        <SubscriptionContainer>
          <BottomTextContainer>
            {Strings.hSettingsSubscriptionExpiration({ expiryStr: Dt.dateFormat(hostPublicSubscription.endsOn) })(locale)}
          </BottomTextContainer>
          <BottomLinkContainer target="_blank" href={`${landingProfileUrl}`}>
            {Strings.hSettingsSubscriptionRenewal()(locale)}
          </BottomLinkContainer>
        </SubscriptionContainer>
      </FluidContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${colMd}px;
  width: 100%;
`;

const ScreenTitleText = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
`;

const StyledRow = styled(Row)`
`;

const StyledCol = styled(Col)`
  margin-top: 30px;
`;

const StyledFieldTextInput = styled(FieldTextInput)`
  margin-top: 10px
`;

const SubscriptionContainer = styled.div`
  align-items: center;
  margin-top: 20px;
  text-align: center;
`;

const BottomTextContainer = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
`;

const BottomLinkContainer = styled.a`
  color: ${({ theme }) => theme.t.col.red};
  cursor: pointer;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  text-align: center;
  text-decoration-line: underline;
`;

const SaveSquareButton = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.red};
  color: ${({ theme }) => theme.t.col.white};
  margin-top: 10px;
  width: 100%;
`;
