import * as React from 'react';
import { EPrintAdapter } from '../../../../../core/src/printer/PrintAdapters';
import { Strings } from '../../../../../core/src/locale/Strings';
import { TStation } from '../../../../../core/src/models/db/station/StationTypes';
import { useItemCallback } from '../../../../../lib-react/src/hooks/useItemCallback';
import styled from 'styled-components';
import { ELocale } from '../../../../../core/src/locale/Locale';

function adapterToString(
  adapter: EPrintAdapter,
  locale: ELocale,
) {
  switch (adapter) {
    case EPrintAdapter.USB:
      return Strings.hStationAdapterUsb()(locale);
    case EPrintAdapter.Serial:
      return Strings.hStationAdapterSerial()(locale);
    case EPrintAdapter.Network:
      return Strings.hStationAdapterNetwork()(locale);
    default:
      return Strings.hStationAdapterConsole()(locale);
  }
}

export type TFieldStationInputPickerItemProps = {
  locale: ELocale;
  adapterType: EPrintAdapter;
  onSelect: (value: EPrintAdapter) => void;
};

export function FieldStationInputPickerItem<T extends Partial<TStation>>({
  locale,
  adapterType,
  onSelect,
}: TFieldStationInputPickerItemProps) {
  const onClick = useItemCallback(adapterType, onSelect);
  return (
    <ItemContainer onClick={onClick}>
      <PickableItem>
        {adapterToString(adapterType, locale)}
      </PickableItem>
    </ItemContainer>
  );
}

const ItemContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: normal;
  height: 50px;
  justify-content: space-between;
  line-height: 18px;
  padding: 10px;
  text-transform: none;
  width: 100%;
`;

const PickableItem = styled.div`
  background: none;
  border: none;
  flex: 1;
  outline: none;
  width: 100%;
`;
