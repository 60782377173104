import * as React from 'react';
import { ReactComponent as UploadLogo } from '../../assets/upload-icon.svg';
import { useSingleFileUploadInput } from '../useSingleFileUploadInput';
import styled from 'styled-components';
import { MdSearch } from "react-icons/md";
import { ImagePickerModal } from "../modals/ImagePickerModal";
import { Theme } from "../../config/Theme";
import { Log } from "../../config/Instance";
import { TUnsplashPhoto } from "../modals/imagePicker/unsplash/unsplash-api";
import {ELocale} from '../../../../core/src/locale/Locale';
import fetch from 'node-fetch';
import PacmanLoader from "react-spinners/PacmanLoader";

export type TFieldImageFileInputProps = {
  locale: ELocale,
  placeholder: string;
  onChange: (url: string) => void;
  value?: string;
  className?: string;
  withImagePicker?: {
    initialSearch: string
  };
};

const FieldImageFileInputBase = ({
                                   locale,
                                   onChange,
                                   placeholder,
                                   value,
                                   className,
                                   withImagePicker,
                                 }: TFieldImageFileInputProps) => {
  const {
    input,
    onTriggerUpload,
    uploadAndSetArrayBuffer,
    setLoadingArrayBuffer,
    isLoading,
  } = useSingleFileUploadInput('.jpg,.png', onChange);

  const [open, setOpen] = React.useState(false);
  const handleImageSelected = React.useCallback(async (photos: TUnsplashPhoto[]) => {
    setOpen(false);

    if (photos.length === 0) {
      Log.v('FieldImageFileInput', '_', `No images picked`)
    } else if (photos.length === 1) {
      setLoadingArrayBuffer(true);
      try {
        const [photo] = photos;

        const url = photo?.urls?.regular
        if (!url) {
          Log.w('FieldImageFileInput', '_', `Url was not defined`)
        }

        const data = await fetch(url);
        const arrayBuffer = await data.arrayBuffer();
        uploadAndSetArrayBuffer(arrayBuffer, {
          referralPhn: photo.user.name,
          referralPhu: photo.user.username,
        });

      } finally {
        setLoadingArrayBuffer(false);
      }
    } else {
      Log.w('FieldImageFileInput', '_', `Picked more than 1 image when only 1 should have been picked`)
    }
  }, [uploadAndSetArrayBuffer, setLoadingArrayBuffer, setOpen]);

  return (
    <>
      <Container
        className={className}
        disabled={isLoading}
        onClick={onTriggerUpload}>
        <InputContainer>
          {value && (
            <ImageContainer>
              {!isLoading ? <StyledImg src={value} alt=""/> : (
                <PacmanLoader
                  loading={true}
                  color={Theme.col.red}
                  size={10}
                  speedMultiplier={1.5}
                />
              )}
            </ImageContainer>
          )}
          <LabelContainer>
            {placeholder}
          </LabelContainer>
          <UploadLogoContainer>
            <UploadLogo/>
            {withImagePicker != null && (
              <>
                <MdSearch
                  style={{ marginLeft: 6 }}
                  size={30}
                  color={Theme.col.accentDark}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(true);
                  }}/>
              </>
            )}
          </UploadLogoContainer>
        </InputContainer>
        {input}
      </Container>
      <ImagePickerModal
        locale={locale}
        maxCount={1}
        open={open}
        onClose={handleImageSelected}
        initialSearch={withImagePicker?.initialSearch ?? ''}
      />
    </>
  );
};

export const FieldImageFileInput = React.memo(FieldImageFileInputBase) as typeof FieldImageFileInputBase;

const fieldHeight = 48;

type TInputContainer = {
  disabled: boolean;
};

const Container = styled.div<TInputContainer>`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.t.col.accentLight};
  border-radius: 1px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  height: 50px;
  line-height: 19px;
  padding: 15px;
  text-transform: none;
  width: 100%;
`;

const InputContainer = styled.div`
  align-items: center;
  background: none;
  border: none;
  color: ${({ theme }) => theme.t.col.accentLight};
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 13px;
  font-weight: normal;
  line-height: 18px;
  outline: none;
  text-align: left;
`;

const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${fieldHeight}px;
  justify-content: center;
  margin-left: -15px;
  overflow: hidden;
  width: ${fieldHeight}px;

  img {
    flex-shrink: 0;
    min-height: 100%;
    min-width: 100%;
  }
`;

const StyledImg = styled.img`
  height: ${fieldHeight}px;
  width: ${fieldHeight}px;
`;

const LabelContainer = styled.div`
  margin-left: 15px;
`;

const UploadLogoContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
