import * as React from 'react';
import * as _ from 'lodash';
import { TStation } from '../../../../../core/src/models/db/station/StationTypes';
import { useTriggerOnBlurOrEnter } from '../../../../../lib-react/src/hooks/form/useTriggerOnBlurOrEnter';
import styled from 'styled-components';

export type TFieldStationInputAdapterInputProps = {
  station: Partial<TStation>;
  valuePathInStation: string;
  onItemSave: () => void;
  saveOnBlur?: boolean;
  onChange?: (path: string, value: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  autoFocus?: boolean;
  className?: string;
};

export function FieldStationInputInputField<T extends Partial<TStation>>({
  station,
  valuePathInStation,
  onItemSave,
  onChange,
  placeholder,
  className,
  readOnly = false,
  autoFocus = false,
  saveOnBlur = true,
}: TFieldStationInputAdapterInputProps) {
  const value = _.get(station, valuePathInStation, '');

  const onInputChange = React.useCallback((event: any) => {
    return onChange && onChange(valuePathInStation, event.target.value);
  }, [valuePathInStation, onChange]);

  const {
    itemRef,
    onBlur,
    onEnterPress,
  } = useTriggerOnBlurOrEnter({
    triggerOnBlur: saveOnBlur,
    onTrigger: onItemSave,
  });

  return (
    <Input
      ref={itemRef}
      className={className}
      readOnly={readOnly}
      autoFocus={autoFocus}
      value={value}
      onChange={onInputChange}
      placeholder={placeholder}
      onKeyPress={onEnterPress}
      onBlur={onBlur}
    />
  );
}

type TInputProps = {
  readOnly?: boolean;
};

const Input = styled.input<TInputProps>`
  background: none;
  border: none;
  cursor: ${({ readOnly }) => (!readOnly ? 'text' : 'default')};
  flex: 1;
  outline: none;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.t.col.accentLight};
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
  }
`;
