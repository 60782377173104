import * as React from 'react';
import * as _ from 'lodash';
import { useSyncedDataRef } from './useSyncedDataRef';

export function useEventValueCallback(cb: (value: string) => any) {
  const cbRef = useSyncedDataRef(cb);
  return React.useCallback((event) => {
    return cbRef.current(_.get(event, 'target.value', ''));
  }, []);
}
