import React from 'react'
import { createApi } from 'unsplash-js'
import { PhotoList } from './photo-list.component'
import { SearchBar } from './SearchBar'
import { TUnsplashPhoto } from "./unsplash-api";

interface Props {
  imagePickerNothingFoundText: string;
  imagePickerLoadingText: string;
  searchPlaceholder: string;
  unsplashAccessKey: string
  initialPhotoSearchQuery?: string
  selectedPhotos: Record<string, TUnsplashPhoto>;
  onPhotoClick?: (photo: TUnsplashPhoto) => void
}

export function UnsplashImagePicker({
  searchPlaceholder,
                                      imagePickerNothingFoundText,
                                      imagePickerLoadingText,
                                      unsplashAccessKey,
                                      selectedPhotos,
                                      initialPhotoSearchQuery = '',
                                      onPhotoClick = (_: any) => {
                                      }
                                    }: Props) {
  const [pics, setPics] = React.useState<any[]>([])
  const [total, setTotal] = React.useState<number | undefined>()
  const [query, setQuery] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoadingMore, setIsLoadingMore] = React.useState(false)
  const [page, setPage] = React.useState(1)

  const unsplash = createApi({ accessKey: unsplashAccessKey })

  React.useEffect(() => {
    if (initialPhotoSearchQuery !== '') {
      setQuery(initialPhotoSearchQuery)
      fetchPhotos(1, initialPhotoSearchQuery)
    }
  }, [])

  const fetchPhotos = (page: number, text: string, reset = false) => {
    if (isLoading || isLoadingMore) {
      return
    }
    if (page === 1) {
      setIsLoading(true)
    } else {
      setIsLoadingMore(true)
    }
    setPage(page)
    unsplash.search
      .getPhotos({
        page: page,
        perPage: 30,
        query: text,
        orientation: 'landscape'
      })
      .then((response: any) => {
        const newPics = response?.response?.results
        if (newPics) {
          let mergedPics = newPics
          if (!reset) {
            mergedPics = [...pics, ...newPics]
          }
          setPics(mergedPics)
          setTotal(response.response.total)
        }
        setIsLoading(false)
        setIsLoadingMore(false)
      })
  }

  const handleSearch = React.useCallback((searchString: string) => {
    setPics([]);
    fetchPhotos(1, searchString, true)
  }, [setPics, fetchPhotos]);

  return (
    <div className='ImagePicker items-center bg-white rounded'>
      <div className='bg-white '>
        <div className='Picker relative h-full rounded'>
          <SearchBar
            placeholder={searchPlaceholder}
            onSearch={handleSearch}
            query={query}
            setQuery={setQuery}
          />
          <PhotoList
            imagePickerNothingFoundText={imagePickerNothingFoundText}
            imagePickerLoadingText={imagePickerLoadingText}
            total={total}
            photoList={pics}
            isLoading={isLoading}
            isLoadingMore={isLoadingMore}
            selectedPhotos={selectedPhotos}
            loadMore={() => {
              fetchPhotos(page + 1, query)
            }}
            onPhotoClick={onPhotoClick}
          />
        </div>
      </div>
    </div>
  )
}
