import * as React from 'react';
import * as _ from 'lodash';
import {
  NumberInput,
  TNumberInputProps,
} from './NumberInput';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

export type TFieldNumberInputProps = TNumberInputProps & {
  locale: ELocale;
  value: string;
  ref?: React.RefObject<HTMLInputElement>;
  placeholder?: string;
  unit?: string;
  error?: string;
  onChange?: (newValue: string) => void;
  readOnly?: boolean;
  className?: string;
  showErrorOnlyBlur?: boolean;
};

const FieldNumberInputBase = ({
  error,
  className,
  showErrorOnlyBlur = true,
  ...numberInputProps
}: TFieldNumberInputProps) => {
  const [showError, setShowError] = React.useState<boolean>();
  const onBlur = React.useCallback(() => setShowError(true), [setShowError]);

  const usedError = (!showErrorOnlyBlur || showError)
    ? error
    : undefined;
  return (
    <Root className={className}>
      <Container hasError={usedError != null}>
        <StyledNumberInput
          onBlur={onBlur}
          {...numberInputProps}
        />
      </Container>
      {!_.isEmpty(usedError) && (
        <FixedErrorContainer>
          <Error>{usedError}</Error>
        </FixedErrorContainer>
      )}
    </Root>
  );
};

export const FieldNumberInput = React.memo(FieldNumberInputBase) as typeof FieldNumberInputBase;

const Root = styled.div`
  width: 100%;
`;

type TContainerProps = {
  hasError: boolean;
};

const StyledNumberInput = styled(NumberInput)`
  padding: 15px;
`;

const Container = styled.div<TContainerProps>`
  align-items: center;
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.t.col.red : theme.t.col.accentLight)};
  border-radius: 1px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 19px;
  min-height: 50px;
`;

const FixedErrorContainer = styled.div`
  font-weight: normal;
  height: 20px;
  text-align: right;
  text-transform: none;
  width: 100%;
`;

const Error = styled.span`
  color: ${({ theme }) => theme.t.col.red};
  font-size: 12px;
`;
