import { SnackbarOptions } from '../../lib/snackbars/SnackbarOptions';
import { Snackbars } from '../../lib/snackbars/Snackbars';
import { TDispatch } from '../../../../lib-react/src/redux/redux';
import { TGetState } from '../Store';
import { makeSnackbarAction } from '../../components/snackbars/ActionButton';
import { ELocale } from '../../../../core/src/locale/Locale';

type TRunWithUndoSnackbarParams<R1, R2> = {
  run: () => Promise<R1>;
  undo: (r1: R1) => Promise<R2>;
  msg: (locale: ELocale) => string;
};

export function actionRunWithUndoSnackbar<R1, R2>({
  run,
  undo,
  msg,
}: TRunWithUndoSnackbarParams<R1, R2>) {
  return async (dispatch: TDispatch, getState: TGetState) => {
    const { locale } = getState().app;
    const r1 = await run();
    Snackbars.enqueueSnackbar({
      content: msg(locale),
      options: {
        ...SnackbarOptions.globalSuccess({}),
        action: makeSnackbarAction({
          text: 'undo',
          async onClick() {
            return undo(r1);
          },
        }),
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    });
  };
}
