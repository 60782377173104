import * as React from 'react';
import * as _ from 'lodash';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../../core/src/db/DbDefs';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import {
  TStation,
  TStationId,
} from '../../../../core/src/models/db/station/StationTypes';
import { deterministicHex } from '../../../../core/src/lib/HelperFunctions';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import styled from 'styled-components';

type TStationChatListProps = {
  hostData: THost;
  selectedStationId: string | null;
  setSelectedStationId: (stationId: TStationId) => void;
};

export const StationChatList = ({
  hostData,
  selectedStationId,
  setSelectedStationId,
}: TStationChatListProps) => {
  return (
    <Container>
      {_.values(hostData.stations).map((station) => (
        <StationChatListItem
          key={station[FIELD_ID]}
          station={station}
          isSelected={selectedStationId == station[FIELD_ID]}
          setSelectedStationId={setSelectedStationId}
        />
      ))}
    </Container>
  );
};

type TStationChatListItemProps = {
  station: TStation;
  isSelected: boolean;
  setSelectedStationId: (stationId: TStationId) => void;
};

export const StationChatListItem = ({
  station,
  isSelected,
  setSelectedStationId,
}: TStationChatListItemProps) => {
  const stationId = station[FIELD_ID];
  const onStationClick = useItemCallback(stationId, setSelectedStationId);
  return (
    <ContactCardContainer
      isSelected={isSelected}
      onClick={onStationClick}
    >
      <ContactCardLogo backgroundColor={deterministicHex(stationId)}/>
      <CartTitleText>{station[FIELD_NAME]}</CartTitleText>
    </ContactCardContainer>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.t.col.white};
  flex: 1;
  max-width: 315px;
  overflow-y: scroll;
  padding: 10px;
`;

const ContactCardContainer = styled.div<{ isSelected: boolean }>`
  align-items: center;
  background-color: ${({ theme, isSelected }) => (isSelected ? theme.t.col.lightGray3 : 'transparent')};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  min-height: 80px;
  padding: 20px 10px;
`;

const ContactCardLogo = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 20px;
  height: 40px;
  margin-right: 20px;
  width: 40px;
`;

const CartTitleText = styled.span`
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
`;
