import { CIInterface } from '../../config/CIInterface';
import { TTable } from '../db/table/TableTypes';
import { Validator } from '../../lib/error/Validators';
import { buildObjValidator } from '../../lib/error/lib/buildObjValidator';

const validateTableInternalNote = Validator.applyAll<string>([
  Validator.expectStrOfLen({ max: CIInterface.validateTableInternalNoteMaxLen }),
]);

export const validateTable = buildObjValidator<TTable>({ internalNote: validateTableInternalNote });
