import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FluidContainer } from '../bootstrap/FluidContainer';
import { Hairline } from '../Hairline';
import {
  LabeledFieldColorInput,
  LabeledFieldImageFileInput,
  LabeledFieldStationListInput,
  LabeledFieldTextInput,
} from '../form/LabeledFields';
import { ProfileFiscalView } from './ProfileFiscalInfo';
import { ProfileViewPlaceSuggestionList, TPlaceResult } from './ProfileViewPlaceSuggestionList';
import { RoomEditList } from '../form/duplexInputListResources/RoomsEditList';
import { SquareButton } from '../Button/SquareButton';
import { Strings } from '../../../../core/src/locale/Strings';
import { TProfileFormParams, useProfileFormHandler } from '../../lib/forms/ProfileForm';
import { CurrenciesList, TCurrencyCode } from '../../../../core/src/locale/Currencies';
import { colMd } from '../bootstrap/Bootstrap';
import Stick from 'react-stick';
import styled from 'styled-components';
import { ESubscriptionFeatureFlags } from '../../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';
import { FeatureFlagOverlay } from '../featureFlagOverlay/FeatureFlagOverlay';
import { THostPublicSubscription } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import { ELocale } from '../../../../core/src/locale/Locale';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';
import { ProfileCurrencySuggestionList } from "./ProfileViewCurrencySuggestionList";

type TProfileViewProps = TProfileFormParams & {
  locale: ELocale;
  hostPublicSubscription: THostPublicSubscription;
};

export const ProfileView = ({
  locale,
  hostPublicSubscription,
  ...props
}: TProfileViewProps) => {
  const formHandler = useProfileFormHandler(props);
  const formHandlerRef = useSyncedDataRef(formHandler);

  const onSuggestionSelect = React.useCallback((suggestion: TPlaceResult) => {
    const { fields } = formHandlerRef.current;
    fields.name.setValue(suggestion.name);
    fields.placeId.setValue(suggestion.placeId);

    if (suggestion.name) {
      fields.denomination.setValue(suggestion.name);
    }

    if (suggestion.address) {
      fields.address.setValue(suggestion.address);
    }

    if (suggestion.coordinates) {
      fields.coordinates.setValue(suggestion.coordinates);
    }

    if (suggestion.number) {
      fields.phone.setValue(suggestion.number);
    }
  }, []);

  const onCurrencySelect = React.useCallback((currency: TCurrencyCode) => {
    const { fields } = formHandlerRef.current;
    fields.currency.setValue(currency);
  }, []);

  return (
    <Root>
      <ScreenTitleText>
        {Strings.hProfileEdit()(locale)}
      </ScreenTitleText>
      <FluidContainer>
        <StyledRow>
          <StyledCol md={12}
            lg={6}>
            <Stick
              sameWidth
              node={
                <ProfileViewPlaceSuggestionList
                  visible={formHandler.fields.name.isFocused}
                  bindedSearch={formHandler.fields.name.value}
                  onSelect={onSuggestionSelect}
                />
              }>
              <LabeledFieldTextInput
                isRequired
                value={formHandler.fields.name.value}
                onChange={formHandler.fields.name.setValue}
                error={formHandler.fields.name.errorStr}
                setIsFocused={formHandler.fields.name.setIsFocused}
                label={Strings.hProfileName()(locale)}
                placeholder={Strings.hProfileNamePhd()(locale)}
              />
            </Stick>
          </StyledCol>
          <StyledCol md={12}
            lg={6}>
            <LabeledFieldTextInput
              isRequired
              value={formHandler.fields.description.value}
              onChange={formHandler.fields.description.setValue}
              error={formHandler.fields.description.errorStr}
              label={Strings.hProfileType()(locale)}
              placeholder={Strings.hProfileTypePhd()(locale)}
            />
          </StyledCol>
        </StyledRow>


        <StyledRow>
          <StyledCol md={12}
            lg={6}>
            <Stick
              sameWidth
              node={
                <ProfileCurrencySuggestionList
                  visible={formHandler.fields.currency.isFocused}
                  bindedSearch={formHandler.fields.currency.value}
                  onSelect={onCurrencySelect}
                />
              }>
              <LabeledFieldTextInput
                isRequired
                value={formHandler.fields.currency.value}
                onChange={formHandler.fields.currency.setValue}
                error={formHandler.fields.currency.errorStr}
                setIsFocused={formHandler.fields.currency.setIsFocused}
                label={Strings.hProfileCurrency()(locale)}
                placeholder={Strings.hProfileCurrencyPhd()(locale)}
              />
            </Stick>
          </StyledCol>
        </StyledRow>


        <StyledRow>
          <StyledCol md={12}
            lg={6}>
            <RoomEditList
              label={Strings.hProfileTableNr()(locale)}
              newItemMainPlaceholder={Strings.hProfileTableNamePhd()(locale)}
              {...formHandler.fields.rooms}
            />
          </StyledCol>
          <StyledCol md={12}>
            <FeatureFlagOverlay
              locale={locale}
              uniqueId={`ff-ProfileView-StationListInput`}
              hostPublicSubscription={hostPublicSubscription}
              requiredFeatureList={[
                ESubscriptionFeatureFlags.guestOrders,
              ]}
            >
              <LabeledFieldStationListInput
                locale={locale}
                value={formHandler.fields.stations.value}
                onChange={formHandler.fields.stations.setValue}
                newItem={formHandler.fields.stations.newItem.value}
                newItemSet={formHandler.fields.stations.newItem.setValue}
                newItemReset={formHandler.fields.stations.newItem.reset}
                label={Strings.hStations()(locale)}
              />
            </FeatureFlagOverlay>
          </StyledCol>
        </StyledRow>
        <StyledHairline/>
        <StyledRow>
          <StyledCol md={12}
            lg={6}>
            <LabeledFieldImageFileInput
              locale={locale}
              value={formHandler.fields.logoImg.value}
              onChange={formHandler.fields.logoImg.setValue}
              label={Strings.hProfileLogo()(locale)}
              caption={Strings.hProfileLogoHint()(locale)}
              placeholder={Strings.hProfileLogoPhd()(locale)}
            />
          </StyledCol>
          <StyledCol md={12}
            lg={6}>
            <LabeledFieldImageFileInput
              locale={locale}
              value={formHandler.fields.coverImg.value}
              onChange={formHandler.fields.coverImg.setValue}
              label={Strings.hProfileCoverImg()(locale)}
              caption={Strings.hProfileCoverImgHint()(locale)}
              placeholder={Strings.hProfileCoverImgPhd()(locale)}
            />
          </StyledCol>
        </StyledRow>
        {false && (
          <StyledRow>
            <StyledCol md={12}
              lg={6}>
              <LabeledFieldColorInput
                value={formHandler.fields.primaryColor.value}
                onChange={formHandler.fields.primaryColor.setValue}
                label={Strings.hProfileColor1()(locale)}
              />
            </StyledCol>
            <StyledCol md={12}
              lg={6}>
              <LabeledFieldColorInput
                value={formHandler.fields.secondaryColor.value}
                onChange={formHandler.fields.secondaryColor.setValue}
                label={Strings.hProfileColor2()(locale)}
              />
            </StyledCol>
          </StyledRow>
        )}
        <StyledHairline/>
        <ProfileFiscalView
          locale={locale}
          hostData={props.hostData}
          formHandler={formHandler}
        />
        <StyledRow>
          <StyledCol>
            <SaveSquareButton
              title={Strings.hProfileSaveCmd()(locale)}
              onClick={formHandler.onSubmit}
              loading={formHandler.loading}
              disabled={formHandler.submitDisabled}
            />
          </StyledCol>
        </StyledRow>
      </FluidContainer>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${colMd}px;
  width: 100%;
`;

const ScreenTitleText = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
`;

const StyledRow = styled(Row)`
`;

const StyledCol = styled(Col)`
  margin-top: 30px;
`;

const StyledHairline = styled(Hairline)`
  margin-bottom: 10px;
  margin-top: 40px;
`;

const SaveSquareButton = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.red};
  color: ${({ theme }) => theme.t.col.white};
  margin-top: 10px;
  width: 100%;
`;
