import {css} from 'styled-components';

export const htmlDefaultStyles = css`
  display: inline;
  ul {
    margin: 0
  }
  p {
    margin: 0
  }
  div {
    display: inline;
  }
  span {
    display: inline;
    margin-right: 1px;
  }
`;