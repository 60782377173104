import * as React from 'react';
import * as _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { FluidContainer } from '../bootstrap/FluidContainer';
import { Menu } from '../../../../core/src/models/db/menu/Menu';
import { MenuMainFormCard } from './MenuMainFormCard';
import { MenuMainFormCardCreate } from './MenuMainFormCardCreate';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TMenu } from '../../../../core/src/models/db/menu/MenuTypes';
import { TMenuForm } from '../../lib/forms/MenuForm';
import { colLg } from '../bootstrap/Bootstrap';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

type TMenuMainViewProps = {
  locale: ELocale;
  hostData: THost;
  onChange: (menu: TMenu, data: Partial<TMenuForm>) => Promise<any>;
  onUnPublish: (menu: TMenu) => Promise<any>;
  onArchive: (menu: TMenu) => Promise<any>;
  onPublish: (menu: TMenu) => Promise<any>;
  onDuplicate: (menu: TMenu) => Promise<any>;
  onEditLocalePress: (menu: TMenu, locale: ELocale) => void;
  onLocaleDeletePress: (menu: TMenu, locale: ELocale) => void;
  onCreateSubMenu: (data: TMenuForm) => Promise<any>;
};

export const MenuMainView = ({
  locale,
  hostData,
  onChange,
  onUnPublish,
  onArchive,
  onPublish,
  onDuplicate,
  onEditLocalePress,
  onLocaleDeletePress,
  onCreateSubMenu,
}: TMenuMainViewProps) => {
  return (
    <Root>
      <ScreenTitleText>Crea Menu</ScreenTitleText>
      <ContentRoot>
        <FluidContainer>
          <StyledRow>
            {Menu.sortSubMenusByHiddenStatus(hostData.menu)
              .map((menu) => (
                <StyledCol
                  key={menu[FIELD_ID]}
                  sm={12}
                  md={6}
                  lg={4}>
                  <MenuMainFormCard
                    locale={locale}
                    hostData={hostData}
                    menu={menu}
                    onChange={onChange}
                    onPublish={onPublish}
                    onUnPublish={onUnPublish}
                    onArchive={onArchive}
                    onDuplicate={onDuplicate}
                    onEditLocalePress={onEditLocalePress}
                    onLocaleDeletePress={onLocaleDeletePress}
                  />
                </StyledCol>
              ))}
            <StyledCol
              sm={12}
              md={6}
              lg={4}>
              <MenuMainFormCardCreate
                key={`new-menu-${_.size(hostData.menu.subMenus)}`}
                hostData={hostData}
                locale={locale}
                index={_.size(hostData.menu.subMenus)}
                onCreate={onCreateSubMenu}
              />
            </StyledCol>
          </StyledRow>
        </FluidContainer>
      </ContentRoot>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${colLg}px;
  width: 100%;
`;

const ScreenTitleText = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
`;

const StyledRow = styled(Row)`
`;

const StyledCol = styled(Col)`
  margin-top: 30px;
`;

const ContentRoot = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1;
  max-width: ${colLg}px;
`;
