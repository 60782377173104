import { ServiceError } from '../error/ServiceError';
import { TArchivedMObj } from '../../models/db/archivedMObj/ArchivedMObjTypes';
import { TDbResName } from '../../db/DbResources';
import { objToUrlParams } from '../HelperFunctions';
import fetch, { Response } from 'node-fetch';
import { AlacarteUrls } from '../AlacarteUrls';

export type TSrvFirebaseSyncParams<RK extends TDbResName> = {
  resource: RK;
  ownerUid?: string;
  pathMatcher?: string;
  fromMs?: number;
  toMs?: number;
};

class SrvFirebaseSyncBase {
  private readonly baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.getArchivedResource = this.getArchivedResource.bind(this);
  }

  private get = async (tokenId: string, subUrl: string) => {
    const response = await fetch(`${this.baseUrl}${subUrl}`, { headers: { Authorization: `Bearer ${tokenId}` } });
    return this.handleResponse(response);
  };

  private handleResponse = async (response: Response) => {
    if (!response.ok) {
      const text = await response.text();
      throw ServiceError.pack(text);
    }

    return response.json();
  };

  getArchivedResource<RK extends TDbResName>(firebaseIdToken: string, params: TSrvFirebaseSyncParams<RK>): Promise<TArchivedMObj<RK>[]> {
    return this.get(firebaseIdToken, `/${params.resource}?${objToUrlParams(params)}`);
  }
}

// todo deprecate in favour of Requestable.wrapApi
export const SrvFirebaseSync = new SrvFirebaseSyncBase(AlacarteUrls.BASE_URL_ARCHIVE_SYNC.https);
