import { CurryFunc } from '../../../core/src/lib/Types';
import { ErrorHandler } from '../../../core/src/lib/error/ErrorHandler';
import { SnackbarOptions } from '../lib/snackbars/SnackbarOptions';
import { Snackbars } from '../lib/snackbars/Snackbars';
import { TDispatch } from '../../../lib-react/src/redux/redux';
import {
  TGetState,
  TState,
} from './Store';
import { select } from 'redux-saga/effects';
import { ELocale } from '../../../core/src/locale/Locale';

export function handleError(locale: ELocale, error: any) {
  const handledError = ErrorHandler.handle(error);
  const resolvedError = ErrorHandler.resolve(handledError.knownError, {});
  Snackbars.enqueueSnackbar({
    content: resolvedError.message(locale),
    options: SnackbarOptions.globalError(),
  });
}

export function sagaWithErrorHandler<T extends (...args: any[]) => any>(func: T) {
  return function* (...args: Parameters<T>) {
    try {
      return yield func(...args);
    } catch (error) {
      return yield sagaErrorHandler(error);
    }
  };
}

export function* sagaErrorHandler(error: any) {
  const locale: ELocale = yield select((state: TState) => state.app.locale);
  handleError(locale, error);
}

export function dispatchWithErrorHandler<T extends (...args: any[]) => any>(func: T): CurryFunc<T> {
  return function (...args: Parameters<T>) {
    return async (dispatch) => {
      try {
        return await dispatch(await func(...args));
      } catch (error) {
        return dispatch(actionErrorHandler(error));
      }
    };
  } as CurryFunc<T>;
}

export function actionErrorHandler(error: any) {
  return (dispatch: TDispatch, getState: TGetState) => {
    const { locale } = getState().app;
    handleError(locale, error);
  };
}
