import { Colors as CoreColors } from '../../../core/src/config/Colors';
import { TStyledComponentsTheme } from '../lib-components/theme/StyledComponentsTheme';

export const Colors = CoreColors;

export type TTheme = TStyledComponentsTheme<typeof Theme>;

export const Theme = {
  col: Colors,
  font: { nunitoSans: 'Nunito Sans' },

  // Keeps track of the content height
  // that is not drawable on the screen
  drawableContentOffset: 0,
};

/**
 * Change theme type on styled() components
 */
declare module 'styled-components' {
  type Theme = TTheme;

  // @ts-ignore
  export type DefaultTheme = TTheme;
}
