import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useSyncedDataRef } from '../hooks/useSyncedDataRef';
import { WindowContext } from './WindowContext';

export const URLStateContext = React.createContext({
  url: '',
  setUrl: ((url: string) => undefined) as (url: string) => void,
});

export type TURLStateContextProviderProps = {
  //
};

export const URLStateContextProvider = ({ children }: PropsWithChildren<TURLStateContextProviderProps>) => {
  const { window, windowRef } = React.useContext(WindowContext);
  const [url, setUrlBase] = React.useState(window.location.href);

  const setUrlBaseRef = useSyncedDataRef(setUrlBase);

  // If state changes, change browser URL
  const setUrl = React.useCallback((u: string) => {
    setUrlBaseRef.current(u);
    windowRef.current.history.replaceState(null, null as any, u as any);
  }, [windowRef, setUrlBaseRef]);

  return (
    <URLStateContext.Provider
      value={{
        url,
        setUrl,
      }}
    >
      {children}
    </URLStateContext.Provider>
  );
};

