import * as React from 'react';
import * as _ from 'lodash';
import { EMessageSender } from '../../../core/src/models/db/hostMessage/HostMessageTypes';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../core/src/db/DbDefs';
import { FullScreenErrorView } from '../components/fullScreenViews/FullScreenErrorView';
import { FullScreenLoadingView } from '../components/fullScreenViews/FullScreenLoadingView';
import { Hist } from '../lib/navigation/History';
import { HostMessageSuggestions } from '../lib/HostMessageSuggestions';
import { MaxHeightDiv } from '../components/MaxHeightDiv';
import { MessagesView } from '../components/hostMessages/MessagesView';
import { Re2direct } from '../components/router/Re2direct';
import { Routes } from '../config/Routes';
import { SecondaryNavbar } from '../components/navbars/SecondaryNavbar';
import { Strings } from '../../../core/src/locale/Strings';
import { THostId } from '../../../core/src/models/db/host/HostTypes';
import {
  TPropCheck,
  withPropCheckMiddleware,
} from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import {
  TScreenNavigationProps,
  getNavParam,
} from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import { TStationId } from '../../../core/src/models/db/station/StationTypes';
import { TTableId } from '../../../core/src/models/db/table/TableTypes';
import { actionHostMessagesSendMessage } from '../redux/HostMessages';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import { connect } from 'react-redux';
import styled from 'styled-components';

export type TScreenHostGuestSessionMessagesNavParams = {
  hostId: THostId;
  tableId: TTableId;
  stationId?: TStationId;
};

export type TScreenHostGuestSessionMessagesProps = TScreenNavigationProps<TScreenHostGuestSessionMessagesNavParams> & {
  //
};

type TScreenHostGuestSessionMessagesBaseProps = TScreenHostGuestSessionMessagesProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TScreenHostGuestSessionMessagesBaseState = {
  //
};

class ScreenHostGuestSessionMessagesBase extends React.Component<TPropCheck<typeof ScreenHostGuestSessionMessagesBase>, TScreenHostGuestSessionMessagesBaseState> {
  static checkProps(props: TScreenHostGuestSessionMessagesBaseProps) {
    const {
      locale,
      stationId,
      tableId,
      hostData,
      guestSession,
    } = props;

    if (hostData == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    const tableNr = _.get(hostData.tables, `${tableId}.${FIELD_NAME}`) as unknown as string;
    if (guestSession == null || tableNr == null) {
      return () => (
        <Re2direct
          route={Routes.ScreenHostGuestSessionOverview}
          params={{
            hostId: hostData[FIELD_ID],
            tableId,
          }}
        />
      );
    }

    const firstStationId = _.get(_.values(hostData.stations), `[0][${FIELD_ID}]`);
    const existingStationId = stationId || firstStationId;
    if (existingStationId == null) {
      const hostId = hostData[FIELD_ID];
      return () => (
        <FullScreenErrorView
          locale={locale}
          title={''}
          message={Strings.hStationNoneToContact()(locale)}
          actionText={Strings.hBackToTableCheck({ tableNr })(locale)}
          actionOnClick={() => Hist.replace(Routes.ScreenHostGuestSessionOverview, {
            hostId,
            tableId,
          })}
        />
      );
    }

    if (existingStationId != stationId) {
      return () => (
        <Re2direct
          route={Routes.ScreenHostGuestSessionMessages}
          params={{
            hostId: hostData[FIELD_ID],
            tableId,
            stationId: existingStationId,
          }}
        />
      );
    }

    return {
      ...props,
      stationId: existingStationId,
      guestSession,
      hostData,
    };
  }

  navigateToStationChat = (stationId: TStationId) => {
    const {
      tableId,
      hostData,
    } = this.props;
    Hist.replace(Routes.ScreenHostGuestSessionMessages, {
      hostId: hostData[FIELD_ID],
      tableId,
      stationId,
    });
  };

  onMessageSend = async (destStationId: TStationId, message: string) => {
    const {
      tableId,
      hostMessagesSendMessage,
    } = this.props;
    await hostMessagesSendMessage(destStationId, {
      text: message,
      sender: EMessageSender.HOST,
      delayMs: 0,
      tableId,
    });
  };

  render() {
    const {
      locale,
      stationId,
      hostData,
      hostMessages,
      messageSuggestions,
    } = this.props;
    return (
      <Root>
        {this.renderSecondaryNavbar()}
        <ContentRoot>
          <MessagesView
            locale={locale}
            hostData={hostData}
            previousMessages={_.values(hostMessages)}
            selectedStationId={stationId}
            setSelectedStationId={this.navigateToStationChat}
            onMessageSend={this.onMessageSend}
            messageSuggestions={messageSuggestions || []}
          />
        </ContentRoot>
      </Root>
    );
  }

  private renderSecondaryNavbar = () => {
    const {
      locale,
      tableId,
      hostData,
    } = this.props;
    const tableNameStr = Strings.hTableID({ tableNr: _.get(hostData.tables, `[${tableId}][${FIELD_NAME}]`, '') as string })(locale);
    return (
      <SecondaryNavbar
        locale={locale}
        hostId={hostData[FIELD_ID]}
        name={tableNameStr}
      />
    );
  };
}

function mapStateToProps(state: TState, ownProps: TScreenHostGuestSessionMessagesProps) {
  const tableId = getNavParam(ownProps, 'tableId');
  const { hostData } = state.host;
  const stationId = getNavParam(ownProps, 'stationId');

  const hostMessages = _.values(_.get(state.hostMessages.hostMessages, `${stationId}`))
    .filter((hostMessage) => hostMessage.tableId == tableId);

  return {
    tableId,
    stationId,
    locale: state.app.locale,
    hostData,
    guestSession: _.get(state.host.hostGuestSessions, tableId),
    hostMessages,
    messageSuggestions: stationId && HostMessageSuggestions.getSuggestionsForStation(stationId, 3),
  };
}

function mapDispatchToProps(dispatch) {
  return { hostMessagesSendMessage: bindActionCreators(actionHostMessagesSendMessage, dispatch) };
}

export const ScreenHostGuestSessionMessages = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(ScreenHostGuestSessionMessagesBase));

const Root = styled.div`
`;

const ContentRoot = styled(MaxHeightDiv)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

