import * as React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { SquareButton } from '../Button/SquareButton';
import styled from 'styled-components';

type TFullScreenModalProps = {
  title: string;
  body?: string;
  bodyNode?: React.ReactNode;
  leftButtonText: string;
  leftButtonOnClick: () => void;
  rightButtonText: string;
  rightButtonOnClick: () => void;
  onDismissClick: () => void;
};

export const FullScreenModal = ({
  title,
  body,
  bodyNode,
  leftButtonText,
  leftButtonOnClick,
  rightButtonText,
  rightButtonOnClick,
  onDismissClick,
}: TFullScreenModalProps) => {
  return (
    <FullScreenContainer>
      <ClickAwayListener onClickAway={onDismissClick}>
        <Container>
          {onDismissClick && (
            <IconContainer onClick={onDismissClick}>
              <CloseIcon/>
            </IconContainer>
          )}
          <TitleText>{title}</TitleText>
          {body && <ParagraphText>{body}</ParagraphText>}
          {bodyNode}
          <ButtonsContainer>
            <SquareButtonLeft
              title={rightButtonText}
              onClick={rightButtonOnClick}
            />
            <SquareButtonRight
              onClick={leftButtonOnClick}
              title={leftButtonText}
            />
          </ButtonsContainer>
        </Container>
      </ClickAwayListener>
    </FullScreenContainer>
  );
};

const FullScreenContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
`;

const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-height: 380px;
  max-width: 530px;
  position: relative;
  width: 100%;
`;

const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 30px;
`;

const TitleText = styled.h2`
  color: ${({ theme }) => theme.t.col.lightDark};
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  margin-bottom: 20px;

  text-align: center;

  width: 70%;
`;

const ParagraphText = styled.p`
  color: ${({ theme }) => theme.t.col.lightDark};
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 30px;
  text-align: center;
  width: 68%;
`;

const ButtonsContainer = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;;
  flex-direction: row;
  margin: 0 auto;
  width: 68%;
`;

const SquareButtonLeft = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.accentLight};
  margin-right: 20px;
`;

const SquareButtonRight = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.accentDark};
`;
