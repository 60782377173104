import * as React from 'react';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { Menu } from '../../../../core/src/models/db/menu/Menu';
import {
  MenuEditorMenuItemListItem,
  menuEditorGrid,
} from './MenuEditorMenuItemListItem';
import { Strings } from '../../../../core/src/locale/Strings';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TMenu } from '../../../../core/src/models/db/menu/MenuTypes';
import { TMenuItem } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';
import { Languages } from '../../../../core/src/locale/Languages';

type TMenuEditorViewProps = {
  locale: ELocale;
  hostData: THost;
  menuData: TMenu;
  menuLocale: ELocale;
  onMenuItemNameChange: (menuItem: TMenuItem, newName: string) => void;
  onMenuItemEditClick: (menuItem: TMenuItem) => void;
  onMenuItemDuplicate: (menuItem: TMenuItem) => void;
  onMenuItemArchive: (menuItem: TMenuItem) => void;
  onMenuItemPublish: (menuItem: TMenuItem) => void;
  onMenuItemUnPublish: (menuItem: TMenuItem) => void;
};

export const MenuEditorView = ({
  locale,
  hostData,
  menuData,
  menuLocale,
  onMenuItemNameChange,
  onMenuItemEditClick,
  onMenuItemDuplicate,
  onMenuItemArchive,
  onMenuItemPublish,
  onMenuItemUnPublish,
}: TMenuEditorViewProps) => {
  const menuItems = Menu.menuItemsInMenu(menuData, hostData);
  const languageName = Languages[menuLocale]?.language ?? 'Language';
  return (
    <Root>
      <Content>
        <TableHeader>
          <HeaderElementHash>#</HeaderElementHash>
          <HeaderElement>{Strings.hMenuName()(locale)} ({languageName})</HeaderElement>
          <HeaderElement>{Strings.hMenuItemPriceCol()(locale)}</HeaderElement>
          <HeaderElement>{Strings.hMenuDescription()(locale)} ({languageName})</HeaderElement>
          <HeaderElement>{Strings.hMenuItemStatusCol()(locale)}</HeaderElement>
          <HeaderElement>{Strings.hMenuItemActionsCol()(locale)}</HeaderElement>
        </TableHeader>
        <TableContent>
          {menuItems.map((menuItem) => (
            <MenuEditorMenuItemListItem
              key={menuItem[FIELD_ID]}
              locale={menuLocale}
              menuItem={menuItem}
              viewLocale={menuLocale}
              onMenuItemNameChange={onMenuItemNameChange}
              onMenuItemEdit={onMenuItemEditClick}
              onMenuItemDuplicate={onMenuItemDuplicate}
              onMenuItemArchive={onMenuItemArchive}
              onMenuItemPublish={onMenuItemPublish}
              onMenuItemUnPublish={onMenuItemUnPublish}
            />
          ))}
          <TableFooter>
            {/*{Strings.hMenuItemDto()(locale)}*/}
          </TableFooter>
        </TableContent>
      </Content>
    </Root>
  );
};

const tableHeaderContainerHeight = 42;
const tableContentZIndex = 100;
const tableContentItemPadding = 10;

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Content = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TableHeader = styled.div`
  ${menuEditorGrid}
  color: ${({ theme }) => theme.t.col.white};
  font-size: 14px;
  font-weight: 600;
  height: ${tableHeaderContainerHeight}px;
  line-height: 19px;
  overflow: hidden;
  position: fixed;
  text-overflow: ellipsis;
  top: ${({ theme }) => theme.t.drawableContentOffset};
  white-space: nowrap;
  width: 100vw;
  z-index: ${tableContentZIndex + 1};
`;

const HeaderElement = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.accentDark};
  justify-content: flex-start;
  max-width: 100%;
  overflow: hidden;
  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.t.col.accentLight};
  }
  padding: ${tableContentItemPadding}px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const HeaderElementHash = styled(HeaderElement)`
  background-color: ${({ theme }) => theme.t.col.red};
  justify-content: center;
`;

const TableContent = styled.div`
  margin-top: ${tableHeaderContainerHeight}px;
  max-height: calc(100% - ${tableHeaderContainerHeight}px);
  overflow: scroll;
  z-index: ${tableContentZIndex};
`;

const TableFooter = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.t.col.accentLight};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: center;
  line-height: 20px;
  margin: 30px auto;
  text-align: center;
`;
