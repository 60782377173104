import * as React from 'react';
import {
  EFullScreenModal,
  actionFullScreenModalHide,
} from '../../redux/FullScreenModal';
import { GuestSessionCloseModal } from './GuestSessionCloseModal';
import { TState } from '../../redux/Store';
import { useDispatch } from '../../../../lib-react/src/redux/redux';
import { useSelector } from 'react-redux';
import { MenuTranslatorModal } from "./MenuTranslatorModal";

type TFullScreenModalsProps = {
  //
};

export const FullScreenModals = (props: TFullScreenModalsProps) => {
  const dispatch = useDispatch();
  const locale = useSelector((state: TState) => state.app.locale);

  const onDismissClick = React.useCallback(() => {
    dispatch(actionFullScreenModalHide());
  }, []);

  const { type, params } = useSelector((state: TState) => state.fullScreenModal);
  return (
    <>
      {EFullScreenModal.guestSessionClose === type && (
        <GuestSessionCloseModal
          {...params}
          locale={locale}
          onDismissClick={onDismissClick}
        />
      )}
      {EFullScreenModal.menuTranslator === type && (
        <MenuTranslatorModal
          {...params}
          locale={locale}
          onDismissClick={onDismissClick}
        />
      )}
    </>
  );
};

