import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { FieldInputListItem } from './duplexInputList/FieldInputListItem';
import { FieldPickableSortableListItem } from './FieldPickableSortableListItem';
import { TFieldInputListItem } from './duplexInputList/FieldInputList';
import { fpPush } from '../../../../core/src/lib/HelperFunctions';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';
import styled from 'styled-components';

export type TFieldPickableSortableItem = TFieldInputListItem & {
  id: string;
};

export type TFieldPickableSortableListSelectableItem = Omit<TFieldPickableSortableItem, 'actionable'> & {
  //
};

export function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export type TFieldPickableSortableListProps<T> = {
  componentId: string;
  value: T[];
  onChange: (items: T[]) => void;
  mapListItemToValue: (listItem: TFieldPickableSortableItem) => T;
  mapValueToListItem: (value: T) => TFieldPickableSortableItem;
  onAction?: (value: T) => void;
  listItemAction?: React.ReactNode;
  onAction2?: (value: T) => void;
  listItemAction2?: React.ReactNode;
  newItem?: T;
  newItemSet?: (value: T) => void;
  newItemReset?: () => void;
  selectableItems?: T[];
  className?: string;
  newItemMainPlaceholder?: string;
  newItemSecondaryPlaceholder?: string;
  mapNewValueToListItem?: (value: T) => Omit<TFieldPickableSortableItem, 'actionable'>;
};

function FieldPickableSortableListBase<T>({
  componentId,
  value,
  onChange,
  mapListItemToValue,
  mapValueToListItem,
  onAction,
  listItemAction,
  onAction2,
  listItemAction2,
  newItem,
  className,
  selectableItems,
  newItemMainPlaceholder = '',
  newItemSecondaryPlaceholder = '',
  mapNewValueToListItem = mapValueToListItem,
}: TFieldPickableSortableListProps<T>) {
  const valueRef = useSyncedDataRef(value);
  const mapListItemToValueRef = useSyncedDataRef(mapListItemToValue);

  const onDragEnd = React.useCallback((result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      valueRef.current,
      result.source.index,
      result.destination.index,
    );

    // @ts-ignore
    onChange(items);
  }, [onChange]);

  const onListItemAction = React.useCallback((index: number, listItem: TFieldPickableSortableItem) => {
    onAction?.(mapListItemToValueRef.current(listItem));
  }, [onAction]);

  const onListItemAction2 = React.useCallback((index: number, listItem: TFieldPickableSortableItem) => {
    onAction2?.(mapListItemToValueRef.current(listItem));
  }, [onAction2]);

  const onNewItemSelect = React.useCallback((listItem: TFieldPickableSortableListSelectableItem) => {
    const val = valueRef.current;
    onChange(fpPush(val, mapListItemToValueRef.current(listItem)));
  }, [onChange]);

  const listItems = value.map(mapValueToListItem);
  const newItemListItem = newItem != null && mapNewValueToListItem(newItem);
  return (
    <Root className={className}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={componentId}>
          {(providedA, snapshotA) => (
            <DroppableRoot
              {...providedA.droppableProps}
              ref={providedA.innerRef}
              isDraggingOver={snapshotA.isDraggingOver}
            >
              {listItems.map((item: TFieldPickableSortableItem, index) => (
                <Draggable
                  key={index}
                  draggableId={item.id}
                  index={index}
                  disableInteractiveElementBlocking={true}>
                  {(providedB, snapshotB) => (
                    <DraggableRoot
                      ref={providedB.innerRef}
                      {...providedB.draggableProps}
                      {...providedB.dragHandleProps}
                      style={providedB.draggableProps.style}
                      isDragging={snapshotB.isDragging}
                    >
                      <StyledFieldInputListItem<any>
                        index={index}
                        listItem={item}
                        onAction={onListItemAction}
                        action={listItemAction}
                        onAction2={onListItemAction2}
                        action2={listItemAction2}
                        autoFocus={false}
                        readOnly={true}
                      />
                    </DraggableRoot>
                  )}
                </Draggable>
              ))}
              {(!!newItemListItem && (!selectableItems || selectableItems.length > 0)) && (
                <StyledFieldPickableSortableListItem
                  key={listItems.length}
                  componentId={`${componentId}-${listItems.length}`}
                  index={listItems.length}
                  listItem={newItemListItem}
                  selectableItems={(selectableItems || []).map(mapValueToListItem)}
                  onSelectableItemSelect={onNewItemSelect}
                  action={undefined}
                  mainPlaceholder={newItemMainPlaceholder}
                  secondaryPlaceholder={newItemSecondaryPlaceholder}
                />
              )}
            </DroppableRoot>
          )}
        </Droppable>
      </DragDropContext>
    </Root>
  );
}

export const FieldPickableSortableList = React.memo(FieldPickableSortableListBase) as typeof FieldPickableSortableListBase;

const Root = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

type TDroppableRootProps = {
  isDraggingOver: boolean;
};

const DroppableRoot = styled.div<TDroppableRootProps>`
  background: ${({ theme, isDraggingOver }) => (!isDraggingOver ? theme.t.col.accentLight : theme.t.col.white)};
  background-color: ${({ theme, isDraggingOver }) => (!isDraggingOver ? theme.t.col.accentLight : theme.t.col.white)};
`;

type TDraggableRootProps = {
  isDragging: boolean;
};

const DraggableRoot = styled.div<TDraggableRootProps>`
  background: ${({ theme, isDragging }) => (isDragging ? theme.t.col.lightGray3 : theme.t.col.white)};
  background-color: ${({ theme, isDragging }) => (isDragging ? theme.t.col.lightGray3 : theme.t.col.white)};
  margin-top: -1px;
  user-select: none;
`;

const StyledFieldInputListItem = styled(FieldInputListItem)`
  background-color: ${({ theme }) => theme.t.col.white};
`;

const StyledFieldPickableSortableListItem = styled(FieldPickableSortableListItem)`
  background-color: ${({ theme }) => theme.t.col.white};
  margin-top: -1px;
`;
