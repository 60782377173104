import * as React from 'react';
import { AlertNavbarLink } from './links/AlertNavbarLink';
import { FIELD_NAME } from '../../../../core/src/db/DbDefs';
import { PrimaryNavbarLink } from './links/PrimaryNavbarLink';
import { SecondaryNavbarLink } from './links/SecondaryNavbarLink';
import { Strings } from '../../../../core/src/locale/Strings';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { hexToRgbaString } from '../../../../core/src/lib/Color';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';
import { Lang } from "../Lang";

type THostNavbarMainBaseProps = {
  locale: ELocale;
  hostData: THost;
  onSignOut: () => Promise<any>;
  hostSelected: boolean;
  onHostOverviewClick: () => void;
  hostProfileSelected: boolean;
  onHostProfileClick: () => void;
  hostMenuSelected: boolean;
  onHostMenuClick: () => void;
  hostSettingsSelected: boolean;
  onHostSettingsClick: () => void;
};

export const HostNavbarMainBase = ({
                                     locale,
                                     hostData,
                                     onSignOut,
                                     hostSelected,
                                     onHostOverviewClick,
                                     hostProfileSelected,
                                     onHostProfileClick,
                                     hostMenuSelected,
                                     onHostMenuClick,
                                     hostSettingsSelected,
                                     onHostSettingsClick,
                                   }: THostNavbarMainBaseProps) => {
  return (
    <Root>
      <Content>
        <LeftContainer>
          <HostNameSpan>
            {hostData[FIELD_NAME]}
          </HostNameSpan>
        </LeftContainer>
        <CenterContainer>
          <LinkContainer>
            {[
              {
                title: Strings.hHostHomeTab()(locale),
                onClick: onHostOverviewClick,
                selected: hostSelected,
              },
              {
                title: Strings.hMenuTab()(locale),
                onClick: onHostMenuClick,
                selected: hostMenuSelected,
              },
              {
                title: Strings.hProfileTab()(locale),
                onClick: onHostProfileClick,
                selected: hostProfileSelected,
              },
            ].map((navbarItem) => (
              <PrimaryNavbarLink
                key={navbarItem.title}
                {...navbarItem}
              />
            ))}
          </LinkContainer>
        </CenterContainer>
        <RightContainer>
          <RightLinksContainer>
            <LangContainer>
              <Lang/>
            </LangContainer>
            {[
              {
                title: Strings.hSettings()(locale),
                onClick: onHostSettingsClick,
                selected: hostSettingsSelected,
              },
            ].map((navbarItem) => (
              <SecondaryNavbarLink
                key={navbarItem.title}
                {...navbarItem}
              />
            ))}
            {[
              {
                title: Strings.hLocaleBtn()(locale),
                onClick: onSignOut,
              },
            ].map((navbarItem) => (
              <AlertNavbarLink
                key={navbarItem.title}
                {...navbarItem}
              />
            ))}
          </RightLinksContainer>
        </RightContainer>
      </Content>
    </Root>
  );
};

const flexTotal = 1;
const flexLeftRightContainers = 0.2;
const flexCenterContainer = flexTotal - (flexLeftRightContainers * 2);
export const hostNavbarMainBaseHeight = 60;

const Root = styled.div`
  background-color: ${({ theme }) => theme.t.col.white};
  border-bottom: 1px solid ${({ theme }) => hexToRgbaString(theme.t.col.accentDark, 0.2)};
  display: flex;
  height: ${hostNavbarMainBaseHeight}px;
  width: 100vw;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex: ${flexTotal};
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 10px;
`;

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

const LeftContainer = styled(FlexContainer)`
  flex: ${flexLeftRightContainers};
  justify-content: flex-start;
`;

const CenterContainer = styled(FlexContainer)`
  flex: ${flexCenterContainer};
  justify-content: center;
`;

const RightContainer = styled(FlexContainer)`
  flex: ${flexLeftRightContainers};
  justify-content: flex-end;
`;

const HostNameSpan = styled.span`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-size: 16px;
  font-weight: 600;
  text-align: left;
`;

const LinkContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
`;

const RightLinksContainer = styled(LinkContainer)`
  justify-content: space-between;
  width: 200px;
`;

const LangContainer = styled.div`
  margin-top: -4.5px;
`;

