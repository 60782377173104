import { CIInterface } from '../../config/CIInterface';
import {
  FIELD_ID,
  FIELD_NAME,
  TObjList,
} from '../../db/DbDefs';
import { TMObjDataCreator } from '../../lib/model/ModelTypes';
import {
  TMenu,
  TMenuId,
} from '../db/menu/MenuTypes';
import { TRKMenu } from '../../db/DbResources';
import { Validator } from '../../lib/error/Validators';
import { buildObjValidator } from '../../lib/error/lib/buildObjValidator';
import {LanguageCodes, TLanguage} from '../../locale/Languages';

const validateMenuId = Validator.applyAll<string>([
  Validator.expectSet,
  Validator.expectUUID,
]);

const validateMenuName = Validator.applyAll<string>([
  Validator.expectSet,
  Validator.expectStrOfLen({ max: CIInterface.validateMenuNameMaxLen }),
]);

const validateIsHidden = Validator.applyAll<boolean>([
  Validator.expectSet,
  Validator.expectBool,
]);

const validateDescription = Validator.applyAll<string>([
  Validator.expectSet,
  Validator.expectStrOfLen({ max: CIInterface.validateMenuDescriptionMaxLen }),
]);

const validateCoverCharge = Validator.applyAll<number>([
  Validator.expectSet,
  Validator.expectNumInRange({
    min: CIInterface.validateMenuCoverChargeMin,
    max: CIInterface.validateMenuCoverChargeMax,
  }),
]);

const validateDefaultLocale = Validator.applyAll<TLanguage>([
  Validator.expectSet,
  Validator.expectOneValueOf({ allowedValues: LanguageCodes }),
]);

const validateSubMenus = Validator.applyAll<TObjList<TMenuId, TMenu>>([
  Validator.applyToObjListAsArr(Validator.expectValidator({
    validator: (menu: TMenu) => {
      return validateMenu(menu);
    },
  })),
]);

export const validateIngredientCreator = buildObjValidator<TMObjDataCreator<TRKMenu>>({
  [FIELD_NAME]: validateMenuName,
  isHidden: validateIsHidden,
  description: validateDescription,
  coverCharge: validateCoverCharge,
  subMenus: validateSubMenus,
  defaultLocale: validateDefaultLocale,
});

export const validateMenu = buildObjValidator<TMenu>({
  [FIELD_ID]: validateMenuId,
  [FIELD_NAME]: validateMenuName,
  isHidden: validateIsHidden,
  description: validateDescription,
  coverCharge: validateCoverCharge,
  subMenus: validateSubMenus,
  defaultLocale: validateDefaultLocale,
});
