import * as _ from 'lodash';
import { NoUndefinedField } from '../../Types';
import { TValidator } from '../ErrorTypes';
import { Validator } from '../Validators';

type TBuildObjValidatorParams<T> = Partial<{
  [K in keyof T]: TValidator<NoUndefinedField<T>[K]>;
}>;

export function buildObjValidator<T>(builder: TBuildObjValidatorParams<T>) {
  const baseValidatorBuilder = Validator.expectPathedObj(builder);

  // Main TValidator
  function func(value: T) {
    return baseValidatorBuilder(value);
  }

  // Add TBuildObjValidatorParams
  _.forOwn(builder, (v, path) => {
    Object.defineProperty(func, path, {
      value: builder[path],
      writable: false,
    });
  });
  return func as TBuildObjValidatorParams<T> & TValidator<T>;
}
