import * as _ from 'lodash';
import { FIELD_ID } from '../../../core/src/db/DbDefs';
import { HostMessageBuilder } from '../../../core/src/models/db/hostMessage/HostMessageBuilder';
import { THostMessage } from '../../../core/src/models/db/hostMessage/HostMessageTypes';
import { TStationId } from '../../../core/src/models/db/station/StationTypes';

const sortedSuggestionsByStationId = {} as {
  // array of [index=count, value={ [lcMessageText]: hostMessage.text }]
  [stationId: string]: { [lowerCaseMessageText: string]: string }[];
};

const messageSuggestions = {} as {
  [stationId: string]: {
    [lowerCaseMessageText: string]: {
      [messageId: string]: 1;
    };
  };
};

export class HostMessageSuggestionsBase {
  clear = () => {
    Object.assign(sortedSuggestionsByStationId, null);
    Object.assign(sortedSuggestionsByStationId, {});
    Object.assign(messageSuggestions, null);
    Object.assign(messageSuggestions, {});
  };

  addUnique = (hostMessages: THostMessage[]) => {
    hostMessages.forEach((hostMessage) => {
      const { stationId } = HostMessageBuilder.getPathBuilderParamsFromDataPath(hostMessage);

      const hostMessageId = hostMessage[FIELD_ID];
      const lcMessageText = hostMessage.text.toLowerCase();

      // Check if message was already counted
      if (_.get(messageSuggestions, `${stationId}.${lcMessageText}.${hostMessageId}`) != null) {
        // Already counted, return
        return;
      }

      // Count
      const currentCount = _.size(_.get(messageSuggestions, `${stationId}.${lcMessageText}`));
      const nextCount = currentCount + 1;

      // Update unique suggestions array
      _.set(messageSuggestions, `${stationId}.${lcMessageText}.${hostMessageId}`, 1);

      // Remove lcMessageText from currentCountObj
      const currentCountObj = _.get(sortedSuggestionsByStationId, `${stationId}[${currentCount}]`, {}) as any;
      const newCurrentCountObj = _.omitBy(currentCountObj, (val, el) => el == lcMessageText);
      _.set(sortedSuggestionsByStationId, `${stationId}[${currentCount}]`, newCurrentCountObj);

      // Add lc to nextCountObj
      _.set(sortedSuggestionsByStationId, `${stationId}[${nextCount}].${lcMessageText}`, hostMessage.text);
    });
  };

  getSuggestionsForStation = (stationId: TStationId, numberOfSuggestions: number) => {
    const result = [] as string[];
    if (numberOfSuggestions <= 0) {
      return result;
    }

    const suggestionsForStation = sortedSuggestionsByStationId[stationId] || [];

    /* eslint-disable */
    PickerLoop:
    for (let i = suggestionsForStation.length - 1; i >= 0; i--) {
      const suggestionAtIndex = suggestionsForStation[i];
      if (_.isEmpty(suggestionAtIndex)) {
        continue;
      }

      const keys = _.keys(suggestionAtIndex);
      for (let k = 0; k < keys.length; k++) {
        result.push(suggestionAtIndex[keys[k]]);
        if (result.length >= numberOfSuggestions) {
          break PickerLoop;
        }
      }
    }

    return result.sort();
  };
}

export const HostMessageSuggestions = new HostMessageSuggestionsBase();
