import * as _ from 'lodash';
import {ELocale} from '../../../core/src/locale/Locale';
import {Languages, TLanguage} from '../../../core/src/locale/Languages';

export function formatWithLocale(
  getString: ((locale: ELocale) => string) | string,
  locale: ELocale | TLanguage,
  viewLocale: ELocale | TLanguage,
) {
  const localized = _.isFunction(getString)
    ? getString(locale as ELocale)
    : getString;

  const maybeSpace = !_.isEmpty(localized)
    ? ' '
    : '';

  const localeName = Languages[viewLocale]
    ? Languages[viewLocale].language
    : '';

  const wrappedLocaleName = !_.isEmpty(localeName)
    ? `(${localeName})`
    : '';

  return `${localized}${maybeSpace}${wrappedLocaleName}`;
}
