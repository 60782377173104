import * as _ from 'lodash';
import { KnownError } from '../../../../core/src/lib/error/KnownError';
import { Validator } from '../../../../core/src/lib/error/Validators';
import { useFormEmailField } from '../../../../lib-react/src/hooks/form/useFormEmailField';
import { useFormHandler } from '../../../../lib-react/src/hooks/form/useFormHandler';
import { useFormStringField } from '../../../../lib-react/src/hooks/form/useFormStringField';

export type TSettingsForm = {
  newEmail?: string;
  newPassword?: string;
};

export type TSettingsFormParams = {
  onSubmit: (data: TSettingsForm) => Promise<any>;
};

export function useSettingsFormHandler({ onSubmit }: TSettingsFormParams) {
  return useFormHandler({
    onSubmit,
    fields: {
      newEmail: useFormEmailField({ required: false }),

      confirmNewEmail: useFormEmailField({
        required: (value, context) => {
          return !_.isEmpty(_.get(context, 'newEmail.value'));
        },
        valueToError: (value, context) => {
          if (_.get(context, 'newEmail.value') !== value) {
            return [
              Validator.make(KnownError.inputEmailsDontMatch as any),
            ];
          }
          return [];
        },
      }),

      newPassword: useFormStringField({
        required: false,
        resetOnError: true,
      }),

      confirmNewPassword: useFormStringField({
        resetOnError: true,
        required: (value, context) => {
          return !_.isEmpty(_.get(context, 'newPassword.value'));
        },
        valueToError: (value, context) => {
          if (_.get(context, 'newPassword.value') !== value) {
            return [
              Validator.make(KnownError.inputPasswordsDontMatch as any),
            ];
          }
          return [];
        },
      }),

      currentPassword: useFormStringField({
        resetOnError: true,
        required: (value, context) => {
          return !_.isEmpty(_.get(context, 'newPassword.value'));
        },
        valueToError: (value, context) => {
          if (!_.isEmpty(_.get(context, 'newPassword.value')) && _.isEmpty(value)) {
            return [
              Validator.make(KnownError.inputPasswordsDontMatch as any),
            ];
          }
          return [];
        },
      }),
    },
  });
}
