import { DbPaths } from '../../../db/DbRefs';
import { DbRes } from '../../../db/DbResources';
import { HostMessage } from './HostMessage';
import { MObjDef } from '../../../lib/model/MObjDef';
import { THostMessage } from './HostMessageTypes';
import { defHostMessage } from '../ModelDefaults';

const pathBuilderKeys = [
  'hostId',
  'stationId',
  'messageId',
] as const;

export type THostMessagePathBuilderParams = {
  [key in ((typeof pathBuilderKeys)[number])]: string
};

export class HostMessageBuilderBase extends MObjDef<typeof DbRes.HostMessage> {
  readonly resourceKey = DbRes.HostMessage;

  protected readonly pathBuilderKeys = [...pathBuilderKeys];

  protected readonly _default = defHostMessage;

  protected newChild(item: THostMessage) {
    return new HostMessage(item);
  }

  pathBuilder(pbp: THostMessagePathBuilderParams): string {
    return DbPaths.hostMessage(pbp);
  }
}

export const HostMessageBuilder = new HostMessageBuilderBase();
