import { Config } from '../config/Config';
import { Firebase } from '../lib/Firebase';
import { HostBuilder } from '../../../core/src/models/db/host/HostBuilder';
import { Log } from '../config/Instance';
import { TFirebaseUser } from '../../../core/src/FirebaseApi';
import {
  defineReducer,
  TThunkAction,
} from '../../../lib-react/src/redux/redux';
import { actions } from './Actions';
import { LocalError } from '../../../core/src/lib/error/LocalError';
import { KnownError } from '../../../core/src/lib/error/KnownError';
import { ELocale } from '../../../core/src/locale/Locale';

export type TStateApp = {
  user: TFirebaseUser | undefined;
  locale: ELocale;
  lockScreen: boolean;
};

const initialState: TStateApp = {
  user: undefined,
  locale: Config.defaultLocale,
  lockScreen: false,
};

export const {
  actionTypeResetToInitialState,
  actionTypeAppSetUser,
  actionTypeAppSetLocale,
  actionTypeAppSetLockScreen,
  reducer: reducerApp,
} = defineReducer<TStateApp, typeof actions>({
  actions,
  initialState,
  reducer(state, action, getType) {
    switch (action.type) {
      case getType(actions.actionTypeResetToInitialState):
        return initialState;
      case getType(actions.actionTypeAppSetUser):
        return {
          ...state,
          user: action.payload.user,
        };
      case getType(actions.actionTypeAppSetLocale):
        return {
          ...state,
          locale: action.payload.locale,
        };
      case getType(actions.actionTypeAppSetLockScreen):
        return {
          ...state,
          lockScreen: action.payload.lockScreen,
        };
      case getType(actions.actionTypeAppSetShowSplashScreen):
        return {
          ...state,
          showSplashScreen: action.payload.showSplashScreen,
        };
    }
    return state;
  },
});

export function actionAppSignInWithEmailAndPassword({
  email,
  password,
}: { email: string; password: string }): TThunkAction<Promise<void>> {
  return async (dispatch) => {
    const authUser = await Firebase.authSignInWithEmailAndPassword(email, password);
    return dispatch(actionAppOnPostSignIn(authUser));
  };
}

export function actionAppOnPostSignIn(authUser: TFirebaseUser): TThunkAction<Promise<void>> {
  return async (dispatch) => {
    Log.v('App', 'actionAppOnPostSignIn', `authUser.uid=${authUser?.uid}`);
    const hostExists = await HostBuilder.dataExistsById({ hostId: authUser.uid });
    if (!hostExists) {
      Log.v('App', 'actionAppOnPostSignIn', `host doesn't exist, creating uid=${authUser.uid} email=${authUser.email}`);
      throw new LocalError('App', 'actionAppOnPostSignIn', KnownError.hostNotFound);
    }

    // Bind app to host
    dispatch(actions.actionTypeResetToInitialState());
    dispatch(actions.actionTypeAppSetHostId({ hostId: authUser.uid }));

    // Keep at end
    dispatch(actionTypeAppSetUser({ user: authUser }));
  };
}

export function actionAppSignOut(): TThunkAction<Promise<void>> {
  return async (dispatch) => {
    Log.v('App', 'actionAppSignOut', 'Signing out');
    await Firebase.authLogout();

    // Unbind app from host
    await dispatch(actionTypeResetToInitialState());
  };
}

export function actionAppOnStoreRehydrate(): TThunkAction<Promise<void>> {
  return async (dispatch) => {
    await dispatch(actionAppWithVarFromStore('user', async (authUser) => {
      dispatch(actions.actionTypeAppSetHostId({ hostId: authUser.uid }));
    }));
  };
}

function actionAppWithVarFromStore<Key extends keyof TStateApp>(
  key: Key,
  cb: (data: NonNullable<TStateApp[Key]>) => Promise<any> | any,
) {
  return async (dispatch, getState) => {
    const data = getState().app[key];
    if (!data) {
      Log.e('redux/App', 'actionAppWithVarFromStore', `${key} not defined in store`);
      return;
    }
    await cb(data);
  };
}

