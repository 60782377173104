import * as React from 'react';
import { FieldStationInputInputField } from './FieldStationInputInputField';
import { TStation } from '../../../../../core/src/models/db/station/StationTypes';
import styled from 'styled-components';

export type TFieldStationInputAdapterNetworkParamsProps = {
  station: Partial<TStation>;
  onChange: (path: string, value: string) => void;
  onItemSave: () => void;
  saveOnBlur?: boolean;
};

export function FieldStationInputAdapterNetworkParams({
  station,
  onChange,
  onItemSave,
  saveOnBlur = true,
}: TFieldStationInputAdapterNetworkParamsProps) {
  return (
    <Root>
      <AddressFieldStationInputInputField
        station={station}
        valuePathInStation={'adapter.params.address'}
        placeholder={'Indirizzo'}
        onChange={onChange}
        onItemSave={onItemSave}
        saveOnBlur={saveOnBlur}
      />
      <PortFieldStationInputInputField
        station={station}
        valuePathInStation={'adapter.params.port'}
        placeholder={'Porta'}
        onChange={onChange}
        onItemSave={onItemSave}
        saveOnBlur={saveOnBlur}
      />
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  & > *:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.t.col.accentLight};
  }
`;

const AddressFieldStationInputInputField = styled(FieldStationInputInputField)`
  flex: 0.7;
  padding: 10px;
`;

const PortFieldStationInputInputField = styled(FieldStationInputInputField)`
  flex: 0.3;
  padding: 10px;
`;
