import * as React from 'react';
import { FIELD_ID } from '../../../core/src/db/DbDefs';
import { Footer } from '../components/Footer';
import { FullScreenLoadingView } from '../components/fullScreenViews/FullScreenLoadingView';
import { Hist } from '../lib/navigation/History';
import { HostNavbarMain } from '../components/navbars/HostNavbarMain';
import {
  MaxHeightDiv,
  MaxScreenDiv,
} from '../components/MaxHeightDiv';
import { ProfileView } from '../components/profile/ProfileView';
import { Routes } from '../config/Routes';
import { Strings } from '../../../core/src/locale/Strings';
import { THostId } from '../../../core/src/models/db/host/HostTypes';
import { TProfileForm } from '../lib/forms/ProfileForm';
import {
  TPropCheck,
  withPropCheckMiddleware,
} from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import { TScreenNavigationProps } from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import { actionHostOnProfileUpdate } from '../redux/actions/Host';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import { connect } from 'react-redux';
import styled from 'styled-components';

export type TScreenHostProfileNavParams = {
  hostId: THostId;
};

export type TScreenHostProfileProps = TScreenNavigationProps<TScreenHostProfileNavParams> & {
  //
};

type TScreenHostProfileBaseProps = TScreenHostProfileProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TScreenHostProfileBaseState = {
  //
};

class ScreenHostProfileBase extends React.Component<TPropCheck<typeof ScreenHostProfileBase>, TScreenHostProfileBaseState> {
  static checkProps(props: TScreenHostProfileBaseProps) {
    const {
      locale,
      hostData,
      hostPublicSubscription,
    } = props;

    if (hostData == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    if (hostPublicSubscription == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    return {
      ...props,
      hostData,
      hostPublicSubscription,
    };
  }

  private onHostCustomizationSave = async (hostDataChange: TProfileForm) => {
    const {
      hostData,
      hostOnProfileUpdate,
    } = this.props;
    await hostOnProfileUpdate(hostData, hostDataChange);

    Hist.push(Routes.ScreenHostHome, { hostId: hostData[FIELD_ID] });
  };

  render() {
    const {
      locale,
      hostData,
      hostPublicSubscription,
    } = this.props;
    return (
      <Root>
        <HostNavbarMain
          locale={locale}
        />
        <ScrollableContent>
          <Content>
            <ProfileView
              locale={locale}
              hostData={hostData}
              hostPublicSubscription={hostPublicSubscription}
              onSubmit={this.onHostCustomizationSave}
            />
          </Content>
        </ScrollableContent>
        <Footer
          locale={locale}
        />
      </Root>
    );
  }
}

function mapStateToProps(state: TState) {
  return {
    locale: state.app.locale,
    hostData: state.host.hostData,
    hostPublicSubscription: state.host.hostPublicSubscription,
  };
}

function mapDispatchToProps(dispatch) {
  return { hostOnProfileUpdate: bindActionCreators(actionHostOnProfileUpdate, dispatch) };
}

export const ScreenHostProfile = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(ScreenHostProfileBase));

const Root = styled(MaxScreenDiv)`
`;

const ScrollableContent = styled(MaxHeightDiv)`
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 42px;
`;

