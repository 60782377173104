import { AppAlacarteRoutes } from '../../../../core/src/lib/apis/AppAlacarteRoutes';
import { AlacarteUrls } from '../../../../core/src/lib/AlacarteUrls';
import { Firebase } from '../Firebase';
import { Fetchable } from '../../../../core/src/lib/define/fetchable/Fetchable';

export const AppAlacarteApiAccess = Fetchable.wrapApi({
  fetch: window.fetch.bind(window),
  baseUrl: `${AlacarteUrls.BASE_URL_PUBLIC_API.https}`,
  routes: AppAlacarteRoutes,
  getBearerToken: async () => {
    const user = Firebase.getCurrentUser();
    if (!user) {
      return undefined;
    }
    return user.getIdToken();
  },
});
