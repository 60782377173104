import { Log } from '../../config/Instance';
import { Refs } from '../../../../core/src/db/DbRefs';
import { THostId } from '../../../../core/src/models/db/host/HostTypes';
import { actions } from '../Actions';
import { put } from 'redux-saga/effects';
import { sagaBindRefByKey } from '../../../../lib-react/src/sagas/sagaBindRefByKey';
import { LocalError } from '../../../../core/src/lib/error/LocalError';
import { KnownError } from '../../../../core/src/lib/error/KnownError';
import { HostPublicSubscriptionBuilder } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionBuilder';

export function* sagaSubscribeToHostPublicSubscription(pbp: { hostId: THostId }) {
  Log.v('sagaSubscribeToHostPublicSubscription', 'sagaSubscribeToHostPublicSubscription', `subscribing to ${pbp.hostId}`);
  const subscriptionExists = yield HostPublicSubscriptionBuilder.dataExistsById(pbp);
  if (!subscriptionExists) {
    const message = [
      'sagaSubscribeToHostPublicSubscription',
      'sagaSubscribeToHostPublicSubscription',
      `A Host subscription should always exist, hostId=${pbp.hostId}`,
    ];
    Log.wtf(message[0], message[1], message[2]);
    throw new LocalError(message[0], message[1], KnownError.hostPublicSubscriptionNotFound);
  }

  const ref = Refs.hostPublicSubscription(pbp);

  yield put(actions.actionTypeHostSetHostPublicSubscription({ hostPublicSubscription: yield ref.val() }));

  yield sagaBindRefByKey('sagaSubscribeToHostPublicSubscription', ref, function* (hostPublicSubscription) {
    yield put(actions.actionTypeHostSetHostPublicSubscription({ hostPublicSubscription }));
  });
}
