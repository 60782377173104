import {DbRes} from '../db/DbResources';
import {EGuestSessionStatus} from '../models/db/guestSession/GuestSessionTypes';
import {EIngredientType} from '../models/db/ingredient/IngredientTypes';
import {EMenuItemState} from '../models/db/menuItem/MenuItemTypes';
import {EMessageSender} from '../models/db/hostMessage/HostMessageTypes';
import {EPrintAdapter} from '../printer/PrintAdapters';
import {EStationType} from '../models/db/station/StationTypes';
import {FIELD_ID, FIELD_RESOURCE_TYPE,} from '../db/DbDefs';
import {EOrderCreatedBy} from '../models/db/order/OrderTypes';
import {FIELD_STRIPE_CUSTOMER_ID} from '../models/db/hostSubscription/HostSubscriptionTypes';
import {SupportedLocales} from '../locale/Locale';
import {CurrencyCodes} from '../locale/Currencies';
import {LanguageCodes} from '../locale/Languages';

export const CIInterface = {
  validateMenuNameMinLen: 3,
  validateMenuNameMaxLen: 30,
  validateMenuDescriptionMinLen: 0,
  validateMenuDescriptionMaxLen: 300,
  validateMenuCoverChargeMin: 0,
  validateMenuCoverChargeMax: 1000,
  validateMenuSubMenusMax: 30,

  validateMenuItemNameMinLen: 3,
  validateMenuItemNameMaxLen: 50,
  validateMenuItemDescriptionMinLen: 0,
  validateMenuItemDescriptionMaxLen: 1000,

  validateIngredientNameMinLen: 1,
  validateIngredientNameMaxLen: 30,
  validateIngredientPriceMin: -10000,
  validateIngredientPriceMax: 10000,

  validateStationNameMinLen: 3,
  validateStationNameMaxLen: 20,

  validateHostNameMinLen: 3,
  validateHostNameMaxLen: 30,
  validateHostDescriptionMinLen: 3,
  validateHostDescriptionMaxLen: 10000,

  validateTableInternalNoteMaxLen: 15,

  validateRoomNameMinLen: 3,
  validateRoomNameMaxLen: 15,

  FIELD_STRIPE_CUSTOMER_ID,

  EMenuItemState,
  EIngredientType,
  EOrderCreatedBy,
  EStationType,
  EPrintAdapter,
  EGuestSessionStatus,
  EMessageSender,
  DbRes,
  FIELD_ID,
  FIELD_RESOURCE_TYPE,
  SupportedLocales,
  LanguageCodes,
  CurrencyCodes,
};
