import * as React from 'react';
import { FieldColorInput } from './FieldColorInput';
import { FieldCurrencyInput } from './CurrencyInput';
import { FieldDualOptionInput } from './FieldDualOptionInput';
import { FieldImageFileInput } from './FieldImageFileInput';
import { FieldInputList } from './duplexInputList/FieldInputList';
import { FieldMenuItemTagInput } from './FieldMenuItemTagInput';
import { FieldNumberInput } from './FieldNumberInput';
import { FieldPickableSortableList } from './FieldPickableSortableList';
import { FieldSingleListItemSelect } from './FieldSingleListItemSelect';
import { FieldStationListInput } from './stationInput/FieldStationListInput';
import { FieldTextArea } from './FieldTextAreaInput';
import { FieldTextInput } from './FieldTextInput';
import {
  FormLabel,
  TFormLabelProps,
} from './FormLabel';
import styled from 'styled-components';
import { FieldHTMLArea } from "./FieldHTMLAreaInput";

function buildLabeledField<Props>(Component: (props: Props) => JSX.Element) {
  return (props: Omit<TFormLabelProps, 'children'> & Props) => {
    const {
      label,
      caption,
      onClickHelp,
      className,
      isRequired,
      ...componentProps
    } = props;
    return (
      <FormLabel
        label={label}
        caption={caption}
        onClickHelp={onClickHelp}
        className={className}
        isRequired={isRequired}
      >
        <StyledChild
          Component={Component}
          {...componentProps}
        />
      </FormLabel>
    );
  };
}

export const LabeledFieldColorInput = buildLabeledField(FieldColorInput);
export const LabeledFieldImageFileInput = buildLabeledField(FieldImageFileInput);
export const LabeledFieldInputList = buildLabeledField(FieldInputList);
export const LabeledFieldPickableSortableList = buildLabeledField(FieldPickableSortableList);
export const LabeledFieldMenuItemTagInput = buildLabeledField(FieldMenuItemTagInput);
export const LabeledFieldSingleListItemSelect = buildLabeledField(FieldSingleListItemSelect);
export const LabeledFieldTextInput = buildLabeledField(FieldTextInput);
export const LabeledFieldNumberInput = buildLabeledField(FieldNumberInput);
export const LabeledFieldCurrencyInput = buildLabeledField(FieldCurrencyInput);
export const LabeledFieldTextArea = buildLabeledField(FieldTextArea);
export const LabeledFieldHTMLArea = buildLabeledField(FieldHTMLArea);
export const LabeledFieldDualOptionInput = buildLabeledField(FieldDualOptionInput);
export const LabeledFieldStationListInput = buildLabeledField(FieldStationListInput);

const StyledChild = styled(({ Component, ...componentProps }: any) => <Component {...componentProps}/>)`
  margin-top: 10px;
`;
