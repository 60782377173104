import { CIInterface } from '../../config/CIInterface';
import {
  FIELD_ID,
  FIELD_NAME,
  TObjList,
} from '../../db/DbDefs';
import {
  TIngredient,
  TIngredientId,
} from '../db/ingredient/IngredientTypes';
import { TMenuItem } from '../db/menuItem/MenuItemTypes';
import { Validator } from '../../lib/error/Validators';
import { buildObjValidator } from '../../lib/error/lib/buildObjValidator';
import { validateIngredient } from './validateIngredient';
import {LanguageCodes} from '../../locale/Languages';

export const validateMenuItem = buildObjValidator<TMenuItem>({
  [FIELD_ID]: Validator.applyAll([
    Validator.expectSet,
    Validator.expectUUID,
  ]),
  [FIELD_NAME]: Validator.applyAll([
    Validator.expectSet,
    Validator.expectStrOfLen({
      min: CIInterface.validateMenuItemNameMinLen,
      max: CIInterface.validateMenuItemNameMaxLen,
    }),
  ]),
  price: Validator.applyAll([
    Validator.expectSet,
    Validator.expectNum,
  ]),
  description: Validator.applyAll([
    Validator.expectSet,
    Validator.expectStrOfLen({
      min: CIInterface.validateMenuItemDescriptionMinLen,
      max: CIInterface.validateMenuItemDescriptionMaxLen,
    }),
  ]),
  itemImg: Validator.applyAll([
    Validator.expectStr,
  ]),
  shareable: Validator.applyAll([
    Validator.expectSet,
    Validator.expectBool,
  ]),
  allergens: Validator.applyAll([
    Validator.expectSet,
    Validator.expectStr,
  ]),
  tags: Validator.applyAll([
    Validator.expectSet,
    Validator.expectStr,
  ]),
  ingredients: Validator.applyAll<TObjList<TIngredientId, TIngredient>>([
    Validator.applyToObjListAsArr(Validator.expectValidator({
      validator: (ingredient: TIngredient) => {
        return validateIngredient(ingredient);
      },
    })),
  ]),
  defaultLocale: Validator.applyAll([
    Validator.expectSet,
    Validator.expectOneValueOf({ allowedValues: LanguageCodes }),
  ]),
});
