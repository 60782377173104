import * as React from 'react';
import styled from 'styled-components';
import { BlockAndBlurOverlay } from './BlockAndBlurOverlay';
import { THostPublicSubscription } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import { ESubscriptionFeatureFlags } from '../../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';
import { HostSubscription } from '../../../../core/src/models/db/hostSubscription/HostSubscription';
import { Strings } from '../../../../core/src/locale/Strings';
import { AppAlacarteApiAccess } from '../../lib/api/AppAlacarteApiAccess';
import { Config } from '../../config/Config';
import { ELocale } from '../../../../core/src/locale/Locale';

type TFeatureFlagOverlay = {
  locale: ELocale;
  className?: string;
  uniqueId: string;
  hostPublicSubscription: THostPublicSubscription;
  requiredFeatureList: ESubscriptionFeatureFlags[];
  children: React.ReactNode;
  childrenDimensionsCheckFrequencyMs?: number;
  blurDeviation?: number;
  dontOverlay?: boolean;
  dontTooltip?: boolean;
  blockCursor?: boolean;
};

export const FeatureFlagOverlay = ({
  locale,
  className,
  uniqueId,
  hostPublicSubscription,
  requiredFeatureList,
  children,
  childrenDimensionsCheckFrequencyMs,
  blurDeviation,
  dontOverlay = false,
  dontTooltip = false,
  blockCursor = false,
}: TFeatureFlagOverlay) => {
  const missingFeatureFlag = requiredFeatureList.find((feature) => {
    return !HostSubscription.hasAccessToFeature(hostPublicSubscription, feature as any);
  });

  const onFeatureFlagOverlayCtaPress = React.useCallback(async () => {
    const { redirect } = await AppAlacarteApiAccess.stripeCheckoutSessionCreate.request({
      body: {
        stripeCheckoutPriceId: Config.STRIPE_PREMIUM_CHECKOUT_PRICE_ID,
        successUrl: window.location.href,
        cancelUrl: window.location.href,
      },
    });
    window.location.href = redirect;
  }, []);

  const shouldBlockContent = missingFeatureFlag != null;
  return (
    <BlockAndBlurOverlay
      className={className}
      uniqueId={uniqueId}
      shouldBlockChildren={shouldBlockContent}
      childrenDimensionsCheckFrequencyMs={childrenDimensionsCheckFrequencyMs}
      blurDeviation={blurDeviation}
      blockCursor={blockCursor}
      tooltipContent={!dontTooltip ? (
        <StyledTooltip>
          <StyledTooltipText>
            {`${Strings.hPremiumUpsellFeatureText()(locale)}${Strings.hPremiumUpsellFeatureTextCta()(locale)}`}
          </StyledTooltipText>
          <StyledTooltipCta onClick={onFeatureFlagOverlayCtaPress}>
            {Strings.hPremiumUpsellFeatureButtonCta()(locale)}
          </StyledTooltipCta>
        </StyledTooltip>
      ) : undefined}
      overlay={!dontOverlay && (
        <PremiumUpsellContainer>
          <PremiumUpsellText>{Strings.hPremiumUpsellFeatureText()(locale)}</PremiumUpsellText>
          <PremiumUpsellLink onClick={onFeatureFlagOverlayCtaPress}>
            {Strings.hPremiumUpsellFeatureTextCta()(locale)}
          </PremiumUpsellLink>
        </PremiumUpsellContainer>
      )}
    >
      {children}
    </BlockAndBlurOverlay>
  );
};

const StyledTooltip = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 24px;
`;

const StyledTooltipText = styled.div`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-weight: 600;
  text-align: center;
`;

const StyledTooltipCta = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.accentDark};
  color: ${({ theme }) => theme.t.col.white};
  cursor: pointer;
  display: flex;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  margin-top: 18px;
  text-transform: uppercase;
  width: 240px;
`;

const PremiumUpsellContainer = styled.div`
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
`;

const PremiumUpsellText = styled.span`
  color: ${({ theme }) => theme.t.col.red};
  font-size: 13px;
  font-weight: 600;
  margin-bottom: -28px;
  text-align: left;
`;

const PremiumUpsellLink = styled(PremiumUpsellText)`
  cursor: pointer;
  text-decoration: underline;
`;
