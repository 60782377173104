import * as React from 'react';
import * as _ from 'lodash';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../../core/src/db/DbDefs';
import { LabeledFieldSingleListItemSelect } from '../form/LabeledFields';
import { TFieldSingleListItemSelectListItem } from '../form/FieldSingleListItemSelect';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TStationId } from '../../../../core/src/models/db/station/StationTypes';
import { useMenuItemEditFormHandler } from '../../lib/forms/MenuItemEditForm';

export type TMenuItemEditAssignedStationProps = ReturnType<typeof useMenuItemEditFormHandler>['fields']['stationId'] & {
  label: string;
  hostData: THost;
};

export const MenuItemEditAssignedStation = (props: TMenuItemEditAssignedStationProps) => {
  const {
    label,
    hostData,
  } = props;

  const mapValueToListItem = React.useCallback((stationId: TStationId) => {
    const station = _.get(hostData.stations, stationId);
    return { mainText: _.get(station, FIELD_NAME, '') };
  }, [hostData.stations]);

  const mapListItemToValue = React.useCallback((listItem: TFieldSingleListItemSelectListItem) => {
    const station = _.find(hostData.stations, (stn) => {
      return stn[FIELD_NAME] === listItem.mainText;
    });

    return _.get(station, FIELD_ID, '');
  }, [hostData.stations]);

  return (
    <LabeledFieldSingleListItemSelect
      isRequired
      label={label}
      value={props.value}
      onChange={props.setValue}
      mapValueToListItem={mapValueToListItem}
      mapListItemToValue={mapListItemToValue}
      items={_.keys(hostData.stations)}
    />
  );
};
