import { Log } from '../../config/Instance';
import { eventChannel } from 'redux-saga';
import { put, select, take } from 'redux-saga/effects';
import { TState } from '../Store';
import { Firebase } from '../../lib/Firebase';
import { actionAppOnPostSignIn } from '../App';
import firebase from 'firebase';

export function* sagaVerifyUserSignIn() {
  Log.v('sagaVerifyUserSignIn', 'sagaVerifyUserSignIn', `Starting`);

  // Run once
  yield verifyUserAlignment(Firebase.getCurrentUser());

  // Listen
  const channel = eventChannel((emitter) => {
    const { unsubscribe } = Firebase.onAuthStateChanged((user) => {
      emitter(user);
    });
    return unsubscribe;
  });

  while (true) {
    const user = yield take(channel);
    yield verifyUserAlignment(user);
  }
}

function* verifyUserAlignment(user: firebase.User | undefined) {
  const storeUser = yield select((store: TState) => store.app.user);
  if (user) {
    // Ensure firebaseUser email is same as storeUser email
    if (user.email != storeUser?.email) {
      // Update sign in
      yield put(actionAppOnPostSignIn(user) as any);
    }
  }
}
