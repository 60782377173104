import * as React from 'react';
import {
  NavbarLinkBase,
  TNavbarLinkBaseProps,
  Underline,
} from './NavbarLinkBase';
import styled from 'styled-components';

export type TPrimaryNavbarLinkProps = Omit<TNavbarLinkBaseProps, 'underline'> & TSelectedUnderlineProps & {
  //
};

export const PrimaryNavbarLink = ({
  selected,
  ...navbarLinkProps
}: TPrimaryNavbarLinkProps) => {
  return (
    <StyledNavbarLinkBase
      {...navbarLinkProps}
      underline={(
        <PrimaryUnderline
          selected={selected}
        />
      )}
    />
  );
};

const StyledNavbarLinkBase = styled(NavbarLinkBase)`
  color: ${({ theme }) => theme.t.col.accentDark};
`;

type TSelectedUnderlineProps = {
  selected?: boolean;
};

const PrimaryUnderline = styled(Underline)<TSelectedUnderlineProps>`
  background-color: ${({ theme, selected }) => (selected ? theme.t.col.accentDark : undefined)};
`;
