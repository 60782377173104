import * as React from 'react';
import * as _ from 'lodash';
import {
  CheckmarkArrowEmpty,
  CheckmarkArrowFull,
  TrashIcon,
} from '../../assets/Assets';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { GuestSessionOverviewCalculatorHeader } from './GuestSessionOverviewCalculatorHeader';
import { GuestSessionOverviewOrderItem } from './GuestSessionOverviewOrderItem';
import { ReactComponent as ReloadIcon } from '../../assets/reload-icon.svg';
import { Strings } from '../../../../core/src/locale/Strings';
import {
  TOrderItem,
  TOrderItemId,
} from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import {
  TOrderItemIdsToStatus,
  useOrderItemStatusManager,
} from './useOrderItemStatusManager';
import { formatSingularPlural } from '../../../../core/src/lib/formatters/LocaleLib';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import styled from 'styled-components';
import {
  LeadingActions,
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  Type,
} from 'react-swipeable-list';
import { dbItemCreatedOnSort } from '../../../../core/src/db/DbLib';
import { ELocale } from '../../../../core/src/locale/Locale';

type TGuestSessionOverviewOrderItemListProps = ReturnType<typeof useOrderItemStatusManager> & {
  locale: ELocale;
  onOrderItemDelete: (orderItemId: TOrderItem) => void;
};

export const GuestSessionOverviewOrderItemList = ({
  locale,
  guestSessionOrderItems,
  orderItemIdsToStatus,
  atLeastOneSelected,
  onOrderItemStatusToggle,
  onOrderItemDelete,
  onOrderItemSelectedStatusSetAll,
  onOrderItemSelectedStatusResetAll,
}: TGuestSessionOverviewOrderItemListProps) => {
  const onToggleAllClick = useItemCallback(!atLeastOneSelected, onOrderItemSelectedStatusSetAll);
  return (
    <Root>
      <GuestSessionOverviewCalculatorHeader>
        <ClickableIcon onClick={onToggleAllClick}>
          {!atLeastOneSelected && <CheckmarkArrowEmpty/>}
          {atLeastOneSelected && <CheckmarkArrowFull/>}
        </ClickableIcon>
        <TitleText>
          {formatSingularPlural(
            locale,
            guestSessionOrderItems.length,
            Strings.hMenuMenuItemsSingularCount({ count: `${guestSessionOrderItems.length}` }),
            Strings.hMenuMenuItemsPluralCount({ count: `${guestSessionOrderItems.length}` }),
          )}
        </TitleText>
        <ClickableIcon onClick={onOrderItemSelectedStatusResetAll}>
          <ReloadIcon/>
        </ClickableIcon>
      </GuestSessionOverviewCalculatorHeader>
      <ListContainer>
        <OrdersPrecedenceList
          locale={locale}
          guestSessionOrderItems={guestSessionOrderItems}
          onOrderItemStatusToggle={onOrderItemStatusToggle}
          onOrderItemDelete={onOrderItemDelete}
          orderItemIdsToStatus={orderItemIdsToStatus}
        />
      </ListContainer>
    </Root>
  );
};

type TStationListProps = {
  locale: ELocale;
  guestSessionOrderItems: TOrderItem[];
  onOrderItemStatusToggle: (orderItemId: TOrderItemId) => void;
  onOrderItemDelete: (orderItemId: TOrderItem) => void;
  orderItemIdsToStatus: TOrderItemIdsToStatus;
};

const OrdersPrecedenceList = ({
  locale,
  guestSessionOrderItems,
  onOrderItemStatusToggle,
  onOrderItemDelete,
  orderItemIdsToStatus,
}: TStationListProps) => {
  // Group orderItems by station
  const groupedOrderItems = _.groupBy(guestSessionOrderItems, 'precedenceNumber');

  const precedenceNames = _.keys(groupedOrderItems)
    .sort((p1, p2) => `${p1}`.localeCompare(p2));

  return (
    <>
      {precedenceNames.map((precedenceName: string, index) => {
        const precedence = parseInt(precedenceName, 10) + 1;
        return (
          <React.Fragment key={precedenceName}>
            <ListSectionTitle>
              {precedence <= 1
                ? Strings.hOrderSummary1stCourse()(locale)
                : Strings.hOrderSummaryNndCourse({ n: `${precedence}` })(locale)}
            </ListSectionTitle>
            <OrderItemList
              orderItems={groupedOrderItems[precedenceName]}
              onOrderItemStatusToggle={onOrderItemStatusToggle}
              onOrderItemDelete={onOrderItemDelete}
              orderItemIdsToStatus={orderItemIdsToStatus}
              isLastGroup={index === precedenceNames.length - 1}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

type TOrderItemListProps = {
  orderItems: TOrderItem[];
  onOrderItemStatusToggle: (orderItemId: TOrderItemId) => void;
  onOrderItemDelete: (orderItemId: TOrderItem) => void;
  orderItemIdsToStatus: TOrderItemIdsToStatus;
  isLastGroup: boolean;
};

const OrderItemList = ({
  orderItems,
  onOrderItemStatusToggle,
  onOrderItemDelete,
  orderItemIdsToStatus,
  isLastGroup,
}: TOrderItemListProps) => {
  return (
    <SwipeableList
      fullSwipe={true}
      type={Type.IOS}>
      {orderItems
        .sort(dbItemCreatedOnSort)
        .map((orderItemData, index) => (
          <SwipeableListItem
            key={orderItemData[FIELD_ID]}
            trailingActions={(
              <LeadingActions>
                <SwipeAction onClick={() => onOrderItemDelete(orderItemData)}>
                  <SwipeableButton>
                    <TrashIcon/>
                  </SwipeableButton>
                </SwipeAction>
              </LeadingActions>
            )}>
            <GuestSessionOverviewOrderItem
              orderItemData={orderItemData}
              onOrderItemStatusToggle={onOrderItemStatusToggle}
              orderItemStatus={orderItemIdsToStatus[orderItemData[FIELD_ID]]}
              isLastItem={isLastGroup && index === orderItems.length - 1}
            />
          </SwipeableListItem>
        ))}
    </SwipeableList>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.t.col.white};
  overflow: scroll;
`;

const TitleText = styled.span`
  color: ${({ theme }) => theme.t.col.white};
  flex: 1;
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-align: center;
`;

const ListContainer = styled.div`
  padding: 20px;
`;

const ListSectionTitle = styled.h2`
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 14px;

  margin-bottom: 10px;
  text-transform: uppercase;

  &:not(:first-of-type) {
    margin-top: 30px;
  }
`;

const ClickableIcon = styled.div`
  cursor: pointer;
`;

const SwipeableButton = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.red};
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 12px;
  max-width: 120px;
  width: 120px;
`;
