import { OpenLocationCode } from 'open-location-code';

type GooglePlacePlusCode = {
  compound_code?: string;
  global_code: string;
};

export function plusCodeToCoordinates(plusCode: GooglePlacePlusCode | undefined): string | undefined {
  if (!plusCode) {
    return undefined;
  }

  try {
    const decodeResult = new OpenLocationCode().decode(plusCode);
    return `${decodeResult.latitudeCenter}, ${decodeResult.longitudeCenter}`;
  } catch (e) {
    return undefined;
  }
}
