import * as React from 'react';
import { useTargetValue } from '../../../../lib-react/src/hooks/useTargetValue';
import styled from 'styled-components';

export type TFieldColorInputProps = {
  onChange: (newValue: string) => void;
  value: string;
  disabled?: boolean;
  className?: string;
};

const FieldColorInputBase = ({
  onChange,
  value,
  className,
  disabled = false,
}: TFieldColorInputProps) => {
  const onInputChange = useTargetValue(onChange);

  const inputRef = React.createRef<HTMLInputElement>();
  const onContainerClick = React.useCallback(() => {
    inputRef.current && inputRef.current.click();
  }, []);

  return (
    <Container
      className={className}
      disabled={disabled}
      onClick={onContainerClick}>
      <StyledColorInput
        ref={inputRef}
        type="color"
        onChange={onInputChange}
      />
      <CoverColorInput backgroundColor={value}/>
      <TextContainer color={value}>
        {value}
      </TextContainer>
    </Container>
  );
};

export const FieldColorInput = React.memo(FieldColorInputBase) as typeof FieldColorInputBase;

type TContainerProps = {
  disabled: boolean;
};

const Container = styled.div<TContainerProps>`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.t.col.accentLight};
  border-radius: 1px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 50px;
  position: relative;
  width: 100%;
`;

const StyledColorInput = styled.input`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  visibility :hidden;
  width: 100%;
`;

const TextContainer = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  display: block;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  padding: 10px;
  text-transform: uppercase;
`;

const CoverColorInput = styled.div<{ backgroundColor: string }>`
  align-self: stretch;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 50px;
`;
