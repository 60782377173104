import { DbRes } from '../../../db/DbResources';
import { HostMessageBuilder } from './HostMessageBuilder';
import { MObjCreatorById } from '../../../lib/model/MObjCreator';

class HostMessageCreatorBase extends MObjCreatorById<typeof DbRes.HostMessage, 'messageId'> {
  constructor() {
    super(HostMessageBuilder);
  }

  protected readonly idKey = 'messageId';
}

export const HostMessageCreator = new HostMessageCreatorBase();
