import * as React from 'react';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../core/src/db/DbDefs';
import { Footer } from '../components/Footer';
import { FullScreenErrorView } from '../components/fullScreenViews/FullScreenErrorView';
import { FullScreenLoadingView } from '../components/fullScreenViews/FullScreenLoadingView';
import { Hist } from '../lib/navigation/History';
import {
  MaxHeightDiv,
  MaxScreenDiv,
} from '../components/MaxHeightDiv';
import { Menu } from '../../../core/src/models/db/menu/Menu';
import { MenuBuilderView } from '../components/menuBuilder/MenuBuilderView';
import { Routes } from '../config/Routes';
import { SecondaryNavbar } from '../components/navbars/SecondaryNavbar';
import { Strings } from '../../../core/src/locale/Strings';
import { THostId } from '../../../core/src/models/db/host/HostTypes';
import {
  TMenu,
  TMenuUpdate,
  TMenuId,
} from '../../../core/src/models/db/menu/MenuTypes';
import {
  TPropCheck,
  withPropCheckMiddleware,
} from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import {
  TScreenNavigationProps,
  getNavParam,
} from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import {
  actionMenuAddSubMenu,
  actionMenuArchive,
  actionMenuResortSubMenus,
  actionMenuUpdate,
} from '../redux/actions/Menu';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import { connect } from 'react-redux';
import { formatWithLocale } from '../lib/Format';
import { localize } from '../../../core/src/lib/formatters/LocaleLib';
import styled from 'styled-components';
import { ELocale } from '../../../core/src/locale/Locale';

export type TScreenHostMenuBuilderNavParams = {
  hostId: THostId;
  menuId: TMenuId;
  menuLocale: ELocale;
};

export type TScreenHostMenuBuilderProps = TScreenNavigationProps<TScreenHostMenuBuilderNavParams> & {
  //
};

type TScreenHostMenuBuilderBaseProps = TScreenHostMenuBuilderProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TScreenHostMenuBuilderBaseState = {
  //
};

class ScreenHostMenuBuilderBase extends React.Component<TPropCheck<typeof ScreenHostMenuBuilderBase>, TScreenHostMenuBuilderBaseState> {
  static checkProps(props: TScreenHostMenuBuilderBaseProps) {
    const {
      locale,
      hostData,
      menuData,
    } = props;

    if (hostData == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    if (menuData == null) {
      const hostId = hostData[FIELD_ID];
      return () => (
        <FullScreenErrorView
          locale={locale}
          message={Strings.gMenuNonExistentMsg()(locale)}
          actionText={Strings.hBackToMenuMain()(locale)}
          actionOnClick={() => Hist.replace(Routes.ScreenHostMenuMain, { hostId })}
        />
      );
    }

    return {
      ...props,
      menuData,
      hostData,
    };
  }

  private onMenuCreate = async (parentMenu: TMenu, newMenu: TMenuUpdate) => {
    const { menuAddSubMenu } = this.props;
    return menuAddSubMenu({
      parentMenu,
      subMenu: newMenu,
    });
  };

  private onMenuArchive = async (menu: TMenu) => {
    const { menuArchive } = this.props;
    await menuArchive({ menu });
  };

  private onMenuItemEditClick = async (menu: TMenu) => {
    const {
      locale,
      menuLocale,
      hostData,
    } = this.props;
    Hist.push(Routes.ScreenHostMenuEditor, {
      hostId: hostData[FIELD_ID],
      menuId: menu[FIELD_ID],
      menuLocale,
      navContext: { goBackText: Strings.hBackToMenu({ hMenuTab: this.getScreenName() })(locale) },
    });
  };

  private onSubMenuReorder = async (parentMenu: TMenu, sortedSubMenus: TMenu[]) => {
    const { menuResortSubMenus } = this.props;
    await menuResortSubMenus({
      menu: parentMenu,
      sortedSubMenus,
    });
  };

  private onMenuNameChange = async (menu: TMenu, newName: string) => {
    const {
      menuLocale,
      menuUpdate,
    } = this.props;
    await menuUpdate({
      menu,
      update: { [FIELD_NAME]: newName },
      viewLocale: menuLocale,
    });
  };

  render() {
    const {
      locale,
      hostData,
      menuData,
      menuLocale,
    } = this.props;
    return (
      <Root>
        {this.renderSecondaryNavbar()}
        <ScrollableContent>
          <Content>
            <MenuBuilderView
              hostData={hostData}
              menuData={menuData}
              locale={locale}
              menuLocale={menuLocale}
              onMenuCreate={this.onMenuCreate}
              onMenuArchive={this.onMenuArchive}
              onMenuItemEditClick={this.onMenuItemEditClick}
              onSubMenuReorder={this.onSubMenuReorder}
              onMenuNameChange={this.onMenuNameChange}
            />
          </Content>
        </ScrollableContent>
        <Footer
          locale={locale}
        />
      </Root>
    );
  }

  private renderSecondaryNavbar = () => {
    const {
      locale,
      hostData,
    } = this.props;
    return (
      <SecondaryNavbar
        locale={locale}
        hostId={hostData[FIELD_ID]}
        name={this.getScreenName()}
      />
    );
  };

  private getScreenName = () => {
    const { locale, menuData, menuLocale } = this.props;
    const menuItemName = localize(menuData, FIELD_NAME, menuLocale);
    return formatWithLocale(menuItemName, locale, menuLocale);
  };
}

function mapStateToProps(state: TState, ownProps: TScreenHostMenuBuilderProps) {
  const menuId = getNavParam(ownProps, 'menuId');
  const menuLocale = getNavParam(ownProps, 'menuLocale');
  const { hostData } = state.host;
  return {
    locale: state.app.locale,
    menuId,
    menuLocale,
    hostData,
    menuData: hostData && Menu.findMenuById(hostData.menu, menuId) as TMenu,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    menuAddSubMenu: bindActionCreators(actionMenuAddSubMenu, dispatch),
    menuUpdate: bindActionCreators(actionMenuUpdate, dispatch),
    menuArchive: bindActionCreators(actionMenuArchive, dispatch),
    menuResortSubMenus: bindActionCreators(actionMenuResortSubMenus, dispatch),
  };
}

export const ScreenHostMenuBuilder = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(ScreenHostMenuBuilderBase));

const Root = styled(MaxScreenDiv)`
  background-color: ${({ theme }) => theme.t.col.lightGray3};
`;

const ScrollableContent = styled(MaxHeightDiv)`

`;

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

