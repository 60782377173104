import { URLToolbox } from '../../../define/url-toolbox/URLToolbox';
import { addIfDefined2 } from '../../../HelperFunctions';
import { XOR } from '../../../Types';

export const URLParamsSingleSignOn = {
  in: {
    expectAuthEmail: 'expectAuthEmail',
    signInLink: 'signInLink',
  } as XOR<{
    expectAuthEmail?: string | undefined;
    signInLink?: undefined;
  }, {
    expectAuthEmail: string;
    signInLink: string;
  }>,
  out: {
    expectAuthEmail: 'expectAuthEmail',
    signInLink: 'signInLink',
  } as XOR<{
    expectAuthEmail?: string | undefined;
    signInLink?: undefined;
  }, {
    expectAuthEmail: string;
    signInLink: string;
  }>,
  map(p: any) {
    return {
      ...addIfDefined2('expectAuthEmail', p.expectAuthEmail, () => URLToolbox.safeEncode(p.expectAuthEmail)),
      ...addIfDefined2('signInLink', p.signInLink, () => URLToolbox.safeEncode(p.signInLink)),
    };
  },
  invert(p: any) {
    return {
      ...addIfDefined2('expectAuthEmail', p.expectAuthEmail, () => URLToolbox.safeDecode(p.expectAuthEmail)),
      ...addIfDefined2('signInLink', p.signInLink, () => URLToolbox.safeDecode(p.signInLink)),
    };
  },
};
