import * as React from 'react';
import * as _ from 'lodash';
import { ClickAwayListener } from '@material-ui/core';
import { FirstParameter } from '../../../../../core/src/lib/Types';
import { useFormStringField } from '../../../../../lib-react/src/hooks/form/useFormStringField';
import { useToggle } from '../../../../../lib-react/src/hooks/useToggle';
import { useTriggerOnBlurOrEnter } from '../../../../../lib-react/src/hooks/form/useTriggerOnBlurOrEnter';
import styled from 'styled-components';

type TOmitInputProps = {
  ref: any;
  onBlur: any;
  onKeyPress: any;
  onChange: any;
  value: any;
};

export type TFieldSyncedInputComponentProps = {
  isClickable: boolean;
  error?: boolean;
};

export type TFieldSyncedStringProps = Omit<React.HTMLProps<HTMLInputElement>, keyof TOmitInputProps>
& FirstParameter<typeof useFormStringField>
& {
  isEditable: boolean;
  onComplete?: () => void;
  saveOnBlur?: boolean;
};

export const FieldSyncedString = ({
  isEditable,
  onComplete = _.noop,
  saveOnBlur = true,

  // useFormStringFieldProps
  value,
  syncingActive = true,
  required = true,
  resetOnError = false,
  onValueChange,
  validate,
  valueToError,
  checkForErrorOnRequired = false,

  // All other props are input props
  ...inputProps
}: TFieldSyncedStringProps) => {
  const {
    value: fieldValue,
    setValueFromEvent: onChange,
    error,
  } = useFormStringField({
    value,
    syncingActive,
    throttleSyncMs: 2000,
    required,
    resetOnError,
    checkForErrorOnRequired,

    onValueChange: (val: string) => {
      onValueChange && onValueChange(val);
    },

    validate: (val: string, context: any) => {
      if (validate) {
        return validate(val, context);
      }
      return true;
    },

    valueToError: (val: string, context: any) => {
      return valueToError && valueToError(val, context);
    },
  });

  const {
    itemRef,
    onBlur,
    onEnterPress,
  } = useTriggerOnBlurOrEnter({
    triggerOnBlur: saveOnBlur,
    onTrigger: onComplete,
  });

  const [menuNameIsEditing, , setMenuNameIsEditingTrue, setMenuNameIsEditingFalse] = useToggle(false);

  return (
    <ClickAwayListener onClickAway={setMenuNameIsEditingFalse}>
      {/* @ts-ignore */}
      <Input
        ref={itemRef}
        // @ts-ignore
        error={error}
        value={fieldValue}
        onChange={onChange}
        onClick={setMenuNameIsEditingTrue}
        onBlur={onBlur}
        onKeyPress={onEnterPress}
        isClickable={isEditable && !menuNameIsEditing}
        {...inputProps}
      />
    </ClickAwayListener>
  );
};

const Input = styled.input<TFieldSyncedInputComponentProps>`
  background: none;
  border: 0;
  color: ${({ theme, error = false }) => (!error ? theme.t.col.accentDark : theme.t.col.red)};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  outline: 0;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.t.col.lightGray1};
  }
`;
