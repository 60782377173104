import * as React from 'react';
import { Snackbars } from '../../lib/snackbars/Snackbars';
import styled from 'styled-components';

export function makeSnackbarAction(params: Omit<TUndoButtonProps, 'snackbarKey'>) {
  return (key: string) => (
    <ActionButton
      snackbarKey={key}
      {...params}
    />
  );
}

type TUndoButtonProps = {
  snackbarKey: string;
  text: string;
  onClick: () => void;
  dismissSnackbarOnClick?: boolean;
};

export const ActionButton = ({
  snackbarKey,
  text,
  onClick,
  dismissSnackbarOnClick = true,
}: TUndoButtonProps) => {
  const onActionClick = React.useCallback(() => {
    onClick();
    dismissSnackbarOnClick && Snackbars.dismissByKey(snackbarKey);
  }, [onClick, dismissSnackbarOnClick]);
  return (
    <Root onClick={onActionClick}>{text}</Root>
  );
};

const Root = styled.div`
  background-color: rgba(0, 0, 0, 0.15);
  border: 0;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  padding: 4px 6px;
  text-transform: uppercase;
`;
