import * as React from 'react';
import * as _ from 'lodash';
import { FIELD_ID } from '../../../core/src/db/DbDefs';
import {
  MaxHeightDiv,
  MaxScreenDiv,
} from '../components/MaxHeightDiv';
import { SecondaryNavbar } from '../components/navbars/SecondaryNavbar';
import { Strings } from '../../../core/src/locale/Strings';
import {
  TPropCheck,
  withPropCheckMiddleware,
} from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import { TScreenNavigationProps } from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import { connect } from 'react-redux';
import styled from 'styled-components';

export type TScreenHelpAllergensNavParams = {
};

export type TScreenHelpAllergensProps = TScreenNavigationProps<TScreenHelpAllergensNavParams> & {
};

type TScreenHelpAllergensBaseProps = TScreenHelpAllergensProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TScreenHelpAllergensBaseState = {
};

class ScreenHelpAllergensBase extends React.Component<TPropCheck<typeof ScreenHelpAllergensBase>, TScreenHelpAllergensBaseState> {
  static checkProps(props: TScreenHelpAllergensBaseProps) {
    return props;
  }

  render() {
    const { locale } = this.props;
    return (
      <Root>
        {this.renderSecondarySnackbar()}
        <ScrollableContent>
          <Content
            dangerouslySetInnerHTML={{ __html: Strings.hAllergensInfoContent()(locale) }}
          />
        </ScrollableContent>
      </Root>
    );
  }

  private renderSecondarySnackbar = () => {
    const {
      locale,
      host,
    } = this.props;
    return (
      <SecondaryNavbar
        locale={locale}
        hostId={_.get(host, FIELD_ID)}
        name={Strings.hAllergensInfo()(locale)}
      />
    );
  };
}

function mapStateToProps(state: TState, ownProps: TScreenHelpAllergensProps) {
  return {
    locale: state.app.locale,
    host: state.host.hostData,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export const ScreenHelpAllergens = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(ScreenHelpAllergensBase));

const Root = styled(MaxScreenDiv)`
`;

const ScrollableContent = styled(MaxHeightDiv)`
`;

const Content = styled.div`
  display: flex;
  font-family: Nunito Sans;
  justify-content: center;
  margin: 56px 0;
  padding: 0 25%;
`;

