import * as React from 'react';
import styled from 'styled-components';

function calcHeight(theme: any, padding?: number) {
  const realPadding = padding != null ? padding : 0;
  return theme.dim.height - theme.t.drawableContentOffset - realPadding;
}

export const MaxHeightDiv = styled.div<{ padding?: number }>`
  height: ${({ theme, padding }) => calcHeight(theme, padding)}px;
  overflow: scroll;
  width: 100%;
`;

export const MaxScreenDiv = styled.div`
  height: ${({ theme }) => theme.dim.height}px;
  overflow: hidden;
  width: 100%;
`;

export const maxHeightDivPadding = (padding: number) => {
  return (props: any) => (
    <MaxHeightDiv
      padding={padding}
      {...props}
    />
  );
};
