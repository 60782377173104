import { ArchivedMenuBuilder } from '../../../../core/src/models/db/menu/ArchivedMenuBuilder';
import { MenuBuilder } from '../../../../core/src/models/db/menu/MenuBuilder';
import { Strings } from '../../../../core/src/locale/Strings';
import { TArchivedMObj } from '../../../../core/src/models/db/archivedMObj/ArchivedMObjTypes';
import {
  TDispatch,
  TThunkAction,
} from '../../../../lib-react/src/redux/redux';
import { TGetState } from '../Store';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import {
  TMenu,
  TMenuUpdate,
} from '../../../../core/src/models/db/menu/MenuTypes';
import { TRKMenu } from '../../../../core/src/db/DbResources';
import { actionRunWithUndoSnackbar } from './ActionHelpers';
import { dispatchWithErrorHandler } from '../ReduxErrorHandlers';
import { ELocale } from '../../../../core/src/locale/Locale';
import { Languages } from '../../../../core/src/locale/Languages';

type TActionMenu = {
  menu: TMenu;
};

type TActionMenuAddSubMenuParams = {
  parentMenu: TMenu;
  subMenu: TMenuUpdate;
};

export const actionMenuAddSubMenu = dispatchWithErrorHandler(({ parentMenu, subMenu }: TActionMenuAddSubMenuParams): TThunkAction<Promise<TMenu>> => {
  return async (dispatch: TDispatch) => {
    return MenuBuilder.fromItem(parentMenu).subMenuUpdate(subMenu);
  };
});

type TActionMenuUpdate = TActionMenu & {
  update: Partial<TMenuUpdate>;
  viewLocale: ELocale;
};

export const actionMenuUpdate = dispatchWithErrorHandler(({ menu, update, viewLocale }: TActionMenuUpdate): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    return MenuBuilder.fromItem(menu).updateMenu({
      menuDataChange: update,
      localizedUpdateLocale: viewLocale,
    });
  };
});

type TActionMenuDuplicate = TActionMenu & {
  host: THost;
};

export const actionMenuDuplicate = dispatchWithErrorHandler(({ menu, host }: TActionMenuDuplicate): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await MenuBuilder.fromItem(menu).duplicate(host);
  };
});

type TActionMenuArchive = TActionMenu & {
  //
};

export const actionMenuArchive = dispatchWithErrorHandler(({ menu }: TActionMenuArchive): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    return dispatch(actionRunWithUndoSnackbar({
      msg: Strings.hMenuDeletedMsg(),

      run: () => {
        return MenuBuilder.fromItem(menu).moveToDbArchive();
      },

      undo: (archivedItem: TArchivedMObj<TRKMenu>) => {
        return ArchivedMenuBuilder.fromItem(archivedItem)
          .unArchive();
      },
    }));
  };
});

type TActionMenuResortSubMenus = TActionMenu & {
  sortedSubMenus: TMenu[];
};

export const actionMenuResortSubMenus = dispatchWithErrorHandler(({ menu, sortedSubMenus }: TActionMenuResortSubMenus): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await MenuBuilder.fromItem(menu).resortSubMenus({ sorted: sortedSubMenus });
  };
});

type TActionMenuArchiveLocale = TActionMenu & {
  menu: TMenu;
  localeToArchive: ELocale;
};

export const actionMenuArchiveLocale = dispatchWithErrorHandler(({ menu, localeToArchive }: TActionMenuArchiveLocale): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    return dispatch(actionRunWithUndoSnackbar({
      msg: Strings.hMenuLocaleDeletedMsg({
        localeStr: Languages[localeToArchive]?.language,
      }),

      run: async () => {
        const undoObj = menu[localeToArchive];
        await MenuBuilder.fromItem(menu).removeLocale(localeToArchive);
        return undoObj;
      },

      undo: (undoObj) => {
        return MenuBuilder.fromItem(menu).updateMenu({
          menuDataChange: { [localeToArchive]: undoObj },
          localizedUpdateLocale: menu.defaultLocale as ELocale,
        });
      },
    }));
  };
});

