import {FIELD_NAME, TLocalizableObj,} from '../../../../core/src/db/DbDefs';
import {TMenu, TMenuUpdate,} from '../../../../core/src/models/db/menu/MenuTypes';
import * as _ from 'lodash';
import {Validator} from '../../../../core/src/lib/error/Validators';
import {dbItemLocalizableSupportedLocalesGet} from '../../../../core/src/db/DbLib';
import {useFormArrayField} from '../../../../lib-react/src/hooks/form/useFormArrayField';
import {useFormHandler} from '../../../../lib-react/src/hooks/form/useFormHandler';
import {useFormNumberField} from '../../../../lib-react/src/hooks/form/useFormNumberField';
import {useFormStringField} from '../../../../lib-react/src/hooks/form/useFormStringField';
import {validateMenu} from '../../../../core/src/models/validators/validateMenu';
import {TLanguage} from '../../../../core/src/locale/Languages';

export type TMenuForm = TMenuUpdate & {
  //
};

export type TMenuFormParams = {
  menu: Pick<TMenu, 'coverCharge'> & TLocalizableObj<Pick<TMenu, typeof FIELD_NAME>>;
  onSubmit?: (data: TMenuForm) => Promise<any>;
  onSyncedSubmit?: (data: Partial<TMenuForm>) => Promise<any>;
  minLocales: number;
  onLanguagesChangedSuccessfully?: (addedLanguages: TLanguage[]) => void
};

const menuFormThrottle = 1000;

export function useMenuFormHandler({
                                     menu,
                                     onSubmit,
                                     onSyncedSubmit,
                                     onLanguagesChangedSuccessfully,
                                     minLocales = 0,
                                   }: TMenuFormParams) {
  return useFormHandler({
    onSubmit: (params) => {
      return onSubmit && onSubmit({
        name: params.name,
        coverCharge: params.coverCharge,
        locales: params.locales,
        isHidden: true,
      });
    },
    fields: {
      name: useFormStringField({
        value: menu[FIELD_NAME],
        required: true,
        valueToError: validateMenu.name,

        throttleSyncMs: menuFormThrottle,
        syncingActive: !!onSyncedSubmit,
        onValueChange: (value) => {
          return onSyncedSubmit && onSyncedSubmit({name: value});
        },
      }),

      locales: useFormArrayField<TLanguage>({
        value: dbItemLocalizableSupportedLocalesGet(menu),
        required: true,
        newItem: '' as any,
        checkForErrorOnRequired: true,
        valueToError: Validator.applyAll([
          Validator.applyToArr<TLanguage>(Validator.expectLanguage),
          Validator.expectArrayRange({min: minLocales}),
        ]),
        throttleSyncMs: menuFormThrottle,
        syncingActive: !!onSyncedSubmit,
        onValueChange: async (value) => {
          if (onSyncedSubmit) {
            const result = await onSyncedSubmit({locales: value});

            // Only after the update was successful check if
            // a locale was added
            const previousLocales = _.uniq([
              menu.defaultLocale,
              ...dbItemLocalizableSupportedLocalesGet(menu)
            ]);

            const addedLocales = value.filter((locale) => !previousLocales.includes(locale));
            if (addedLocales.length > 0) {
              onLanguagesChangedSuccessfully?.(addedLocales);
            }

            return result;
          }

          return undefined;
        },
      }),

      coverCharge: useFormNumberField({
        value: menu.coverCharge,
        required: true,
        min: 0,
        valueToError: validateMenu.coverCharge,

        throttleSyncMs: menuFormThrottle,
        syncingActive: !!onSyncedSubmit,
        onValueChange: (value) => {
          return onSyncedSubmit && onSyncedSubmit({coverCharge: value});
        },
      }),
    },
  });
}
