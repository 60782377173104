import { ArchivedMenuItemBuilder } from '../../../../core/src/models/db/menuItem/ArchivedMenuItemBuilder';
import { MenuItemBuilder } from '../../../../core/src/models/db/menuItem/MenuItemBuilder';
import { SnackbarOptions } from '../../lib/snackbars/SnackbarOptions';
import { Snackbars } from '../../lib/snackbars/Snackbars';
import { Strings } from '../../../../core/src/locale/Strings';
import { TArchivedMObj } from '../../../../core/src/models/db/archivedMObj/ArchivedMObjTypes';
import { TDispatch, TThunkAction } from '../../../../lib-react/src/redux/redux';
import { TGetState } from '../Store';
import { TMenuItem, TMenuItemDataChange } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { TRKMenu } from '../../../../core/src/db/DbResources';
import { actionRunWithUndoSnackbar } from './ActionHelpers';
import { dispatchWithErrorHandler } from '../ReduxErrorHandlers';
import { ELocale } from '../../../../core/src/locale/Locale';
import { TLanguage } from '../../../../core/src/locale/Languages';

type TActionMenuItem = {
  menuItem: TMenuItem;
};

export const actionMenuItemOnMenuItemPublish = dispatchWithErrorHandler((menuItem: TMenuItem): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await MenuItemBuilder.fromItem(menuItem).publish();
  };
});

export const actionMenuItemOnMenuItemUnPublish = dispatchWithErrorHandler((menuItem: TMenuItem): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await MenuItemBuilder.fromItem(menuItem).unPublish();
  };
});

type TActionMenuItemUpdate = TActionMenuItem & {
  update: Partial<TMenuItemDataChange>;
  language: TLanguage;
};

export const actionMenuItemUpdate = dispatchWithErrorHandler(({
  menuItem,
  update,
                                                                language,
}: TActionMenuItemUpdate): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    const { locale } = getState().app;

    // todo: extract and cleanup
    const { hostId } = MenuItemBuilder.getPathBuilderParamsFromDataPath(menuItem);

    await MenuItemBuilder.fromItem(menuItem).updateMenuItem({
      menuItemDataChange: update,
      localizedUpdateLocale: language as ELocale,
    });

    Snackbars.enqueueSnackbar({
      content: Strings.hMenuItemEditedSuccessfully()(locale),
      options: { ...SnackbarOptions.globalSuccess({}) },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    });
  };
});

type TActionMenuItemNameUpdate = TActionMenuItem & {
  name: string;
  viewLocale: ELocale;
};

export const actionMenuItemNameUpdate = dispatchWithErrorHandler(({
  menuItem,
  name,
  viewLocale,
}: TActionMenuItemNameUpdate): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    await MenuItemBuilder.fromItem(menuItem).updateMenuItem({
      menuItemDataChange: { name },
      localizedUpdateLocale: viewLocale,
    });
  };
});

type TActionMenuItemDuplicate = TActionMenuItem & {
  //
};

export const actionMenuItemDuplicate = dispatchWithErrorHandler(({ menuItem }: TActionMenuItemDuplicate): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await MenuItemBuilder.fromItem(menuItem).duplicate();
  };
});

type TActionMenuItemArchive = TActionMenuItem & {
  //
};

export const actionMenuItemArchive = dispatchWithErrorHandler(({ menuItem }: TActionMenuItemArchive): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    return dispatch(actionRunWithUndoSnackbar({
      msg: Strings.hMenuItemDeletedMsg(),

      run: () => {
        return MenuItemBuilder.fromItem(menuItem).moveToDbArchive();
      },

      undo: (archivedItem: TArchivedMObj<TRKMenu>) => {
        return ArchivedMenuItemBuilder.fromItem(archivedItem)
          .unArchive();
      },
    }));
  };

  return async (dispatch: TDispatch) => {
    await MenuItemBuilder.fromItem(menuItem).moveToDbArchive();
  };
});

