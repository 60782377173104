import React from 'react'
import { UnsplashPhotoCard } from './UnsplashPhotoCard'
import styled from "styled-components";
import PuffLoader from "react-spinners/PuffLoader";
import { Theme } from "../../../../config/Theme";
import { TUnsplashPhoto } from "./unsplash-api";

interface Props {
  imagePickerNothingFoundText: string,
  imagePickerLoadingText: string,
  isLoading?: boolean
  isLoadingMore?: boolean
  selectedPhotos: Record<string, TUnsplashPhoto>
  photoList: TUnsplashPhoto[]
  total?: number | undefined
  onPhotoClick: (photo: TUnsplashPhoto) => void
  loadMore: () => void
}

export function PhotoList({
                            imagePickerNothingFoundText,
                            imagePickerLoadingText,
                            isLoading = false,
                            isLoadingMore = false,
                            selectedPhotos,
                            photoList,
                            total,
                            onPhotoClick,
                            loadMore
                          }: Props) {
  const scrollableListRef = React.useMemo(() => React.createRef<HTMLDivElement>(), [])
  const onScroll = React.useCallback(() => {
    if (scrollableListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableListRef.current
      if (scrollHeight - (scrollTop + clientHeight) < 20) {
        // Lazy loading you're at the bottom of the page
        loadMore()
      }
    }
  }, [scrollableListRef, loadMore]);

  return (
    <Root>
      {isLoading && (
        <EmptyStateOrLoader>
          <EmptyStateOrLoaderText>
            {imagePickerLoadingText}
          </EmptyStateOrLoaderText>
          <PuffLoader
            loading={true}
            color={Theme.col.red}
            size={32}
            speedMultiplier={1.5}
          />
        </EmptyStateOrLoader>
      )}
      {!isLoading && photoList.length <= 0 && total === 0 && (
        <EmptyStateOrLoader>
          <EmptyStateOrLoaderText>
            {imagePickerNothingFoundText}
          </EmptyStateOrLoaderText>
        </EmptyStateOrLoader>
      )}
      {!isLoading && photoList.length <= 0 && !total && ( // todo what is this?
        <div className='flex items-center justify-center h-96 text-gray-600'/>
      )}
      {!isLoading && photoList.length > 0 && (
        <Grid
          ref={scrollableListRef}
          onScroll={onScroll}
        >
          {photoList.map((photo: TUnsplashPhoto) => {
            return (
              <UnsplashPhotoCard
                key={photo.id}
                selected={selectedPhotos[photo.id] != null}
                photo={photo}
                onPhotoClick={onPhotoClick}
              />
            )
          })}
          {isLoadingMore && (
            <PuffLoader
              loading={true}
              color={Theme.col.red}
              size={32}
              speedMultiplier={1.5}
            />
          )}
        </Grid>
      )}
    </Root>
  )
}

const rootHeight = 300;
const rootVerticalPadding = 12;
const listHeight = rootHeight - (rootVerticalPadding * 2);

const Root = styled.div`
  height: ${rootHeight}px;
  max-height: ${rootHeight}px;
  min-height: ${rootHeight}px;
  padding: ${rootVerticalPadding}px 0;
`;

const Grid = styled.div`
  max-height: ${listHeight}px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const EmptyStateOrLoader = styled.div`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyStateOrLoaderText = styled.h2`
  align-items: center;
  color: ${({ theme }) => theme.t.col.accentDark};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  text-align: center;
`;
