import * as _ from 'lodash';
import {
  TUseFormField,
  useFormField,
} from './useFormField';
import { Validator } from '../../../../core/src/lib/error/Validators';

export function useFormEmailField(params: Partial<TUseFormField<string>> = {}) {
  return useFormField<string>({
    value: '',
    ...params,
    valueToError: (value, context) => {
      const parentErr = params.valueToError && params.valueToError(value, context);
      if (!_.isEmpty(parentErr)) {
        return parentErr;
      }

      return Validator.expectEmail(value);
    },
  });
}
