import { Log } from '../../config/Instance';
import { Refs } from '../../../../core/src/db/DbRefs';
import { THostId } from '../../../../core/src/models/db/host/HostTypes';
import { actions } from '../Actions';
import {
  fork,
  put,
} from 'redux-saga/effects';
import { sagaBindRefByKey } from '../../../../lib-react/src/sagas/sagaBindRefByKey';
import { sagaSubscribeToHostPublicSubscription } from './sagaSubscribeToHostPublicSubscription';

export function* sagaSubscribeToHost(pbp: { hostId: THostId }) {
  Log.v('sagaSubscribeToHost', 'sagaSubscribeToHost', `subscribing to ${pbp.hostId}`);

  const ref = Refs.host(pbp);

  yield put(actions.actionTypeHostSetHost({ hostData: yield ref.val() }));

  yield fork(sagaSubscribeToHostPublicSubscription, { hostId: pbp.hostId });

  yield sagaBindRefByKey('sagaSubscribeToHost', ref, function* (hostData) {
    yield put(actions.actionTypeHostSetHost({ hostData }));
  });
}
