import * as React from 'react';
import styled from 'styled-components';

type Props = {
  onClick?: () => void;
  label?: string;
  children?: React.ReactNode;
};
export const IconButton = ({
  children,
  label,
  onClick,
}: Props) => {
  return (
    <MainContainer pointer={!!onClick}>
      <ButtonContainer onClick={onClick}>
        {children}
      </ButtonContainer>
      {label && <LabelText>{label}</LabelText>}
    </MainContainer>
  );
};

const MainContainer = styled.div<{ pointer: boolean }>`
  align-items: center;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const LabelText = styled.span`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 5px;
`;
