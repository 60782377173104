import * as React from 'react';
import { CurrencyInput } from '../CurrencyInput';
import { TSecondaryItemInputRendererProps } from './buildSecondaryItemInputDefaultRenderer';
import { ELocale } from '../../../../../core/src/locale/Locale';
import { TCurrencyCode } from '../../../../../core/src/locale/Currencies';

type TBuildSecondaryItemInputCurrencyParams = {
  locale: ELocale;
  currency: TCurrencyCode;
};

export function buildSecondaryItemInputCurrencyRenderer({ locale, currency }: TBuildSecondaryItemInputCurrencyParams) {
  return (props: TSecondaryItemInputRendererProps) => {
    return (
      <CurrencyInput
        locale={locale}
        currency={currency}
        // @ts-ignore
        inputRef={props.secondaryTextRef}
        readOnly={props.readOnly}
        value={`${props.secondaryText}`}
        onChange={props.onItemSecondaryChange}
        onKeyPress={props.secondaryTextOnEnterPress}
        onBlur={props.secondaryTextOnBlur}
        placeholder={props.secondaryPlaceholder}
      />
    );
  };
}
