import * as React from 'react';
import * as _ from 'lodash';
import {
  FIELD_ID,
  FIELD_NAME,
  FIELD_SORTABLE_INDEX,
} from '../../../../../core/src/db/DbDefs';
import { LabeledFieldInputList } from '../LabeledFields';
import { RemoveIcon } from '../../../assets/Assets';
import { TFieldInputListItem } from '../duplexInputList/FieldInputList';
import { TRoomToTableCount } from '../../../../../core/src/models/db/host/HostTypes';
import { buildSecondaryItemInputDefaultRenderer } from '../duplexInputList/buildSecondaryItemInputDefaultRenderer';
import { dbItemSortableNextIndex } from '../../../../../core/src/db/DbLib';
import { useProfileFormHandler } from '../../../lib/forms/ProfileForm';

function mapRoomToListItem(value: TRoomToTableCount): TFieldInputListItem {
  return {
    [FIELD_ID]: _.get(value.room, FIELD_ID, undefined),
    mainText: _.get(value.room, FIELD_NAME, ''),
    secondaryText: `${_.get(value, 'tableCount', 0)}`,
    hasSecondary: true,
    actionable: true,
  };
}

export type TRoomsEditListProps = ReturnType<typeof useProfileFormHandler>['fields']['rooms'] & {
  label: string;
  newItemMainPlaceholder: string;
};

export const RoomEditList = (props: TRoomsEditListProps) => {
  const mapListItemToRoom = React.useCallback((listItem: TFieldInputListItem): TRoomToTableCount => {
    const rooms = props.value.map(({ room }) => room);
    return {
      room: {
        [FIELD_NAME]: listItem.mainText,
        [FIELD_SORTABLE_INDEX]: dbItemSortableNextIndex(rooms),
        ...(listItem[FIELD_ID] ? { [FIELD_ID]: listItem[FIELD_ID] } : {}),
      },
      tableCount: parseInt(_.get(listItem, 'secondaryText', '0'), 10),
    };
  }, [props.value]);

  const removeIcon = React.useMemo(() => <RemoveIcon/>, []);

  return (
    <LabeledFieldInputList<TRoomToTableCount>
      isRequired
      value={props.value}
      onChange={props.setValue}
      mapListItemToValue={mapListItemToRoom}
      mapValueToListItem={mapRoomToListItem}
      newItem={props.newItem.value}
      newItemSet={props.newItem.setValue}
      newItemReset={props.newItem.reset}
      newItemMainPlaceholder={props.newItemMainPlaceholder}
      newItemSecondaryPlaceholder={'0'}
      listItemAction={removeIcon}
      renderSecondaryInput={buildSecondaryItemInputDefaultRenderer()}
      label={props.label}
    />
  );
};
