import { Log } from '../../config/Instance';
import { Refs } from '../../../../core/src/db/DbRefs';
import { THostId } from '../../../../core/src/models/db/host/HostTypes';
import { actions } from '../Actions';
import { put } from 'redux-saga/effects';
import { sagaBindRefByKey } from '../../../../lib-react/src/sagas/sagaBindRefByKey';
import { sagaWithErrorHandler } from '../ReduxErrorHandlers';

function* sagaSubscribeToGuestSessionsBase(pbp: { hostId: THostId }) {
  Log.v('sagaSubscribeToGuestSessions', 'sagaSubscribeToGuestSessions', `subscribing to ${pbp.hostId}`);

  const ref = Refs.hostGuestSessions(pbp);

  yield put(actions.actionTypeHostSetGuestSessions({ hostGuestSessions: yield ref.val() }));

  yield sagaBindRefByKey('sagaSubscribeToGuestSessions', ref, function* (guestSessions) {
    yield put(actions.actionTypeHostSetGuestSessions({ hostGuestSessions: guestSessions }));
  });
}

export const sagaSubscribeToGuestSessions = sagaWithErrorHandler(sagaSubscribeToGuestSessionsBase);
