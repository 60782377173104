import * as React from 'react';
import styled from 'styled-components';

type TBlurChildrenProps = {
  children: React.ReactNode;
  className?: string;
  blurDeviation?: number;
};

const blurSvgId = 'svg-blur';

export const BlurChildren = ({
  children,
  className,
  blurDeviation = 1.2,
}: TBlurChildrenProps) => {
  return (
    <>
      <svg style={{ display: 'none' }}>
        <filter id={blurSvgId}>
          <feGaussianBlur in="SourceGraphic" stdDeviation={blurDeviation}></feGaussianBlur>
        </filter>
      </svg>
      <Root
        className={className}>
        {children}
      </Root>
    </>
  );
};

const Root = styled.div`
  -webkit-filter: url(#${blurSvgId});
  filter: url(#${blurSvgId});
`;
