import * as React from 'react';
import * as _ from 'lodash';
import { useEnterKeyListener } from '../useKeyListener';
import styled from 'styled-components';

type TFormContainerProps = {
  children: React.ReactNode;
  error?: string;
  onEnterPress?: () => any;
  className?: string;
};

export const FormContainer = ({
  children,
  error,
  onEnterPress,
  className,
}: TFormContainerProps) => {
  useEnterKeyListener(() => onEnterPress && onEnterPress());
  return (
    <Root className={className}>
      {children}
      {!_.isEmpty(error) && (
        <FixedErrorContainer>
          <Error>{error}</Error>
        </FixedErrorContainer>
      )}
    </Root>
  );
};

const Root = styled.div`
`;

const FixedErrorContainer = styled.div`
  height: 20px;
  text-align: right;
  width: 100%;
`;

const Error = styled.span`
  color: ${({ theme }) => theme.t.col.red};
  font-size: 12px;
`;
