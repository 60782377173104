import 'firebase/app';
import 'firebase/database';
import { Config } from '../config/Config';
import { FirebaseApi } from '../../../core/src/FirebaseApi';
import { Log } from '../config/Instance';
import { safe, safeWaitLogExpectDefined } from '../../../core/src/lib/HelperFunctions';
import firebase from 'firebase';

export type TUpdateProfileParams = {
  newEmail?: string;
  newPassword?: string;
};

class FirebaseBase extends FirebaseApi {
  private readonly config: any;

  private app: firebase.app.App;

  private static initialize(config: any) {
    Log.v('Firebase', 'initialize', 'Initializing FirebaseBase');
    return safe(firebase.app) ?? firebase.initializeApp(config);
  }

  constructor(config: any) {
    Log.v('Firebase', 'constructor', 'Initializing FirebaseBase');
    const app = FirebaseBase.initialize(config);
    super({
      // @ts-ignore
      database: () => firebase.database(),
      // @ts-ignore
      storage: () => firebase.storage(),
      // @ts-ignore
      auth: () => firebase.auth(),
    });
    this.config = config;
    this.app = app;
  }

  authSignInWithEmailAndPassword(email: string, password: string): Promise<firebase.User> {
    return safeWaitLogExpectDefined('Firebase', 'authSignInWithEmailAndPassword', async () => {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      return userCredential.user;
    });
  }

  async authUpdateProfile({ newEmail, newPassword }: TUpdateProfileParams) {
    const user = await safeWaitLogExpectDefined('Firebase', 'authSignInWithEmailAndPassword', async () => {
      return this.getCurrentUser();
    });
    newEmail && await user.updateEmail(newEmail);
    newPassword && await user.updatePassword(newPassword);
  }

  getCurrentUser(): firebase.User | undefined {
    const value = firebase.auth().currentUser;
    return value != null
      ? value
      : undefined;
  }

  async authLogout(): Promise<void> {
    await firebase.auth().signOut();
    await this.app.delete();
    this.app = FirebaseBase.initialize(this.config);
    Log.v('Firebase', 'logout', 'Sign off complete');
  }

  onAuthStateChanged(onChange: (user: firebase.User | null) => void) {
    return { unsubscribe: firebase.auth().onAuthStateChanged(onChange) };
  }
}

export const Firebase = new FirebaseBase(Config.firebase);
