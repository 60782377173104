import * as React from 'react';
import styled from 'styled-components';

type TMenuMainFormCardFrameProps = {
  title: React.ReactNode;
  contentItems: React.ReactNode[];
  bottomLeftButtons?: React.ReactNode;
  bottomRightButtons?: React.ReactNode;
};

export const MenuMainFormCardFrame = ({
  title,
  contentItems,
  bottomLeftButtons,
  bottomRightButtons,
}: TMenuMainFormCardFrameProps) => {
  return (
    <Root>
      {title}
      <Content>
        {contentItems.map((field, index) => (
          <FieldContainer key={index}>
            {field}
          </FieldContainer>
        ))}
        {bottomLeftButtons && bottomRightButtons && (
          <BottomButtonsContainer>
            {LeftButtonContainer && (
              <LeftButtonContainer>
                {bottomLeftButtons}
              </LeftButtonContainer>
            )}
            {bottomRightButtons && (
              <RightButtonsContainer>
                {bottomRightButtons}
              </RightButtonsContainer>
            )}
          </BottomButtonsContainer>
        )}
      </Content>
    </Root>
  );
};

export const MenuFormCardTitle = styled.h2`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.accentDark};
  color: ${({ theme }) => theme.t.col.white};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  min-height: 60px;
  text-align: center;
`;

const Root = styled.div`
  background-color: ${({ theme }) => theme.t.col.white};
  display: flex;
  flex-direction: column;
  width: 315px;
`;

const Content = styled.div`
  padding: 20px;
`;

const FieldContainer = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const BottomButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`;

const LeftButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
`;
const RightButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;
