import * as React from 'react';
import sanitizeHtml from 'sanitize-html-react';

const defaultOptions = {
  allowedTags: ['h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
    'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'span',
    'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre'],
  allowedAttributes: {
    'a': ['href']
  }
}

type TProps = {
  html: string
  options?: any;
  className?: string;
}

export const RenderHTMLSafely = React.memo(({ className, html, options }: TProps) => {
  const sanitized = React.useMemo(() => {
    return {
      __html: sanitizeHtml(
        html,
        options || defaultOptions,
      )
    }
  }, [html])
  return (
    <div className={className} dangerouslySetInnerHTML={sanitized}/>
  )
}, (a, b) => a.html === b.html && a.className === b.className);