import * as React from 'react';
import { SquareButton } from '../Button/SquareButton';
import styled from 'styled-components';

type TFieldDualOptionInput<Left, Right, None = undefined> = {
  value: Left | Right | None;
  onChange: (value: Left | Right | None) => void;
  leftTitle: string;
  rightTitle: string;
  leftValue: Left;
  rightValue: Right;
  className?: string;
};

function FieldDualOptionInputBase<Left, Right, None>({
  value,
  onChange,
  leftTitle,
  rightTitle,
  leftValue,
  rightValue,
  className,
}: TFieldDualOptionInput<Left, Right, None>) {
  const onLeftClick = React.useCallback(() => {
    onChange(leftValue);
  }, [onChange, leftValue]);

  const onRightClick = React.useCallback(() => {
    onChange(rightValue);
  }, [onChange, rightValue]);

  const [LeftButton, RightButton] = React.useMemo(() => {
    if (value === leftValue) {
      return [SelectedButton, DeselectedButton];
    }
    if (value === rightValue) {
      return [DeselectedButton, SelectedButton];
    }
    return [DeselectedButton, DeselectedButton];
  }, [value, leftValue, rightValue]);

  return (
    <Root className={className}>
      <LeftButton
        title={leftTitle}
        onClick={onLeftClick}
      />
      <RightButton
        title={rightTitle}
        onClick={onRightClick}
      />
    </Root>
  );
}

export const FieldDualOptionInput = React.memo(FieldDualOptionInputBase) as typeof FieldDualOptionInputBase;

const Root = styled.div`
  column-gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
`;

const SelectedButton = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.accentLight};
  color: ${({ theme }) => theme.t.col.white};
`;

const DeselectedButton = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.white};
  border: 1px solid ${({ theme }) => theme.t.col.accentLight};
  color: ${({ theme }) => theme.t.col.accentDark};
`;
