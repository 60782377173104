import * as _ from 'lodash';
import { Host } from '../../../../core/src/models/db/host/Host';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TOrderItem } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { useFormNumberField } from '../../../../lib-react/src/hooks/form/useFormNumberField';

type TUseOrderItemPriceCalculatorParams = {
  host: THost;
  orderItems: TOrderItem[];
  peopleCount: number;
};

export type TUseOrderItemPriceCalculatorResult = ReturnType<typeof useOrderItemPriceCalculator>;

export function useOrderItemPriceCalculator({
  host,
  orderItems,
  peopleCount,
}: TUseOrderItemPriceCalculatorParams) {
  const priceInfo = Object.freeze(Host.getPriceInfoForOrderItems(host, orderItems, peopleCount));

  const {
    valueStr: orderDiscount,
    value: orderDiscountNum,
    setValue: setOrderDiscount,
  } = useFormNumberField({
    syncingActive: true,
    value: _.sumBy(orderItems, 'discount'),
    min: 0,
    max: priceInfo.totalWithoutDiscount,
  });

  const discountedInfo = Object.freeze(Host.getPriceInfoForOrderItems(host, orderItems, peopleCount, orderDiscountNum));
  return {
    orderDiscount,
    setOrderDiscount,
    orderDiscountNum,
    total: discountedInfo.totalWithDiscount,
    orderDiscountPercentage: discountedInfo.orderDiscountPercentage,
    subTotal: discountedInfo.elementsTotal,
    coverTotal: discountedInfo.coverTotal,
  };
}
