import * as React from 'react';
import * as _ from 'lodash';
import { DbRes } from '../../../../core/src/db/DbResources';
import { ReactComponent as DragIconBlack } from '../../assets/drag-icon-black.svg';
import { ReactComponent as DragIconWhite } from '../../assets/drag-icon-white.svg';
import { FIELD_ID, FIELD_NAME } from '../../../../core/src/db/DbDefs';
import { EditIconDark, EditIconLight, MenuIconLightGray5, MenuIconWhite, RemoveIcon } from '../../assets/Assets';
import { FieldSyncedString } from '../form/syncedFields/FieldSyncedString';
import { IconButton } from '../Button/IconButton';
import { PopoverConfirmation } from '../popup/PopoverConfirmation';
import { Strings } from '../../../../core/src/locale/Strings';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TMObjDataCreator } from '../../../../core/src/lib/model/ModelTypes';
import { TMenu, TMenuId } from '../../../../core/src/models/db/menu/MenuTypes';
import { addIfTrueArr } from '../../../../core/src/lib/HelperFunctions';
import { dbItemLocalizableGetLocalizedFieldWithDefault } from '../../../../core/src/db/DbLib';
import { formatSingularPlural } from '../../../../core/src/lib/formatters/LocaleLib';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

type TMenuBuilderItemProps<T extends TMObjDataCreator<typeof DbRes.Menu>> = {
  componentId: string;
  locale: ELocale;
  hostData: THost;
  menuData: T;
  menuLocale: ELocale;
  onMenuNameChange: (menu: T, newName: string) => void;
  onMenuNameBlur?: (menu: T) => void;
  onItemSelect?: (menuId: TMenuId) => void;
  onMenuArchive?: (menu: T) => void;
  onMenuItemEditClick?: (menu: T) => void;
  isSelected?: boolean;
};

export function MenuBuilderItem<T extends TMObjDataCreator<typeof DbRes.Menu>>({
                                                                                 componentId,
                                                                                 locale,
                                                                                 hostData,
                                                                                 menuData,
                                                                                 menuLocale,
                                                                                 onMenuNameChange,
                                                                                 onMenuNameBlur,
                                                                                 onItemSelect,
                                                                                 onMenuArchive,
                                                                                 onMenuItemEditClick,
                                                                                 isSelected = false,
                                                                               }: TMenuBuilderItemProps<T>) {
  const menuName = dbItemLocalizableGetLocalizedFieldWithDefault<TMenu, 'name'>(menuData, FIELD_NAME, menuLocale);

  const setMenuName = React.useCallback((newValue: string) => {
    return onMenuNameChange(menuData, newValue);
  }, [menuData]);

  const onMenuItemClick = onItemSelect && useItemCallback(menuData[FIELD_ID], onItemSelect);
  const onMenuItemEditClicked = onMenuItemEditClick && useItemCallback(menuData, onMenuItemEditClick);
  const onMenuArchiveClick = onMenuArchive && useItemCallback(menuData, onMenuArchive);

  const onNameInputSave = React.useCallback(() => {
    onMenuNameBlur && onMenuNameBlur(menuData);
  }, [onMenuNameBlur, menuData]);

  const { subMenuCount, itemsCount } = getSubMenuAndItemsCount({
    hostData,
    menuData,
  });
  const containsSubMenu = subMenuCount > 0;
  return (
    <Root
      isSelected={isSelected}
      isFilled={containsSubMenu}
      isClickable={!!onMenuItemClick}
      onClick={onMenuItemClick}
      isValid={true}
    >
      <InnerContainer>
        <SingleIconContainer>
          <IconButton>
            {isSelected
              ? <DragIconWhite/>
              : <DragIconBlack/>}
          </IconButton>
        </SingleIconContainer>
        <TextContainer>
          <MenuNameFieldSyncedInput
            isSelected={isSelected}
            value={menuName}
            onValueChange={setMenuName}
            placeholder={Strings.hMenuCreateCat()(locale)}
            isEditable={!!onMenuItemClick}
            onComplete={onNameInputSave}
          />
          <SubtitleText
            isSelected={isSelected}
            isFilled={containsSubMenu}>
            {[
              ...addIfTrueArr(subMenuCount > 0, Strings.hMenuSubMenusCount({ count: `${subMenuCount}` })(locale)),
              ...addIfTrueArr(itemsCount > 0, formatSingularPlural(
                locale,
                itemsCount,
                Strings.hMenuMenuItemsSingularCount({ count: `${itemsCount}` }),
                Strings.hMenuMenuItemsPluralCount({ count: `${itemsCount}` }),
              )),
            ].join(` ${Strings.hTokenAnd()(locale)} `)}
          </SubtitleText>
        </TextContainer>
        <ColumnIconContainer>
          {containsSubMenu && !onMenuItemEditClicked && !onMenuArchiveClick && (
            <IconButton>
              {isSelected && <MenuIconWhite/>}
              {!isSelected && <MenuIconLightGray5/>}
            </IconButton>
          )}
          {onMenuItemEditClicked && (
            <IconButton onClick={onMenuItemEditClicked}>
              {isSelected ? <EditIconLight/> : <EditIconDark/>}
            </IconButton>
          )}
          {onMenuArchiveClick && (
            <PopoverConfirmation
              componentId={componentId}
              buttonOnClick={onMenuArchiveClick}
              placement={'bottom'}
              buttonText={Strings.hMenuDelCmd()(locale)}
              confirmationMessage={Strings.hMenuDelMsg()(locale)}
            >
              <RemoveIcon/>
            </PopoverConfirmation>
          )}
        </ColumnIconContainer>
      </InnerContainer>
    </Root>
  );
}

export function getSubMenuAndItemsCount({
                                          hostData,
                                          menuData,
                                        }: Pick<TMenuBuilderItemProps<TMObjDataCreator<typeof DbRes.Menu>>, 'hostData' | 'menuData'>) {
  return {
    subMenuCount: _.size(menuData.subMenus),
    itemsCount: _.chain(hostData.items)
      .values()
      .filter((menuItem) => _.values(menuItem.menuIds).includes(menuData[FIELD_ID]))
      .size()
      .value(),
  };
}

type TRootProps = {
  isSelected: boolean;
  isClickable: boolean;
  isValid: boolean;
  isFilled: boolean;
};

function getRootBackgroundColor(props: TRootProps & { theme: any }) {
  if (props.isSelected) {
    return props.theme.t.col.accentDark;
  }
  if (props.isFilled) {
    return props.theme.t.col.aLaCarteTheme;
  }
  return props.theme.t.lightGray4;
}

const Root = styled.div<TRootProps>`
  // flex: 0 0 109px;
  height: 109px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  background-color: ${getRootBackgroundColor};
  border 1px solid ${({ theme, isValid }) => (isValid ? theme.t.col.lightGray2 : theme.t.col.red)};
`;

const InnerContainer = styled.div`
  align-items: center;
  display: grid;
  flex: 1;
  grid-template-columns: 30px 1fr 40px;
  height: 100%;
`;

const TextContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
`;

type TNameTextProps = {
  isSelected: boolean;
};

function getNameTextColor(props: TNameTextProps & { theme: any }) {
  if (props.isSelected) {
    return props.theme.t.col.white;
  }
  return props.theme.t.col.accentDark;
}

const MenuNameFieldSyncedInput = styled(FieldSyncedString)<TNameTextProps>`
  color: ${getNameTextColor};
`;

type TSubtitleTextProps = {
  isSelected: boolean;
  isFilled: boolean;
};

function getSubtitleTextColor(props: TSubtitleTextProps & { theme: any }) {
  if (props.isSelected) {
    return props.theme.t.col.lightGray1;
  }
  if (props.isFilled) {
    return props.theme.t.col.lightGray1;
  }
  return props.theme.t.col.red;
}

const SubtitleText = styled.span<TSubtitleTextProps>`
  bottom: 2px;
  color: ${getSubtitleTextColor};
  font-size: 10px;
  font-weight: 600;
  line-height: 22px;
  position: absolute;
`;

const SingleIconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const ColumnIconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  & > div {
    margin: 5px auto;
  }
`;
