import * as React from 'react';
import * as _ from 'lodash';
import { Re2direct } from '../components/router/Re2direct';
import {
  Route,
  Switch,
} from 'react-router-dom';
import {
  Routes,
  routeDef2ToRoutes,
} from '../config/Routes';
import { THostId } from '../../../core/src/models/db/host/HostTypes';
import {
  TPropCheck,
  withPropCheckMiddleware,
} from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import { TScreenNavigationProps } from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import { connect } from 'react-redux';

export type TNavigatorHostNavParams = {
  hostId: THostId;
};

export type TNavigatorHostProps = TScreenNavigationProps<TNavigatorHostNavParams> & {
};

type TNavigatorHomeBaseProps = TNavigatorHostProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TNavigatorHomeBaseState = {
};

class NavigatorHostBase extends React.Component<TPropCheck<typeof NavigatorHostBase>, TNavigatorHomeBaseState> {
  static checkProps(props: TNavigatorHomeBaseProps) {
    const { hostId } = props;
    if (hostId == null) {
      return () => (
        <Re2direct
          route={Routes.ScreenLogin}
          params={{ hostId }}
        />
      );
    }

    return {
      ...props,
      hostId,
    };
  }

  render() {
    const { hostId } = this.props;
    return (
      <Switch>
        {routeDef2ToRoutes([
          Routes.ScreenHostMenuEditor,
          Routes.ScreenHostMenuBuilder,
          Routes.ScreenHostMenuMain,
          Routes.ScreenHostMenuItemEditor,
          Routes.ScreenHostSettings,
          Routes.ScreenHostProfile,
          Routes.ScreenHostGuestSessionMessages,
          Routes.ScreenHostGuestSessionOverview,
          Routes.ScreenHostHome,
        ])}
        <Route exact path="/hosts">
          <Re2direct
            route={Routes.ScreenHostHome}
            params={{ hostId }}
          />
        </Route>
      </Switch>
    );
  }
}

function mapStateToProps(state: TState, props: TNavigatorHostProps) {
  return {
    hostId: _.get(state.app.user, 'uid'),
    locale: state.app.locale,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export const NavigatorHost = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(NavigatorHostBase));

