import * as React from 'react';
import { MaxHeightDiv } from '../MaxHeightDiv';
import { StationChat } from '../messages/StationChat';
import { StationChatList } from '../messages/StationChatList';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { THostMessage } from '../../../../core/src/models/db/hostMessage/HostMessageTypes';
import { TStationId } from '../../../../core/src/models/db/station/StationTypes';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

type TMessagesViewProps = {
  locale: ELocale;
  hostData: THost;
  previousMessages: THostMessage[];
  selectedStationId: TStationId;
  setSelectedStationId: (stationId: TStationId) => void;
  messageSuggestions: string[];
  onMessageSend: (destStationId: TStationId, message: string) => Promise<any>;
};
export const MessagesView = ({
  locale,
  hostData,
  previousMessages,
  selectedStationId,
  setSelectedStationId,
  messageSuggestions,
  onMessageSend,
}: TMessagesViewProps) => {
  const onMessageSendPress = React.useCallback((message: string) => {
    return onMessageSend(selectedStationId, message);
  }, [onMessageSend, selectedStationId]);

  return (
    <OuterContainer>
      <InnerContainer>
        <StationChatList
          hostData={hostData}
          selectedStationId={selectedStationId}
          setSelectedStationId={setSelectedStationId}
        />
        <StationChat
          locale={locale}
          previousMessages={previousMessages}
          onMessageSend={onMessageSendPress}
          messageSuggestions={messageSuggestions}
        />
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(MaxHeightDiv)`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.lightGray3};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const InnerContainer = styled.div`

  align-items: stretch;
  display: flex;;
  flex: 1;
  max-width: 1000px;
  overflow: hidden;
  padding: 20px;
  & > div:first-child {
    margin-right: 20px;
  }
  width: 100%;
`;
