import * as React from 'react';
import { StyledComponentsTheme } from '../lib-components/theme/StyledComponentsTheme';
import { Theme } from '../config/Theme';
import { useSyncedDataRef } from '../../../lib-react/src/hooks/useSyncedDataRef';

export function useBindDrawableContentOffset(height: number) {
  const setDrawableContentOffset = useSetDrawableContentOffset();
  const setDrawableContentOffsetRef = useSyncedDataRef(setDrawableContentOffset);
  React.useEffect(() => {
    setDrawableContentOffsetRef.current(height);
    return () => setDrawableContentOffsetRef.current(-height);
  }, []);
}

export function useSetDrawableContentOffset() {
  const { setTheme } = React.useContext(StyledComponentsTheme.Context);
  return React.useCallback((withHeight: number) => {
    setTheme((previousTheme: typeof Theme) => {
      return {
        ...previousTheme,
        drawableContentOffset: previousTheme.drawableContentOffset + withHeight,
      };
    });
  }, [setTheme]);
}
