import * as React from 'react';

import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import {
  HostNavbarMainBase,
  hostNavbarMainBaseHeight,
} from './HostNavbarMainBase';
import { Routes } from '../../config/Routes';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TState } from '../../redux/Store';
import { actionAppSignOut } from '../../redux/App';
import { useBindDrawableContentOffset } from '../../hooks/useBindDrawableContentOffset';
import { useDispatchActionCallback } from '../../../../lib-react/src/hooks/useDispatchAction';
import {
  useIsRouteSelected,
  usePushToRoute,
} from '../../lib/navigation/Navigation';
import { useSelector } from 'react-redux';
import { ELocale } from '../../../../core/src/locale/Locale';

type THostNavbarMainProps = {
  locale: ELocale;
};

export const HostNavbarMain = ({ locale }: THostNavbarMainProps) => {
  const hostData = useSelector((state: TState) => state.host.hostData as THost);

  useBindDrawableContentOffset(hostNavbarMainBaseHeight);

  const onSignOut = useDispatchActionCallback(actionAppSignOut);

  const hostSelected = useIsRouteSelected(Routes.ScreenHostHome);
  const onHostOverviewClick = usePushToRoute(Routes.ScreenHostHome, { hostId: hostData[FIELD_ID] });

  const hostMenuSelected = useIsRouteSelected(Routes.ScreenHostMenuMain);
  const onHostMenuClick = usePushToRoute(Routes.ScreenHostMenuMain, { hostId: hostData[FIELD_ID] });

  const hostProfileSelected = useIsRouteSelected(Routes.ScreenHostProfile);
  const onHostProfileClick = usePushToRoute(Routes.ScreenHostProfile, { hostId: hostData[FIELD_ID] });

  const hostSettingsSelected = useIsRouteSelected(Routes.ScreenHostSettings);
  const onHostSettingsClick = usePushToRoute(Routes.ScreenHostSettings, { hostId: hostData[FIELD_ID] });

  return (
    <HostNavbarMainBase
      locale={locale}
      hostData={hostData}
      onSignOut={onSignOut}
      hostSelected={hostSelected}
      onHostOverviewClick={onHostOverviewClick}
      hostProfileSelected={hostProfileSelected}
      onHostProfileClick={onHostProfileClick}
      hostMenuSelected={hostMenuSelected}
      onHostMenuClick={onHostMenuClick}
      hostSettingsSelected={hostSettingsSelected}
      onHostSettingsClick={onHostSettingsClick}
    />
  );
};
