import * as _ from 'lodash';
import { Log } from '../../config/Instance';
import { TBuiltRouteDef } from './Routes';
import { useHistory } from 'react-router-dom';

type TNavContextParam = {
  goBackText: string;
};

export type TNavContext = {
  navContext?: TNavContextParam;
};

const routeNavContext: { [pathTemplate: string]: TNavContextParam | undefined } = {};

const historyStack: string[] = [
];

type THistory = ReturnType<typeof useHistory>;

class HistoryBase {
  private history: THistory | null = null;

  setParams(history: THistory) {
    this.history = history;
  }

  getPreviousNavContext = (): TNavContextParam | undefined => {
    const previousRoutePath = _.get(historyStack, '[1]');
    if (!previousRoutePath) {
      return undefined;
    }

    return routeNavContext[previousRoutePath];
  };

  push<T>(route: TBuiltRouteDef<T>, params: T & TNavContext): boolean {
    if (!this.history) {
      return false;
    }

    // Take the last mounted screen and save the params
    const currentRoute = _.head(historyStack);
    if (currentRoute) {
      routeNavContext[currentRoute] = params.navContext;
    }

    const routePath = route.paramsToRoute(params);
    historyStack.unshift(routePath);

    Log.v('History', 'push', `routePath=${routePath}`);
    this.history.push(routePath);
    return true;
  }

  replace<T>(route: TBuiltRouteDef<T>, params: T): boolean {
    const routePath = route.paramsToRoute(params);
    historyStack[0] = routePath;

    Log.v('History', 'replace', `routePath=${routePath}`);

    if (!this.history) {
      Log.e('History', 'replace', 'history not defined, using window.location');
      window.location.pathname = routePath;
      return true;
    }

    this.history.replace(routePath);
    return true;
  }

  goBack() {
    if (!this.history) {
      Log.e('History', 'goBack', 'history not defined');
      return false;
    }
    historyStack.shift();

    Log.v('History', 'goBack', '');
    // @ts-ignore
    this.history.goBack();
    return true;
  }
}

export const Hist = new HistoryBase();
