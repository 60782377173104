import * as React from 'react';
import { FIELD_ID, FIELD_NAME, } from '../../../core/src/db/DbDefs';
import { FullScreenErrorView } from '../components/fullScreenViews/FullScreenErrorView';
import { FullScreenLoadingView } from '../components/fullScreenViews/FullScreenLoadingView';
import { Hist } from '../lib/navigation/History';
import { MaxHeightDiv, MaxScreenDiv, } from '../components/MaxHeightDiv';
import { Menu } from '../../../core/src/models/db/menu/Menu';
import { MenuEditorCreateNew } from '../components/menuEdit/MenuEditorCreateNew';
import { MenuEditorView } from '../components/menuEdit/MenuEditorView';
import { Routes } from '../config/Routes';
import { SecondaryNavbar } from '../components/navbars/SecondaryNavbar';
import { Strings } from '../../../core/src/locale/Strings';
import { THost, THostId, } from '../../../core/src/models/db/host/HostTypes';
import { TMenuId } from '../../../core/src/models/db/menu/MenuTypes';
import { TMenuItem } from '../../../core/src/models/db/menuItem/MenuItemTypes';
import { TPropCheck, withPropCheckMiddleware, } from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import { getNavParam, TScreenNavigationProps, } from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import { actionHostAddNewDefaultMenuItem } from '../redux/actions/Host';
import {
  actionMenuItemArchive,
  actionMenuItemDuplicate,
  actionMenuItemNameUpdate,
  actionMenuItemOnMenuItemPublish,
  actionMenuItemOnMenuItemUnPublish,
} from '../redux/actions/MenuItem';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import { connect } from 'react-redux';
import { formatWithLocale } from '../lib/Format';
import { localize } from '../../../core/src/lib/formatters/LocaleLib';
import styled from 'styled-components';
import { ELocale } from '../../../core/src/locale/Locale';

export type TScreenHostMenuEditorNavParams = {
  hostId: THostId;
  menuId: TMenuId;
  menuLocale: ELocale;
};

export type TScreenHostMenuEditorProps = TScreenNavigationProps<TScreenHostMenuEditorNavParams> & {
  //
};

type TScreenHostMenuEditorBaseProps = TScreenHostMenuEditorProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

type TScreenHostMenuEditorBaseState = {
  //
};

class ScreenHostMenuEditorBase extends React.Component<TPropCheck<typeof ScreenHostMenuEditorBase>, TScreenHostMenuEditorBaseState> {
  static checkProps(props: TScreenHostMenuEditorBaseProps) {
    const {
      locale,
      hostData,
    } = props;

    if (hostData == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    if (props.menuData == null) {
      const hostId = hostData[FIELD_ID];
      return () => (
        <FullScreenErrorView
          locale={locale}
          message={Strings.gMenuNonExistentMsg()(locale)}
          actionText={Strings.hBackToMenuMain()(locale)}
          actionOnClick={() => Hist.replace(Routes.ScreenHostMenuMain, { hostId })}
        />
      );
    }

    return {
      ...props,
      menuData: props.menuData,
      hostData: props.hostData as THost,
    };
  }

  private navigateToScreenHostMenuItemEditor = async (menuItem: TMenuItem) => {
    const {
      locale,
      hostData,
      menuLocale,
    } = this.props;
    const goBackText = Strings.hBackToMenu({ hMenuTab: this.getScreenName() })(locale);
    Hist.push(Routes.ScreenHostMenuItemEditor, {
      hostId: hostData[FIELD_ID],
      menuItemId: menuItem[FIELD_ID],
      viewLocale: menuLocale,
      navContext: { goBackText },
    });
  };

  private onMenuItemDuplicate = async (menuItem: TMenuItem) => {
    const { menuItemDuplicate } = this.props;
    return menuItemDuplicate({ menuItem });
  };

  private onMenuItemArchive = async (menuItem: TMenuItem) => {
    const { menuItemArchive } = this.props;
    return menuItemArchive({ menuItem });
  };

  private onMenuItemCreateClick = async () => {
    const {
      hostAddNewDefaultMenuItem,
      hostData,
      menuData,
    } = this.props;
    const menuItem = await hostAddNewDefaultMenuItem({
      hostData,
      menuId: menuData[FIELD_ID],
    });

    return this.navigateToScreenHostMenuItemEditor(menuItem);
  };

  private onMenuItemNameChange = async (menuItem: TMenuItem, newName: string) => {
    const {
      menuItemNameUpdate,
      menuLocale,
    } = this.props;
    await menuItemNameUpdate({
      menuItem,
      name: newName,
      viewLocale: menuLocale,
    });
  };

  render() {
    const {
      locale,
      hostData,
      menuData,
      menuLocale,
      menuItemOnMenuItemPublish,
      menuItemOnMenuItemUnPublish,
    } = this.props;
    return (
      <Root>
        {this.renderSecondarySnackbar()}
        <ScrollableContent>
          <Content>
            <MenuEditorView
              locale={locale}
              hostData={hostData}
              menuData={menuData}
              menuLocale={menuLocale}
              onMenuItemNameChange={this.onMenuItemNameChange}
              onMenuItemEditClick={this.navigateToScreenHostMenuItemEditor}
              onMenuItemArchive={this.onMenuItemArchive}
              onMenuItemDuplicate={this.onMenuItemDuplicate}
              onMenuItemPublish={menuItemOnMenuItemPublish}
              onMenuItemUnPublish={menuItemOnMenuItemUnPublish}
            />
          </Content>
        </ScrollableContent>
      </Root>
    );
  }

  private renderSecondarySnackbar = () => {
    const {
      locale,
      hostData,
    } = this.props;
    return (
      <SecondaryNavbar
        locale={locale}
        hostId={hostData[FIELD_ID]}
        name={this.getScreenName()}
        rightActionButton={(
          <MenuEditorCreateNew
            locale={locale}
            onClick={this.onMenuItemCreateClick}
          />
        )}
      />
    );
  };

  private getScreenName = () => {
    const { locale, menuData, menuLocale } = this.props;
    const menuItemName = localize(menuData, FIELD_NAME, menuLocale);
    return formatWithLocale(menuItemName, locale, menuLocale);
  };
}

function mapStateToProps(state: TState, ownProps: TScreenHostMenuEditorProps) {
  const menuId = getNavParam(ownProps, 'menuId');
  const menuLocale = getNavParam(ownProps, 'menuLocale');
  const { hostData } = state.host;
  return {
    locale: state.app.locale,
    menuId,
    menuLocale,
    hostData,
    menuData: hostData && Menu.findMenuById(hostData.menu, menuId),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hostAddNewDefaultMenuItem: bindActionCreators(actionHostAddNewDefaultMenuItem, dispatch),
    menuItemOnMenuItemPublish: bindActionCreators(actionMenuItemOnMenuItemPublish, dispatch),
    menuItemOnMenuItemUnPublish: bindActionCreators(actionMenuItemOnMenuItemUnPublish, dispatch),
    menuItemNameUpdate: bindActionCreators(actionMenuItemNameUpdate, dispatch),
    menuItemDuplicate: bindActionCreators(actionMenuItemDuplicate, dispatch),
    menuItemArchive: bindActionCreators(actionMenuItemArchive, dispatch),
  };
}

export const ScreenHostMenuEditor = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(ScreenHostMenuEditorBase));

const Root = styled(MaxScreenDiv)`
`;

const ScrollableContent = styled(MaxHeightDiv)`
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

