import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';

import { ReactComponent as CheckmarkEmpty } from '../../assets/checkmark-empty.svg';
import { ReactComponent as CheckmarkFull } from '../../assets/checkmark-filled.svg';
import {
  EIngredientType,
  TIngredient,
  TIngredientId,
} from '../../../../core/src/models/db/ingredient/IngredientTypes';
import { EOrderItemStatus } from './useOrderItemStatusManager';
import { FIELD_ID, FIELD_NAME, TObjList, } from '../../../../core/src/db/DbDefs';
import { Ingredient } from '../../../../core/src/models/db/ingredient/Ingredient';
import { Num } from '../../../../core/src/lib/formatters/Num';
import { OrderItem } from '../../../../core/src/models/db/orderItem/OrderItem';
import { TOrderItem, TOrderItemId, } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { dbItemNameableSort } from '../../../../core/src/db/DbLib';
import { TCurrencyCode } from '../../../../core/src/locale/Currencies';
import { useCurrentHost } from "../../hooks/useCurrentHost";

type TContoListItemProps = {
  orderItemData: TOrderItem;
  onOrderItemStatusToggle: (orderItemId: TOrderItemId) => void;
  orderItemStatus: EOrderItemStatus;
  isLastItem?: boolean;
};

export const GuestSessionOverviewOrderItem = ({
                                                orderItemData,
                                                onOrderItemStatusToggle,
                                                orderItemStatus,
                                                isLastItem = false,
                                              }: TContoListItemProps) => {
  const host = useCurrentHost();

  const onCheckboxClick = React.useCallback(() => {
    onOrderItemStatusToggle(orderItemData[FIELD_ID]);
  }, [orderItemData, onOrderItemStatusToggle]);

  const inActive = orderItemData.paid == true;
  const selected = !inActive && orderItemStatus === EOrderItemStatus.SELECTED;
  return (
    <Container inactive={inActive}>
      <CheckMarkContainer
        onClick={!inActive ? onCheckboxClick : undefined}
        clickable={!inActive}
      >
        {selected
          ? <CheckmarkFull/>
          : <CheckmarkEmpty/>}
      </CheckMarkContainer>
      <TextContainer>
        <ItemNameText>{orderItemData[FIELD_NAME]}</ItemNameText>
        <PriceText>{Num.formatPrice2(OrderItem.getTotalPrice(orderItemData), host?.currency)}</PriceText>
      </TextContainer>
      <ExtrasContainer lineSeparator={!isLastItem}>
        {renderIngredients(EIngredientType.OPTION, orderItemData.ingredients, host?.currency)}
        {renderIngredients(EIngredientType.ADDABLE, orderItemData.ingredients, host?.currency)}
        {renderIngredients(EIngredientType.REMOVABLE, orderItemData.ingredients, host?.currency)}
      </ExtrasContainer>
    </Container>
  );
};

export function renderIngredients(type: EIngredientType,
                                  ingredients: TObjList<TIngredientId, TIngredient> | undefined,
                                  currency?: TCurrencyCode) {
  const Comp = type == EIngredientType.REMOVABLE
    ? ExtraTextNegative
    : ExtraTextNeutral;

  const symbolMap = {
    [EIngredientType.OPTION]: 'x',
    [EIngredientType.REMOVABLE]: '-',
    [EIngredientType.ADDABLE]: '+',
  };

  return (
    <>
      {_.values(ingredients)
        .filter(Ingredient.filterType(type))
        .sort(dbItemNameableSort)
        .map((ingredient) => (
          <Comp key={`${ingredient[FIELD_ID]}-${type}`}>
            <span>{symbolMap[type]} {ingredient[FIELD_NAME]}</span>
            <span>{Num.formatPrice2(ingredient.price, currency)}</span>
          </Comp>
        ))}
    </>
  );
}

const Container = styled.div<{ inactive?: boolean }>`
  cursor: pointer;
  display: grid;
  grid-template-areas:
    "checkbox item-info"
    " .       extras   ";
  grid-template-columns: 50px 1fr;
  grid-template-rows: 60px 1fr;
  opacity: ${({ inactive }) => (inactive ? '0.3' : '1')};
  width: 100%;
`;

const CheckMarkContainer = styled.div<{ clickable?: boolean }>`
  align-items: center;
  cursor: ${({ clickable }) => clickable && 'pointer'};
  display: flex;
  flex-direction: row;;
  grid-area: checkbox;
  justify-content: flex-start;
`;

const TextContainer = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: row;
  grid-area: item-info;;
  justify-content: space-between;
`;

const ItemNameText = styled.span`
  font-size: 15px;
  font-weight: normal;
  line-height: 20px;
`;

const PriceText = styled.span`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
`;

const ExtrasContainer = styled.div<{ lineSeparator?: boolean }>`
  border-bottom: ${({ lineSeparator, theme }) => (lineSeparator ? `1px solid ${theme.t.col.lightGray3}` : '0')};
  display: flex;
  flex-direction: column;
  grid-area: extras;
`;

const ExtraText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 14px;
  font-weight: normal;
  justify-content: space-between;
  line-height: 22px;

  &:last-of-type {
    margin-bottom: 20px;
  }
`;

const ExtraTextNeutral = styled(ExtraText)`
  color: ${({ theme }) => theme.t.col.accentLight};
`;

const ExtraTextNegative = styled(ExtraText)`
  color: ${({ theme }) => theme.t.col.red};
`;
