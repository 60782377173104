import * as _ from 'lodash';
import { TTag } from '../../../../core/src/models/Tag';
import { TUseFormField } from './useFormField';
import { TagParser } from '../../../../core/src/lib/TagParser';
import { useFormArrayField } from './useFormArrayField';

export type TUseFormTagArrayFieldParams = Partial<TUseFormField<string>> & {
  //
};

export function useFormTagArrayField(params: TUseFormTagArrayFieldParams) {
  return useFormArrayField<TTag>({
    value: TagParser.parseFormatAndSortTags(params.value),
    newItem: '',

    validate: (value: TTag[], context: any): boolean => {
      const tagIsValidBase = value
        .map(TagParser.format)
        .every(TagParser.tagIsValid);

      if (!tagIsValidBase) {
        return false;
      }

      if (params.validate) {
        return params.validate(TagParser.joinTags(value), context);
      }

      return true;
    },

    valueToError: (value: TTag[], context: any) => {
      if (params.valueToError) {
        return params.valueToError(TagParser.joinTags(value), context);
      }

      return undefined;
    },

    required: (value: TTag[], context: any) => {
      if (_.isFunction(params.required)) {
        return params.required(TagParser.joinTags(value), context);
      }

      if (params.required) {
        return params.required;
      }

      return false;
    },

    checkForErrorOnRequired: params.checkForErrorOnRequired,
    resetOnError: params.resetOnError,
  });
}
