import * as React from 'react';
import { ALaCarteLogo } from '../assets/Assets';
import styled from 'styled-components';

export type TBouncingLogoProps = {
  [key: string]: never;
};

export const BouncingLogo = (props: TBouncingLogoProps) => {
  return (
    <Root>
      <Logo/>
      <Shadow/>
    </Root>
  );
};

const dropHeight = 150;
const logoSize = 200;
const offset = 50;
export const bouncingLogoTotalHeight = dropHeight + logoSize + offset;

const Root = styled.div`
  --drop-height: ${dropHeight}px;
  --duration: 0.5s;
  --logo-size: ${logoSize}px;
  --offset: ${offset}px;
  --shadow-height: 8px;
  position: relative;
    @keyframes bounce {
      from { transform: translateY(0) scale(1); }
      to { transform: translateY(var(--drop-height)) scale(1, 0.7); }
  }
  @keyframes grow {
    from { transform: scale(0.2, 0.5); }
    to { transform: scale(1.5, 0.8); }
  }
`;

const Logo = styled(ALaCarteLogo)`
  animation-direction: alternate;
  animation-duration: var(--duration);
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  height: var(--logo-size);
  left: calc(50% - var(--logo-size) / 2);
  position: absolute;
  top: var(--offset);
  width: var(--logo-size);
`;

const Shadow = styled.div`
  animation-direction: alternate;
  animation-duration: var(--duration);
  animation-iteration-count: infinite;
  animation-name: grow;
  animation-timing-function: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  background: radial-gradient(
    50% 50%,
    rgba(150, 150, 150, 1),
    rgba(150, 150, 150, 0.05)
  );
  height: var(--shadow-height);
  left: calc(50% - var(--logo-size) / 2);
  position: absolute;
  top: calc(
    var(--offset) + var(--drop-height) + var(--logo-size) - 1.5 *
      var(--shadow-height)
  );
  width: var(--logo-size);
`;
