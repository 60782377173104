import * as React from 'react';
import styled from 'styled-components';

type TRoundTextInputProps = {
  children?: React.ReactNode;
  handleChange: (newValue: string) => void;
  icon?: React.ReactNode;
  onBlur?: () => void;
  onFocus?: () => void;
  onSubmit?: () => void;
  placeholder?: string;
  value: string | null;
};

/**
 * Text input used in the search box in the topbar, or for messages.
 */
export const RoundedTextInput = ({
  handleChange,
  icon,
  onBlur,
  onFocus,
  onSubmit,
  placeholder,
  value,
}: TRoundTextInputProps) => {
  const handleFormSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (onSubmit) onSubmit();
    },
    [onSubmit],
  );
  const onChange = React.useCallback(({ target: { value: newValue } }) => {
    handleChange(newValue);
  }, [handleChange]);
  return (
    <Container onSubmit={handleFormSubmit}>
      <StyledInput
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value || ''}
        onChange={onChange}
      />
      <IconContainer onClick={onSubmit}>{
        icon}
      </IconContainer>
    </Container>
  );
};

const Container = styled.form`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.lightGray3};
  border-radius: 20px;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  padding-left: 30px;;
  padding-right: 30px;
`;
const StyledInput = styled.input`
  background: none;
  border: none;
  caret-color: ${({ theme }) => theme.t.col.red};
  flex: 1;
  &::placeholder {
    color: ${({ theme }) => theme.t.col.accentLight};
    font-size: 15px;
    line-height: 20px;
  }
  outline: none;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;
