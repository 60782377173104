import * as _ from 'lodash';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { GuestSession } from '../../../../core/src/models/db/guestSession/GuestSession';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TOrderItemId } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { useFormNumberField } from '../../../../lib-react/src/hooks/form/useFormNumberField';
import { useOrderItemPriceCalculator } from './useOrderItemPriceCalculator';

type TGuestSessionCalculatorParams = {
  hostData: THost;
  guestSessionData: TGuestSession;
  orderItemSelectedIds: TOrderItemId[];
};

export type TGuestSessionCalculatorResult = ReturnType<typeof useGuestSessionCalculator>;

export function useGuestSessionCalculator({
  hostData,
  guestSessionData,
  orderItemSelectedIds,
}: TGuestSessionCalculatorParams) {
  const selectedOrderItems = GuestSession.getAllConfirmedOrderItems(guestSessionData)
    .filter((orderItem) => orderItemSelectedIds.includes(orderItem[FIELD_ID]))
    .filter((orderItem) => !_.get(orderItem, 'paid', false));

  const {
    valueStr: selectedNumberOfGuests,
    value: selectedNumberOfGuestsNum,
    setValue: setSelectedNumberOfGuests,
  } = useFormNumberField({
    value: guestSessionData.numberOfGuests,
    min: 1,
    max: guestSessionData.numberOfGuests,
  });

  const {
    orderDiscount,
    orderDiscountNum,
    orderDiscountPercentage,
    setOrderDiscount,
    subTotal,
    total,
    coverTotal,
  } = useOrderItemPriceCalculator({
    host: hostData,
    orderItems: selectedOrderItems,
    peopleCount: selectedNumberOfGuestsNum,
  });

  return {
    selectedNumberOfGuests,
    setSelectedNumberOfGuests,
    orderDiscountPercentage,
    orderDiscount,
    setOrderDiscount,
    subTotal,
    totalCoverCharge: coverTotal,
    total,
    dutchCountTotal: total / selectedNumberOfGuestsNum,
    selectedNumberOfGuestsNum,
    orderDiscountNum,
  };
}
