import * as _ from 'lodash';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import { Log } from '../../config/Instance';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { TState } from '../Store';
import { TTableId } from '../../../../core/src/models/db/table/TableTypes';
import {
  all,
  call,
  delay,
  select,
} from 'redux-saga/effects';
import { GuestSessionBuilder } from '../../../../core/src/models/db/guestSession/GuestSessionBuilder';
import { TObjList } from '../../../../core/src/db/DbDefs';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { dbItemIdableMap } from '../../../../core/src/db/DbLib';
import { OrderBatcher } from '../../../../core/src/lib/batching/OrderBatcher';

const pollIntervalMs = 3 * 1000;
const batchingUpdateThresholdMs = 10 * 1000;

export function* sagaCheckBatchingStatus() {
  Log.v('sagaCheckBatchingStatus', 'sagaCheckBatchingStatus', 'Starting');

  while (true) {
    const guestSessions: TObjList<TTableId, TGuestSession> = yield select((store: TState) => {
      return store.host.hostGuestSessions;
    });
    const host: THost = yield select((store: TState) => {
      return store.host.hostData;
    });

    const tasks = _.values(guestSessions).map((guestSession) => {
      return call(sagaCheckBatchingStatusGuestSession, {
        host,
        guestSession,
      });
    });

    yield all(tasks);
    yield delay(pollIntervalMs);
  }
}

type TSagaCheckBatchingStatusGuestSessionParams = {
  host: THost;
  guestSession: TGuestSession;
};

export function* sagaCheckBatchingStatusGuestSession({
  host,
  guestSession,
}: TSagaCheckBatchingStatusGuestSessionParams) {
  // todo: At the moment of writing this, guestSession.tableId doesn't seem to be reliable
  const { tableId } = GuestSessionBuilder.getPathBuilderParamsFromDataPath(guestSession);

  const batches = OrderBatcher.batch(host, guestSession);
  const tasks = batches.map(({ batchTTLMs, batch }) => {
    return call(sagaCheckBatchingStatusGuestSessionBatch, {
      host,
      guestSession,
      batch,
      batchTTLMs,
    });
  });

  yield all(tasks);
}

type TSagaCheckBatchingStatusGuestSessionBatchParams = {
  host: THost;
  guestSession: TGuestSession;
  batch: TOrder[];
  batchTTLMs: number;
};

export function* sagaCheckBatchingStatusGuestSessionBatch({
  host,
  guestSession,
  batch,
  batchTTLMs,
}: TSagaCheckBatchingStatusGuestSessionBatchParams) {
  // todo: At the moment of writing this, guestSession.tableId doesn't seem to be reliable
  const { tableId } = GuestSessionBuilder.getPathBuilderParamsFromDataPath(guestSession);

  Log.v(
    'sagaCheckBatchingStatus',
    'sagaCheckBatchingStatusGuestSessionBatch',
    `tableId=${tableId} batchingSec=${Math.floor(batchTTLMs / 1000)}`,
  );

  if (batchTTLMs >= batchingUpdateThresholdMs) {
    Log.v(
      'sagaCheckBatchingStatus',
      'sagaCheckBatchingStatusGuestSessionBatch',
      `tableId=${tableId} Batching waiting`,
    );
    return;
  }

  Log.v(
    'sagaCheckBatchingStatus',
    'sagaCheckBatchingStatusGuestSessionBatch',
    `tableId=${tableId} Batching complete`,
  );

  // Update all orders to printing in one go
  try {
    const orderIds = batch.map(dbItemIdableMap);
    yield GuestSessionBuilder.fromItem(guestSession).tryPrintBatchAtomically(host, orderIds);
  } catch (e) {
    Log.v(
      'sagaCheckBatchingStatus',
      'sagaCheckBatchingStatusGuestSessionBatch',
      `tableId=${tableId} Failed to tryPrintBatchAtomically, ${e && e.message}`,
    );
  }
}
