import * as React from 'react';
import * as _ from 'lodash';
import { useSyncedDataRef } from './useSyncedDataRef';

type TUseSafeStateRet<T> = [
  T,
  (set: T) => void,
  React.MutableRefObject<{ state: T; setState: (set: T) => void }>
];

export function useSafeState<T>(initial: T): TUseSafeStateRet<T> {
  const [state, setState] = React.useState(initial);

  const safeSetState = React.useCallback((update: T) => {
    if (!_.isEqual(state, update)) {
      setState(update);
    }
  }, [state, setState]);

  const stateRef = useSyncedDataRef({
    state,
    setState: safeSetState,
  });

  return [state, safeSetState, stateRef];
}
