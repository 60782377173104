import * as React from 'react';
import { FieldCurrencyInput } from '../form/CurrencyInput';
import { FieldNumberInput } from '../form/FieldNumberInput';
import { GuestSessionOverviewCalculatorHeader } from './GuestSessionOverviewCalculatorHeader';
import { Num } from '../../../../core/src/lib/formatters/Num';
import { SquareButton } from '../Button/SquareButton';
import { Strings } from '../../../../core/src/locale/Strings';
import { TActionHostAddMenuItemParams } from '../../redux/actions/HostPrintQueue';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import {
  TGuestSessionCalculatorResult,
  useGuestSessionCalculator,
} from './useOrderItemCalculator';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TOrderItemId } from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';
import { DEFAULT_CURRENCY, TCurrencyCode } from '../../../../core/src/locale/Currencies';

type TGuestSessionOverviewCalculatorProps =
  Omit<TGuestSessionOverviewCalculatorBaseProps, keyof TGuestSessionCalculatorResult | 'currency'>
  & {
    locale: ELocale;
    hostData: THost;
    guestSessionData: TGuestSession;
    orderItemSelectedIds: TOrderItemId[];
  };

export const GuestSessionOverviewCalculator = (props: TGuestSessionOverviewCalculatorProps) => {
  const {
    locale,
    onOrderItemsPaidClick,
    onOrderPrintClick,
    onGuestSessionCloseClick,
  } = props;

  const guestSessionCalculator = useGuestSessionCalculator(props);
  return (
    <GuestSessionOverviewCalculatorBase
      {...guestSessionCalculator}
      locale={locale}
      currency={props.hostData.currency ?? DEFAULT_CURRENCY}
      onOrderPrintClick={onOrderPrintClick}
      onOrderItemsPaidClick={onOrderItemsPaidClick}
      onGuestSessionCloseClick={onGuestSessionCloseClick}
    />
  );
};

type TGuestSessionOverviewCalculatorBaseProps = TGuestSessionCalculatorResult & {
  locale: ELocale;
  currency: TCurrencyCode;
  onOrderItemsPaidClick: () => void;
  onOrderPrintClick: (params: Omit<TActionHostAddMenuItemParams, 'host' | 'guestSession' | 'filterOrderItemIds'>) => Promise<any>;
  onGuestSessionCloseClick: () => void;
};

export const GuestSessionOverviewCalculatorBase = ({
  locale,
                                                     currency,
  selectedNumberOfGuests,
  selectedNumberOfGuestsNum,
  setSelectedNumberOfGuests,
  orderDiscountPercentage,
  orderDiscount,
  orderDiscountNum,
  setOrderDiscount,
  subTotal,
  totalCoverCharge,
  total,
  dutchCountTotal,
  onOrderItemsPaidClick,
  onOrderPrintClick,
  onGuestSessionCloseClick,
}: TGuestSessionOverviewCalculatorBaseProps) => {
  const onPrintClick = React.useCallback(() => {
    return onOrderPrintClick({
      numberOfGuests: selectedNumberOfGuestsNum,
      discountNum: orderDiscountNum,
    });
  }, [onOrderPrintClick, selectedNumberOfGuestsNum]);
  return (
    <Container>
      <GuestSessionOverviewCalculatorHeader>
        <TotalText>
          {Strings.hTableBillTotal()(locale)}
        </TotalText>
        <TotalPriceText>
          {Num.formatPrice2(total, currency, 0)}
        </TotalPriceText>
      </GuestSessionOverviewCalculatorHeader>
      <ListContainer>
        <RowContainer>
          <SubsectionTitleText>
            {Strings.hTableBillSubTotal()(locale)}
          </SubsectionTitleText>
          <RightValueContainer>
            {Num.formatPrice2(subTotal, currency, 0)}
          </RightValueContainer>
        </RowContainer>
        <RowContainer>
          <SubsectionTitleWithBoxContainer>
            <SubsectionTitleText>
              {Strings.hTableBillPlaceCharge()(locale)}
            </SubsectionTitleText>
            <SquareFieldNumberInput
              locale={locale}
              value={selectedNumberOfGuests}
              onChange={setSelectedNumberOfGuests}
              placeholder={'1'}
            />
          </SubsectionTitleWithBoxContainer>
          <RightValueContainer>
            {Num.formatPrice2(totalCoverCharge, currency, 0)}
          </RightValueContainer>
        </RowContainer>

        <RowContainer>
          <SubsectionTitleWithBoxContainer>
            <SubsectionTitleText>
              {Strings.hTableBillDiscount()(locale)} ({Num.formatPercentage(orderDiscountPercentage)})
            </SubsectionTitleText>
          </SubsectionTitleWithBoxContainer>
          <RightValueContainer>
            <DiscountNumberInput
              locale={locale}
              currency={currency}
              value={orderDiscount}
              onChange={setOrderDiscount}
              placeholder={'0'}
            />
          </RightValueContainer>
        </RowContainer>
        <PrintSquareButton
          title={Strings.hTablePrintCmd()(locale)}
          onClick={onPrintClick}
        />
        <CloseGuestSessionSquareButton
          title={Strings.hTableMarkPayedCmd()(locale)}
          onClick={onOrderItemsPaidClick}
        />
        <RowContainer>
          <SubsectionTitleText>
            {Strings.hTableDutchCount()(locale)}
          </SubsectionTitleText>
          <RightValueContainer>
            {Num.formatPrice2(dutchCountTotal, currency)}
          </RightValueContainer>
        </RowContainer>
        <LinkTextRow
          onClick={onGuestSessionCloseClick}>
          {Strings.hTableDeleteBillCmd()(locale)}
        </LinkTextRow>
      </ListContainer>
    </Container>
  );
};

const maxRightValueWidth = 124;

const Container = styled.div`
  align-self: flex-start;
  background-color: ${({ theme }) => theme.t.col.white};
  border-radius: 2px;
  flex: 1;
`;

const TotalText = styled.span`
  color: ${({ theme }) => theme.t.col.white};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
`;

const RightValueContainer = styled.div`
  font-size: 18px;
  font-weight: normal;
  line-height: 25px;
  max-width: ${maxRightValueWidth}px;
  text-align: right;
`;

const TotalPriceText = styled.span`
  color: ${({ theme }) => theme.t.col.white};
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-align: right;
  width: ${maxRightValueWidth}px;
`;

const ListContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const RowContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  min-height: 40px;
  &:first-of-type {
    margin-top: 10px;
  }
`;

const SubsectionTitleText = styled.span`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-right: 10px;
  text-align: left;
`;

const SubsectionTitleWithBoxContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const LinkTextRow = styled.a`
  color: ${({ theme }) => theme.t.col.accentLight};
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 10px;
  text-align: center;
`;

const PrintSquareButton = styled(SquareButton)`
  margin-bottom: 10px;
`;

const CloseGuestSessionSquareButton = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.accentDark};
  margin-bottom: 20px;
`;

const DiscountNumberInput = styled(FieldCurrencyInput)`
  flex: 1;
  text-align: center;
  width: 65px;
`;

const SquareFieldNumberInput = styled(FieldNumberInput)`
  width: 60px;
`;
