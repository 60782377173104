import * as React from 'react';
import * as _ from 'lodash';
import { Log } from '../../config/Instance';
import {
  TResolvedError,
  TValidationError,
} from '../../../../core/src/lib/error/ErrorTypes';
import {
  TUseStateWithExternalSyncProps,
  useStateWithExternalSync,
} from '../useStateWithExternalSync';
import { useSyncedDataRef } from '../useSyncedDataRef';

export type TUseFormField<Value> = TUseStateWithExternalSyncProps<Value> & {
  valueToError?: (value: Value, context: any) => TResolvedError<TValidationError>[] | undefined;
  validate?: (value: Value, context: any) => boolean;
  required?: boolean | ((value: Value, context: any) => boolean);
  resetOnError?: boolean;
  checkForErrorOnRequired?: boolean;
};

export function useFormField<Value>({
  validate,
  valueToError,
  required = true,
  resetOnError = false,
  checkForErrorOnRequired = false,
  value: syncValue,
  onValueChange: syncOnValueChange,
  throttleSyncMs: syncThrottleSyncMs,
  syncingActive: syncSyncingActive = false,
}: TUseFormField<Value>) {
  const formContext = React.useRef<any>();

  const initialValueRef = React.useRef(syncValue);
  const validateRef = useSyncedDataRef(validate);

  const [isFocused, setIsFocused] = React.useState(false);

  const [value, setValue] = useStateWithExternalSync<Value>({
    value: syncValue,
    onValueChange: async (val: any) => {
      try {
        syncOnValueChange && await syncOnValueChange(val);
      } catch (e) {
        Log.v('useFormField', `onValueChange err, ${e.message}`);
      }
    },
    syncingActive: syncSyncingActive,
    throttleSyncMs: syncThrottleSyncMs,
  });

  const onValueChange = React.useCallback((val: Value) => {
    const validateFunc = validateRef.current;
    if (!validateFunc || validateFunc(val, formContext.current)) {
      setValue(val);
    }
  }, [setValue]);

  const onReset = React.useCallback(() => {
    setValue(initialValueRef.current);
  }, [setValue]);

  const parentFormError = React.useCallback(() => {
    resetOnError && onReset();
  }, [onReset, resetOnError]);

  const requiredThunk = _.isFunction(required)
    ? required
    : () => required;

  const isRequired = requiredThunk(value, formContext.current);
  const notRequiredOrSet = !isRequired
    || ((value as any) !== '' && value != null);

  const shouldCheckForError = !_.isEmpty(value)
    || checkForErrorOnRequired;

  const error = valueToError && shouldCheckForError
    ? valueToError(value, formContext.current)
    : undefined;

  const firstError = _.head(error);
  const errorStr = firstError && firstError.message();

  return {
    initialValue: initialValueRef.current,
    error,
    errorStr,
    formContext,
    value,
    setValue: onValueChange,
    isRequired,
    isValid: notRequiredOrSet && _.isEmpty(error),
    reset: onReset,
    parentFormError,
    isFocused,
    setIsFocused,
  };
}
