export enum EPrintAdapter {
  USB = 'USB',
  Network = 'Network',
  Serial = 'Serial',
  Console = 'Console',
}

export type TEscposAdapterUSB = {
  name: EPrintAdapter.USB;
  params: {
    vid?: string;
    pid?: string;
  };
};

export type TEscposAdapterNetwork = {
  name: EPrintAdapter.Network;
  params: {
    address: string;
    port?: number;
  };
};

export type TEscposAdapterSerial = {
  name: EPrintAdapter.Serial;
  params: {
    port: number;
    options?: {
      baudRate: number;
      autoOpen: boolean;
    };
  };
};

export type TEscposAdapterConsole = {
  name: EPrintAdapter.Console;
  params: any;
};

export type TStationAdapter = TEscposAdapterUSB
| TEscposAdapterNetwork
| TEscposAdapterSerial
| TEscposAdapterConsole;

export type TStationEscposMap = {
  stationId: string;
  adapter: TStationAdapter;
};
