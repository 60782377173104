import * as React from 'react';
import { PropsWithChildren } from 'react';
import { TWindowContextProviderProps, WindowContextProvider } from './WindowContext';
import { URLStateContextProvider } from './URLStateContext';
import { FirebaseUserContextProvider } from '../contexts/FirebaseUserContext';

type TBrowserContextsProviderProps = TWindowContextProviderProps & {
  //
};

export const BrowserContextsProvider = ({
  window,
  children,
}: PropsWithChildren<TBrowserContextsProviderProps>) => {
  return (
    <WindowContextProvider window={window}>
      <URLStateContextProvider>
        <FirebaseUserContextProvider>
          {children}
        </FirebaseUserContextProvider>
      </URLStateContextProvider>
    </WindowContextProvider>
  );
};

