import * as React from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { FieldNumberInput } from '../form/FieldNumberInput';
import { GuestSessionOverviewSearchResults } from './GuestSessionOverviewSearchResults';
import { Host } from '../../../../core/src/models/db/host/Host';
import { RoundedTextInput } from '../RoundedTextInput';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import { Strings } from '../../../../core/src/locale/Strings';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TMenuItem } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { colLg } from '../bootstrap/Bootstrap';
import { useBindDrawableContentOffset } from '../../hooks/useBindDrawableContentOffset';
import { useStateWithExternalSync } from '../../../../lib-react/src/hooks/useStateWithExternalSync';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

type TGuestSessionOverviewSearchbarProps = {
  locale: ELocale;
  hostData: THost;
  guestSessionData: TGuestSession;
  onNumberOfGuestsChange: (guestCount: string) => Promise<any>;
  onAddMenuItem: (menuItem: TMenuItem) => Promise<any>;
  searchFocused?: boolean;
  onSearchOpen: () => void;
  onSearchClose: () => void;
};
export const GuestSessionOverviewSearchbar = ({
  locale,
  hostData,
  guestSessionData,
  onNumberOfGuestsChange,
  onAddMenuItem,
  searchFocused,
  onSearchOpen,
  onSearchClose,
}: TGuestSessionOverviewSearchbarProps) => {
  useBindDrawableContentOffset(navbarHeight);

  const [guestCount, setGuestCount] = useStateWithExternalSync<string>({
    value: `${guestSessionData.numberOfGuests}`,
    onValueChange: onNumberOfGuestsChange,
  });

  const [searchValue, setSearchValue] = React.useState<string>('');

  return (
    <Root>
      <ClickAwayListener onClickAway={onSearchClose}>
        <Content>
          <SearchBar>
            <Search>
              <RoundedTextInput
                placeholder={Strings.hItemSearchbar()(locale)}
                icon={<SearchIcon/>}
                onFocus={onSearchOpen}
                value={searchValue}
                handleChange={setSearchValue}
              />
            </Search>
            {!searchFocused && (
              <>
                <GuestCountSpan>
                  {Strings.hTableGuestNr()(locale)}
                </GuestCountSpan>
                <SquareFieldNumberInput
                  locale={locale}
                  value={guestCount}
                  onChange={setGuestCount}
                  placeholder={'0'}
                />
              </>
            )}
          </SearchBar>
          {searchFocused && (
            <GuestSessionOverviewSearchResults
              menuItems={Host.getActiveMenuItems(hostData)}
              onMenuItemClick={onAddMenuItem}
              searchString={searchValue}
            />
          )}
        </Content>
      </ClickAwayListener>
    </Root>
  );
};

export const navbarHeight = 60;
export const searchBarPadding = 20;
export const searchBarTotalHeight = navbarHeight + (searchBarPadding * 2);

const Root = styled.div`
  background-color: ${({ theme }) => theme.t.col.white};
  display: flex;
  margin: 0 auto;
  max-width: ${colLg}px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SearchBar = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: ${navbarHeight}px;
  justify-content: space-between;
  max-height: ${navbarHeight}px;
  padding: ${searchBarPadding}px;
`;

const Search = styled.div`
  flex: 1;
  margin: 0 15px 0 15px
`;

const GuestCountSpan = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-right: 10px;
  text-align: right;
`;

const SquareFieldNumberInput = styled(FieldNumberInput)`
  width: 60px;
`;
