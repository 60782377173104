import {
  TUseFormField,
  useFormField,
} from './useFormField';

export type TUseFormArrayFieldParams<T> = Partial<TUseFormField<T[]>> & {
  newItem: T;
};

export function useFormArrayField<T>(params: TUseFormArrayFieldParams<T>) {
  const newItemMainFormField = useFormField<T>({ value: params.newItem });

  const valueFormField = useFormField<T[]>({
    value: [],
    ...params,
  });
  return {
    ...valueFormField,
    newItem: newItemMainFormField,
  };
}
