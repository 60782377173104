import * as React from 'react';
import { ContactIcon } from '../../assets/Assets';
import styled,
{ FlattenSimpleInterpolation } from 'styled-components';
//
type TContactButtonProps = {
  children?: React.ReactNode;
  onClick: () => void;
  otherStyles?: FlattenSimpleInterpolation;
  type?: 'button' | 'submit' | 'reset';
};

export const ContactButton = ({
  children,
  onClick,
  otherStyles,
  type = 'button',
}: TContactButtonProps) => {
  return (
    <StyledButton type={type} onClick={onClick} otherStyles={otherStyles}>
      <IconContainer>
        <ContactIcon/>
      </IconContainer>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<{
  otherStyles?: FlattenSimpleInterpolation;
}>`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.white};
  border: 1px solid ${({ theme }) => theme.t.col.accentDark};
  cursor: pointer;

  display: flex;
  flex-direction: row;
  font-size: 11px;
  font-weight: 600;


  height: 50px;
  justify-content: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;;

  ${({ otherStyles }) => otherStyles};

  width: 100%;
`;

const IconContainer = styled.span`
  margin-right: 15px;
`;
