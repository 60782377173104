import * as React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import {
  TStateApp,
  actionAppOnStoreRehydrate,
  reducerApp,
} from './App';
import {
  TStateFullScreenModal,
  reducerFullScreenModal,
} from './FullScreenModal';
import {
  TStateHost,
  reducerHost,
} from './Host';
import {
  TStateHostMessages,
  reducerHostMessages,
} from './HostMessages';
import {
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import {
  persistReducer,
  persistStore,
} from 'redux-persist';
import { rootSaga } from './Sagas';
import { useDispatch } from '../../../lib-react/src/redux/redux';
import { useSyncedDataRef } from '../../../lib-react/src/hooks/useSyncedDataRef';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

export type TGetState = () => TState;
export type TState = {
  app: TStateApp;
  host: TStateHost;
  fullScreenModal: TStateFullScreenModal<any>;
  hostMessages: TStateHostMessages;
};

const saga = createSagaMiddleware();

export const store = createStore(
  persistReducer(
    {
      key: 'root',
      storage,
      whitelist: [
        'app',
      ],
    },
    combineReducers({
      app: reducerApp,
      host: reducerHost,
      fullScreenModal: reducerFullScreenModal,
      hostMessages: reducerHostMessages,
    }),
  ),
  applyMiddleware(saga, thunk),
);

export const persistor = persistStore(store as any);

type TChildrenProps = {
  children: React.ReactNode;
};

export const StoreProvider = ({ children }: TChildrenProps) => (
  <Provider store={store}>
    {children}
  </Provider>
);

export const Persistor = ({ children, loadingComp }: TChildrenProps & { loadingComp: React.ReactNode }) => {
  const rehydratedRef = React.useRef(false);
  const childrenRef = useSyncedDataRef(children);
  const loadingCompRef = useSyncedDataRef(loadingComp);

  const dispatch = useDispatch();
  const onRehydrated = React.useCallback(async () => {
    await dispatch(actionAppOnStoreRehydrate());
  }, [dispatch]);
  const onRehydratedRef = useSyncedDataRef(onRehydrated);

  const childrenFunc = React.useCallback((bootstrapped: boolean) => {
    if (bootstrapped && !rehydratedRef.current) {
      rehydratedRef.current = true;
      saga.run(rootSaga);
      onRehydratedRef.current();
    }
    if (!bootstrapped && rehydratedRef.current) {
      rehydratedRef.current = false;
    }
    return !bootstrapped
      ? loadingCompRef.current
      : childrenRef.current;
  }, []);

  return (
    <PersistGate
      persistor={persistor}>
      {childrenFunc}
    </PersistGate>
  );
};
