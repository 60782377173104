import * as React from 'react';
import * as _ from 'lodash';
import {
  actionFullScreenModalShow,
  EFullScreenModal,
} from '../redux/FullScreenModal';
import { EGuestSessionStatus } from '../../../core/src/models/db/guestSession/GuestSessionTypes';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../core/src/db/DbDefs';
import { FullScreenErrorView } from '../components/fullScreenViews/FullScreenErrorView';
import { FullScreenLoadingView } from '../components/fullScreenViews/FullScreenLoadingView';
import { GuestSessionOverview } from '../components/guestSessionOverview/GuestSessionOverview';
import { GuestSessionOverviewSearchbar } from '../components/guestSessionOverview/GuestSessionOverviewSearchbar';
import { Hist } from '../lib/navigation/History';
import {
  MaxHeightDiv,
  MaxScreenDiv,
} from '../components/MaxHeightDiv';
import { Routes } from '../config/Routes';
import { SecondaryNavbar } from '../components/navbars/SecondaryNavbar';
import { Strings } from '../../../core/src/locale/Strings';
import {
  actionHostPrintQueuePrintGuestSession,
  TActionHostAddMenuItemParams,
} from '../redux/actions/HostPrintQueue';
import {
  THost,
  THostId,
} from '../../../core/src/models/db/host/HostTypes';
import { TMenuItem } from '../../../core/src/models/db/menuItem/MenuItemTypes';
import {
  TOrderItem,
  TOrderItemId,
} from '../../../core/src/models/db/orderItem/OrderItemTypes';
import {
  TPropCheck,
  withPropCheckMiddleware,
} from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import {
  getNavParam,
  TScreenNavigationProps,
} from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import { TTableId } from '../../../core/src/models/db/table/TableTypes';
import {
  actionAddMenuItemToGuestSessionAsHost,
  actionOrderItemDelete,
  actionOrderItemsResetToUnPaid,
  actionOrderItemsSetToPaid,
  actionSetGuestSessionCount,
} from '../redux/actions/GuestSession';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { GuestSessionBuilder } from '../../../core/src/models/db/guestSession/GuestSessionBuilder';

export type TScreenHostGuestSessionOverviewNavParams = {
  hostId: THostId;
  tableId: TTableId;
};

export type TScreenHostGuestSessionOverviewProps = TScreenNavigationProps<TScreenHostGuestSessionOverviewNavParams> & {
  //
};

type TScreenHostGuestSessionOverviewBaseProps = TScreenHostGuestSessionOverviewProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TScreenHostGuestSessionOverviewBaseState = {
  searchFocused: boolean;
};

export class ScreenHostGuestSessionOverviewBase extends React.Component<TPropCheck<typeof ScreenHostGuestSessionOverviewBase>, TScreenHostGuestSessionOverviewBaseState> {
  static checkProps(props: TScreenHostGuestSessionOverviewBaseProps) {
    const {
      locale,
      hostData,
    } = props;

    if (hostData == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    if (props.guestSession == null) {
      return () => (
        <FullScreenErrorView
          locale={locale}
          title={Strings.gOops()(locale)}
          message={Strings.gTableNrNonExistentMsg()(locale)}
          actionText={Strings.hBackToMenu({ hMenuTab: Strings.hHostHomeTab()(locale) })(locale)}
          actionOnClick={() => {
            Hist.replace(Routes.ScreenHostHome, { hostId: hostData[FIELD_ID] });
          }}
        />
      );
    }

    return {
      ...props,
      guestSession: props.guestSession,
      hostData: props.hostData as THost,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.state = { searchFocused: false };
  }

  private onSearchOpen = () => {
    this.setState({ searchFocused: true });
  };

  private onSearchClose = () => {
    this.setState({ searchFocused: false });
  };

  navigateToGuestSessionMessages = () => {
    const {
      locale,
      tableId,
      hostData,
      guestSession,
    } = this.props;
    const pbp = GuestSessionBuilder.getPathBuilderParamsFromDataPath(guestSession);
    const goBackText = Strings.hBackToTableCheck({ tableNr: _.get(hostData.tables, `[${tableId}][${FIELD_NAME}]`, '') as string })(locale);

    Hist.push(Routes.ScreenHostGuestSessionMessages, {
      ...pbp,
      navContext: { goBackText },
    });
  };

  componentDidUpdate() {
    this.checkIfOrderFullyPaid();
  }

  private checkIfOrderFullyPaid = () => {
    const {
      guestSession,
      fullScreenModalShow,
    } = this.props;

    if (guestSession.status !== EGuestSessionStatus.CLOSED) {
      return;
    }

    fullScreenModalShow(
      EFullScreenModal.guestSessionClose,
      GuestSessionBuilder.getPathBuilderParamsFromDataPath(guestSession),
    );
  };

  onOrderItemsPaidClick = async (orderItemIds: TOrderItemId[]) => {
    const {
      guestSession,
      orderItemsSetToPaid,
    } = this.props;
    await orderItemsSetToPaid({
      guestSessionData: guestSession,
      orderItemIds,
    });
  };

  onOrderItemsResetClick = async () => {
    const {
      orderItemsResetToUnPaid,
      guestSession,
    } = this.props;
    await orderItemsResetToUnPaid({ guestSessionData: guestSession });
  };

  onOrderPrintClick = async (params: Omit<TActionHostAddMenuItemParams, 'host' | 'guestSession'>) => {
    const {
      hostData,
      guestSession,
      hostPrintQueuePrintGuestSession,
    } = this.props;
    await hostPrintQueuePrintGuestSession({
      host: hostData,
      guestSession,
      ...params,
    });
  };

  onGuestSessionCloseClick = async () => {
    const { guestSession } = this.props;
    this.props.fullScreenModalShow(
      EFullScreenModal.guestSessionClose,
      GuestSessionBuilder.getPathBuilderParamsFromDataPath(guestSession),
    );
  };

  private onNumberOfGuestsChange = async (newGuestCount: string) => {
    const {
      guestSession,
      setGuestSessionCount,
    } = this.props;
    await setGuestSessionCount(guestSession, newGuestCount);
  };

  private onOrderItemDelete = async (orderItem: TOrderItem) => {
    await this.props.orderItemDelete({ orderItem });
  };

  private onAddMenuItem = async (menuItem: TMenuItem) => {
    this.onSearchClose();
    this.props.addMenuItemToGuestSessionAsHost({
      guestSession: this.props.guestSession,
      menuItem,
    });
  };

  render() {
    const {
      locale,
      hostData,
      guestSession,
    } = this.props;
    const { searchFocused } = this.state;
    return (
      <Root>
        {this.renderSecondaryNavbar()}
        <GuestSessionOverviewSearchbar
          locale={locale}
          hostData={hostData}
          guestSessionData={guestSession}
          onNumberOfGuestsChange={this.onNumberOfGuestsChange}
          onAddMenuItem={this.onAddMenuItem}
          searchFocused={searchFocused}
          onSearchOpen={this.onSearchOpen}
          onSearchClose={this.onSearchClose}
        />
        <ScrollableContent>
          <Content searchFocused={searchFocused}>
            <GuestSessionOverview
              locale={locale}
              hostData={hostData}
              guestSessionData={guestSession}
              navigateToGuestSessionMessages={this.navigateToGuestSessionMessages}
              onOrderItemsPaidClick={this.onOrderItemsPaidClick}
              onOrderItemsResetClick={this.onOrderItemsResetClick}
              onOrderPrintClick={this.onOrderPrintClick}
              onGuestSessionCloseClick={this.onGuestSessionCloseClick}
              onOrderItemDelete={this.onOrderItemDelete}
            />
          </Content>
        </ScrollableContent>
      </Root>
    );
  }

  private renderSecondaryNavbar = () => {
    const {
      locale,
      tableId,
      hostData,
    } = this.props;
    const tableNameStr = Strings.hTableID({ tableNr: _.get(hostData.tables, `[${tableId}][${FIELD_NAME}]`, '') as string })(locale);
    return (
      <SecondaryNavbar
        locale={locale}
        hostId={hostData[FIELD_ID]}
        name={tableNameStr}
      />
    );
  };
}

function mapStateToProps(state: TState, ownProps: TScreenHostGuestSessionOverviewProps) {
  const tableId = getNavParam(ownProps, 'tableId');
  return {
    tableId,
    locale: state.app.locale,
    hostData: state.host.hostData,
    guestSession: _.get(state.host.hostGuestSessions, tableId),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fullScreenModalShow: bindActionCreators(actionFullScreenModalShow, dispatch),
    addMenuItemToGuestSessionAsHost: bindActionCreators(actionAddMenuItemToGuestSessionAsHost, dispatch),
    orderItemsSetToPaid: bindActionCreators(actionOrderItemsSetToPaid, dispatch),
    orderItemDelete: bindActionCreators(actionOrderItemDelete, dispatch),
    orderItemsResetToUnPaid: bindActionCreators(actionOrderItemsResetToUnPaid, dispatch),
    hostPrintQueuePrintGuestSession: bindActionCreators(actionHostPrintQueuePrintGuestSession, dispatch),
    setGuestSessionCount: bindActionCreators(actionSetGuestSessionCount, dispatch),
  };
}

export const ScreenHostGuestSessionOverview = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(ScreenHostGuestSessionOverviewBase));

const Root = styled(MaxScreenDiv)`
`;

const ScrollableContent = styled(MaxHeightDiv)`
  background-color: ${({ theme }) => theme.t.col.lightGray3};
`;

type TContentProps = {
  searchFocused: boolean;
};

const Content = styled.div<TContentProps>`
  display: flex;
  justify-content: center;
  padding: 20px;
  ${({ searchFocused, theme }) => (searchFocused ? `background-color: ${theme.t.col.lightGray3}` : '')};
  visibility: ${({ searchFocused }) => (searchFocused ? `hidden` : 'visible')};
`;
