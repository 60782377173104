import * as React from 'react';
import {
  SnackbarProvider as NoTiStackSnackbarProvider,
  ProviderContext,
  withSnackbar,
} from 'notistack';
import { Snackbars } from './Snackbars';

type TChildrenProps = {
  children: React.ReactNode;
};

/**
 * Snackbars
 * ---
 * Merges SnackbarProvider and SnackbarsRefBinder
 */
export const SnackbarProvider = ({ children }: TChildrenProps) => {
  return (
    <NoTiStackSnackbarProviderWrapper>
      <SnackbarsRefBinder>
        {children}
      </SnackbarsRefBinder>
    </NoTiStackSnackbarProviderWrapper>
  );
};

/**
 * NoTiStackSnackbarProviderWrapper
 * ---
 * Adds the main Snackbar provider to the app
 */
const NoTiStackSnackbarProviderWrapper = ({ children }: TChildrenProps) => {
  return (
    <NoTiStackSnackbarProvider maxSnack={3}>
      {children}
    </NoTiStackSnackbarProvider>
  );
};

/**
 * SnackbarsRefListener
 * ---
 * Binds the snackbar context to SnackbarsRef
 */
type TSnackbarRefProps = ProviderContext & TChildrenProps & {
  //
};
const SnackbarsRefBinder = withSnackbar(({
  children,
  ...providerContext
}: TSnackbarRefProps) => {
  React.useEffect(() => {
    Snackbars._setProviderContext(providerContext);
  }, [providerContext]);
  return (
    <>
      {children}
    </>
  );
});
