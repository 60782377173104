import * as React from 'react';
import { ContactButton } from '../Button/ContactButton';
import { GuestSessionOverviewCalculator } from './GuestSessionOverviewCalculator';
import { GuestSessionOverviewOrderItemList } from './GuestSessionOverviewOrderItemList';
import { Strings } from '../../../../core/src/locale/Strings';
import { TActionHostAddMenuItemParams } from '../../redux/actions/HostPrintQueue';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import {
  TOrderItem,
  TOrderItemId,
} from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { colLg } from '../bootstrap/Bootstrap';
import { maxHeightDivPadding } from '../MaxHeightDiv';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import { useOrderItemStatusManager } from './useOrderItemStatusManager';
import styled,
{ css } from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

type TGuestSessionOverviewProps = {
  locale: ELocale;
  hostData: THost;
  guestSessionData: TGuestSession;
  onOrderItemsResetClick: () => void;
  navigateToGuestSessionMessages: () => void;
  onOrderItemsPaidClick: (items: TOrderItemId[]) => void;
  onOrderPrintClick: (params: Omit<TActionHostAddMenuItemParams, 'host' | 'guestSession'>) => Promise<any>;
  onGuestSessionCloseClick: () => void;
  onOrderItemDelete: (orderItemId: TOrderItem) => void;
};

export const GuestSessionOverview = ({
  locale,
  hostData,
  guestSessionData,
  onOrderItemsResetClick,
  navigateToGuestSessionMessages,
  onOrderItemsPaidClick,
  onGuestSessionCloseClick,
  onOrderPrintClick,
  onOrderItemDelete,
}: TGuestSessionOverviewProps) => {
  const orderItemStatusManager = useOrderItemStatusManager({
    guestSessionData,
    onOrderItemsResetClick,
  });
  const { orderItemSelectedIds } = orderItemStatusManager;

  const onOrderItemsPaid = useItemCallback(orderItemSelectedIds, onOrderItemsPaidClick);

  const onOrderPrint = React.useCallback((params: Omit<TActionHostAddMenuItemParams, 'host' | 'guestSession' | 'filterOrderItemIds'>) => {
    return onOrderPrintClick({
      filterOrderItemIds: orderItemSelectedIds,
      ...params,
    });
  }, [
    onOrderPrintClick,
    orderItemSelectedIds,
  ]);

  return (
    <Root>
      <ContentRoot>
        <OrderListItemContainer>
          <GuestSessionOverviewOrderItemList
            locale={locale}
            onOrderItemDelete={onOrderItemDelete}
            {...orderItemStatusManager}
          />
        </OrderListItemContainer>
        <CalculatorContainer>
          <GuestSessionOverviewCalculator
            locale={locale}
            hostData={hostData}
            guestSessionData={guestSessionData}
            orderItemSelectedIds={orderItemSelectedIds}
            onOrderPrintClick={onOrderPrint}
            onOrderItemsPaidClick={onOrderItemsPaid}
            onGuestSessionCloseClick={onGuestSessionCloseClick}
          />
          <ContactButton
            onClick={navigateToGuestSessionMessages}
            otherStyles={contactButtonStyles}
          >
            {Strings.hTableChatButton()(locale)}
          </ContactButton>
        </CalculatorContainer>
      </ContentRoot>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const ContentRoot = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1;
  max-width: ${colLg}px;
`;

// In ScreenHostGuestSession we use a total of 40px padding
const PaddedMaxHeightDiv = maxHeightDivPadding(40);
const OrderListItemContainer = styled(PaddedMaxHeightDiv)`
  border-radius: 2px;
  flex: 2;
`;

const CalculatorContainer = styled.div`
  flex: 1;
  flex-direction: column;
  margin-left: 20px;
`;

const contactButtonStyles = css`
  margin-top: 20px;
`;
