import * as React from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { SvgGlobe } from '../assets/Assets';
import { Strings as CoreStrings } from '../../../core/src/locale/Strings';
import { enumToArray } from '../../../core/src/lib/HelperFunctions';
import { ELocale } from '../../../core/src/locale/Locale';
import { useDispatch, useSelector } from "react-redux";
import { TState } from "../redux/Store";
import { actions } from "../redux/Actions";
import { Colors } from "../config/Theme";

export const Lang = () => {
  const [open, setOpen] = React.useState<boolean>(false)

  const currentLocale = useSelector((store: TState) => store.app.locale);
  const dispatch = useDispatch();
  const handleLocaleSelect = React.useCallback((locale: ELocale) => {
    dispatch(actions.actionTypeAppSetLocale({ locale }));
    setOpen(false);
  }, [dispatch, setOpen]);

  return (
    <OverlayTrigger
      show={open}
      onToggle={setOpen}
      trigger="click"
      placement="bottom"
      rootClose={true}
      overlay={(
        <Popover id="Lang">
          <PopoverBody>
            {enumToArray<ELocale>(ELocale).map((locale) => (
              <LangRoot key={locale} onClick={() => handleLocaleSelect(locale)}>
                <LangEmojiContainer>
                  {CoreStrings.landingLocaleEmoji()(locale)}
                </LangEmojiContainer>
                <LangNameContainer selected={currentLocale.toLowerCase() === locale.toLowerCase()}>
                  {CoreStrings.localeName()(locale)}
                </LangNameContainer>
              </LangRoot>
            ))}
          </PopoverBody>
        </Popover>
      )}
    >
      <GlobeContainer>
        <SvgGlobe
          height={20}
          width={20}
        />
      </GlobeContainer>
    </OverlayTrigger>
  );
};

const GlobeContainer = styled.div`
  cursor: pointer;
`;

// @ts-ignore
const PopoverBody = styled.div`
  padding: 0;
`;

const LangRoot = styled.div`
  background-color: ${Colors.white};
  color: ${Colors.accentDark};
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 15px;
  padding: 8px 24px;

  :hover {
    background-color: ${Colors.aLaCarteTheme};
  }



`;

const LangEmojiContainer = styled.div`
  margin-right: 8px;
`;

const LangNameContainer = styled.div<{ selected: boolean }>`
  color: ${({ selected }) => (selected ? Colors.red : Colors.accentDark)};
  font-weight: ${({ selected }) => (selected ? 600 : 400)};
`;
