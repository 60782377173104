import * as React from 'react'
import styled from "styled-components";
import { TUnsplashPhoto } from "./unsplash-api";
import { makeUnsplashReferralComponents } from '../../../../../../core/src/lib/imageReferrals';

type TUnsplashPhotoCardProps = {
  photo: TUnsplashPhoto;
  selected: boolean;
  onPhotoClick: (photo: TUnsplashPhoto) => void
}

export function UnsplashPhotoCard({
                                    photo,
                                    selected,
                                    onPhotoClick,
                                  }: TUnsplashPhotoCardProps) {
  const {
    segment1Text,
    segment2LinkText,
    segment2LinkHref,
    segment3Text,
    segment4LinkText,
    segment4LinkHref,
  } = makeUnsplashReferralComponents({
    photographerName: photo.user.name,
    photographerUsername: photo.user.username
  })
  return (
    <Root onClick={() => onPhotoClick(photo)}>
      <ReferralContainer>
        <ReferralName>
          {segment1Text}
          <a href={segment2LinkHref}>
            {segment2LinkText}
          </a>
          {segment3Text}
          <a href={segment4LinkHref}>
            {segment4LinkText}
          </a>
        </ReferralName>
      </ReferralContainer>
      <Image
        selected={selected}
        src={photo.urls.thumb}
        alt={photo.alt_description}
      />
    </Root>
  )
}

export const unsplashPhotoCardSize = 140;

const Root = styled.div`
  flex-basis: ${unsplashPhotoCardSize}px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Image = styled.img<{ selected: boolean }>`
  object-fit: cover;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: 5px;
  cursor: pointer;
  border: solid ${({ theme, selected }) => selected ? `${theme.t.col.red} 2px` : '0px'};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

const ReferralContainer = styled.div`
  padding: 0 2px;
  border-top-left-radius: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const ReferralName = styled.span`
  font-size: 7px;
  line-height: 10px;
  color: white;
  text-align: right;
`;
