import * as React from 'react';
import * as _ from 'lodash';
import { TFieldInputListItem } from './FieldInputList';
import {
  TSecondaryItemInputRendererProps,
  buildSecondaryItemInputDefaultRenderer,
} from './buildSecondaryItemInputDefaultRenderer';
import { useSyncedDataRef } from '../../../../../lib-react/src/hooks/useSyncedDataRef';
import { useTargetValue } from '../../../../../lib-react/src/hooks/useTargetValue';
import { useTriggerOnBlurOrEnter } from '../../../../../lib-react/src/hooks/form/useTriggerOnBlurOrEnter';
import styled from 'styled-components';

export type TFieldInputListItemProps<T extends TFieldInputListItem> = {
  index: number;
  listItem: T;
  onChange?: (index: number, listItem: T) => void;
  onSave?: (index: number, listItem: T) => void;
  onAction?: (index: number, listItem: T) => void;
  action?: React.ReactNode;
  action2?: React.ReactNode;
  onAction2?: (index: number, listItem: T) => void;
  mainPlaceholder?: string;
  secondaryPlaceholder?: string;
  className?: string;
  autoFocusMainInput?: boolean;
  readOnly?: boolean;
  saveOnBlur?: boolean;
  renderSecondaryInput?: (props: TSecondaryItemInputRendererProps) => React.ReactNode;
};

export function FieldInputListItem<T extends TFieldInputListItem>({
  index,
  listItem,
  onChange,
  onSave,
  onAction,
  action,
  onAction2,
  action2,
  mainPlaceholder,
  secondaryPlaceholder,
  className,
  autoFocusMainInput = false,
  readOnly = false,
  saveOnBlur = true,
  renderSecondaryInput = buildSecondaryItemInputDefaultRenderer(),
}: TFieldInputListItemProps<T>) {
  const listItemRef = useSyncedDataRef(listItem);

  const onItemAction = React.useCallback(() => {
    listItemRef.current.actionable && onAction?.(index, listItemRef.current);
  }, [onAction, index]);

  const onItemAction2 = React.useCallback(() => {
    listItemRef.current.actionable && onAction2?.(index, listItemRef.current);
  }, [onAction2, index]);

  const onItemMainChange = useTargetValue((value: string) => {
    onChange && onChange(index, {
      ...listItemRef.current,
      mainText: value,
    });
  });

  const onItemSecondaryChange = React.useCallback((value: string) => {
    onChange?.(index, {
      ...listItemRef.current,
      secondaryText: value,
    });
  }, [onChange, index]);

  const onItemSave = React.useCallback(() => {
    const li = listItemRef.current;
    const itemCompleted = !_.isEmpty(li.mainText)
      && (!li.hasSecondary || !_.isEmpty(li.secondaryText));
    itemCompleted && onSave && onSave(index, li);
  }, [onSave]);

  const {
    itemRef: mainTextRef,
    onBlur: mainTextOnBlur,
    onEnterPress: mainTextOnEnterPress,
  } = useTriggerOnBlurOrEnter({
    triggerOnBlur: saveOnBlur,
    onTrigger: onItemSave,
  });

  const {
    itemRef: secondaryTextRef,
    onBlur: secondaryTextOnBlur,
    onEnterPress: secondaryTextOnEnterPress,
  } = useTriggerOnBlurOrEnter({
    triggerOnBlur: saveOnBlur,
    onTrigger: onItemSave,
  });

  return (
    <InnerContainer
      className={className}
      twoColumns={listItem.hasSecondary}>
      <ItemContainer>
        <Input
          autoFocus={autoFocusMainInput}
          ref={mainTextRef}
          readOnly={readOnly}
          value={listItem.mainText}
          onChange={onItemMainChange}
          onKeyPress={mainTextOnEnterPress}
          onBlur={mainTextOnBlur}
          placeholder={mainPlaceholder}
        />
        {onAction2 && listItem.actionable && (
          <IconContainer onClick={onItemAction2}>
            {action2}
          </IconContainer>
        )}
        {onAction && listItem.actionable && (
          <IconContainer onClick={onItemAction}>
            {action}
          </IconContainer>
        )}
      </ItemContainer>
      {listItem.hasSecondary && (
        <InputContainer>
          {renderSecondaryInput({
            secondaryTextRef,
            readOnly,
            onItemSecondaryChange,
            secondaryTextOnEnterPress,
            secondaryTextOnBlur,
            secondaryText: listItem.secondaryText,
            secondaryPlaceholder,
          })}
        </InputContainer>
      )}
    </InnerContainer>
  );
}

type TInnerContainerProps = {
  twoColumns?: boolean;
};

const InnerContainer = styled.div<TInnerContainerProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr ${({ twoColumns }) => (twoColumns ? '0.6fr' : '')};
  border: 1px solid ${({ theme }) => theme.t.col.accentLight};
  &:not(:first-child) {
    // Accounts for the 1px border between items, use box-sizing: border-box; instead
    margin-top: -1px;
  }
  &:first-child {
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
  }
  &:not(:last-child) {
    border-bottom: 0;
  }
  &:last-child {
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
  }
`;
const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: normal;
  height: 50px;
  justify-content: space-between;
  line-height: 18px;
  padding: 10px;
  text-transform: none;
  width: 100%;
`;

const InputContainer = styled.div`
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.t.col.accentLight};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  min-height: 50px;
  padding: 10px;
  text-align: right;
  text-transform: none;
  width: 100%;
`;

const IconContainer = styled.div`
  cursor: pointer;
  padding: 0 5px;
`;

type TInputProps = {
  readOnly?: boolean;
};

export const Input = styled.input<TInputProps>`
  background: none;
  border: none;
  flex: 1;
  outline: none;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.t.col.accentLight};
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
  }
`;
