import * as React from 'react';
import * as _ from 'lodash';
import { LocaleDefinitions } from '../../../../core/src/locale/LocaleDefinitions';
import { Num } from '../../../../core/src/lib/formatters/Num';
import { useEventValueCallback } from '../../../../lib-react/src/hooks/useEventValueCallback';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

type InputProps = Pick<React.HTMLAttributes<HTMLInputElement>, 'onKeyPress' | 'onBlur'>;

export type TNumberInputProps = InputProps & {
  locale: ELocale;
  className?: string;
  value: string;
  inputRef?: React.MutableRefObject<HTMLInputElement>;
  placeholder?: string;
  unit?: string;
  onChange?: (newValue: string) => void;
  readOnly?: boolean;
};

const NumberInputBase = ({
  locale,
  className,
  inputRef,
  value,
  placeholder,
  unit,
  onChange = _.noop,
  readOnly = false,
  ...inputProps
}: TNumberInputProps) => {
  const onInputChange = useEventValueCallback((val: string) => {
    return onChange(`${Num.parseFloat(val)}`);
  });

  const getInputRef = React.useCallback((elem: HTMLInputElement) => {
    if (inputRef) {
      inputRef.current = elem;
    }
  }, [inputRef]);
  return (
    <StyledNumberFormat
      className={className}
      getInputRef={getInputRef}
      value={value}
      prefix={unit ? `${unit.trim()} ` : undefined}
      onChange={onInputChange}
      placeholder={placeholder}
      readOnly={readOnly}
      displayType={readOnly ? 'text' : 'input'}
      thousandSeparator={LocaleDefinitions[locale].delimiters.thousands}
      decimalSeparator={LocaleDefinitions[locale].delimiters.decimal}
      isNumericString
      {...inputProps}
    />
  );
};

export const NumberInput = React.memo(NumberInputBase) as typeof NumberInputBase;

type TInputProps = {
  readOnly: boolean;
};

const StyledNumberFormat = styled(NumberFormat)<TInputProps>`
  background: none;
  border: none;
  color: ${({ theme, readOnly }) => (readOnly ? theme.t.col.accentLight : theme.t.col.black)};
  flex: 1;
  outline: none;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.t.col.accentLight};
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
  }
`;
