import {
  TUseFormField,
  useFormField,
} from './useFormField';
import { useEventValueCallback } from '../useEventValueCallback';

export function useFormStringField(params: Partial<TUseFormField<string>> = {}) {
  const formField = useFormField<string>({
    value: '',
    ...params,
  });

  const setValueFromEvent = useEventValueCallback(formField.setValue);

  return {
    ...formField,
    setValueFromEvent,
  };
}
