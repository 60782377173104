import * as React from 'react';
import {
  EFullScreenModal,
  TFullScreenModalProps,
} from '../../redux/FullScreenModal';
import { FullScreenModal } from './FullScreenModal';
import { Hist } from '../../lib/navigation/History';
import { Routes } from '../../config/Routes';
import { Strings } from '../../../../core/src/locale/Strings';
import {
  actionGuestSessionClose,
  actionGuestSessionSetOpen,
} from '../../redux/actions/GuestSession';
import { useDispatch } from '../../../../lib-react/src/redux/redux';
import { GuestSessionBuilder } from '../../../../core/src/models/db/guestSession/GuestSessionBuilder';
import { ELocale } from '../../../../core/src/locale/Locale';

type TGuestSessionCloseModalProps = TFullScreenModalProps[EFullScreenModal.guestSessionClose] & {
  locale: ELocale;
  onDismissClick: () => void;
};

export const GuestSessionCloseModal = ({
  locale,
  hostId,
  tableId,
  onDismissClick,
}: TGuestSessionCloseModalProps) => {
  const dispatch = useDispatch();

  const onGuestSessionClose = React.useCallback(async () => {
    const guestSessionData = await GuestSessionBuilder.remoteFromPath({
      hostId,
      tableId,
    });
    await dispatch(actionGuestSessionClose({ guestSessionData }));
    onDismissClick();

    Hist.replace(Routes.ScreenHostHome, { hostId });
  }, [onDismissClick, hostId, tableId]);

  const onBackToTable = React.useCallback(async () => {
    const guestSessionData = await GuestSessionBuilder.remoteFromPath({
      hostId,
      tableId,
    });
    await dispatch(actionGuestSessionSetOpen({ guestSessionData }));
    onDismissClick();
  }, [onDismissClick, hostId, tableId]);

  return (
    <FullScreenModal
      title={Strings.hPayedBillMsgTitle()(locale)}
      body={Strings.hPayedBillMsgSubtitle()(locale)}
      leftButtonText={Strings.hFreeTableCmd()(locale)}
      leftButtonOnClick={onGuestSessionClose}
      rightButtonText={Strings.hPayedBillMsgBack()(locale)}
      rightButtonOnClick={onBackToTable}
      onDismissClick={onBackToTable}
    />
  );
};
