import * as React from 'react';
import * as _ from 'lodash';
import { ErrorHandler } from '../../../../core/src/lib/error/ErrorHandler';
import { Log } from '../../config/Instance';
import { useSyncedDataRef } from '../useSyncedDataRef';

type TFormDefItem = {
  value: any;
  isValid: boolean;
  parentFormError?: () => void;
  parentFormSuccess?: () => void;
  formContext?: React.MutableRefObject<any>;
};

type TSubmitParams<T, V> = {
  [key in keyof T]: V
};

type TFormDef<T extends { [Key in keyof T]: TFormDefItem }> = {
  onSubmit: (params: TSubmitParams<T, any>, context: T) => any;
  fields: T;
};

export function useFormHandler<T extends { [Key in keyof T]: TFormDefItem }>({
  onSubmit,
  fields,
}: TFormDef<T>) {
  const onSubmitRef = useSyncedDataRef(onSubmit);
  const fieldsRef = useSyncedDataRef(fields);
  Object.keys(fields).forEach((key) => {
    // Set the context for all fields of this form
    fields[key].formContext.current = fields;
  });

  const [loading, setLoading] = React.useState<boolean>(false);
  const [formError, setFormError] = React.useState<string | undefined>(undefined);

  const submitDisabled = !Object.keys(fields)
    .reduce((res, fieldKey) => {
      const fieldIsValid = fields[fieldKey].isValid;
      if (!fieldIsValid) {
        Log.v('useFormHandler', 'useFormHandler', `Submit disabled because of ${fieldKey}`);
      }
      return res && fieldIsValid;
    }, true);

  const onFormSubmit = React.useCallback(async () => {
    if (submitDisabled) {
      Log.v('useFormHandler', 'useFormHandler', 'Tried to submit form on submitDisabled');
      return;
    }

    setLoading(true);
    try {
      const result = Object.keys(fieldsRef.current).reduce((res, fieldKey) => {
        return _.set(res, fieldKey, fieldsRef.current[fieldKey].value);
      }, {} as any);
      await onSubmitRef.current(result, fieldsRef.current);

      Object.keys(fieldsRef.current).forEach((key: string) => {
        const field = fieldsRef.current[key];
        field.parentFormError && field.parentFormError();
      });
    } catch (e) {
      Log.e('useFormHandler', 'useFormHandler', e.message);
      setFormError(ErrorHandler.handleAndResolveToMessage(e));

      Object.keys(fieldsRef.current).forEach((key: string) => {
        const field = fieldsRef.current[key];
        field.parentFormError && field.parentFormError();
      });
    }

    setLoading(false);
  }, [submitDisabled]);

  return {
    fields,
    formError,
    submitDisabled,
    loading,
    onSubmit: onFormSubmit,
  };
}
