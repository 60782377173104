import * as React from 'react';
import { valuesOfObjSortedByKeys } from '../../../../core/src/lib/HelperFunctions';

function getWindowDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    fontScale: 1,
    scale: 1,
    get drawableContentOffset() {
      return 0;
    },
  };
}

/**
 * Memoized dimensions
 */
export type TDimensions = ReturnType<typeof getWindowDimensions>;

export function useGetDimensions(): TDimensions {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());
  React.useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return React.useMemo(() => windowDimensions, valuesOfObjSortedByKeys(windowDimensions));
}

/**
 * Scaler
 */
export type TScaler = ReturnType<typeof buildScaler>;

export function buildScaler({ width, height }: TDimensions) {
  // Base sizes are based on alacarte's figma view
  const baseWidth = 1024;
  const baseHeight = 872;

  const scale = (size: number) => (width / baseWidth) * size;
  const verticalScale = (size: number) => (height / baseHeight) * size;
  const moderateScale = (size: number, factor = 0.5) => size + (scale(size) - size) * factor;
  const moderateVerticalScale = (size: number, factor = 0.5) => size + (verticalScale(size) - size) * factor;
  return {
    scale,
    verticalScale,
    moderateScale,
    moderateVerticalScale,
  };
}
