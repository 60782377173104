import { AlacarteUrls } from '../../AlacarteUrls';
import { ESubscriptionType } from '../../../models/db/hostSubscription/HostSubscriptionTypes';
import { Routable } from '../../define/routable/Routable';
import {
  TRoutableRouteInParams,
  TRoutableRouteNames,
  TRoutableRouteOutParams,
} from '../../define/routable/RoutableTypes';
import { ELang } from '../../../locale/Locale';
import { URLToolbox } from '../../define/url-toolbox/URLToolbox';
import { URLParamsSingleSignOn } from './common/URLParamsSingleSignOn';
import { URLParamsContinueURL } from './common/URLParamsContinueURL';

export type TRoutesLandingNames = TRoutableRouteNames<typeof RoutesLanding>;
export type TRoutesLandingInParams = TRoutableRouteInParams<typeof RoutesLanding>;
export type TRoutesLandingOutParams = TRoutableRouteOutParams<typeof RoutesLanding>;

export const RoutesLanding = {
  Home: {
    name: 'Home' as const,
    path: '/:locale/home',
    secondaryPaths: {
      root: '/:locale',
      unlocalized: '/',
    } as const,
    hashes: {
      top: 'home' as const,
      info: 'info' as const,
      contact: 'contact' as const,
      download: 'download' as const,
    } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: { in: undefined },
  },
  Start: {
    name: 'Start' as const,
    path: '/:locale/start',
    secondaryPaths: {
      root: '/:locale',
      unlocalized: '/',
    } as const,
    hashes: { top: 'start' as const } as const,
    pathParams: { in: undefined },
    params: { in: undefined },
  },
  Plans: {
    name: 'Plans' as const,
    path: '/:locale/plans',
    secondaryPaths: { unlocalized: '/plans' } as const,
    hashes: { top: 'plans' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: URLToolbox.mergeParams(URLParamsSingleSignOn, { in: { plan: undefined as undefined | ESubscriptionType } }),
  },
  Profile: {
    name: 'Profile' as const,
    path: '/:locale/profile',
    secondaryPaths: { unlocalized: '/profile' } as const,
    hashes: { top: 'profile' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: { in: undefined },
  },
  Login: {
    name: 'Login' as const,
    path: '/:locale/login',
    secondaryPaths: { unlocalized: '/login' } as const,
    hashes: { top: 'login' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: URLToolbox.mergeParams(URLParamsContinueURL, { in: undefined }),
  },

  // Todo use common in params with wizard salesman
  Signup: {
    name: 'Signup' as const,
    path: '/:locale/signup',
    secondaryPaths: { unlocalized: '/signup' } as const,
    hashes: { top: 'signup' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: {
      in: {
        name: '' as string | undefined,
        surname: '' as string | undefined,
        email: '' as string | undefined,
        hostName: '' as string | undefined,
        hostDescription: '' as string | undefined,
        hostPhone: '' as string | undefined,
        hostVatId: '' as string | undefined,
        hostDenomination: '' as string | undefined,
        hostCountry: '' as string | undefined,
        hostState: '' as string | undefined,
        hostPostalCode: '' as string | undefined,
        hostCity: '' as string | undefined,
        hostAddressLine1: '' as string | undefined,
        hostAddressLine2: '' as string | undefined,
        hostPlaceId: '' as string | undefined,
        hostCoordinates: '' as string | undefined,
      },
      out: { init: 'init' as string },
      map(params) {
        return {
          init: URLToolbox.safeEncode({
            name: params.name,
            surname: params.surname,
            email: params.email,
            hostName: params.hostName,
            hostDescription: params.hostDescription,
            hostPhone: params.hostPhone,
            hostVatId: params.hostVatId,
            hostDenomination: params.hostDenomination,
            hostCountry: params.hostCountry,
            hostState: params.hostState,
            hostPostalCode: params.hostPostalCode,
            hostCity: params.hostCity,
            hostAddressLine1: params.hostAddressLine1,
            hostAddressLine2: params.hostAddressLine2,
            hostPlaceId: params.hostPlaceId,
            hostCoordinates: params.hostCoordinates,
          }),
        };
      },
      invert(params) {
        return URLToolbox.safeDecode(params.init);
      },
    },
  },
  LegalTermsApp: {
    name: 'LegalTermsApp' as const,
    path: '/:locale/legal/app',
    secondaryPaths: { unlocalized: '/legal/app' } as const,
    hashes: { top: 'terms-app' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: { in: undefined },
  },
  LegalTermsLanding: {
    name: 'LegalTermsLanding' as const,
    path: '/:locale/legal/landing',
    secondaryPaths: {
      unlocalized: '/legal/landing',
      terms: '/:locale/terms',
      unlocalizedTerms: '/terms',
    } as const,
    hashes: { top: 'terms-landing' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: { in: undefined },
  },
  LegalPrivacyPolicy: {
    name: 'LegalPrivacyPolicy' as const,
    path: '/:locale/legal/privacy-policy',
    secondaryPaths: {
      unlocalized: '/legal/privacy-policy',
      privacyPolicy: '/:locale/privacy-policy',
      privacy: '/:locale/privacy',
      unlocalizedPrivacyPolicy: '/privacy-policy',
      unlocalizedPrivacy: '/privacy',
    } as const,
    hashes: { top: 'privacy-policy' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: { in: undefined },
  },
  Legal: {
    name: 'Legal' as const,
    path: '/:locale/legal',
    secondaryPaths: { unlocalized: '/legal' } as const,
    hashes: { top: 'legal' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: { in: undefined },
  },

  WizardSalesStart: {
    name: 'WizardSalesStart' as const,
    path: '/:locale/wizard/sales',
    secondaryPaths: { unlocalizedWizardSales: '/wizard/sales' } as const,
    hashes: { top: 'wizard' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: {
      in: {
        referralId: undefined as string | undefined,
        sessionId: '' as string,
        email: undefined as string | undefined,
        name: undefined as string | undefined,
        surname: undefined as string | undefined,
        hostName: undefined as string | undefined,
        hostCoordinates: undefined as string | undefined,
        hostPlaceId: undefined as string | undefined,
        hostAddressLine1: undefined as string | undefined,
        hostPhone: undefined as string | undefined,
        hostCountry: undefined as string | undefined,
        hostState: undefined as string | undefined,
        hostPostalCode: undefined as string | undefined,
        hostCity: undefined as string | undefined,
      },
      out: { state: 'state' as string },
      map(params) {
        return { state: URLToolbox.safeEncode(params) };
      },
      invert(params) {
        return URLToolbox.safeDecode(params.state);
      },
    },
  },
  WizardSalesVenue: {
    name: 'WizardSalesVenue' as const,
    path: '/:locale/wizard/venue',
    secondaryPaths: { unlocalizedWizardSalesVenue: '/wizard/sales/venue' } as const,
    hashes: { top: 'wizard' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: {
      in: {
        sessionId: '' as string,
        referralId: undefined as string | undefined,
        email: undefined as string | undefined,
        name: undefined as string | undefined,
        surname: undefined as string | undefined,
        hostName: undefined as string | undefined,
        hostCoordinates: undefined as string | undefined,
        hostPlaceId: undefined as string | undefined,
        hostAddressLine1: undefined as string | undefined,
        hostPhone: undefined as string | undefined,
        hostCountry: undefined as string | undefined,
        hostState: undefined as string | undefined,
        hostPostalCode: undefined as string | undefined,
        hostCity: undefined as string | undefined,
      },
      out: { state: 'state' as string },
      map(params) {
        return { state: URLToolbox.safeEncode(params) };
      },
      invert(params) {
        return URLToolbox.safeDecode(params.state);
      },
    },
  },
  WizardSalesInfo: {
    name: 'WizardSalesInfo' as const,
    path: '/:locale/wizard/sales/info',
    secondaryPaths: { unlocalizedWizardSalesInfo: '/wizard/sales/info' } as const,
    hashes: { top: 'wizard' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: {
      in: {
        sessionId: '' as string,
        referralId: undefined as string | undefined,
        email: undefined as string | undefined,
        name: undefined as string | undefined,
        surname: undefined as string | undefined,
        hostName: undefined as string | undefined,
        hostCoordinates: undefined as string | undefined,
        hostPlaceId: undefined as string | undefined,
        hostAddressLine1: undefined as string | undefined,
        hostPhone: undefined as string | undefined,
        hostCountry: undefined as string | undefined,
        hostState: undefined as string | undefined,
        hostPostalCode: undefined as string | undefined,
        hostCity: undefined as string | undefined,
      },
      out: { state: 'state' as string },
      map(params) {
        return { state: URLToolbox.safeEncode(params) };
      },
      invert(params) {
        return URLToolbox.safeDecode(params.state);
      },
    },
  },
  WizardSalesDone: {
    name: 'WizardSalesDone' as const,
    path: '/:locale/wizard/sales/done',
    secondaryPaths: { unlocalizedWizardSalesDone: '/wizard/sales/done' } as const,
    hashes: { top: 'wizard' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: {
      in: {
        sessionId: '' as string,
        referralId: undefined as string | undefined,
        email: undefined as string | undefined,
        name: undefined as string | undefined,
        surname: undefined as string | undefined,
        hostName: undefined as string | undefined,
        hostCoordinates: undefined as string | undefined,
        hostPlaceId: undefined as string | undefined,
        hostAddressLine1: undefined as string | undefined,
        hostPhone: undefined as string | undefined,
        hostCountry: undefined as string | undefined,
        hostState: undefined as string | undefined,
        hostPostalCode: undefined as string | undefined,
        hostCity: undefined as string | undefined,
      },
      out: { state: 'state' as string },
      map(params) {
        return { state: URLToolbox.safeEncode(params) };
      },
      invert(params) {
        return URLToolbox.safeDecode(params.state);
      },
    },
  },

  Success: {
    name: 'Success' as const,
    path: '/:locale/success',
    secondaryPaths: { unlocalized: '/success' } as const,
    hashes: { top: 'success' as const } as const,
    pathParams: { in: { locale: 'it' as ELang } },
    params: {
      in: {
        showHeader: '' as string | undefined,
        title: '' as string | undefined,
        description: '' as string | undefined,
        imageSrc: '' as string | undefined,
        ctaPositive: '' as string | undefined,
        ctaPositiveHref: '' as string | undefined,
        ctaNegative: '' as string | undefined,
        ctaNegativeHref: '' as string | undefined,
      },
      out: { p: 'p' as string },
      map(params) {
        return {
          p: URLToolbox.safeEncode({
            showHeader: params.showHeader,
            title: params.title,
            description: params.description,
            imageSrc: params.imageSrc,
            ctaPositive: params.ctaPositive,
            ctaPositiveHref: params.ctaPositiveHref,
            ctaNegative: params.ctaNegative,
            ctaNegativeHref: params.ctaNegativeHref,
          }),
        };
      },
      invert(params) {
        return URLToolbox.safeDecode(params.p);
      },
    },
  },
};

export const RoutableLanding = Routable.wrapApi({
  baseUrl: AlacarteUrls.BASE_URL_LANDING_PAGE.https,
  routes: RoutesLanding,
});
