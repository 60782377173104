import * as React from 'react';
import { useGoogleServiceRef } from './useGoogleService';

type AutocompleteService = google.maps.places.AutocompleteService;
type QueryAutocompletePrediction = google.maps.places.QueryAutocompletePrediction;
type PlacesServiceStatus = google.maps.places.PlacesServiceStatus;

// https://developers.google.com/maps/documentation/javascript/places-autocomplete#place_autocomplete_service
export function useGetPlacesSuggestions() {
  const { serviceRef, awaitService } = useGoogleServiceRef();

  return React.useCallback(async (search: string) => {
    await awaitService();
    const service = serviceRef.current;
    if (!service) {
      return;
    }

    return new Promise<QueryAutocompletePrediction[]>((resolve) => {
      service.getQueryPredictions(
        { input: search },
        (predictions: QueryAutocompletePrediction[] | null, status: PlacesServiceStatus) => {
          if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
            return resolve([]);
          }

          return resolve(predictions);
        },
      );
    });
  }, [awaitService]);
}
