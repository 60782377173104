import { DbRes } from '../../../db/DbResources';
import { FIELD_CREATED_ON } from '../../../db/DbDefs';
import { HostMessageBuilder } from './HostMessageBuilder';
import { MObj } from '../../../lib/model/MObj';
import { THostMessage } from './HostMessageTypes';
import { parseIntOrDefault } from '../../../lib/HelperFunctions';

export class HostMessage extends MObj<typeof DbRes.HostMessage> {
  static sortMessagesChronologically(messages: THostMessage[]): THostMessage[] {
    return messages.sort((msgA, msgB) => {
      const delayA = parseIntOrDefault(`${msgA.delayMs}`, 0);
      const delayB = parseIntOrDefault(`${msgA.delayMs}`, 0);
      return (msgA[FIELD_CREATED_ON] + delayA) - (msgB[FIELD_CREATED_ON] + delayB);
    });
  }

  constructor(message: THostMessage) {
    super(HostMessageBuilder, message);
  }
}
