import * as React from 'react';
import * as _ from 'lodash';
import * as fp from 'lodash/fp';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../../../core/src/db/DbDefs';
import { FieldStationInputListItem } from './FieldStationInputListItem';
import { Strings } from '../../../../../core/src/locale/Strings';
import { TStationUpdate } from '../../../../../core/src/models/db/station/StationTypes';
import { fpRemove } from '../../../../../core/src/lib/HelperFunctions';
import { useSyncedDataRef } from '../../../../../lib-react/src/hooks/useSyncedDataRef';
import styled from 'styled-components';
import { ELocale } from '../../../../../core/src/locale/Locale';

export type TFieldStationListInputProps = {
  locale: ELocale;
  value: TStationUpdate[];
  onChange: (items: TStationUpdate[]) => void;
  newItem?: TStationUpdate;
  newItemSet?: (value: TStationUpdate) => void;
  newItemReset?: () => void;
  error?: string;
  className?: string;
};

export function FieldStationListBase({
  locale,
  value,
  onChange,
  newItem,
  newItemSet,
  newItemReset,
  className,
}: TFieldStationListInputProps) {
  const valueRef = useSyncedDataRef(value);

  const onValueChange = React.useCallback((index: number, station: TStationUpdate) => {
    const val = valueRef.current;
    onChange && onChange(fp.set(`[${index}]`, station, val));
  }, [onChange]);

  const onItemRemove = React.useCallback((index: number) => {
    const val = valueRef.current;
    onChange && onChange(fpRemove(val, index));
  }, [onChange]);

  const onNewItemChange = React.useCallback((index: number, station: TStationUpdate) => {
    newItemSet && newItemSet(station);
  }, [newItemSet]);

  const onNewItemSave = React.useCallback((index: number, station: TStationUpdate) => {
    const val = valueRef.current;
    onValueChange(val.length, station);
    newItemReset && newItemReset();
  }, [onValueChange, newItemReset]);

  return (
    <Root className={className}>
      {value.map((item, index) => (
        <FieldStationInputListItem
          key={_.get(item, FIELD_ID, index)}
          locale={locale}
          index={index}
          station={item}
          onChange={onValueChange}
          onRemove={onItemRemove}
          namePlaceholder={item[FIELD_NAME] || ''}
          saveOnBlur={true}
        />
      ))}
      {!!newItem && (
        <FieldStationInputListItem
          index={value.length}
          locale={locale}
          station={newItem}
          onChange={onNewItemChange}
          onSave={onNewItemSave}
          namePlaceholder={Strings.hProfileStationName()(locale)}
          saveOnBlur={false}
        />
      )}
    </Root>
  );
}

export const FieldStationListInput = React.memo(FieldStationListBase) as typeof FieldStationListBase;

const Root = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
  & > *:not(:first-child) {
    margin-top: -1px;
  }
`;

