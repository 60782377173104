import { THost } from '../../models/db/host/HostTypes';
import {
  EOrderCreatedBy,
  EOrderStatus,
  TOrder,
} from '../../models/db/order/OrderTypes';
import * as _ from 'lodash';
import { NoUndefinedField } from '../Types';
import { calculateBatching } from './TimestampBatcher';
import { nowMs } from '../HelperFunctions';
import { TGuestSession } from '../../models/db/guestSession/GuestSessionTypes';

const defaultHostOrderBatchingTTLMs = 0.5 * 60 * 1000;
const defaultGuestOrderBatchingTTLMs = 2 * 60 * 1000;

type TBatchResult = {
  batch: TOrder[];
  batchTTLMs: number;
};

type TWithOrderedOn = NoUndefinedField<Pick<TOrder, 'orderedOn'>>;
type TBatchableOrder = TOrder & TWithOrderedOn;

export class OrderBatcher {
  private constructor() {
    // Only static methods
  }

  static batch(host: THost, { orders }: Pick<TGuestSession, 'orders'>) {
    const filteredOrders = OrderBatcher.filterOrdersToBatch(_.values(orders));
    return [
      ...OrderBatcher.getHostBatches(host, filteredOrders),
      ...OrderBatcher.getGuestBatches(host, filteredOrders),
    ];
  }

  static filterOrdersToBatch(orders: TOrder[]) {
    return _.values(orders)
      .filter(({ items }) => _.size(items) > 0)
      .filter(({ orderedOn }) => !!orderedOn && orderedOn > 0)
      .filter(({ status }) => status === EOrderStatus.BATCHING) as TBatchableOrder[];
  }

  private static getHostBatches(host: THost, orders: TBatchableOrder[]): TBatchResult[] {
    const batch = orders
      .filter(({ createdBy }) => createdBy == EOrderCreatedBy.HOST);

    const batchTTLMs = OrderBatcher.calculateBatchTTLMs(
      host.hostOrderBatchingTTLMs || defaultHostOrderBatchingTTLMs,
      batch,
    );

    if (batchTTLMs == null) {
      return [];
    }

    return [{
      batchTTLMs,
      batch,
    }];
  }

  private static getGuestBatches(host: THost, orders: TBatchableOrder[]): TBatchResult[] {
    const batch = orders
      .filter(({ createdBy }) => !createdBy || (createdBy == EOrderCreatedBy.GUEST));

    const batchTTLMs = OrderBatcher.calculateBatchTTLMs(
      host.guestOrderBatchingTTLMs || defaultGuestOrderBatchingTTLMs,
      batch,
    );

    if (batchTTLMs == null) {
      return [];
    }

    return [{
      batchTTLMs,
      batch,
    }];
  }

  private static calculateBatchTTLMs(
    batchingTTLMs: number,
    orders: TWithOrderedOn[],
  ): number | null {
    if (orders.length <= 0) {
      return null;
    }

    const orderTimestamps = orders.map(({ orderedOn }) => orderedOn);
    return calculateBatching(orderTimestamps, batchingTTLMs) - nowMs();
  }
}
