import * as React from 'react';
import { ReactComponent as CheckmarkEmptyIcon } from '../../assets/checkmark-empty.svg';
import { ReactComponent as CheckmarkFullIcon } from '../../assets/checkmark-filled.svg';
import { ReactComponent as DragIconBlack } from '../../assets/drag-icon-black.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/duplicate-icon.svg';
import { FIELD_ID, FIELD_NAME, } from '../../../../core/src/db/DbDefs';
import { EMenuItemState, TMenuItem, } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { EditIconDark, RemoveIcon, } from '../../assets/Assets';
import { FieldSyncedString } from '../form/syncedFields/FieldSyncedString';
import { IconButton } from '../Button/IconButton';
import { Num } from '../../../../core/src/lib/formatters/Num';
import { PopoverConfirmation } from '../popup/PopoverConfirmation';
import { Strings } from '../../../../core/src/locale/Strings';
import { dbItemLocalizableGetLocalizedFieldWithDefault } from '../../../../core/src/db/DbLib';
import { formatWithLocale } from '../../lib/Format';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import styled, { css } from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';
import { RenderHTMLSafely } from "../../lib-components/RenderHTMLSafely";
import { htmlDefaultStyles } from "../../lib-components/htmlComponentsDefaults";
import { useCurrentHost } from "../../hooks/useCurrentHost";

type TMenuEditorMenuItemListItemProps = {
  locale: ELocale;
  menuItem: TMenuItem;
  viewLocale: ELocale;
  onMenuItemNameChange: (menuItem: TMenuItem, newName: string) => void;
  onMenuItemEdit: (menuItem: TMenuItem) => void;
  onMenuItemDuplicate: (menuItem: TMenuItem) => void;
  onMenuItemArchive: (menuItem: TMenuItem) => void;
  onMenuItemPublish: (menuItem: TMenuItem) => void;
  onMenuItemUnPublish: (menuItem: TMenuItem) => void;
};

export const MenuEditorMenuItemListItem = ({
                                             locale,
                                             menuItem,
                                             viewLocale,
                                             onMenuItemNameChange,
                                             onMenuItemEdit,
                                             onMenuItemDuplicate,
                                             onMenuItemArchive,
                                             onMenuItemPublish,
                                             onMenuItemUnPublish,
                                           }: TMenuEditorMenuItemListItemProps) => {
  const host = useCurrentHost();

  const onMenuItemEditClick = useItemCallback(menuItem, onMenuItemEdit);
  const onMenuItemDuplicateClick = useItemCallback(menuItem, onMenuItemDuplicate);
  const onMenuItemArchiveClick = useItemCallback(menuItem, onMenuItemArchive);
  const menuItemPublish = useItemCallback(menuItem, onMenuItemPublish);
  const menuItemUnPublish = useItemCallback(menuItem, onMenuItemUnPublish);

  const setMenuItemName = React.useCallback((newValue: string) => {
    return onMenuItemNameChange(menuItem, newValue);
  }, [menuItem, onMenuItemNameChange]);

  const menuItemName = dbItemLocalizableGetLocalizedFieldWithDefault<TMenuItem, 'name'>(menuItem, FIELD_NAME, viewLocale);
  const menuItemDescription = dbItemLocalizableGetLocalizedFieldWithDefault<TMenuItem, 'description'>(menuItem, 'description', viewLocale);

  const namePlaceholder = formatWithLocale(Strings.hMenuItemNamePhd(), locale, viewLocale);

  return (
    <ItemRowContainer key={menuItem[FIELD_ID]}>
      <CenteredSingleButtonContainer>
        <IconButton>
          <DragIconBlack/>
        </IconButton>
      </CenteredSingleButtonContainer>
      <MenuItemFieldSyncedInput
        tabIndex={0}
        value={menuItemName}
        onValueChange={setMenuItemName}
        placeholder={namePlaceholder}
        isEditable={!!onMenuItemEditClick}
      />
      <TextItem>
        {Num.formatPrice2(menuItem.price, host?.currency)}
      </TextItem>

      <RenderHTMLSafelyItem
        html={menuItemDescription}
      />
      <StatusCont>
        <IconButton
          label={menuItem.status === EMenuItemState.ACTIVE
            ? Strings.hPublishedChk()(locale)
            : Strings.hDraftChk()(locale)
          }
          onClick={menuItem.status === EMenuItemState.ACTIVE ? menuItemUnPublish : menuItemPublish}
        >
          {menuItem.status === EMenuItemState.ACTIVE
            ? <CheckmarkFullIcon/>
            : <CheckmarkEmptyIcon/>}
        </IconButton>
      </StatusCont>

      <ActionCont>
        <IconButton onClick={onMenuItemEditClick}>
          <EditIconDark/>
        </IconButton>
        <IconButton onClick={onMenuItemDuplicateClick}>
          <DuplicateIcon/>
        </IconButton>
        <PopoverConfirmation
          componentId={menuItem[FIELD_ID]}
          buttonOnClick={onMenuItemArchiveClick}
          placement={'bottom'}
          buttonText={Strings.hMenuDelCmd()(locale)}
          confirmationMessage={Strings.hMenuDelCmd()(locale)}
        >
          <RemoveIcon/>
        </PopoverConfirmation>
      </ActionCont>
    </ItemRowContainer>
  );
};

const tableContentItemPadding = 10;

export const menuEditorGrid = css`
  align-items: stretch;
  display: grid;
  grid-template-columns: 0.75fr 7fr 1.5fr 7fr 2fr 2fr;
`;

const ItemRowContainer = styled.div`
  ${menuEditorGrid};
  background-color: ${({ theme }) => theme.t.col.white};
  border-bottom: 1px solid ${({ theme }) => theme.t.col.accentLight};
  min-height: 100px;

  & > *:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.t.col.accentLight};
  }
`;

const MenuItemFieldSyncedInput = styled(FieldSyncedString)`
  padding: ${tableContentItemPadding}px;
`;

const TextItem = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 19px;
  padding: ${tableContentItemPadding}px;
`;

const RenderHTMLSafelyItem = styled(RenderHTMLSafely)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 19px;
  padding: ${tableContentItemPadding}px;
  pointer-events: none;
  ${htmlDefaultStyles}
`;

const StatusCont = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: ${tableContentItemPadding}px;
`;

const ActionCont = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: ${tableContentItemPadding}px;

  & > * {
    padding: ${tableContentItemPadding / 2}px;
  }
`;

const CenteredSingleButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${tableContentItemPadding}px;
`;
