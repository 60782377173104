import * as React from 'react';
import { FIELD_ID, FIELD_NAME } from '../../../../core/src/db/DbDefs';
import { FieldSyncedString } from '../form/syncedFields/FieldSyncedString';
import { Strings } from '../../../../core/src/locale/Strings';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { TTable } from '../../../../core/src/models/db/table/TableTypes';
import { TTheme } from '../../config/Theme';
import { Validator } from '../../../../core/src/lib/error/Validators';
import { stopPropagation } from '../../lib-components/utils/stopPropagation';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import { validateTable } from '../../../../core/src/models/validators/validateTable';
import styled from 'styled-components';
import { ESubscriptionFeatureFlags } from '../../../../core/src/models/db/hostSubscription/HostSubscriptionTypes';
import { THostPublicSubscription } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import { FeatureFlagOverlay } from '../featureFlagOverlay/FeatureFlagOverlay';
import { ELocale } from '../../../../core/src/locale/Locale';

type THostTableGridItemProps = {
  locale: ELocale;
  table: TTable;
  onTableClick: (table: TTable) => void;
  onInternalNoteChange: (table: TTable, note: string) => void;
  guestSessionData: TGuestSession | undefined;
  hostPublicSubscription: THostPublicSubscription;
};

export const HostTableGridItem = ({
  locale,
  table,
  onTableClick,
  guestSessionData,
  onInternalNoteChange,
  hostPublicSubscription,
}: THostTableGridItemProps) => {
  const onClick = useItemCallback(table, onTableClick);
  const onValueChange = useItemCallback(table, onInternalNoteChange);
  return (
    <StyledFeatureFlagOverlay
      dontTooltip
      locale={locale}
      uniqueId={`ff-HostTableGridItem-${table[FIELD_ID]}`}
      hostPublicSubscription={hostPublicSubscription}
      dontOverlay
      blockCursor
      blurDeviation={0}
      requiredFeatureList={[
        ESubscriptionFeatureFlags.guestOrders,
      ]}
    >
      <TableContainer
        key={table[FIELD_ID]}
        guestSessionData={guestSessionData}
        onClick={guestSessionData ? onClick : undefined}
      >
        <TableNumberText>
          {Strings.hTableID({ tableNr: table[FIELD_NAME] })(locale)}
        </TableNumberText>
        <TableNoteInput
          tabIndex={0}
          onClick={stopPropagation}
          value={table.internalNote}
          onValueChange={onValueChange}
          placeholder={Strings.hTableNotePhd()(locale)}
          isEditable={true}
          validate={Validator.use(validateTable.internalNote)}
        />
      </TableContainer>
    </StyledFeatureFlagOverlay>
  );
};

function guestSessionDataToColor(theme: TTheme, guestSessionData: TGuestSession | undefined) {
  if (!guestSessionData) {
    return theme.t.col.accentLight;
  }

  if ((guestSessionData.lastBillRequestTs || 0) > 0) {
    return theme.t.col.accentDark;
  }

  return theme.t.col.red;
}

const StyledFeatureFlagOverlay = styled(FeatureFlagOverlay)`
  height: 100px;
  width: 150px;
`;

type TTableContainerProps = {
  guestSessionData: TGuestSession | undefined;
};

const TableContainer = styled.div<TTableContainerProps>`
  align-items: center;
  background-color: ${({ theme, guestSessionData }) => guestSessionDataToColor(theme, guestSessionData)};
  cursor: ${({ guestSessionData }) => (guestSessionData ? 'pointer' : 'default')};
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  min-width: 120px;
`;

const TableNumberText = styled.span`
  color: ${({ theme }) => theme.t.col.white};
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
`;

const TableNoteInput = styled(FieldSyncedString)`
  color: ${({ theme }) => theme.t.col.lightGray6};
  cursor: text;
  font-weight: normal !important;
  justify-content: center;
  margin: 8px 12px 0 12px;
  max-width: 80%;
  text-align: center;
  width: 80%;

  ::placeholder {
    color: ${({ theme }) => theme.t.col.lightGray1};
  }
`;
