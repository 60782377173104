import * as React from 'react';
import { Config } from '../../config/Config';
import { Loader } from '@googlemaps/js-api-loader';
import { safeDelay } from '../../../../core/src/lib/HelperFunctions';

type AutocompleteService = google.maps.places.AutocompleteService;
type PlacesService = google.maps.places.PlacesService;

export function useGoogleServiceRef() {
  const serviceRef = React.useRef<AutocompleteService | undefined>();
  const placesServiceRef = React.useRef<PlacesService | undefined>();

  React.useEffect(() => {
    const loader = new Loader({
      apiKey: Config.firebase.apiKey,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then(() => {
      serviceRef.current = new google.maps.places.AutocompleteService();
      placesServiceRef.current = new google.maps.places.PlacesService(document.createElement('div'));
    });
  }, []);

  const awaitService = React.useCallback(async () => {
    let attempt = 20;
    while (serviceRef.current == null && attempt > 0) {
      await safeDelay(100);
      attempt--;
    }
  }, []);

  return {
    serviceRef,
    awaitService,
    placesServiceRef,
  };
}
