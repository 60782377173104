import * as React from 'react';
import * as _ from 'lodash';
import styled from 'styled-components';

type TFieldCheckbox2Props = {
  value: boolean;
  label: string;
  className?: string;
  onChange?: (newValue: boolean) => void;
  error?: string;
  readOnly?: boolean;
};

export const FieldCheckbox2 = ({
  value,
  label,
  error,
  className,
  onChange = _.noop,
  readOnly = false,
}: TFieldCheckbox2Props) => {
  const onValueChange = React.useCallback(() => onChange(!value), [onChange, value]);
  const hasError = !_.isEmpty(error);
  return (
    <Root className={className}>
      <CheckboxLabel
        onClick={onValueChange}>
        <input
          readOnly={readOnly}
          type="checkbox"
          checked={value}
          onChange={_.noop}
        />
        {label}
      </CheckboxLabel>
      {hasError && (
        <FixedErrorContainer>
          <Error>{error}</Error>
        </FixedErrorContainer>
      )}
    </Root>
  );
};

const Root = styled.div`
  align-items: flex-start;
  display: flex;
  padding: 20px 0;
  width: 100%;
`;

const CheckboxLabel = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  line-height: 18px;
  input[type="checkbox"] {
    margin-right: 10px;
  }
`;

const FixedErrorContainer = styled.div`
  height: 20px;
  text-align: right;
  width: 100%;
`;

const Error = styled.span`
  color: ${({ theme }) => theme.t.col.red};
  font-size: 12px;
`;
