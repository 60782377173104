import * as React from 'react';
import { notEmptyOrUndefined } from '../../../../core/src/lib/HelperFunctions';
import { plusCodeToCoordinates } from '../../lib/Coordinates';
import { useGoogleServiceRef } from './useGoogleService';

export type TPlaceDetails = {
  coordinates: string | undefined;
  number: string | undefined;
};

const def = {
  coordinates: undefined,
  number: undefined,
};

// https://developers.google.com/maps/documentation/places/web-service/details
export function useGetPlaceInfo() {
  const { placesServiceRef, awaitService } = useGoogleServiceRef();

  return React.useCallback(async (placeId: string): Promise<TPlaceDetails | undefined> => {
    await awaitService();
    const service = placesServiceRef.current;
    if (!service) {
      return;
    }

    return new Promise<TPlaceDetails | undefined>((resolve) => {
      const params = {
        placeId,
        fields: [
          'international_phone_number',
          'plus_code',
        ],
        sessionToken: undefined,
      };

      service.getDetails(params, (result, status) => {
        if (status != google.maps.places.PlacesServiceStatus.OK || !result) {
          return resolve(def);
        }
        resolve({
          coordinates: plusCodeToCoordinates(result.plus_code),
          number: notEmptyOrUndefined(result.international_phone_number),
        });
      });
    });
  }, [awaitService]);
}
