import * as React from 'react';
import * as _ from 'lodash';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../../../../core/src/db/DbDefs';
import { EPrintAdapter } from '../../../../../core/src/printer/PrintAdapters';
import {
  EStationType,
  TStationUpdate,
} from '../../../../../core/src/models/db/station/StationTypes';
import { FieldStationInputAdapterNetworkParams } from './FieldStationInputAdapterNetworkParams';
import { FieldStationInputAdapterSerialParams } from './FieldStationInputAdapterSerialParams';
import { FieldStationInputAdapterUSBParams } from './FieldStationInputAdapterUSBParams';
import { FieldStationInputInputField } from './FieldStationInputInputField';
import { FieldStationInputPickerItem } from './FieldStationInputPickerItem';
import { PopoverWithClickAway } from '../../popup/PopoverWithClickAway';
import { RemoveIcon } from '../../../assets/Assets';
import { enumToArray } from '../../../../../core/src/lib/HelperFunctions';
import { useSyncedDataRef } from '../../../../../lib-react/src/hooks/useSyncedDataRef';
import { useToggle } from '../../../../../lib-react/src/hooks/useToggle';
import styled from 'styled-components';
import { ELocale } from '../../../../../core/src/locale/Locale';

export type TFieldStationInputListItemProps = {
  locale: ELocale;
  index: number;
  station: TStationUpdate;
  namePlaceholder: string;
  onChange?: (index: number, station: TStationUpdate) => void;
  onSave?: (index: number, station: TStationUpdate) => void;
  onRemove?: (index: number, station: TStationUpdate) => void;
  className?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  saveOnBlur?: boolean;
};

export function FieldStationInputListItem<T extends TStationUpdate>({
  locale,
  index,
  station,
  namePlaceholder,
  onChange,
  onSave,
  onRemove,
  className,
  autoFocus = false,
  readOnly = false,
  saveOnBlur = true,
}: TFieldStationInputListItemProps) {
  const stationRef = useSyncedDataRef(station);

  const onStationRemove = React.useCallback(() => {
    onRemove && onRemove(index, stationRef.current);
  }, [onRemove, index]);

  const onStationPathChange = React.useCallback((path: string, value: string) => {
    onChange && onChange(index, _.set({ ...stationRef.current }, path, value));
  }, [onChange, index]);

  const [adapterPopoverOpen, , setAdapterPopoverOpen, setAdapterPopoverClose] = useToggle(false);
  const onStationAdapterChange = React.useCallback((value: EPrintAdapter) => {
    onChange && onChange(index, {
      ...stationRef.current,
      adapter: {
        name: value,
        params: {} as any,
      },
    });
    setAdapterPopoverClose();
  }, [onChange, index, setAdapterPopoverClose]);

  const onItemSave = React.useCallback(() => {
    const li = stationRef.current;
    const itemCompleted = !_.isEmpty(li[FIELD_NAME])
      && (!li.adapter || !_.isEmpty(li.adapter));
    itemCompleted && onSave && onSave(index, li);
  }, [onSave]);

  const removeIcon = React.useMemo(() => <RemoveIcon/>, []);
  const adapterType = _.get(station, 'adapter.name', EPrintAdapter.Network);
  return (
    <InnerContainer
      className={className}>
      <NameContainer>
        <FieldStationInputInputField
          station={station}
          valuePathInStation={FIELD_NAME}
          readOnly={readOnly}
          autoFocus={autoFocus}
          onChange={onStationPathChange}
          onItemSave={onItemSave}
          saveOnBlur={saveOnBlur}
          placeholder={namePlaceholder}
        />
      </NameContainer>
      <AdapterContainer>
        <PopoverWithClickAway
          componentId={`station-input-${station[FIELD_ID]}`}
          popoverOpen={adapterPopoverOpen}
          closePopover={setAdapterPopoverClose}
          openPopover={setAdapterPopoverOpen}
          popoverContent={(
            <>
              {enumToArray<EPrintAdapter>(EPrintAdapter).map((adapter) => (
                <FieldStationInputPickerItem
                  key={adapter}
                  locale={locale}
                  adapterType={adapter}
                  onSelect={onStationAdapterChange}
                />
              ))}
            </>
          )}
        >
          <AdapterFieldStationInputInputField
            readOnly={true}
            placeholder={'Adattatore'}
            station={station}
            valuePathInStation={'adapter.name'}
            onItemSave={onItemSave}
            saveOnBlur={saveOnBlur}
          />
        </PopoverWithClickAway>
      </AdapterContainer>
      <AdapterParamsContainer>
        {{
          [EPrintAdapter.USB]: (
            <FieldStationInputAdapterUSBParams
              station={station}
              onChange={onStationPathChange}
              onItemSave={onItemSave}
              saveOnBlur={saveOnBlur}
            />
          ),
          [EPrintAdapter.Network]: (
            <FieldStationInputAdapterNetworkParams
              station={station}
              onChange={onStationPathChange}
              onItemSave={onItemSave}
              saveOnBlur={saveOnBlur}
            />
          ),
          [EPrintAdapter.Serial]: (
            <FieldStationInputAdapterSerialParams
              station={station}
              onChange={onStationPathChange}
              onItemSave={onItemSave}
              saveOnBlur={saveOnBlur}
            />
          ),
        }[adapterType]}
      </AdapterParamsContainer>
      {(onRemove && station.type != EStationType.MAIN) && (
        <IconContainer onClick={onStationRemove}>
          {removeIcon}
        </IconContainer>
      )}
    </InnerContainer>
  );
}

const InnerContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.t.col.accentLight};
  border-radius: 1px;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 0.4fr;
  width: 100%;
  & > *:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.t.col.accentLight};
  }
`;
const NameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: normal;
  height: 50px;
  justify-content: space-between;
  line-height: 18px;
  padding: 10px;
  text-transform: none;
  width: 100%;
`;
const AdapterFieldStationInputInputField = styled(FieldStationInputInputField)`
  cursor: pointer !important;
  padding: 10px;
`;

const AdapterContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  min-height: 50px;
  text-align: right;
  text-transform: none;
  width: 100%;
`;
const AdapterParamsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  min-height: 50px;
  text-align: right;
  text-transform: none;
  width: 100%;
`;

const IconContainer = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0 5px;
`;
