import * as React from 'react';
import { PopoverWithClickAway } from '../popup/PopoverWithClickAway';
import {
  TFieldPickableSortableItem,
  TFieldPickableSortableListSelectableItem,
} from './FieldPickableSortableList';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';
import { useToggle } from '../../../../lib-react/src/hooks/useToggle';
import styled from 'styled-components';

export type TFieldPickableSortableListItemProps = {
  componentId: string;
  index: number;
  listItem: TFieldPickableSortableItem;
  selectableItems: TFieldPickableSortableListSelectableItem[];
  onSelectableItemSelect: (selectableListItem: TFieldPickableSortableListSelectableItem) => void;
  onAction?: (index: number, listItem: TFieldPickableSortableItem) => void;
  action?: React.ReactNode;
  mainPlaceholder?: string;
  secondaryPlaceholder?: string;
  className?: string;
};

export function FieldPickableSortableListItem({
  componentId,
  index,
  listItem,
  selectableItems,
  onSelectableItemSelect,
  onAction,
  action,
  mainPlaceholder,
  secondaryPlaceholder,
  className,
}: TFieldPickableSortableListItemProps) {
  const [popoverOpen, , setPopoverOpen, setPopoverClosed] = useToggle(false);
  const listItemRef = useSyncedDataRef(listItem);

  const onItemAction = React.useCallback(() => {
    onAction && listItemRef.current.actionable && onAction(index, listItemRef.current);
  }, [onAction, index]);

  const onSelectableItemClick = React.useCallback((selectableItem: TFieldPickableSortableListSelectableItem) => {
    onSelectableItemSelect(selectableItem);
    setPopoverClosed();
  }, [onSelectableItemSelect, setPopoverClosed]);

  return (
    <PopoverWithClickAway
      componentId={`pickable-sortable-${componentId}`}
      popoverOpen={popoverOpen}
      closePopover={setPopoverClosed}
      openPopover={setPopoverOpen}
      popoverContent={(
        <PopoverContent>
          {selectableItems.map((selectableItem) => (
            <PopoverRoot
              key={selectableItem.id}
              onClick={() => onSelectableItemClick(selectableItem)}
              twoColumns={selectableItem.hasSecondary}>
              <ItemContainer>
                <PickableItem>
                  {selectableItem.mainText}
                </PickableItem>
              </ItemContainer>
              {selectableItem.hasSecondary && (
                <PriceContainer>
                  <PickableItem>
                    {selectableItem.secondaryText}
                  </PickableItem>
                </PriceContainer>
              )}
            </PopoverRoot>
          ))}
        </PopoverContent>
      )}
    >
      <Root
        onClick={setPopoverOpen}
        className={className}
        twoColumns={listItem.hasSecondary}>
        <ItemContainer>
          <PickableItem
            isPlaceholder={!listItem.mainText}>
            {listItem.mainText
              ? listItem.mainText
              : mainPlaceholder}
          </PickableItem>
          {onAction && listItem.actionable && (
            <IconContainer onClick={onItemAction}>
              {action}
            </IconContainer>
          )}
        </ItemContainer>
        {listItem.hasSecondary && (
          <PriceContainer>
            <PickableItem
              isPlaceholder={!listItem.secondaryText}>
              {listItem.secondaryText
                ? listItem.secondaryText
                : secondaryPlaceholder}
            </PickableItem>
          </PriceContainer>
        )}
      </Root>
    </PopoverWithClickAway>
  );
}

type TInnerContainerProps = {
  twoColumns?: boolean;
};

const Root = styled.div<TInnerContainerProps>`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr ${({ twoColumns }) => (twoColumns ? '0.6fr' : '')};
  border: 1px solid ${({ theme }) => theme.t.col.accentLight};
  &:not(:first-child) {
    // Accounts for the 1px border between items, use box-sizing: border-box; instead
    margin-top: -1px;
  }
  &:first-child {
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
  }
  &:not(:last-child) {
    border-bottom: 0;
  }
  &:last-child {
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
  }
  cursor: pointer;
`;

const PopoverRoot = styled(Root)<TInnerContainerProps>`

`;

const PopoverContent = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`;

const ItemContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: normal;
  height: 50px;
  justify-content: space-between;
  line-height: 18px;
  padding: 10px;
  text-transform: none;
  width: 100%;
`;

type TPickableItemProps = {
  isPlaceholder?: boolean;
};

const PickableItem = styled.div<TPickableItemProps>`
  background: none;
  border: none;
  flex: 1;
  outline: none;
  width: 100%;
  ${({ isPlaceholder }) => (isPlaceholder ? `
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: ${({ theme }) => theme.t.col.accentLight};
  ` : '')}
`;

const PriceContainer = styled.div`
  align-items: center;
  border-left: 1px solid ${({ theme }) => theme.t.col.accentLight};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  min-height: 50px;
  padding: 10px;
  text-align: right;
  text-transform: none;
  width: 100%;
`;

const IconContainer = styled.div`
  cursor: pointer;
  padding: 0 5px;
`;
