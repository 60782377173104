import * as React from 'react';
import * as _ from 'lodash';
import * as fp from 'lodash/fp';
import { Modal } from 'react-bootstrap';
import styled from "styled-components";
import { UnsplashImagePicker } from "./imagePicker/unsplash";
import { TUnsplashPhoto } from "./imagePicker/unsplash/unsplash-api";
import { SquareButton } from "../Button/SquareButton";
import {ELocale} from '../../../../core/src/locale/Locale';
import { Strings } from '../../../../core/src/locale/Strings';

type TProps = {
  locale: ELocale;
  open: boolean;
  onClose: (photos: TUnsplashPhoto[]) => void;
  initialSearch: string,
  maxCount?: number;
};

const unsplashAccessKey = 'Cic1y5SizXWL2aNuWnAuKoNSHEpThTOFNMkLDp6en4k';

export const ImagePickerModal = ({
                                   locale,
                                   open,
                                   onClose,
                                   initialSearch,
                                   maxCount,
                                 }: TProps) => {
  const [selectedPhotos, setSelectedPhotos] = React.useState<Record<string, TUnsplashPhoto>>({});

  React.useEffect(() => {
    open && setSelectedPhotos({})
  }, [open]);

  const handleConfirm = React.useCallback(() => {
    return onClose(_.values(selectedPhotos))
  }, [onClose, selectedPhotos]);

  const handleDiscard = React.useCallback(() => {
    return onClose([])
  }, [onClose, selectedPhotos]);

  const handlePhotoClick = React.useCallback((photo: TUnsplashPhoto) => {
    setSelectedPhotos((selected) => {
      if (selected[photo.id] != null) {
        return fp.omit(photo.id, selected);
      }

      if (maxCount && _.size(selected) >= maxCount) {
        if (maxCount === 1) {
          selected = {};
        }
      }

      return {
        ...selected,
        [photo.id]: photo
      }
    })
  }, [setSelectedPhotos]);

  return (
    <Modal
      size="lg"
      centered
      show={open}
      onHide={handleDiscard}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {Strings.imagePickerTitle()(locale)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Content>
          <UnsplashImagePicker
            searchPlaceholder={Strings.imagePickerSearchPlaceholder()(locale)}
            imagePickerLoadingText={Strings.imagePickerLoadingText()(locale)}
            imagePickerNothingFoundText={Strings.imagePickerNothingFoundText()(locale)}
            unsplashAccessKey={unsplashAccessKey}
            initialPhotoSearchQuery={initialSearch}
            selectedPhotos={selectedPhotos}
            onPhotoClick={handlePhotoClick}
          />
        </Content>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsContainer>
          <SquareButtonLeft
            title={Strings.gCancelOp()(locale)}
            onClick={handleDiscard}
          />
          <SquareButtonRight
            onClick={handleConfirm}
            title={Strings.gConfirmCmd()(locale)}
            disabled={_.size(selectedPhotos) <= 0}
          />
        </ButtonsContainer>
      </Modal.Footer>
    </Modal>
  )
};

const Content = styled.div`
  overflow-y: scroll;
`;


const ButtonsContainer = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;;
  flex-direction: row;
  margin: 0 auto;
  width: 68%;
`;

const SquareButtonLeft = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.accentLight};
  margin-right: 20px;
`;

const SquareButtonRight = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.accentDark};
`;