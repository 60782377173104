import * as React from 'react';
import { useSafeState } from './useSafeState';
import { useSyncedDataRef } from './useSyncedDataRef';

type TElementDimensions = {
  width: number | undefined;
  height: number | undefined;
};

type StubbedHtmlDivElement = {
  clientWidth: number;
  clientHeight: number;
};

export function useElementDimensions<El extends StubbedHtmlDivElement>(checkFrequency: number | undefined) {
  const [dimensions, setDimensions] = useSafeState<TElementDimensions>({
    width: undefined,
    height: undefined,
  });

  const elementRef = React.createRef<El>();

  const updaterRef = useSyncedDataRef(() => {
    const ref = elementRef.current;
    !!ref && setDimensions({
      width: ref && ref.clientWidth,
      height: ref && ref.clientHeight,
    });
  });

  // Measure on content mount
  React.useLayoutEffect(() => updaterRef.current(), []);

  // Attach listener
  React.useEffect(() => {
    if (!checkFrequency) {
      return;
    }

    const interval = setInterval(() => updaterRef.current(), checkFrequency);
    return () => clearInterval(interval);
  }, [checkFrequency]);

  return {
    width: dimensions.width,
    height: dimensions.height,
    elementRef,
  };
}
