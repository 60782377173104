import * as _ from 'lodash';
import { ArchivedMenuBuilder } from '../../../../core/src/models/db/menu/ArchivedMenuBuilder';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import {
  Firebase,
  TUpdateProfileParams,
} from '../../lib/Firebase';
import { HostBuilder } from '../../../../core/src/models/db/host/HostBuilder';
import { Log } from '../../../../core/src/config/Instance';
import { MenuBuilder } from '../../../../core/src/models/db/menu/MenuBuilder';
import { MenuItemBuilder } from '../../../../core/src/models/db/menuItem/MenuItemBuilder';
import { SnackbarOptions } from '../../lib/snackbars/SnackbarOptions';
import { Snackbars } from '../../lib/snackbars/Snackbars';
import { Strings } from '../../../../core/src/locale/Strings';
import { TArchivedMObj } from '../../../../core/src/models/db/archivedMObj/ArchivedMObjTypes';
import {
  TDispatch,
  TThunkAction,
} from '../../../../lib-react/src/redux/redux';
import { TGetState } from '../Store';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TMenuId } from '../../../../core/src/models/db/menu/MenuTypes';
import { TMenuItem } from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import { TProfileForm } from '../../lib/forms/ProfileForm';
import { TRKMenu } from '../../../../core/src/db/DbResources';
import { actionRunWithUndoSnackbar } from './ActionHelpers';
import { dispatchWithErrorHandler } from '../ReduxErrorHandlers';

type TActionHostAddMenuItemParams = {
  hostData: THost;
  menuId: TMenuId;
};

export const actionHostAddNewDefaultMenuItem = dispatchWithErrorHandler(({
  hostData,
  menuId,
}: TActionHostAddMenuItemParams): TThunkAction<Promise<TMenuItem>> => {
  return async (dispatch: TDispatch) => {
    return HostBuilder.fromItem(hostData).addMenuItem({
      ...MenuItemBuilder.default,
      menuIds: { [menuId]: menuId },
    });
  };
});

export const actionHostOnProfileUpdate = dispatchWithErrorHandler((hostData: THost, profileForm: TProfileForm): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch, getState: TGetState) => {
    const id = hostData[FIELD_ID];
    Log.v('ScreenHostProfile', 'onHostCustomizationSave', `Host update start for id=${id}`);

    const { rooms } = profileForm;
    await HostBuilder.fromItem(hostData)
      .updateRooms({ rooms });

    const { stations } = profileForm;
    stations != null && await HostBuilder.fromItem(hostData).updateStations({ stations });

    await HostBuilder.fromItem(hostData)
      .updateHostCustomization({ hostCustomizationDataChange: profileForm });

    const { locale } = getState().app;
    Snackbars.enqueueSnackbar({
      content: Strings.hProfileUpdatedSuccessfully()(locale),
      options: { ...SnackbarOptions.globalSuccess({}) },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    });

    Log.v('Host', 'actionHostOnProfileUpdate', `Host update done for id=${id}`);
  };
});

export type TActionHostSecondaryMenuPublishUpdateParams = {
  host: THost;
  secondaryMenuId: TMenuId;
  published: boolean;
};

export const actionHostSecondaryMenuPublishUpdate = dispatchWithErrorHandler(({
  host,
  secondaryMenuId,
  published,
}: TActionHostSecondaryMenuPublishUpdateParams): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    return MenuBuilder.fromItem(host.menu).updateSubMenuPublishStatus({
      menuIdToUpdate: secondaryMenuId,
      newPublishStatus: published,
    });
  };
});

export type TActionHostSecondaryMenuArchiveParams = {
  host: THost;
  secondaryMenuId: TMenuId;
};

export const actionHostSecondaryMenuArchive = dispatchWithErrorHandler(({
  host,
  secondaryMenuId,
}: TActionHostSecondaryMenuArchiveParams): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    const secondaryMenu = _.get(host.menu.subMenus, secondaryMenuId);
    if (!secondaryMenu) {
      throw new Error('Host, actionHostSecondaryMenuArchive, secondaryMenuId not found');
    }

    return dispatch(actionRunWithUndoSnackbar({
      msg: Strings.hMenuDeletedMsg(),

      run: () => {
        return MenuBuilder.fromItem(secondaryMenu).archive();
      },

      undo: (archivedItem: TArchivedMObj<TRKMenu>) => {
        return ArchivedMenuBuilder.fromItem(archivedItem)
          .unArchive();
      },
    }));
  };
});

export type TActionHostUpdateProfileParams = {
  host: THost;
  params: TUpdateProfileParams;
};

export const actionHostUpdateEmail = dispatchWithErrorHandler(({
  host,
  params,
}: TActionHostUpdateProfileParams): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    await Firebase.authUpdateProfile(params);

    const { newEmail } = params;
    newEmail && await HostBuilder.fromItem(host)
      .updateEmail(newEmail);
  };
});
