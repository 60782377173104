import { Log } from '../../config/Instance';
import {
  OptionsObject,
  ProviderContext,
  SnackbarKey,
  SnackbarMessage,
} from 'notistack';
import { SnackbarProps } from '@material-ui/core';

export enum ESnackbarVariant {
  default = 'default',
  success = 'success',
  error = 'error'
}

export type TSnackbar = SnackbarProps & {
  content: SnackbarMessage;
  options: OptionsObject;
};

export class SnackbarsBase {
  private providerContext: ProviderContext | null = null;

  _setProviderContext = (providerContext: ProviderContext) => {
    this.providerContext = providerContext;
  };

  enqueueSnackbar = (snackbar: TSnackbar): SnackbarKey | boolean => {
    const enqueue = this.providerContext && this.providerContext.enqueueSnackbar;
    if (!enqueue) {
      Log.e('Snackbars', 'enqueueSnackbar', 'providerContext.enqueueSnackbar is null');
      return false;
    }
    return enqueue(snackbar.content, snackbar.options);
  };

  dismissByKey = (key?: SnackbarKey) => {
    const close = this.providerContext && this.providerContext.closeSnackbar;
    if (!close) {
      Log.e('Snackbars', 'enqueueSnackbar', 'providerContext.closeSnackbar is null');
      return false;
    }
    return close(key);
  };
}

export const Snackbars = new SnackbarsBase();
