import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useSyncedDataRef, useSyncedEnhancedDataRef } from '../hooks/useSyncedDataRef';
import { Log } from '../config/Instance';
import { makeReffable } from '../../../core/src/lib/HelperFunctions';
import { FirebaseApi } from '../../../core/src/FirebaseApi';

type TSignInWithEmailAndPasswordParams = {
  email: string;
  password: string;
};

const firebaseUserContextInitialBase = {
  user: null as (firebase.User | null),
  setUser: (user: firebase.User | null | undefined): void => undefined,
};

type TFirebaseUserContextProviderProps = {
  //
};

export const FirebaseUserContext = React.createContext(makeReffable({
  ...firebaseUserContextInitialBase,
  signInWithEmailAndPassword: (params: TSignInWithEmailAndPasswordParams) => null as any as Promise<firebase.User | null>,
  authStateReady: false,
}));

export const FirebaseUserContextProvider = (props: PropsWithChildren<TFirebaseUserContextProviderProps>) => {
  const [user, _setUser] = React.useState<firebase.User | null>(null);
  const setUser = React.useCallback((refreshedUser: firebase.User | null | undefined) => {
    if (refreshedUser == null) {
      Log.v('FirebaseUserContext', 'FirebaseUserContextProvider', `fbUser is null, resetting user`);
      return _setUser(null);
    }

    Log.v('FirebaseUserContext', 'FirebaseUserContextProvider', `firebase user is ${refreshedUser.uid}`);
    _setUser(refreshedUser);
  }, [_setUser]);

  const firebaseUserContextBase: typeof firebaseUserContextInitialBase = {
    user,
    setUser,
  };

  const { authStateReady } = useOnLoginStatusChanged(props, firebaseUserContextBase);
  const signInWithEmailAndPassword = useSignInWithEmailAndPassword(props, firebaseUserContextBase);

  const firebaseUserContext = {
    ...firebaseUserContextBase,
    authStateReady,
    signInWithEmailAndPassword,
  };

  const firebaseUserContextRef = useSyncedEnhancedDataRef(firebaseUserContext);
  return (
    <FirebaseUserContext.Provider
      value={firebaseUserContextRef}>
      {props.children}
    </FirebaseUserContext.Provider>
  );
};

/**
 *
 */
function useSignInWithEmailAndPassword(props: TFirebaseUserContextProviderProps, state: typeof firebaseUserContextInitialBase) {
  const stateRef = useSyncedDataRef(state);

  return React.useCallback(async (params: TSignInWithEmailAndPasswordParams) => {
    const fbUser = await FirebaseApi.instance.auth.signInWithEmailAndPassword(
      params.email,
      params.password,
    );

    stateRef.current.setUser(fbUser.user);
    return fbUser.user;
  }, [stateRef]);
}

/**
 *
 */
function useOnLoginStatusChanged(props: TFirebaseUserContextProviderProps, state: typeof firebaseUserContextInitialBase) {
  const stateRef = useSyncedDataRef(state);
  const [authStateReady, setAuthStateReady] = React.useState(false);
  const setAuthStateReadyRef = useSyncedDataRef(setAuthStateReady);

  // On mount check if already logged in
  React.useEffect(() => {
    const dbUser = FirebaseApi.instance.auth.currentUser;
    if (dbUser != null) {
      stateRef.current.setUser(dbUser);
    }
  }, [stateRef]);

  // On mount attach listener to keep state synced
  React.useEffect(() => {
    const unsubscribe = FirebaseApi.instance.auth.onAuthStateChanged((updatedUser) => {
      setAuthStateReadyRef.current(true);
      Log.v(
        'FirebaseUserContext',
        'useOnLoginStatusChanged',
        `onAuthStateChanged ready, user.uid=${updatedUser && updatedUser.uid}`,
      );
      stateRef.current.setUser(updatedUser);
    });

    return () => {
      unsubscribe();
    };
  }, [stateRef]);

  return { authStateReady };
}

