import * as _ from 'lodash';
import { EIngredientType } from '../../models/db/ingredient/IngredientTypes';
import { FIELD_NAME } from '../../db/DbDefs';
import { Host } from '../../models/db/host/Host';
import { Ingredient } from '../../models/db/ingredient/Ingredient';
import { Menu } from '../../models/db/menu/Menu';
import { Num } from '../../lib/formatters/Num';
import { OrderItem } from '../../models/db/orderItem/OrderItem';
import { PrintBuilder } from './PrintBuilder';
import { PrintFormat } from './PrintFormat';
import {
  PrintOps,
  TPrintOp,
} from '../PrintOps';
import { Strings } from '../../locale/Strings';
import { TGuestSession } from '../../models/db/guestSession/GuestSessionTypes';
import { THost } from '../../models/db/host/HostTypes';
import { TOrderItem } from '../../models/db/orderItem/OrderItemTypes';
import { TRoom } from '../../models/db/room/RoomTypes';
import { TStationId } from '../../models/db/station/StationTypes';
import { TTable } from '../../models/db/table/TableTypes';
import {
  addIfTrueArr,
  truncate,
} from '../../lib/HelperFunctions';
import { localize } from '../../lib/formatters/LocaleLib';
import { GuestSessionBuilder } from '../../models/db/guestSession/GuestSessionBuilder';

type TPrintBuilderForStationOrderParams = {
  host: THost;
  guestSession: TGuestSession;
  orderItemsToPrint: TOrderItem[];
  numberOfGuests: number;
  discountNum: number;
  destinationStationId: TStationId;
};

export class PrintBuilderForGuestSessionAccount extends PrintBuilder {
  private readonly guestSession: TGuestSession;

  private readonly numberOfGuests: number;

  private readonly discountNum: number;

  private readonly orderItemsToPrint: TOrderItem[];

  private readonly table: TTable;

  private readonly room: TRoom;

  constructor(params: TPrintBuilderForStationOrderParams) {
    super(params);
    this.guestSession = params.guestSession;
    this.numberOfGuests = params.numberOfGuests;
    this.discountNum = params.discountNum;
    this.orderItemsToPrint = params.orderItemsToPrint;

    const { tableId } = GuestSessionBuilder.getPathBuilderParamsFromDataPath(this.guestSession);
    this.table = Host.findTableByIdOrThrow(this.host, tableId);

    this.room = Host.findRoomWithTableOrThrow(this.host, this.table.roomId);
  }

  readonly buildPrintOps = (): TPrintOp[] => {
    return _.flattenDeep([
      this.buildHeader(),
      this.buildSubHeader(),
      PrintOps.printOpNewLine(3),
      this.buildTableInfo(),
      PrintOps.printOpNewLine(1),
      this.buildOrderItems(),
      PrintOps.printOpDrawLine(),
      this.buildFooter(),
    ]);
  };

  readonly buildHeader = (): TPrintOp[] => {
    return _.flattenDeep([
      PrintOps.printOpAlign('CT'),
      PrintFormat.bold(`${this.host[FIELD_NAME]}`.toUpperCase()),
      PrintOps.printOpAlign('LT'),
    ]);
  };

  readonly buildSubHeader = (): TPrintOp[] => {
    const denomination = (this.host.denomination || '').toUpperCase();
    const address = (this.host.address || '').toUpperCase();
    const phone = (this.host.phone || '').toUpperCase();
    const vatId = (this.host.vatId || '').toUpperCase();

    return _.flattenDeep([
      PrintOps.printOpAlign('CT'),
      ...addIfTrueArr(!_.isEmpty(denomination), PrintOps.printOpText(denomination)),
      ...addIfTrueArr(!_.isEmpty(address), PrintOps.printOpText(address)),
      ...addIfTrueArr(
        !_.isEmpty(phone),
        PrintOps.printOpText(`${Strings.hFiscalPrintPhone()(this.locale)}: ${phone}`),
      ),
      ...addIfTrueArr(
        !_.isEmpty(vatId),
        PrintOps.printOpText(`${Strings.hFiscalPrintVat()(this.locale)}: ${vatId}`),
      ),
      PrintOps.printOpAlign('LT'),
    ]);
  };

  readonly buildFooter = (): TPrintOp[] => {
    const { totalWithDiscount } = Host.getPriceInfoForOrderItems(
      this.host,
      this.orderItemsToPrint,
      this.numberOfGuests,
      this.discountNum,
    );
    return _.flattenDeep([
      ...addIfTrueArr<TPrintOp[]>(this.discountNum > 0, [

        ...this.buildKeyValueTable({
          key: Strings.printerDiscount()(this.locale),
          value: `${Num.format(this.discountNum)}`,
        }),
        PrintOps.printOpDrawLine(),
      ]),

      ...this.buildKeyValueTableBold({
        key: Strings.printerTotalWithCurrency()(this.locale).toUpperCase(),
        value: `${Num.format(totalWithDiscount)}`,
      }),
      PrintOps.printOpNewLine(),
      PrintOps.printOpAlign('CT'),
      PrintOps.printOpText(Strings.printerGuestSessionAccountFooterInto()(this.locale).toUpperCase()),
      PrintOps.printOpAlign('LT'),
      PrintOps.printOpNewLine(3),
      PrintOps.printOpText(this.strTimeNow()),
    ]);
  };

  readonly buildTableInfo = (): TPrintOp[] => {
    const secondaryMenu = Menu.getSecondaryMenu(this.host.menu);
    if (!secondaryMenu) {
      throw new Error([
        'PrintBuilderForGuestSessionAccount',
        'buildTableInfo',
        'build should not be null',
      ].join(', '));
    }

    const { coverCharge } = secondaryMenu;
    return _.flattenDeep([
      PrintOps.printOpText(this.strHostTableInfo(this.table[FIELD_NAME], this.room[FIELD_NAME])),
      PrintOps.printOpNewLine(1),

      ...this.buildKeyValueTable({
        key: `${this.numberOfGuests}x ${Strings.printerCoverCharge()(this.locale)}`,
        value: `${Num.format(this.numberOfGuests * coverCharge)}`,
      }),
    ]);
  };

  readonly buildOrderItems = (): TPrintOp[] => {
    const groupedByEquality = OrderItem.groupByEquality(this.orderItemsToPrint);
    return _.flattenDeep(groupedByEquality.map((orderItemsInGroup): TPrintOp[] => {
      const count = orderItemsInGroup.length;
      if (count <= 0) {
        return [];
      }

      const orderItem = orderItemsInGroup[0];
      const name = truncate(`${localize(orderItem, FIELD_NAME, this.locale)}`.trim(), 30);
      return _.flattenDeep([
        this.buildKeyValueTable({
          key: `${count}x ${name}`,
          value: `${Num.format(OrderItem.getTotalPrice(orderItem) * count)}`.trim(),
          left: 0.80,
        }),

        ..._.values(orderItem.ingredients)
          .filter(Ingredient.filterType(EIngredientType.ADDABLE))
          .sort()
          .map((ingredient) => this.printIngredient(ingredient)),

        ..._.values(orderItem.ingredients)
          .filter(Ingredient.filterType(EIngredientType.REMOVABLE))
          .sort()
          .map((ingredient) => this.printIngredient(ingredient)),

        ..._.values(orderItem.ingredients)
          .filter(Ingredient.filterType(EIngredientType.OPTION))
          .sort()
          .map((ingredient) => this.printIngredient(ingredient)),
      ]);
    }));
  };
}
