import * as React from 'react'
import * as _ from 'lodash'
import { BubbleMenu, EditorContent, mergeAttributes, useEditor } from '@tiptap/react'
import { Link } from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit'
import styled, { css } from "styled-components";
import { Placeholder } from '@tiptap/extension-placeholder';
import { MdFormatBold, MdFormatItalic } from "react-icons/md";
import { htmlDefaultStyles } from "./htmlComponentsDefaults";
import Paragraph from '@tiptap/extension-paragraph';

type TTipTapTextAreaProps = {
  initialHtmlContent: string;
  editable: boolean;
  placeholder?: string
  onBlur?: (event: any) => void;
  onChange?: (content: string) => void;
}

export const TipTapTextArea = ({
                                 initialHtmlContent,
                                 editable,
                                 placeholder,
                                 onBlur,
                                 onChange
                               }: TTipTapTextAreaProps) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({ paragraph: false }),
      Paragraph.extend({
        parseHTML() {
          return [{ tag: 'span' }]
        },
        renderHTML({ HTMLAttributes }) {
          return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
        },
      }),
      Link,
      Placeholder.configure({
        placeholder,
      }),
    ],
    onBlur: ({ event }) => {
      return onBlur?.(event);
    },
    onUpdate: ({ editor }) => {
      if (onChange) {
        const text = editor.getHTML();
        return onChange?.(text);
      }
    },
    content: initialHtmlContent,
    editable,
  })

  if (!editor) {
    return null;
  }

  return (
    <TipTapTextAreaView editor={editor}/>
  )
}

type TTipTapTextAreaViewProps = {
  editor: NonNullable<ReturnType<typeof useEditor>>;
}

const TipTapTextAreaView = ({ editor }: TTipTapTextAreaViewProps) => {
  const [manuallyInputtedLink, setManuallyInputtedLink] = React.useState('');

  const currentSelectionHref = editor.getAttributes('link').href;
  React.useEffect(() => {
    setManuallyInputtedLink(currentSelectionHref || '');
  }, [currentSelectionHref]);

  const handleLinkInputBlur = React.useCallback(() => {
    setManuallyInputtedLink('');

    if (_.isEmpty(manuallyInputtedLink)) {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
      return;
    }

    if (!/^https?:\/\//.test(manuallyInputtedLink ?? '')) {
      // Not a valid link, abort
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
      return;
    }

    // set link
    editor.chain().focus().extendMarkRange('link').setLink({ href: manuallyInputtedLink }).run()

  }, [editor, manuallyInputtedLink, setManuallyInputtedLink]);

  return (
    <Root editable={editor.isEditable}>
      <StyledBubbleMenu tippyOptions={tippyOptions} editor={editor}>
        <MenuButton
          isActive={editor.isActive('bold')}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <MdFormatBold
            color={iconColor}
            size={iconSize}
          />
        </MenuButton>
        <MenuButton
          isActive={editor.isActive('italic')}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <MdFormatItalic
            color={iconColor}
            size={iconSize}
          />
        </MenuButton>
        <LinkInput
          value={manuallyInputtedLink}
          onChange={(e) => setManuallyInputtedLink(e.target.value)}
          placeholder={'https://www.alacartemenu.it'}
          onBlur={handleLinkInputBlur}
          onKeyPress={(e) =>{
            if (e.code === 'Enter') {
              handleLinkInputBlur();
              editor.commands.setTextSelection(editor.state.selection.from)
            }
          }}
        />
      </StyledBubbleMenu>
      <EditorContent editor={editor}/>
    </Root>
  )
}

const iconSize = 24;
const iconColor = 'white';

const tippyOptions = { duration: 100 };

const Root = styled.div<{ editable: boolean }>`
  min-height: 120px;
  width: 100%;
  padding: 15px;
  color: ${({ theme, editable }) => (!editable ? theme.t.col.accentLight : theme.t.col.black)};

  .ProseMirror {
    min-height: 120px;
    width: 100%;
    border: none;
    background: none;
    flex: 1;
    outline: none;
    ${htmlDefaultStyles}
  }

  .ProseMirror p.is-editor-empty:first-child::before {
    color: ${({ theme }) => theme.t.col.accentLight};
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
`

const StyledBubbleMenu = styled(BubbleMenu)`
  display: flex;
  background-color: #0D0D0D;
  padding: 0.2rem;
  border-radius: 0.5rem;
`

const cssMenuItem = css`
  padding: 0 0.2rem;
`

const MenuButton = styled.button<{ isActive: boolean }>`
  border: none;
  background: none;
  color: #FFF;
  font-size: 0.85rem;
  font-weight: 500;
  ${cssMenuItem};
  opacity: ${({ isActive }) => isActive ? 1 : 0.6};

  :hover {
    opacity: 1;
  }
`

const LinkInput = styled.input`
  border: none;
  background: none;
  font-size: 0.85rem;
  font-weight: 500;
  width: 180px;
  color: ${({ theme }) => theme.t.col.white};
  outline: none;
  ${cssMenuItem};

  &::placeholder {
    color: ${({ theme }) => theme.t.col.accentLight};
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
  }
`
