import * as _ from 'lodash';
import { CIInterface } from '../../config/CIInterface';
import { EPrintAdapter } from '../../printer/PrintAdapters';
import {
  EStationType,
  TStation,
  TStationUpdate,
} from '../db/station/StationTypes';
import {
  FIELD_EDITABLE,
  FIELD_ID,
  FIELD_NAME,
} from '../../db/DbDefs';
import { KnownError } from '../../lib/error/KnownError';
import { Validator } from '../../lib/error/Validators';
import { buildObjValidator } from '../../lib/error/lib/buildObjValidator';
import { enumToArray } from '../../lib/HelperFunctions';

const validateStationId = Validator.applyAll([
  Validator.expectSet,
  Validator.expectUUID,
]);

const validateName = Validator.applyAll([
  Validator.expectSet,
  Validator.expectStrOfLen({
    min: CIInterface.validateStationNameMinLen,
    max: CIInterface.validateStationNameMaxLen,
  }),
]);

const validateEditable = Validator.applyAll([
  Validator.expectSet,
  Validator.expectBool,
]);

const validateType = Validator.applyAll([
  Validator.expectSet,
  Validator.expectOneValueOf({ allowedValues: enumToArray(EStationType) }),
]);

const validateAdapter = Validator.applyAll([
  Validator.expectSet,
  Validator.applyOne([
    Validator.applyAll([
      Validator.expectSet,
      function validateAdapterConsole(value) {
        if (_.get(value, 'name') !== EPrintAdapter.USB) {
          return [Validator.make(KnownError.validationInvalidType)];
        }

        const vid = _.get(value, 'params.vid');
        const pid = _.get(value, 'params.pid');
        if (!vid && !pid) {
          return [Validator.make(KnownError.validationInvalidType)];
        }

        return [];
      },
    ]),

    Validator.applyAll([
      Validator.expectSet,
      function validateAdapterConsole(value) {
        if (_.get(value, 'name') !== EPrintAdapter.Network) {
          return [Validator.make(KnownError.validationInvalidType)];
        }

        const address = _.get(value, 'params.address');
        if (!address) {
          return [Validator.make(KnownError.validationInvalidType)];
        }

        return [];
      },
    ]),

    Validator.applyAll([
      Validator.expectSet,
      function validateAdapterConsole(value) {
        if (_.get(value, 'name') !== EPrintAdapter.Serial) {
          return [Validator.make(KnownError.validationInvalidType)];
        }

        const port = _.get(value, 'params.port');
        if (!port) {
          return [Validator.make(KnownError.validationInvalidType)];
        }

        return [];
      },
    ]),

    Validator.applyAll([
      Validator.expectSet,
      function validateAdapterConsole(value) {
        if (_.get(value, 'name') !== EPrintAdapter.Console) {
          return [Validator.make(KnownError.validationInvalidType)];
        }
        return [];
      },
    ]),
  ]),
]);

export const validateStationUpdater = buildObjValidator<TStationUpdate>({
  [FIELD_NAME]: validateName,
  [FIELD_EDITABLE]: validateEditable,
  type: validateType,
  adapter: validateAdapter,
});

export const validateStation = buildObjValidator<TStation>({
  [FIELD_ID]: validateStationId,
  [FIELD_NAME]: validateName,
  [FIELD_EDITABLE]: validateEditable,
  type: validateType,
  adapter: validateAdapter,
});
