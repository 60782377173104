import { CIInterface } from '../../config/CIInterface';
import {
  EIngredientType,
  TIngredient,
} from '../db/ingredient/IngredientTypes';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../db/DbDefs';
import { TMObjDataCreator } from '../../lib/model/ModelTypes';
import { TRKIngredient } from '../../db/DbResources';
import { Validator } from '../../lib/error/Validators';
import { buildObjValidator } from '../../lib/error/lib/buildObjValidator';
import { enumToArray } from '../../lib/HelperFunctions';
import { ELocale } from '../../locale/Locale';
import {TLanguage} from '../../locale/Languages';

const validateIngredientId = Validator.applyAll<string>([
  Validator.expectSet,
  Validator.expectUUID,
]);

const validateIngredientName = Validator.applyAll<string>([
  Validator.expectSet,
  Validator.expectStrOfLen({
    min: CIInterface.validateIngredientNameMinLen,
    max: CIInterface.validateIngredientNameMaxLen,
  }),
]);

const validateIngredientPrice = Validator.applyAll<number>([
  Validator.expectSet,
  Validator.expectNumInRange({
    min: CIInterface.validateIngredientPriceMin,
    max: CIInterface.validateIngredientPriceMax,
  }),
]);

const validateIngredientType = Validator.applyAll<EIngredientType>([
  Validator.expectSet,
  Validator.expectOneValueOf({ allowedValues: enumToArray(EIngredientType) }),
]);

const validateDefaultLanguage = Validator.applyAll<TLanguage>([
  Validator.expectLanguage,
]);

export const validateIngredientCreator = buildObjValidator<TMObjDataCreator<TRKIngredient>>({
  [FIELD_NAME]: validateIngredientName,
  price: validateIngredientPrice,
  type: validateIngredientType,
  defaultLocale: validateDefaultLanguage,
});

export const validateIngredient = buildObjValidator<TIngredient>({
  [FIELD_ID]: validateIngredientId,
  [FIELD_NAME]: validateIngredientName,
  price: validateIngredientPrice,
  type: validateIngredientType,
  defaultLocale: validateDefaultLanguage,
});
