import {
  TUseFormField,
  useFormField,
} from './useFormField';

export function useFormBooleanField(params: Partial<TUseFormField<boolean>> = {}) {
  return useFormField<boolean>({
    value: false,
    ...params,
  });
}
