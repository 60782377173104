import * as React from 'react';
import { Input } from './FieldInputListItem';
import { useTargetValue } from '../../../../../lib-react/src/hooks/useTargetValue';

export type TSecondaryItemInputRendererProps = {
  secondaryTextRef: React.RefObject<HTMLInputElement>;
  readOnly: boolean;
  onItemSecondaryChange: (event: any) => any;
  secondaryTextOnEnterPress: (event: any) => void;
  secondaryTextOnBlur: () => void;
  secondaryText?: string | undefined;
  secondaryPlaceholder?: string | undefined;
};

export function buildSecondaryItemInputDefaultRenderer() {
  return (props: TSecondaryItemInputRendererProps) => {
    const onChange = useTargetValue((value: string) => {
      return props.onItemSecondaryChange?.(value);
    });

    return (
      <Input
        ref={props.secondaryTextRef}
        readOnly={props.readOnly}
        value={props.secondaryText}
        onChange={onChange}
        onKeyPress={props.secondaryTextOnEnterPress}
        onBlur={props.secondaryTextOnBlur}
        placeholder={props.secondaryPlaceholder}
      />
    );
  };
}
