import * as React from 'react';
import { Hist } from '../../lib/navigation/History';
import { TBuiltRouteDef } from '../../lib/navigation/Routes';

type TRedirectProps<T> = {
  route: TBuiltRouteDef<T>;
  params: T;
};

export function Re2direct<T>({
  route,
  params,
}: TRedirectProps<T>) {
  React.useEffect(() => {
    Hist.replace(route, params);
  }, []);

  return (
    <></>
  );
}
