import * as React from 'react';
import {
  Hist,
  TNavContext,
} from './History';
import { History } from 'history';
import { TBuiltRouteDef } from './Routes';
import { useLocation } from 'react-router-dom';
import { valuesOfObjSortedByKeys } from '../../../../core/src/lib/HelperFunctions';

function useBuildPushToRoute<Params>(route: TBuiltRouteDef<Params>) {
  return React.useCallback((params: Params & TNavContext) => {
    Hist.push(route, params);
  }, []);
}

export function usePushToRoute<Params>(route: TBuiltRouteDef<Params>, params: Params & TNavContext) {
  const pushToRoute = useBuildPushToRoute(route);
  return React.useCallback(() => {
    pushToRoute(params);
  }, [pushToRoute, ...valuesOfObjSortedByKeys(params)]);
}

export function useIsRouteSelected(route: TBuiltRouteDef<any>) {
  const { pathname } = useLocation();
  return route.isRouteSelected(pathname);
}

export function useNavigateBack() {
  return React.useCallback(() => {
    Hist.goBack();
  }, []);
}

export function getNavParam<T, Key extends keyof T>(props: TScreenNavigationProps<T>, name: Key): T[Key] {
  // @ts-ignore
  return props.match.params[name];
}

export type TScreenNavigationProps<Props = any> = {
  history: History;
};
