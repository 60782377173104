import {
  DbRes,
  TRKHostMessage,
} from '../../../../core/src/db/DbResources';
import { Firebase } from '../../lib/Firebase';
import { Log } from '../../config/Instance';
import { SrvFirebaseSync } from '../../../../core/src/lib/apis/SrvFirebaseSync';
import { TArchivedMObj } from '../../../../core/src/models/db/archivedMObj/ArchivedMObjTypes';
import { THostId } from '../../../../core/src/models/db/host/HostTypes';
import { actions } from '../Actions';
import { nowMs } from '../../../../core/src/lib/HelperFunctions';
import { put } from 'redux-saga/effects';

export function* sagaFetchArchivedHostMessages({ hostId }: { hostId: THostId }) {
  try {
    const user = Firebase.getCurrentUser();
    if (!user) {
      Log.v(
        'sagaFetchArchivedHostMessages',
        'sagaFetchArchivedHostMessages',
        'Firebase user not set, returning',
      );
      return;
    }

    const tokenId: string = yield user.getIdToken();
    if (!tokenId) {
      Log.v(
        'sagaFetchArchivedHostMessages',
        'sagaFetchArchivedHostMessages',
        'Firebase could not get tokenId, returning',
      );
      return;
    }

    const oneWeekMs = 6.048e+8;
    const archivedMessages: TArchivedMObj<TRKHostMessage>[] = yield SrvFirebaseSync.getArchivedResource(
      tokenId,
      {
        resource: DbRes.HostMessage,
        ownerUid: hostId,
        toMs: nowMs(),
        fromMs: nowMs() - oneWeekMs,
      },
    );

    yield put(actions.actionTypeHostMessagesAddMessages(({
      hostMessages: archivedMessages
        .map(({ archivedItem }) => archivedItem),
    })));
  } catch (e) {
    Log.e(
      'sagaFetchArchivedHostMessages',
      'sagaFetchArchivedHostMessages',
      `Error, ${e.message}`,
    );
  }
}
