import * as React from 'react';
import { FIELD_NAME } from '../../../../core/src/db/DbDefs';
import {
  LabeledFieldCurrencyInput,
  LabeledFieldPickableSortableList,
  LabeledFieldTextInput,
} from '../form/LabeledFields';
import { MenuFormCardTitle, MenuMainFormCardFrame, } from './MenuMainFormCardFrame';
import { SquareButton } from '../Button/SquareButton';
import { Strings } from '../../../../core/src/locale/Strings';
import { TMenuForm, useMenuFormHandler, } from '../../lib/forms/MenuForm';
import styled from 'styled-components';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { ELocale, ELOCALE_GLOBAL_DEFAULT } from '../../../../core/src/locale/Locale';
import {
  ELANGUAGE_GLOBAL_DEFAULT,
  LanguageCodes,
  LanguageList, Languages,
  TLanguage, TLanguageObject
} from '../../../../core/src/locale/Languages';
import { DEFAULT_CURRENCY } from '../../../../core/src/locale/Currencies';

type TMenuMainFormCardCreateProps = {
  locale: ELocale;
  hostData: THost,
  index: number;
  onCreate: (data: TMenuForm) => Promise<any>;
};

export const MenuMainFormCardCreate = (props: TMenuMainFormCardCreateProps) => {
  const {
    locale,
    index,
    hostData,
    onCreate,
  } = props;
  const formHandler = useMenuFormHandler({
    minLocales: 1,
    menu: {
      [FIELD_NAME]: '',
      defaultLocale: ELANGUAGE_GLOBAL_DEFAULT,
      coverCharge: 0,
    },
    onSubmit: onCreate,
  });

  const selectableLanguages = LanguageCodes
    .filter((lang) => !formHandler.fields.locales.value.includes(lang));

  return (
    <MenuMainFormCardFrame
      title={(
        <TitleContainer>
          {Strings.hMenuBlankCover()(locale)}
        </TitleContainer>
      )}
      contentItems={[
        (
          <LabeledFieldTextInput
            key={'0'}
            label={Strings.hMenuName()(locale)}
            value={formHandler.fields.name.value}
            onChange={formHandler.fields.name.setValue}
            error={formHandler.fields.name.errorStr}
          />
        ),
        (
          <LabeledFieldCurrencyInput
            key={'1'}
            locale={locale}
            currency={hostData.currency ?? DEFAULT_CURRENCY}
            isRequired
            value={formHandler.fields.coverCharge.valueStr}
            onChange={formHandler.fields.coverCharge.setValue}
            error={formHandler.fields.coverCharge.errorStr}
            placeholder={`${formHandler.fields.coverCharge.initialValue}`}
            label={Strings.hMenuPlaceCharge()(locale)}
          />
        ),
        (
          <LabeledFieldPickableSortableList<TLanguage>
            key={'2'}
            componentId={`menuFormCardCreate-${index}`}
            label={Strings.hMenuLanguage()(locale)}
            value={formHandler.fields.locales.value}
            onChange={formHandler.fields.locales.setValue}
            mapListItemToValue={(listItem) => {
              return listItem.id as TLanguage;
              // return LanguageList.find((language) => {
              //   return language.name == listItem.mainText;
              // }) || ELOCALE_GLOBAL_DEFAULT;
            }}
            mapValueToListItem={(value: TLanguage) => {
              const lang: TLanguageObject = Languages[value];
              const emojiLength = 4;
              const maxEmojis = 3;
              const emojis = lang.emojis.length > maxEmojis * emojiLength ? lang.emoji : lang.emojis;
              const localeName = `${lang.language} ${emojis}`;
              return {
                id: value,
                mainText: `${localeName}`,
                actionable: true,
              };
            }}
            newItem={formHandler.fields.locales.newItem.value}
            newItemSet={formHandler.fields.locales.newItem.setValue}
            newItemReset={formHandler.fields.locales.newItem.reset}
            mapNewValueToListItem={(value) => {
              return {
                id: 'new-value',
                mainText: value,
                actionable: true,
              };
            }}
            newItemMainPlaceholder={Strings.hMenuLanguageDdl()(locale)}
            selectableItems={selectableLanguages}
          />
        ),
        (
          <StyledSquareButton
            key={'3'}
            title={Strings.hMenuCreateCmd()(locale)}
            onClick={formHandler.onSubmit}
            loading={formHandler.loading}
            disabled={formHandler.submitDisabled}
          />
        ),
      ]}
    />
  );
};

const TitleContainer = styled(MenuFormCardTitle)`
  background-color: ${({ theme }) => theme.t.col.aLaCarteTheme};
  color: ${({ theme }) => theme.t.col.accentDark};
`;

const StyledSquareButton = styled(SquareButton)`
  background-color: ${({ theme }) => theme.t.col.red};
  color: ${({ theme }) => theme.t.col.white};
`;
