import * as React from 'react';
import * as _ from 'lodash';
import {
  FIELD_ID,
  FIELD_NAME,
  TObjList,
} from '../../../../core/src/db/DbDefs';
import { Host } from '../../../../core/src/models/db/host/Host';
import { HostTableGridItem } from './HostTableGridItem';
import { Strings } from '../../../../core/src/locale/Strings';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import {
  TRoom,
  TRoomId,
} from '../../../../core/src/models/db/room/RoomTypes';
import {
  TTable,
  TTableId,
} from '../../../../core/src/models/db/table/TableTypes';
import { dbItemSortableItemSort } from '../../../../core/src/db/DbLib';
import styled from 'styled-components';
import { THostPublicSubscription } from '../../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';
import { ELocale } from '../../../../core/src/locale/Locale';

type THostRoomGridProps = {
  locale: ELocale;
  host: THost;
  hostGuestSessions: TObjList<TTableId, TGuestSession>;
  hostPublicSubscription: THostPublicSubscription;
  onTableClick: (table: TTable) => void;
  onInternalNoteChange: (table: TTable, note: string) => void;
  onChangeTableRoom: (tableId: TTableId, newRoomId: TRoomId) => Promise<void>;
};

export const HostRoomGrid = ({
  locale,
  host,
  hostGuestSessions,
  hostPublicSubscription,
  onTableClick,
  onInternalNoteChange,
}: THostRoomGridProps) => {
  const renderTable = React.useCallback((table: TTable) => (
    <HostTableGridItem
      key={table[FIELD_ID]}
      locale={locale}
      table={table}
      guestSessionData={_.get(hostGuestSessions, table[FIELD_ID])}
      onTableClick={onTableClick}
      onInternalNoteChange={onInternalNoteChange}
      hostPublicSubscription={hostPublicSubscription}
    />
  ), [locale, hostGuestSessions, hostPublicSubscription, onTableClick, onInternalNoteChange]);

  const tablesWithoutRoom = Host.getTablesWithoutRoom(host);
  return (
    <Root>
      <RoomsWrapper>
        {_.values(host.rooms)
          .sort(dbItemSortableItemSort)
          .map((room: TRoom) => (
            <HostRoomGridRoom
              key={room[FIELD_ID]}
              id={room[FIELD_ID]}
              name={room[FIELD_NAME]}
              tables={Host.getTablesInRoom(host, room[FIELD_ID])}
              renderTable={renderTable}
            />
          ))}
        {tablesWithoutRoom.length > 0 && (
          <HostRoomGridRoom
            id={'HostRoomGrid-unknown-room-tables'}
            name={Strings.hTableRoomsUnknown()(locale)}
            tables={tablesWithoutRoom}
            renderTable={renderTable}
          />
        )}
      </RoomsWrapper>
    </Root>
  );
};

type THostRoomGridRoomProps = {
  id: string;
  name: string;
  tables: TTable[];
  renderTable: (table: TTable) => React.ReactNode;
};

const HostRoomGridRoom = ({
  id,
  name,
  tables,
  renderTable,
}: THostRoomGridRoomProps) => (
  <Room key={id}>
    <RoomContainer>
      <RoomHeader>
        <RoomTitle>{name}</RoomTitle>
        <RoomHairline/>
      </RoomHeader>
      <DroppableRoot>
        {tables.map(renderTable)}
      </DroppableRoot>
    </RoomContainer>
  </Room>
);

const hostRoomGridMaxWidth = 1000;

const Root = styled.div`
`;

const RoomsWrapper = styled.div`
  & > *:not(:first-child) {
    margin-top: 20px;
  }
`;

const Room = styled.div`
`;

const RoomHeader = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
`;

const RoomTitle = styled.span`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-family: Nunito Sans;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 20px;
`;

const RoomHairline = styled.span`
  background-color: ${({ theme }) => theme.t.col.lightGray1};
  flex: 1;
  height: 1px;
  margin-bottom: 4px;
  margin-left: 6px;
`;

const RoomContainer = styled.div`
`;

const DroppableRoot = styled.div`
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 12px;
  max-width: 1000px;
  max-width: ${hostRoomGridMaxWidth}px;
  row-gap: 20px;
  width: 100%;
`;
