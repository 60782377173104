import * as React from 'react';
import * as _ from 'lodash';
import { useEventValueCallback } from '../../../../lib-react/src/hooks/useEventValueCallback';
import styled from 'styled-components';
import { TipTapTextArea } from "../../lib-components/TipTapTextArea";

export type TFieldTextAreaProps = {
  value: string;
  placeholder?: string;
  error?: string;
  onChange?: (newValue: string) => void;
  readOnly?: boolean;
  className?: string;
  showErrorOnlyBlur?: boolean;
};

const FieldHTMLAreaBase = ({
                             value,
                             placeholder,
                             error,
                             className,
                             onChange = _.noop,
                             readOnly = false,
                             showErrorOnlyBlur = true,
                           }: TFieldTextAreaProps) => {
  const [showError, setShowError] = React.useState<boolean>();
  const onBlur = React.useCallback(() => setShowError(true), [setShowError]);

  const usedError = (!showErrorOnlyBlur || showError)
    ? error
    : undefined;

  return (
    <Root className={className}>
      <Container hasError={usedError != null}>
        <TipTapTextArea
          initialHtmlContent={value}
          placeholder={placeholder}
          editable={!readOnly}
          onBlur={onBlur}
          onChange={onChange}
        />
      </Container>
      {!_.isEmpty(usedError) && (
        <FixedErrorContainer>
          <Error>{usedError}</Error>
        </FixedErrorContainer>
      )}
    </Root>
  );
};

export const FieldHTMLArea = React.memo(FieldHTMLAreaBase) as typeof FieldHTMLAreaBase;

const Root = styled.div`
  width: 100%;
`;

type TContainerProps = {
  hasError: boolean;
};

const Container = styled.div<TContainerProps>`
  align-items: center;
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.t.col.red : theme.t.col.accentLight)};
  border-radius: 1px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 19px;
  min-height: 50px;
`;

const FixedErrorContainer = styled.div`
  font-weight: normal;
  height: 20px;
  text-align: right;
  text-transform: none;
  width: 100%;
`;

const Error = styled.span`
  color: ${({ theme }) => theme.t.col.red};
  font-size: 12px;
`;
