import * as React from 'react';
import * as _ from 'lodash';
import { selectNotEmpty } from '../../../../core/src/lib/HelperFunctions';
import { useGetPlacesSuggestions } from './useGetPlacesSuggestions';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';

export type TPlaceSuggestion = {
  name: string;
  address: string;
  placeId: string;
};

type TUsePlacesSuggestions = {
  bindedSearch: string;
};

export function usePlacesSuggestions({ bindedSearch }: TUsePlacesSuggestions) {
  const [suggestions, setSuggestions] = React.useState<TPlaceSuggestion[]>([]);

  const getPlacesSuggestions = useGetPlacesSuggestions();
  const getPlacesSuggestionsRef = useSyncedDataRef(getPlacesSuggestions);

  const run = React.useCallback(async () => {
    const result = await getPlacesSuggestionsRef.current(bindedSearch);
    const mappedSuggestions = (result || []).map((suggestion) => ({
      name: selectNotEmpty([
        _.get(suggestion, 'structured_formatting.main_text', ''),
        _.get(suggestion, 'terms[0]', ''),
      ], suggestion.description),
      address: selectNotEmpty([
        _.get(suggestion, 'structured_formatting.secondary_text', ''),
        _.get(suggestion, 'terms', []).splice(1).join(', '),
      ], suggestion.description),
      placeId: suggestion.place_id,
    }));

    setSuggestions(mappedSuggestions as TPlaceSuggestion[]);
  }, [bindedSearch, setSuggestions]);

  // Run on mount and when search changes
  React.useEffect(() => {
    run();
  }, [bindedSearch]);

  return suggestions;
}
