import styled from 'styled-components';

export const GuestSessionOverviewCalculatorHeader = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.t.col.accentDark};
  border-radius: 2px 2px 0px 0px;
  display: flex;
  flex-direction: row;
  height: 54px;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
`;
