import * as React from 'react';
import {
  PopoverWithClickAway,
  TPopoverTopWithClickAwayProps,
} from './PopoverWithClickAway';
import { SquareButton } from '../Button/SquareButton';
import { useToggle } from '../../../../lib-react/src/hooks/useToggle';
import styled from 'styled-components';

type TPopoverConfirmationProps =
  Omit<TPopoverTopWithClickAwayProps, 'popoverOpen' | 'closePopover' | 'openPopover' | 'popoverContent'>
  & {
    buttonText: string;
    buttonOnClick: () => void;
    confirmationMessage: string;
  };

export const PopoverConfirmation = ({
  buttonText,
  buttonOnClick,
  confirmationMessage,
  children,
  ...popoverProps
}: TPopoverConfirmationProps) => {
  const [popoverOpen, , openPopover, closePopover] = useToggle(false);

  const onActionClick = React.useCallback(() => {
    buttonOnClick();
    closePopover();
  }, [buttonOnClick, closePopover]);

  return (
    <PopoverWithClickAway
      popoverOpen={popoverOpen}
      openPopover={openPopover}
      closePopover={closePopover}
      popoverContent={(
        <PopoverContent>
          <span>{confirmationMessage}</span>
          <SquareButton
            title={buttonText}
            onClick={onActionClick}
          />
        </PopoverContent>
      )}
      {...popoverProps}>
      {children}
    </PopoverWithClickAway>
  );
};

const PopoverContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 10px;
  min-width: 155px;
  & > span {
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    margin: 5px auto 15px;
    text-align: center;
  }
`;
