import * as React from 'react';
import styled from 'styled-components';

export type THairlineProps = {
  className?: string;
};

export const Hairline = ({ className }: THairlineProps) => (
  <StyledHairline className={className}/>
);

const StyledHairline = styled.div`
  background: ${({ theme }) => theme.t.col.accentLight};
  height: 1px;
  opacity: 0.2;
  width: 100%;
`;
