import * as React from 'react';
import { useEnterKeyDown } from '../useKeyDownListener';
import { useSyncedDataRef } from '../useSyncedDataRef';

type TUseTriggerOnBlurOrEnterParams = {
  triggerOnBlur: boolean;
  onTrigger: () => any;
};

export function useTriggerOnBlurOrEnter({
  triggerOnBlur,
  onTrigger,
}: TUseTriggerOnBlurOrEnterParams) {
  const triggerOnBlurRef = useSyncedDataRef(triggerOnBlur);

  const wasBluredByEnter = React.useRef(false);
  const itemRef = React.createRef<HTMLInputElement>();

  const onBlur = React.useCallback(() => {
    if (!triggerOnBlurRef.current && !wasBluredByEnter.current) {
      return;
    }

    onTrigger();
    wasBluredByEnter.current = false;
  }, [onTrigger]);

  // On enter press blurs because
  // If we were to directly call triggerOnBlur
  // Then the callback would be fired again if the trigger
  // causes the item to blur
  const onEnterPress = useEnterKeyDown(() => {
    wasBluredByEnter.current = true;
    // @ts-ignore
    itemRef.current && itemRef.current.blur();
  });

  return {
    onBlur,
    onEnterPress,
    itemRef,
  };
}
