import * as React from 'react';
import { ReactComponent as CheckmarkEmptyIcon } from '../../assets/checkmark-empty.svg';
import { ReactComponent as CheckmarkFullIcon } from '../../assets/checkmark-filled.svg';
import { IconButton } from '../Button/IconButton';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';
import styled from 'styled-components';

export type TFieldSingleListItemSelectListItem = {
  mainText: string;
};

export type TFieldSingleListItemSelectProps<T> = {
  value: T;
  onChange: (value: T) => void;
  mapValueToListItem: (value: T) => TFieldSingleListItemSelectListItem;
  mapListItemToValue: (listItem: TFieldSingleListItemSelectListItem) => T;
  items: T[];
  className?: string;
};

function FieldSingleListItemSelectBase<T>({
  value,
  onChange,
  items,
  mapValueToListItem,
  mapListItemToValue,
  className,
}: TFieldSingleListItemSelectProps<T>) {
  const mapListItemToValueRef = useSyncedDataRef(mapListItemToValue);
  const onItemClick = React.useCallback((index: number, listItem: TFieldSingleListItemSelectListItem) => {
    onChange(mapListItemToValueRef.current(listItem));
  }, [onChange]);
  return (
    <MainContainer className={className}>
      {items
        .map(mapValueToListItem)
        .map((listItem, index) => (
          <FieldSingleListItemSelectListItem
            key={index}
            index={index}
            listItem={listItem}
            isSelected={mapListItemToValue(listItem) === value}
            onClick={onItemClick}
          />
        ))}
    </MainContainer>
  );
}

export const FieldSingleListItemSelect = React.memo(FieldSingleListItemSelectBase) as typeof FieldSingleListItemSelectBase;

/**
 * FieldListInputListItem
 */
type TFieldSingleListItemSelectListItemProps = {
  index: number;
  listItem: TFieldSingleListItemSelectListItem;
  isSelected: boolean;
  onClick: (index: number, listItem: TFieldSingleListItemSelectListItem) => void;
};

export function FieldSingleListItemSelectListItem<T>({
  index,
  listItem,
  isSelected,
  onClick,
}: TFieldSingleListItemSelectListItemProps) {
  const onItemClick = React.useCallback(() => {
    onClick(index, listItem);
  }, [onClick, index, listItem]);
  return (
    <ItemContainer
      onClick={onItemClick}
      key={index}>
      {listItem.mainText}
      <IconButton onClick={onItemClick}>
        {isSelected
          ? <CheckmarkFullIcon/>
          : <CheckmarkEmptyIcon/>}
      </IconButton>
    </ItemContainer>
  );
}

const MainContainer = styled.div`
  align-items: stretch;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ItemContainer = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.t.col.accentLight};
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  height: 50px;
  justify-content: space-between;
  line-height: 18px;
  padding: 10px;
  text-transform: none;
  width: 100%;
  &:first-child {
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
  }
  &:not(:last-child) {
    border-bottom: 0;
  }
  &:last-child {
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
  }
`;
