import * as _ from 'lodash';
import fetch from 'node-fetch';
import { FetchableRoute, TRequestableBaseParams } from './FetchableRoute';
import { TGenericRequestableRoutes } from '../requestable/RequestableTypes';

export type TRequestableParams<Routes extends TGenericRequestableRoutes> = TRequestableBaseParams & {
  routes: Routes;
};

export type TWrapApiReturnType<Routes extends TGenericRequestableRoutes> = Fetchable<Routes> & {
  [K in keyof Routes]: FetchableRoute<Routes[K]>;
};

export class Fetchable<Routes extends TGenericRequestableRoutes> {
  static wrapApi<Routes extends TGenericRequestableRoutes>(params: TRequestableParams<Routes>) {
    return new Fetchable(params) as TWrapApiReturnType<Routes>;
  }

  private readonly fetch: typeof fetch;
  private readonly baseUrl: string;
  private readonly getBearerToken?: () => Promise<string | undefined>;

  private readonly routes: Routes;

  private constructor(params: TRequestableParams<Routes>) {
    this.fetch = params.fetch;
    this.baseUrl = params.baseUrl;
    this.routes = params.routes;
    this.getBearerToken = params.getBearerToken;

    const keys = _.keys(this.routes);
    for (let i = 0; i < keys.length; i++) {
      // @ts-ignore
      this[keys[i]] = new FetchableRoute({
        fetch: this.fetch,
        baseUrl: this.baseUrl,
        route: this.routes[keys[i]],
        getBearerToken: this.getBearerToken,
      });
    }
  }
}
