import * as React from 'react';
import { URLToolbox } from '../../../core/src/lib/define/url-toolbox/URLToolbox';
import * as _ from 'lodash';
import { URLStateContext } from './URLStateContext';
import { URLParamsContinueURL } from '../../../core/src/lib/apis/routes/common/URLParamsContinueURL';

type TUseContinueUrlsParams = {
  //
};

/**
 * If expectAuthEmail is set then we check that against the current logged in email
 * if user is not logged in or logged in with a different email then logout
 * -
 * If signInLink url parameter is set then use signInWithEmailLink to sign in (after steps above)
 */
export function useContinueUrls(props: TUseContinueUrlsParams) {
  const { url } = React.useContext(URLStateContext);
  const decoded = URLToolbox.deconstructSearchParams(URLParamsContinueURL, url);

  React.useEffect(() => {
    (async () => {
      if (!_.isEmpty(decoded.continueUrl)) {
        // todo redirect
        return;
      }

      if (!_.isEmpty(decoded.redirectUrl)) {
        // todo redirect
      }
    })();
  }, [decoded.continueUrl, decoded.redirectUrl]);
}
