import * as _fp from 'lodash/fp';
import { DbRes } from '../../../core/src/db/DbResources';
import { FIELD_ID } from '../../../core/src/db/DbDefs';
import { HostMessageBuilder } from '../../../core/src/models/db/hostMessage/HostMessageBuilder';
import { HostMessageCreator } from '../../../core/src/models/db/hostMessage/HostMessageCreator';
import { THostMessage } from '../../../core/src/models/db/hostMessage/HostMessageTypes';
import { TMObjDataCreator } from '../../../core/src/lib/model/ModelTypes';
import { TStationId } from '../../../core/src/models/db/station/StationTypes';
import {
  TThunkAction,
  defineReducer,
} from '../../../lib-react/src/redux/redux';
import { actionHostWithVarFromStore } from './Host';
import { actions } from './Actions';
import { dispatchWithErrorHandler } from './ReduxErrorHandlers';

export type TStateHostMessages = {
  hostMessages: {
    [stationId: string]: {
      [messageId: string]: THostMessage;
    };
  };
};

const initialState: TStateHostMessages = { hostMessages: {} };

export const {
  actionTypeHostMessagesAddMessages,
  reducer: reducerHostMessages,
} = defineReducer<TStateHostMessages, typeof actions>({
  actions,
  initialState,
  reducer(state, action, getType) {
    switch (action.type) {
      case getType(actions.actionTypeResetToInitialState):
        return initialState;
      case getType(actions.actionTypeHostMessagesAddMessages):
        return {
          ...state,
          hostMessages: {
            ...state.hostMessages,
            ...action.payload.hostMessages.reduce((acc, hostMessage) => {
              const { stationId, messageId } = HostMessageBuilder.getPathBuilderParamsFromDataPath(hostMessage);
              return _fp.set(`${stationId}.${messageId}`, hostMessage, acc);
            }, state.hostMessages),
          },
        };
    }
    return state;
  },
});

export const actionHostMessagesSendMessage = dispatchWithErrorHandler((stationId: TStationId, message: TMObjDataCreator<typeof DbRes.HostMessage>): TThunkAction<Promise<void>> => {
  return async (dispatch) => {
    return dispatch(actionHostWithVarFromStore('hostData', async (storeHostData) => {
      const pbp = {
        hostId: storeHostData[FIELD_ID],
        stationId,
      };

      await HostMessageCreator.remoteSaveNewToPath(pbp, message);
    }));
  };
});
