import * as React from 'react';
import {
  NavigatorHost,
  TNavigatorHostNavParams,
} from '../screens/NavigatorHost';
import { Route } from 'react-router-dom';
import {
  ScreenHelpAllergens,
  TScreenHelpAllergensNavParams,
} from '../screens/ScreenHelpAllergens';
import {
  ScreenHostGuestSessionMessages,
  TScreenHostGuestSessionMessagesNavParams,
} from '../screens/ScreenHostGuestSessionMessages';
import {
  ScreenHostGuestSessionOverview,
  TScreenHostGuestSessionOverviewNavParams,
} from '../screens/ScreenHostGuestSessionOverview';
import {
  ScreenHostHome,
  TScreenHostHomeNavParams,
} from '../screens/ScreenHostHome';
import {
  ScreenHostMenuBuilder,
  TScreenHostMenuBuilderNavParams,
} from '../screens/ScreenHostMenuBuilder';
import { ScreenHostMenuEditor } from '../screens/ScreenHostMenuEditor';
import {
  ScreenHostMenuItemEditor,
  TScreenHostMenuItemEditorNavParams,
} from '../screens/ScreenHostMenuItemEditor';
import {
  ScreenHostMenuMain,
  TScreenHostMenuMainNavParams,
} from '../screens/ScreenHostMenuMain';
import {
  ScreenHostProfile,
  TScreenHostProfileNavParams,
} from '../screens/ScreenHostProfile';
import {
  ScreenHostSettings,
  TScreenHostSettingsNavParams,
} from '../screens/ScreenHostSettings';
import {
  ScreenLogin,
  TScreenLoginNavParams,
} from '../screens/ScreenLogin';
import {
  buildRoute,
  TBuiltRouteDef,
} from '../lib/navigation/Routes';

export const Routes = Object.freeze({
  ScreenLogin: buildRoute<TScreenLoginNavParams>({
    name: 'ScreenLogin',
    routerProps: {
      component: ScreenLogin,
      path: '/',
      exact: true,
    },
  }),
  ScreenHelpAllergens: buildRoute<TScreenHelpAllergensNavParams>({
    name: 'ScreenHelpAllergens',
    routerProps: {
      component: ScreenHelpAllergens,
      path: '/help/allergens',
    },
  }),
  ScreenHostMenuEditor: buildRoute<any>({
    name: 'ScreenHostMenuCategory',
    routerProps: {
      component: ScreenHostMenuEditor,
      path: '/hosts/:hostId/menus/:menuId/:menuLocale/items',
    },
  }),
  ScreenHostMenuBuilder: buildRoute<TScreenHostMenuBuilderNavParams>({
    name: 'ScreenHostMenuBuilder',
    routerProps: {
      component: ScreenHostMenuBuilder,
      path: '/hosts/:hostId/menus/:menuId/:menuLocale',
    },
  }),
  ScreenHostMenuMain: buildRoute<TScreenHostMenuMainNavParams>({
    name: 'ScreenHostMenuMain',
    routerProps: {
      component: ScreenHostMenuMain,
      path: '/hosts/:hostId/menus',
    },
  }),
  ScreenHostMenuItemEditor: buildRoute<TScreenHostMenuItemEditorNavParams>({
    name: 'ScreenHostMenuItemEditor',
    routerProps: {
      component: ScreenHostMenuItemEditor,
      path: '/hosts/:hostId/items/:menuItemId/:viewLocale',
    },
  }),
  ScreenHostSettings: buildRoute<TScreenHostSettingsNavParams>({
    name: 'ScreenHostSettings',
    routerProps: {
      component: ScreenHostSettings,
      path: '/hosts/:hostId/settings',
    },
  }),
  ScreenHostProfile: buildRoute<TScreenHostProfileNavParams>({
    name: 'ScreenHostProfile',
    routerProps: {
      component: ScreenHostProfile,
      path: '/hosts/:hostId/profile',
    },
  }),
  ScreenHostGuestSessionMessages: buildRoute<TScreenHostGuestSessionMessagesNavParams>({
    name: 'ScreenHostGuestSessionMessages',
    routerProps: {
      component: ScreenHostGuestSessionMessages,
      path: '/hosts/:hostId/guest-sessions/:tableId/messages/:stationId?',
    },
  }),
  ScreenHostGuestSessionOverview: buildRoute<TScreenHostGuestSessionOverviewNavParams>({
    name: 'ScreenHostGuestSessionOverview',
    routerProps: {
      component: ScreenHostGuestSessionOverview,
      path: '/hosts/:hostId/guest-sessions/:tableId',
    },
  }),
  ScreenHostHome: buildRoute<TScreenHostHomeNavParams>({
    name: 'ScreenHostHome',
    routerProps: {
      component: ScreenHostHome,
      path: '/hosts/:hostId',
    },
  }),
  NavigatorHost: buildRoute<TNavigatorHostNavParams>({
    name: 'NavigatorHost',
    routerProps: {
      component: NavigatorHost,
      path: '/hosts',
    },
  }),
});

export function routeDef2ToRoutes(routes: TBuiltRouteDef<any>[]) {
  return routes.map(({ name, routerProps }) => (
    <Route
      key={`route-${name}`}
      {...routerProps}
    />
  ));
}
