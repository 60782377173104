import * as React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export type TFluidContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

export const FluidContainer = ({ children, className }: TFluidContainerProps) => {
  return (
    <StyledContainer
      fluid
      className={className}>
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)`
  padding: 0;
`;
