import { useFormBooleanField } from '../../../../lib-react/src/hooks/form/useFormBooleanField';
import { useFormEmailField } from '../../../../lib-react/src/hooks/form/useFormEmailField';
import { useFormHandler } from '../../../../lib-react/src/hooks/form/useFormHandler';
import { useFormStringField } from '../../../../lib-react/src/hooks/form/useFormStringField';

export type TLoginForm = {
  email: string;
  password: string;
};

export type TLoginFormParams = {
  onSubmit: (params: TLoginForm) => Promise<any>;
};

export function useLoginFormHandler({ onSubmit }: TLoginFormParams) {
  return useFormHandler({
    onSubmit,
    fields: {
      email: useFormEmailField({}),
      password: useFormStringField({ resetOnError: true }),
      rememberMe: useFormBooleanField({}),
    },
  });
}
