import * as React from 'react';
import * as _ from 'lodash';
import { useEventValueCallback } from '../../../../lib-react/src/hooks/useEventValueCallback';
import styled from 'styled-components';

export type TFieldTextAreaProps = {
  value: string;
  placeholder?: string;
  unit?: string;
  error?: string;
  onChange?: (newValue: string) => void;
  readOnly?: boolean;
  className?: string;
  showErrorOnlyBlur?: boolean;
  rows?: number;
};

const FieldTextAreaBase = ({
  value,
  placeholder,
  unit,
  error,
  className,
  onChange = _.noop,
  readOnly = false,
  showErrorOnlyBlur = true,
  rows = 5,
}: TFieldTextAreaProps) => {
  const onInputChange = useEventValueCallback(onChange);

  const [showError, setShowError] = React.useState<boolean>();
  const onBlur = React.useCallback(() => setShowError(true), [setShowError]);

  const usedError = (!showErrorOnlyBlur || showError)
    ? error
    : undefined;

  return (
    <Root className={className}>
      <Container hasError={usedError != null}>
        <Input
          onBlur={onBlur}
          value={value}
          onChange={onInputChange}
          placeholder={placeholder}
          readOnly={readOnly}
          rows={rows}
        />
        {unit && <UnitText>{unit}</UnitText>}
      </Container>
      {!_.isEmpty(usedError) && (
        <FixedErrorContainer>
          <Error>{usedError}</Error>
        </FixedErrorContainer>
      )}
    </Root>
  );
};

export const FieldTextArea = React.memo(FieldTextAreaBase) as typeof FieldTextAreaBase;

const Root = styled.div`
  width: 100%;
`;

type TContainerProps = {
  hasError: boolean;
};

const Container = styled.div<TContainerProps>`
  align-items: center;
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.t.col.red : theme.t.col.accentLight)};
  border-radius: 1px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 19px;
  min-height: 50px;
`;

type TInputProps = {
  readOnly: boolean;
};

const Input = styled.textarea<TInputProps>`
  background: none;
  border: none;
  color: ${({ theme, readOnly }) => (readOnly ? theme.t.col.accentLight : theme.t.col.black)};
  flex: 1;
  outline: none;
  padding: 15px;
  resize: none;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.t.col.accentLight};
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
  }
`;

const UnitText = styled.span`
  color: #${({ theme }) => theme.t.col.accentDark};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 19px;
  padding: 15px;
  text-align: right;
  text-align: right;
`;

const FixedErrorContainer = styled.div`
  font-weight: normal;
  height: 20px;
  text-align: right;
  text-transform: none;
  width: 100%;
`;

const Error = styled.span`
  color: ${({ theme }) => theme.t.col.red};
  font-size: 12px;
`;
