import * as _ from 'lodash';
import { CIInterface } from '../../config/CIInterface';
import {
  FIELD_ID,
  FIELD_NAME,
} from '../../db/DbDefs';
import { Validator } from '../../lib/error/Validators';
import { buildObjValidator } from '../../lib/error/lib/buildObjValidator';
import { THost } from '../db/host/HostTypes';
import { KnownError } from '../../lib/error/KnownError';
import {CurrencyCodes, TCurrencyCode} from '../../locale/Currencies';

const validateHostId = Validator.applyAll([
  Validator.expectSet,
  Validator.expectUUID,
]);

export const validateHostName = Validator.applyAll([
  Validator.expectSet,
  Validator.expectStrOfLen({
    min: CIInterface.validateHostNameMinLen,
    max: CIInterface.validateHostNameMaxLen,
  }),
]);

export const validateHostDescription = Validator.applyAll([
  Validator.expectSet,
  Validator.expectStrOfLen({
    min: CIInterface.validateHostDescriptionMinLen,
    max: CIInterface.validateHostDescriptionMaxLen,
  }),
]);

export const validateHostCurrency = Validator.applyAll<TCurrencyCode>([
  Validator.expectSet,
  Validator.expectOneValueOf({allowedValues: CurrencyCodes as TCurrencyCode[]}),
]);

export const validateHostEmail = Validator.applyAll([
  Validator.expectSet,
  Validator.expectEmail,
]);

const validateHostAddress = Validator.applyAll([
  Validator.expectSet,
  Validator.expectStr,
]);

export const validateHostPhone = Validator.applyAll([
  Validator.expectSet,
  Validator.expectStr,
]);

export const validateHostDenomination = Validator.applyAll([
  Validator.expectSet,
  Validator.expectStr,
]);

export const validateHostPlaceId = Validator.applyAll([
  Validator.expectSet,
  Validator.expectStr,
]);

export const validateHostVatId = Validator.applyAll([
  Validator.expectSet,
  Validator.expectStr,
]);

export const validateHostCoordinates = Validator.applyAll([
  function validateCoordinates(value: any) {
    if (_.isString(value) || _.isEmpty(value)) {
      return [];
    }

    const valid = /^(-?[0-9]\d*(\.\d+)?), (-?[0-9]\d*(\.\d+)?)$/.test(value);
    if (!valid) {
      return [Validator.make(KnownError.validationInvalidType)];
    }

    return [];
  },
]);

export const validateHost = buildObjValidator<THost>({
  [FIELD_ID]: validateHostId,
  [FIELD_NAME]: validateHostName,
  description: validateHostDescription,
  currency: validateHostCurrency,
  email: validateHostEmail,
  address: validateHostAddress,
  coordinates: validateHostCoordinates,
  denomination: validateHostDenomination,
  phone: validateHostPhone,
  placeId: validateHostPlaceId,
  vatId: validateHostVatId,
});
