import * as _ from 'lodash';
import {EIngredientType} from '../../../../core/src/models/db/ingredient/IngredientTypes';
import {FIELD_NAME} from '../../../../core/src/db/DbDefs';
import {Ingredient} from '../../../../core/src/models/db/ingredient/Ingredient';
import {TMObjDataCreator} from '../../../../core/src/lib/model/ModelTypes';
import {TMenuItem, TMenuItemDataChange} from '../../../../core/src/models/db/menuItem/MenuItemTypes';
import {TRKIngredient} from '../../../../core/src/db/DbResources';
import {TagParser} from '../../../../core/src/lib/TagParser';
import {Validator} from '../../../../core/src/lib/error/Validators';
import {dbItemLocalizableGetLocalizedFieldWithDefault} from '../../../../core/src/db/DbLib';
import {useFormArrayField} from '../../../../lib-react/src/hooks/form/useFormArrayField';
import {useFormBooleanField} from '../../../../lib-react/src/hooks/form/useFormBooleanField';
import {useFormHandler} from '../../../../lib-react/src/hooks/form/useFormHandler';
import {useFormNumberField} from '../../../../lib-react/src/hooks/form/useFormNumberField';
import {useFormStringField} from '../../../../lib-react/src/hooks/form/useFormStringField';
import {useFormTagArrayField} from '../../../../lib-react/src/hooks/form/useFormTagArrayField';
import {validateIngredientCreator} from '../../../../core/src/models/validators/validateIngredient';
import {validateMenuItem} from '../../../../core/src/models/validators/validateMenuItem';
import {TLanguage} from '../../../../core/src/locale/Languages';

export type TMenuItemEditForm = TMenuItemDataChange & {
  //
};

export type TMenuItemEditFormParams = {
  language: TLanguage;
  menuItemData: TMenuItem;
  onSubmit: (data: TMenuItemEditForm) => void;
};

export function useMenuItemEditFormHandler({
                                             language,
                                             menuItemData,
                                             onSubmit,
                                           }: TMenuItemEditFormParams) {
  const itemImg = menuItemData.itemImg;
  const menuItemName = dbItemLocalizableGetLocalizedFieldWithDefault<TMenuItem, 'name'>(menuItemData, FIELD_NAME, language);
  const menuItemDescription = dbItemLocalizableGetLocalizedFieldWithDefault<TMenuItem, 'description'>(menuItemData, 'description', language);
  return useFormHandler({
    onSubmit: (submitParams) => {
      onSubmit({
        ...submitParams,
        tags: TagParser.formatTagArrToString(submitParams.tags),
        allergens: TagParser.formatTagArrToString(submitParams.allergens),
        ingredients: Ingredient.unGroupFromIngredientType(submitParams),
      });
    },
    fields: {
      name: useFormStringField({
        value: menuItemName,
        required: true,
        valueToError: validateMenuItem.name,
      }),

      price: useFormNumberField({
        value: parseFloat(`${menuItemData.price}`),
        required: true,
        min: 0,
        valueToError: validateMenuItem.price,
      }),

      description: useFormStringField({
        value: menuItemDescription,
        required: false,
        valueToError: validateMenuItem.description,
      }),

      stationId: useFormStringField({
        value: menuItemData.stationId,
        required: false,
        valueToError: validateMenuItem.stationId,
      }),

      itemImg: useFormStringField({
        value: itemImg,
        syncingActive: true,
        required: false,
      }),

      shareable: useFormBooleanField({
        value: !!menuItemData.shareable,
        required: true,
        valueToError: validateMenuItem.shareable,
      }),

      allergens: useFormTagArrayField({
        value: menuItemData.allergens,
        required: true,
        valueToError: validateMenuItem.allergens,
      }),

      tags: useFormTagArrayField({
        value: menuItemData.tags,
        required: true,
        valueToError: validateMenuItem.tags,
      }),

      extraRemovables: useFormArrayField<TMObjDataCreator<TRKIngredient>>({
        value: _.values(menuItemData.ingredients)
          .filter(Ingredient.filterType(EIngredientType.REMOVABLE)),
        required: true,
        valueToError: Validator.applyToArr(validateIngredientCreator),
        newItem: {
          name: '',
          price: 0,
          type: EIngredientType.REMOVABLE,
          defaultLocale: menuItemData.defaultLocale,
        },
      }),

      extraAddables: useFormArrayField<TMObjDataCreator<TRKIngredient>>({
        value: _.values(menuItemData.ingredients)
          .filter(Ingredient.filterType(EIngredientType.ADDABLE)),
        required: true,
        valueToError: Validator.applyToArr(validateIngredientCreator),
        newItem: {
          name: '',
          price: 0,
          type: EIngredientType.ADDABLE,
          defaultLocale: menuItemData.defaultLocale,
        },
      }),

      extraOptions: useFormArrayField<TMObjDataCreator<TRKIngredient>>({
        value: _.values(menuItemData.ingredients)
          .filter(Ingredient.filterType(EIngredientType.OPTION)),
        required: true,
        valueToError: Validator.applyToArr(validateIngredientCreator),
        newItem: {
          name: '',
          price: 0,
          type: EIngredientType.OPTION,
          defaultLocale: menuItemData.defaultLocale,
        },
      }),
    },
  });
}
