import * as React from 'react';
import * as _ from 'lodash';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import {
  FIELD_DEFAULT_LOCALE,
  FIELD_ID,
  FIELD_NAME,
  FIELD_SORTABLE_INDEX,
} from '../../../../core/src/db/DbDefs';
import { MenuBuilderItem } from './MenuBuilderItem';
import { MenuBuilderNewItem } from './MenuBuilderNewItem';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import {
  TMenu,
  TMenuUpdate,
} from '../../../../core/src/models/db/menu/MenuTypes';
import {
  dbItemLocalizableSupportedLocalesGet,
  dbItemSortableItemSort,
} from '../../../../core/src/db/DbLib';
import { reorder } from '../form/FieldPickableSortableList';
import { useMenuFormHandler } from '../../lib/forms/MenuForm';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';
import styled from 'styled-components';
import { ELocale, ELOCALE_GLOBAL_DEFAULT } from '../../../../core/src/locale/Locale';

type TMenuBuilderViewProps = Omit<TMenuColumnsProps, 'tabIndexStart'> & {
  //
};

export const MenuBuilderView = (props: TMenuBuilderViewProps) => {
  return (
    <HorizontalScrollContainer>
      <MenuColumns
        {...props}
        tabIndexStart={0}
      />
    </HorizontalScrollContainer>
  );
};

type TMenuColumnsProps = {
  tabIndexStart: number;
  hostData: THost;
  locale: ELocale;
  menuData: TMenu;
  menuLocale: ELocale;
  onMenuArchive: (menu: TMenu) => void;
  onMenuCreate: (parentMenu: TMenu, menu: TMenuUpdate) => Promise<any>;
  onMenuNameChange: (menu: TMenu, newName: string) => void;
  onSubMenuReorder: (parentMenu: TMenu, sortedSubMenus: TMenu[]) => void;
  onMenuItemEditClick: (menu: TMenu) => void;
};
//
const MenuColumns = (props: TMenuColumnsProps) => {
  const {
    hostData,
    locale,
    menuData,
    menuLocale,
    onMenuCreate,
    onMenuArchive,
    onMenuNameChange,
    onSubMenuReorder,
    onMenuItemEditClick,
  } = props;

  const subMenus = _.values(menuData.subMenus)
    .sort(dbItemSortableItemSort);

  const firstSubMenuId = _.get(_.head(subMenus), FIELD_ID);
  const [selectedSubMenuId, setSelectedSubMenuId] = React.useState(firstSubMenuId);

  const selectedMenu = _.find(subMenus, (menu) => {
    return menu[FIELD_ID] === selectedSubMenuId;
  });

  const onSubMenuFormSubmit = React.useCallback((newMenu: TMenuUpdate) => {
    return onMenuCreate(menuData, newMenu);
  }, [onMenuCreate, menuData]);

  const newSubMenuFormHandler = useMenuFormHandler({
    minLocales: 0,
    onSubmit: onSubMenuFormSubmit,
    menu: {
      name: '',
      defaultLocale: menuData.defaultLocale || ELOCALE_GLOBAL_DEFAULT,
      coverCharge: menuData.coverCharge,
      ...dbItemLocalizableSupportedLocalesGet(menuData).reduce((acc, eLocale) => {
        return _.set(acc, eLocale, { [FIELD_NAME]: '' });
      }, {} as any),
    },
  });

  const newSubMenuOnSubmit = React.useCallback(async () => {
    // For user experience reset the value before submitting
    const previousName = newSubMenuFormHandler.fields.name.value;
    newSubMenuFormHandler.fields.name.setValue('');
    try {
      await newSubMenuFormHandler.onSubmit();
    } catch (e) {
      newSubMenuFormHandler.fields.name.setValue(previousName);
    }
  }, [
    newSubMenuFormHandler.onSubmit,
    newSubMenuFormHandler.fields.name.value,
    newSubMenuFormHandler.fields.name.setValue,
  ]);

  const onNewSubMenuNameSet = React.useCallback((menu: any, newName: string) => {
    return newSubMenuFormHandler.fields.name.setValue(newName);
  }, [newSubMenuFormHandler.fields.name.setValue]);

  const subMenusRef = useSyncedDataRef(subMenus);
  const onDragEnd = React.useCallback((result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sortedSubMenus = reorder(
      subMenusRef.current,
      result.source.index,
      result.destination.index,
    );

    // @ts-ignore
    onSubMenuReorder(menuData, sortedSubMenus);
  }, [onSubMenuReorder, menuData]);

  return (
    <>
      <DragDropContext
        onDragEnd={onDragEnd}>
        <Droppable
          droppableId={menuData[FIELD_ID]}>
          {(providedA, snapshotA) => (
            <DroppableRoot
              {...providedA.droppableProps}
              ref={providedA.innerRef}
              isDraggingOver={snapshotA.isDraggingOver}
            >
              {subMenus.map((subMenuData: TMenu, index) => (
                <Draggable
                  key={subMenuData[FIELD_ID]}
                  draggableId={subMenuData[FIELD_ID]}
                  index={index}>
                  {(providedB, snapshotB) => (
                    <DraggableRoot
                      ref={providedB.innerRef}
                      {...providedB.draggableProps}
                      {...providedB.dragHandleProps}
                      style={providedB.draggableProps.style}
                      isDragging={snapshotB.isDragging}
                    >
                      <MenuBuilderItem
                        componentId={subMenuData[FIELD_ID]}
                        locale={locale}
                        hostData={hostData}
                        menuData={subMenuData}
                        menuLocale={menuLocale}
                        onMenuNameChange={onMenuNameChange}
                        isSelected={selectedSubMenuId === subMenuData[FIELD_ID]}
                        onItemSelect={setSelectedSubMenuId}
                        onMenuArchive={onMenuArchive}
                        onMenuItemEditClick={onMenuItemEditClick}
                      />
                    </DraggableRoot>
                  )}
                </Draggable>
              ))}
              <MenuBuilderNewItem
                key={`${menuData[FIELD_ID]}-new-item`}
                locale={locale}
                menuData={{
                  name: newSubMenuFormHandler.fields.name.value,
                  coverCharge: newSubMenuFormHandler.fields.coverCharge.value,
                  [FIELD_DEFAULT_LOCALE]: ELocale['it-IT'],
                  subMenus: {},
                  isHidden: false,
                  description: '',
                  [FIELD_SORTABLE_INDEX]: subMenus.length,
                }}
                menuLocale={menuLocale}
                onMenuNameChange={onNewSubMenuNameSet}
                onMenuNameBlur={newSubMenuOnSubmit}
              />
            </DroppableRoot>
          )}
        </Droppable>
      </DragDropContext>
      {!!selectedMenu && (
        <MenuColumns
          {...props}
          locale={locale}
          menuData={selectedMenu}
        />
      )}
    </>
  );
};

const HorizontalScrollContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: scroll;
  width: 100vw;
`;

type TDroppableRootProps = {
  isDraggingOver: boolean;
};

const DroppableRoot = styled.div<TDroppableRootProps>`
  background: ${({ theme, isDraggingOver }) => (!isDraggingOver ? theme.t.col.lightGray3 : theme.t.col.lightGray2)};
  background-color: ${({ theme, isDraggingOver }) => (!isDraggingOver ? theme.t.col.lightGray3 : theme.t.col.lightGray2)};
  border-right: 1px solid ${({ theme }) => theme.t.col.lightGray2};
  display: flex;
  flex: 0 0 ${256}px;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
`;

type TDraggableRootProps = {
  isDragging: boolean;
};

const DraggableRoot = styled.div<TDraggableRootProps>`
  background: ${({ theme, isDragging }) => (isDragging ? theme.t.col.lightGray3 : theme.t.col.white)};
  background-color: ${({ theme, isDragging }) => (isDragging ? theme.t.col.lightGray3 : theme.t.col.white)};
  cursor: grab;
  opacity: ${({ isDragging }) => (isDragging ? 0.5 : 1)};
`;
