import * as React from 'react';
import * as _ from 'lodash';
import { useSyncedDataRef } from './useSyncedDataRef';

export function useKeyDownListener(key: string, cb: (event: any) => void) {
  const onKeyPressRef = useSyncedDataRef(cb);

  return React.useCallback((event: any) => {
    _.get(event, 'key') === key && onKeyPressRef.current(event);
  }, [key]);
}

export function useEnterKeyDown(cb: (event: any) => void) {
  return useKeyDownListener('Enter', cb);
}
