import * as React from 'react';
import { LabeledFieldInputList } from '../LabeledFields';
import { RemoveIcon } from '../../../assets/Assets';
import { TFieldInputListItem } from '../duplexInputList/FieldInputList';
import { TagParser } from '../../../../../core/src/lib/TagParser';
import { useMenuItemEditFormHandler } from '../../../lib/forms/MenuItemEditForm';
import { ELocale } from '../../../../../core/src/locale/Locale';

function mapValueToFieldListInputListItem(value: string) {
  return {
    mainText: TagParser.format(value),
    actionable: true,
    hasSecondary: false,
  };
}

function mapNewValueToFieldListInputListItem(value: string) {
  return {
    mainText: value,
    hasSecondary: false,
  };
}

function mapFieldListInputListItemToValue(listItem: TFieldInputListItem) {
  return listItem.mainText;
}

export type TMenuItemEditAllergensProps = ReturnType<typeof useMenuItemEditFormHandler>['fields']['allergens'] & {
  locale: ELocale;
  label: string;
  onHelpClick: () => void;
};

export const MenuItemEditAllergens = (props: TMenuItemEditAllergensProps) => {
  const {
    label,
    onHelpClick,
  } = props;
  const removeIcon = React.useMemo(() => <RemoveIcon/>, []);
  return (
    <LabeledFieldInputList
      label={label}
      onClickHelp={onHelpClick}
      value={props.value}
      onChange={props.setValue}
      mapListItemToValue={mapFieldListInputListItemToValue}
      mapValueToListItem={mapValueToFieldListInputListItem}
      newItem={props.newItem.value}
      newItemSet={props.newItem.setValue}
      newItemReset={props.newItem.reset}
      mapNewValueToListItem={mapNewValueToFieldListInputListItem}
      newItemMainPlaceholder={'Cerca...'}
      newItemSecondaryPlaceholder={'0'}
      listItemAction={removeIcon}
    />
  );
};
