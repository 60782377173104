import { HostMessageSuggestions } from '../../lib/HostMessageSuggestions';
import { Log } from '../../config/Instance';
import { actions } from '../Actions';
import { getType } from 'typesafe-actions';
import { takeEvery } from 'redux-saga/effects';

export function* sagaHostMessageSuggestions() {
  Log.v('sagaHostMessageSuggestions', 'sagaHostMessageSuggestions', 'Started');
  yield takeEvery(
    [
      getType(actions.actionTypeHostMessagesAddMessages),
    ],
    (params: ReturnType<typeof actions.actionTypeHostMessagesAddMessages>) => {
      HostMessageSuggestions.addUnique(params.payload.hostMessages);
    },
  );

  yield takeEvery(
    [
      getType(actions.actionTypeResetToInitialState),
    ],
    (params: ReturnType<typeof actions.actionTypeHostMessagesAddMessages>) => {
      HostMessageSuggestions.clear();
    },
  );
}
