import { THostId } from '../../../core/src/models/db/host/HostTypes';
import { TTableId } from '../../../core/src/models/db/table/TableTypes';
import { TLanguage } from '../../../core/src/locale/Languages';
import { TMenuId } from '../../../core/src/models/db/menu/MenuTypes';
import {
  TThunkAction,
  defineReducer,
} from '../../../lib-react/src/redux/redux';
import { actions } from './Actions';

export type TFullScreenModalProps = {
  [EFullScreenModal.guestSessionClose]: {
    hostId: THostId;
    tableId: TTableId;
  };
  [EFullScreenModal.menuTranslator]: {
    hostId: THostId;
    menuId: TMenuId;
    addedLanguages: TLanguage[];
  };
};

export enum EFullScreenModal {
  guestSessionClose = 'guestSessionClose',
  menuTranslator = 'menuTranslator',
}

export type TStateFullScreenModal<T extends EFullScreenModal> = {
  type: T | undefined;
  params: TFullScreenModalProps[T];
};

const initialState: TStateFullScreenModal<any> = {
  type: undefined,
  params: {},
};

export const {
  actionTypeFullScreenModalSet,
  reducer: reducerFullScreenModal,
} = defineReducer<TStateFullScreenModal<any>, typeof actions>({
  actions,
  initialState,
  reducer(state, action, getType) {
    switch (action.type) {
      case getType(actions.actionTypeResetToInitialState):
        return initialState;
      case getType(actions.actionTypeFullScreenModalSet):
        return {
          ...state,
          type: action.payload.type,
          params: action.payload.params,
        };
    }
    return state;
  },
});

export function actionFullScreenModalShow<K extends EFullScreenModal>(type: K, params: TFullScreenModalProps[K]): TThunkAction {
  return (dispatch) => {
    dispatch(actionTypeFullScreenModalSet({
      type,
      params,
    }));
  };
}

export function actionFullScreenModalHide(): TThunkAction {
  return (dispatch) => {
    dispatch(actionTypeFullScreenModalSet({
      type: undefined,
      params: undefined,
    }));
  };
}

