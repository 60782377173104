import React from 'react'
import styled from "styled-components";
import PuffLoader from "react-spinners/PuffLoader";
import { Theme } from "../../../../config/Theme";
import { useDebounce } from '../../../../../../lib-react/src/hooks/useDebounce';

interface Props {
  query: string
  placeholder: string
  setQuery: (query: string) => void
  onSearch: (query: string) => void
}

export const SearchBar = ({ placeholder, setQuery, query, onSearch }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>();
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.setSelectionRange(0, 10000, 'forward');
    }, 300);
    return () => timeout && clearTimeout(timeout);
  }, [])

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
  }, [query]);

  const debouncedQuery = useDebounce(query, 2000);

  React.useEffect(() => {
    (async () => {
      try {
        onSearch(debouncedQuery);
      } catch (e) {
        //
      } finally {
        setLoading(false)
      }
    })()
  }, [debouncedQuery]);

  return (
    <Form>
      <Input
        // @ts-ignore
        ref={inputRef}
        autoFocus
        type='text'
        name='search'
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={placeholder}
      />
      <LoaderContainer>
        <PuffLoader
          loading={loading}
          color={Theme.col.red}
          size={32}
          speedMultiplier={1.5}
        />
      </LoaderContainer>
    </Form>
  )
}

const height = 50;

const Form = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  height: ${height}px;
  border: 1px solid ${({ theme }) => theme.t.col.accentLight};
  border-radius: 1px;
  font-size: 14px;
  line-height: 19px;
`;

const LoaderContainer = styled.div`
  display: flex;
  width: ${height}px;
  height: ${height}px;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  padding-bottom: 4px;
`;

const Input = styled.input`
  background: none;
  border: none;
  color: ${({ theme }) => (theme.t.col.black)};
  flex: 1;
  outline: none;
  padding: 15px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.t.col.accentLight};
    font-size: 13px;
    font-style: normal;
    font-weight: normal;
  }
`;
