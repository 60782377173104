import * as React from 'react';
import * as _ from 'lodash';
import { CheckmarkEmptyIcon, CheckmarkFilledIcon, EditIconDark, RemoveIcon, } from '../../assets/Assets';
import { ReactComponent as DuplicateIcon } from '../../assets/duplicate-icon.svg';
import { FIELD_DEFAULT_LOCALE, FIELD_ID, } from '../../../../core/src/db/DbDefs';
import { IconButton } from '../Button/IconButton';
import {
  LabeledFieldCurrencyInput,
  LabeledFieldPickableSortableList,
  LabeledFieldTextInput,
} from '../form/LabeledFields';
import { MenuFormCardTitle, MenuMainFormCardFrame, } from './MenuMainFormCardFrame';
import { PopoverConfirmation } from '../popup/PopoverConfirmation';
import { Strings } from '../../../../core/src/locale/Strings';
import { TMenu } from '../../../../core/src/models/db/menu/MenuTypes';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TMenuForm, useMenuFormHandler, } from '../../lib/forms/MenuForm';
import { useItemCallback } from '../../../../lib-react/src/hooks/useItemCallback';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';
import { DEFAULT_CURRENCY } from '../../../../core/src/locale/Currencies';
import { LanguageCodes, Languages, TLanguageObject, TLanguage } from '../../../../core/src/locale/Languages';
import { useDispatch } from "react-redux";
import { actionFullScreenModalShow, EFullScreenModal } from "../../redux/FullScreenModal";

type TUseShowMenuTranslatorModal = {
  hostId: string,
  menuId: string,
}

export function useShowMenuTranslatorModal({hostId, menuId}: TUseShowMenuTranslatorModal) {
  const dispatch = useDispatch();
  return React.useCallback((addedLanguages: TLanguage[]) => {
    dispatch(actionFullScreenModalShow(EFullScreenModal.menuTranslator, {
      hostId, menuId,
      addedLanguages
    }));
  }, [hostId, menuId, dispatch]);
}

export type TMenuMainFormCardProps = {
  locale: ELocale;
  hostData: THost;
  menu: TMenu;
  onEditLocalePress: (menu: TMenu, locale: ELocale) => void;
  onLocaleDeletePress: (menu: TMenu, locale: ELocale) => void;
  onChange: (menu: TMenu, data: Partial<TMenuForm>) => Promise<any>;
  onArchive: (menu: TMenu) => Promise<any>;
  onDuplicate: (menu: TMenu) => Promise<any>;
  onPublish: (menu: TMenu) => void;
  onUnPublish: (menu: TMenu) => void;
};

export const MenuMainFormCard = (props: TMenuMainFormCardProps) => {
  const {
    locale,
    menu,
    hostData,
    onEditLocalePress,
    onLocaleDeletePress,
    onChange,
    onArchive,
    onDuplicate,
    onPublish,
    onUnPublish,
  } = props;

  const saveForm = React.useCallback((data: Partial<TMenuForm>) => {
    return onChange(menu, data);
  }, [menu, onChange]);

  const onMenuLanguageEdit = React.useCallback((loc: TLanguage) => {
    return onEditLocalePress(menu, loc as any as ELocale);
  }, [menu, onEditLocalePress]);

  const onLanguageDelete = React.useCallback((loc: TLanguage) => {
    return onLocaleDeletePress(menu, loc as any as ELocale);
  }, [menu, onLocaleDeletePress]);

  const onArchiveClick = useItemCallback(menu, onArchive);
  const onDuplicateClick = useItemCallback(menu, onDuplicate);

  const onPublishClick = useItemCallback(menu, onPublish);
  const onUnPublishClick = useItemCallback(menu, onUnPublish);

  const handleShowMenuTranslatorModal = useShowMenuTranslatorModal({
    hostId: hostData[FIELD_ID],
    menuId: menu[FIELD_ID]
  });

  const formHandler = useMenuFormHandler({
    ...props,
    onSyncedSubmit: saveForm,
    minLocales: 1,
    onLanguagesChangedSuccessfully: handleShowMenuTranslatorModal
  });

  const editIcon = React.useMemo(() => <EditIconDark/>, []);
  const deleteIcon = React.useMemo(() => <RemoveIcon/>, []);

  const menuLanguage = _.uniq([
    menu[FIELD_DEFAULT_LOCALE],
    ...formHandler.fields.locales.value,
  ]);

  const selectableLanguages = LanguageCodes
    .filter((lang) => !formHandler.fields.locales.value.includes(lang));

  return (
    <RefableRoute>
      <MenuMainFormCardFrame
        title={(
          <TitleContainer isHidden={menu.isHidden}>
            {menu.isHidden ? Strings.hMenuDraftCover()(locale) : Strings.hMenuPublishedCover()(locale)}
          </TitleContainer>
        )}
        contentItems={[
          (
            <LabeledFieldTextInput
              key={'0'}
              label={Strings.hMenuName()(locale)}
              value={formHandler.fields.name.value}
              onChange={formHandler.fields.name.setValue}
              error={formHandler.fields.name.errorStr}
            />
          ),
          (
            <LabeledFieldCurrencyInput
              key={'1'}
              locale={locale}
              currency={hostData.currency ?? DEFAULT_CURRENCY}
              isRequired
              value={formHandler.fields.coverCharge.valueStr}
              onChange={formHandler.fields.coverCharge.setValue}
              error={formHandler.fields.coverCharge.errorStr}
              placeholder={`${formHandler.fields.coverCharge.initialValue}`}
              label={Strings.hMenuPlaceCharge()(locale)}
            />
          ),
          (
            <LabeledFieldPickableSortableList<TLanguage>
              key={'2'}
              componentId={menu[FIELD_ID]}
              label={Strings.hMenuLanguage()(locale)}
              value={menuLanguage}
              onChange={formHandler.fields.locales.setValue}
              mapListItemToValue={(listItem) => listItem.id as TLanguage}
              mapValueToListItem={(value: TLanguage) => {
                const defaultLocalePostfix = value == menu.defaultLocale
                  ? ` (${Strings.hMenuLocaleDefault()(locale)})`
                  : '';
                const lang: TLanguageObject = Languages[value];
                const emojiLength = 5;
                const maxEmojis = 3;
                const emojis = lang.emojis.length > maxEmojis * emojiLength ? lang.emoji : lang.emojis;
                const localeName = `${lang.language} ${emojis}`;
                return {
                  id: value,
                  mainText: `${localeName}${defaultLocalePostfix}`,
                  actionable: true,
                };
              }}
              newItem={formHandler.fields.locales.newItem.value}
              newItemSet={formHandler.fields.locales.newItem.setValue}
              newItemReset={formHandler.fields.locales.newItem.reset}
              mapNewValueToListItem={(value) => {
                return {
                  id: 'new-value',
                  mainText: value,
                  actionable: true,
                };
              }}
              newItemMainPlaceholder={Strings.hMenuLanguageDdl()(locale)}

              onAction={onMenuLanguageEdit}
              listItemAction={editIcon}

              onAction2={onLanguageDelete}
              listItemAction2={deleteIcon}

              selectableItems={selectableLanguages}
            />
          ),
        ]}
        bottomLeftButtons={(
          <IconButton
            label={menu.isHidden ? Strings.hDraftChk()(locale) : Strings.hPublishedChk()(locale)}
            onClick={menu.isHidden ? onPublishClick : onUnPublishClick}
          >
            {menu.isHidden ? <CheckmarkEmptyIcon/> : <CheckmarkFilledIcon/>}
          </IconButton>
        )}
        bottomRightButtons={(
          <>
            <IconButton onClick={onDuplicateClick}>
              <DuplicateIcon/>
            </IconButton>
            {menu.isHidden && (
              <>
                <PopoverConfirmation
                  componentId={menu[FIELD_ID]}
                  buttonOnClick={onArchiveClick}
                  placement={'bottom'}
                  buttonText={Strings.hMenuDelCmd()(locale)}
                  confirmationMessage={Strings.hMenuDelMsg()(locale)}
                >
                  <RemoveIcon/>
                </PopoverConfirmation>
              </>
            )}
          </>
        )}
      />
    </RefableRoute>
  );
};

const RefableRoute = styled.div`

`;

type TTitleContainerProps = {
  isHidden: boolean;
};

const TitleContainer = styled(MenuFormCardTitle)<TTitleContainerProps>`
  background-color: ${({ theme, isHidden }) => (isHidden ? theme.t.col.accentLight : theme.t.col.accentDark)};
  color: ${({ theme }) => theme.t.col.white};
`;
