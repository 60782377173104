import * as React from 'react';
import * as _ from 'lodash';

import {
  FIELD_CREATED_ON,
  FIELD_ID,
} from '../../../../core/src/db/DbDefs';
import { MessageSubmitIcon } from '../../assets/Assets';
import { RoundedTextInput } from '../RoundedTextInput';
import { Strings } from '../../../../core/src/locale/Strings';
import { THostMessage } from '../../../../core/src/models/db/hostMessage/HostMessageTypes';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

type TStationChatProps = {
  locale: ELocale;
  previousMessages: THostMessage[];
  onMessageSend: (message: string) => Promise<any>;
  messageSuggestions: string[];
};

export const StationChat = ({
  locale,
  onMessageSend,
  previousMessages,
  messageSuggestions,
}: TStationChatProps) => {
  const [textInput, setTextInput] = React.useState('');

  const handleSubmit = React.useCallback(async () => {
    !_.isEmpty(textInput) && await onMessageSend(textInput);
    setTextInput('');
  }, [onMessageSend, textInput, setTextInput]);

  const handleSuggestionClick = React.useCallback(
    (suggestionText: string) => {
      setTextInput(suggestionText);
    },
    [setTextInput],
  );

  return (
    <StationChatBase
      locale={locale}
      previousMessages={previousMessages}
      handleSuggestionClick={handleSuggestionClick}
      textInput={textInput}
      handleInputChange={setTextInput}
      handleSubmit={handleSubmit}
      messageSuggestions={messageSuggestions}
    />
  );
};

type TStationChatBaseProps = {
  locale: ELocale;
  previousMessages: THostMessage[];
  handleInputChange: React.Dispatch<React.SetStateAction<string>>;
  handleSubmit: () => void;
  handleSuggestionClick: (suggestionText: string) => void;
  messageSuggestions?: string[];
  textInput: string;
};

const StationChatBase = ({
  locale,
  previousMessages,
  handleInputChange,
  handleSubmit,
  handleSuggestionClick,
  messageSuggestions = [],
  textInput,
}: TStationChatBaseProps) => {
  return (
    <Container>
      <MessagesContainer>
        {previousMessages
          .sort((a, b) => b[FIELD_CREATED_ON] - a[FIELD_CREATED_ON])
          .map((hostMessage, index) => (
            <React.Fragment key={hostMessage[FIELD_ID]}>
              {index === 0 && (
                <MessageStatusText>
                  {Strings.hChatPrintedMsg()(locale)}
                </MessageStatusText>
              )}
              <Message>{hostMessage.text}</Message>
            </React.Fragment>
          ))}
      </MessagesContainer>
      {messageSuggestions && (
        <SuggestionsOuterContainer>
          <SuggestionsInnerContainer>
            {messageSuggestions.map((suggestionText) => (
              <SuggestionCard
                key={suggestionText}
                onClick={() => handleSuggestionClick(suggestionText)}
              >
                {suggestionText}
              </SuggestionCard>
            ))}
          </SuggestionsInnerContainer>
        </SuggestionsOuterContainer>
      )}
      <InputContainer>
        <RoundedTextInput
          placeholder={Strings.hChatBox()(locale)}
          value={textInput}
          handleChange={handleInputChange}
          onSubmit={handleSubmit}
        />
        <SubmitButtonContainer onClick={handleSubmit}>
          <MessageSubmitIcon/>
        </SubmitButtonContainer>
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  align-items: stretch;
  background-color: ${({ theme }) => theme.t.col.white};
  display: flex;

  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  overflow-x: visible;

  overflow-y: hidden;
  padding: 10px 0px 10px 0px;
`;

const InputContainer = styled.div`
  align-items: center;

  display: flex;
  flex-direction: row;
  margin-top: 20px;;
  padding-left: 20px;
  padding-right: 15px;
`;

const SubmitButtonContainer = styled.div`
  cursor: pointer;
  margin-left: 20px;
`;

const MessagesContainer = styled.div`
  align-items: flex-end;
  display: flex;

  flex-direction: column-reverse;
  overflow-y: scroll;
  padding-left: 20px;
  padding-right: 15px;
`;

const SuggestionsOuterContainer = styled.div`
  padding-left: 20px;
  padding-right: 15px;
`;

const SuggestionsInnerContainer = styled.div`

  align-items: center;
  display: flex;
  flex-direction: row;;
  flex-wrap: wrap;

  justify-content: flex-end;
  margin-right: -10px;

  & > * {
    margin-bottom: 10px;
    margin-right: 10px;
  }

  margin-top: 10px;
`;

const SuggestionCard = styled.div`
  align-items: center;

  border-radius: 20px;
  box-shadow: 0px 1px 6px rgba(43, 44, 51, 0.200475);
  cursor: pointer;
  display: flex;;
  flex: 0;
  flex-direction: row;
  font-size: 15px;

  font-weight: normal;

  height: 40px;
  justify-content: center;
  line-height: 20px;
  padding: 10px 20px;

  white-space: nowrap;
`;

const Message = styled.div`
  background-color: ${({ theme }) => theme.t.col.accentDark};
  border-radius: 20px 20px 0px 20px;
  color: ${({ theme }) => theme.t.col.white};

  font-size: 15px;
  font-weight: normal;

  line-height: 20px;
  margin-bottom: 5px;
  padding: 10px 15px;
  text-align: left;
`;

const MessageStatusText = styled.span`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  margin-right: 10px;
`;
