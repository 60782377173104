import { HostBuilder } from '../../../core/src/models/db/host/HostBuilder';
import { Log } from '../config/Instance';
import { TDb } from '../../../core/src/db/Db';
import {
  THost,
  THostId,
} from '../../../core/src/models/db/host/HostTypes';
import {
  defineReducer,
  TThunkAction,
} from '../../../lib-react/src/redux/redux';
import { actions } from './Actions';
import { THostPublicSubscription } from '../../../core/src/models/db/HostPublicSubscription/HostPublicSubscriptionTypes';

export type TStateHost = {
  hostData: THost | undefined;
  hostPublicSubscription: THostPublicSubscription | undefined;
  hostGuestSessions: TDb['guestSessions'][THostId];
};

const initialState: TStateHost = {
  hostData: undefined,
  hostPublicSubscription: undefined,
  hostGuestSessions: {},
};

export const {
  actionTypeHostSetHost,
  actionTypeHostSetGuestSessions,
  reducer: reducerHost,
} = defineReducer<TStateHost, typeof actions>({
  actions,
  initialState,
  // @ts-ignore
  reducer(state, action, getType) {
    switch (action.type) {
      case getType(actions.actionTypeResetToInitialState):
        return initialState;
      case getType(actions.actionTypeHostSetHost):
        return {
          ...state,
          hostData: action.payload.hostData,
        };
      case getType(actions.actionTypeHostSetHostPublicSubscription):
        return {
          ...state,
          hostPublicSubscription: action.payload.hostPublicSubscription,
        };
      case getType(actions.actionTypeHostSetGuestSessions):
        return {
          ...state,
          hostGuestSessions: action.payload.hostGuestSessions,
        };
    }
    return state;
  },
});

export function actionHostSetHostId(hostId: THostId): TThunkAction<Promise<void>> {
  return async (dispatch) => {
    // Make sure host exists (fast)
    const hostExists = await HostBuilder.dataExistsById({ hostId });
    if (!hostExists) {
      return;
    }

    const hostData = await HostBuilder.remoteDataFromPath({ hostId });
    if (!hostData) {
      return;
    }

    dispatch(actions.actionTypeHostSetHost({ hostData }));
  };
}

export function actionHostWithVarFromStore<Key extends keyof TStateHost>(
  key: Key,
  cb: (data: NonNullable<TStateHost[Key]>) => Promise<any> | any,
) {
  return async (dispatch, getState) => {
    const data = getState().host[key];
    if (!data) {
      Log.e('redux/Host', 'actionHostWithVarFromStore', `${key} not defined in store`);
      return;
    }
    await cb(data);
  };
}
