import * as React from 'react';
import { FieldNumberInput } from './FieldNumberInput';
import { LocaleDefinitions } from '../../../../core/src/locale/LocaleDefinitions';
import { NumberInput } from './NumberInput';
import { TCurrencyCode, Currencies } from '../../../../core/src/locale/Currencies';

type TAsCurrencyInputProps = {
  currency: TCurrencyCode;
};

type TAsCurrencyInputNeededProps = {
  unit?: string;
  onChange?: (newValue: string) => void;
};

function asCurrencyInput<Props extends TAsCurrencyInputNeededProps>(Component: (props: Props) => JSX.Element) {
  return (props: TAsCurrencyInputProps & Props) => {
    const { onChange, currency } = props;

    const currencyUnit = Currencies[currency].symbol;
    const onValueChange = React.useCallback((val: string) => {
      return onChange && onChange(val.replace(`${currencyUnit}`, '').trim());
    }, [onChange, currencyUnit]);
    return (
      <Component
        {...props}
        unit={currencyUnit}
        onChange={onValueChange}
      />
    );
  };
}

const CurrencyInputBase = asCurrencyInput(NumberInput);
export const CurrencyInput = React.memo(CurrencyInputBase) as typeof CurrencyInputBase;

const FieldCurrencyInputBase = asCurrencyInput(FieldNumberInput);
export const FieldCurrencyInput = React.memo(FieldCurrencyInputBase) as typeof FieldCurrencyInputBase;
