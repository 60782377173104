import fetch from 'node-fetch';
import { UndefinedOptional } from '../../Types';
import { Log } from '../../../config/Instance';
import { RequestableRoute } from '../requestable/RequestableRoute';
import { TGenericRequestableRoute } from '../requestable/RequestableTypes';
import { ServiceError } from '../../error/ServiceError';

type TFetchApi = typeof fetch;

export type TRequestableBaseParams = {
  fetch: any;
  baseUrl: string;
  getBearerToken?: () => Promise<string | undefined>;
};

type TRequestableRouteParams<Def extends TGenericRequestableRoute> = TRequestableBaseParams & {
  route: Def;
};

export type TRouteRunnerParams<Route extends TGenericRequestableRoute> = {
  body: Route['body'];
  pathParams: Route['pathParams'];
  params: Route['params'];
  options?: Parameters<TFetchApi>[1];
};

export class FetchableRoute<Def extends TGenericRequestableRoute> extends RequestableRoute<Def> {
  private readonly fetch: TFetchApi;
  private readonly getBearerToken?: () => Promise<string | undefined>;

  constructor(params: TRequestableRouteParams<Def>) {
    super(params);
    this.fetch = params.fetch;
    this.getBearerToken = params.getBearerToken;
  }

  readonly request = async (_opts: UndefinedOptional<TRouteRunnerParams<Def>>) => {
    const opts = _opts as any;
    const url = this.url(_opts);

    const token = this.route.useBearerAuth && this.getBearerToken != null
      ? await this.getBearerToken()
      : undefined;

    if (this.route.useBearerAuth && token == null) {
      throw new Error([
        'RequestableRoute',
        'request',
        `useBearerAuth was specified but getBearerToken was undefined or returned undefined`,
      ].join(', '));
    }

    const response = await this.fetch(url, {
      method: this.route.method,

      ...([
        'post',
        'patch',
        'put',
      ].includes(this.route.method) ? { body: JSON.stringify(opts.body) } : {}),

      headers: {
        ...(this.route.useBearerAuth && token != null ? ({ Authorization: `Bearer ${token}` }) : {}),

        ...([
          'post',
          'patch',
          'put',
        ].includes(this.route.method) ? { 'Content-Type': 'application/json' } : {}),
      },

      ...opts.options,
    });

    Log.v('FetchableRoute', 'buildRouteRunner', 'Done', response);

    if (!response.ok) {
      const text = await response.text();
      throw ServiceError.pack(text);
    }

    const responseBody = await response.json();
    return responseBody as Def['response'];
  };
}
