import * as React from 'react';
import { FIELD_ID } from '../../../core/src/db/DbDefs';
import { FullScreenLoadingView } from '../components/fullScreenViews/FullScreenLoadingView';
import { Hist } from '../lib/navigation/History';
import { HostNavbarMain } from '../components/navbars/HostNavbarMain';
import {
  MaxHeightDiv,
  MaxScreenDiv,
} from '../components/MaxHeightDiv';
import { MenuMainView } from '../components/menuMain/MenuMainView';
import { Routes } from '../config/Routes';
import { Strings } from '../../../core/src/locale/Strings';
import {
  THost,
  THostId,
} from '../../../core/src/models/db/host/HostTypes';
import { TMenu } from '../../../core/src/models/db/menu/MenuTypes';
import { TMenuForm } from '../lib/forms/MenuForm';
import {
  TPropCheck,
  withPropCheckMiddleware,
} from '../../../lib-react/src/hocs/withPropCheckMiddleware';
import { TScreenNavigationProps } from '../lib/navigation/Navigation';
import { TState } from '../redux/Store';
import {
  actionHostSecondaryMenuArchive,
  actionHostSecondaryMenuPublishUpdate,
} from '../redux/actions/Host';
import {
  actionMenuAddSubMenu,
  actionMenuArchive,
  actionMenuArchiveLocale,
  actionMenuDuplicate,
  actionMenuUpdate,
} from '../redux/actions/Menu';
import { bindActionCreators } from '../../../lib-react/src/redux/redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ELocale } from '../../../core/src/locale/Locale';

export type TScreenHostMenuMainNavParams = {
  hostId: THostId;
};

export type TScreenHostMenuMainProps = TScreenNavigationProps<TScreenHostMenuMainNavParams> & {
  //
};

type TScreenHostMenuMainBaseProps = TScreenHostMenuMainProps
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>;

type TScreenHostMenuMainState = {
  //
};

class ScreenHostMenuMainBase extends React.Component<TPropCheck<typeof ScreenHostMenuMainBase>, TScreenHostMenuMainState> {
  static checkProps(props: TScreenHostMenuMainBaseProps) {
    const {
      locale,
      hostData,
    } = props;

    if (hostData == null) {
      return () => (
        <FullScreenLoadingView
          locale={locale}
          title={Strings.hWelcome()(locale)}
        />
      );
    }

    return {
      ...props,
      hostData: props.hostData as THost,
    };
  }

  private onMenuChange = async (menu: TMenu, menuDataChange: Partial<TMenuForm>) => {
    const {
      locale,
      menuUpdate,
    } = this.props;
    await menuUpdate({
      menu,
      update: menuDataChange,
      viewLocale: locale,
    });
  };

  private onMenuDuplicate = async (menuToDuplicate: TMenu) => {
    const {
      hostData,
      menuDuplicate,
    } = this.props;
    await menuDuplicate({
      host: hostData,
      menu: menuToDuplicate,
    });
  };

  private onEditLocalePress = (menu: TMenu, editedLocale: ELocale) => {
    const {
      hostData,
      locale,
    } = this.props;
    Hist.push(Routes.ScreenHostMenuBuilder, {
      hostId: hostData[FIELD_ID],
      menuId: menu[FIELD_ID],
      menuLocale: editedLocale,
      navContext: { goBackText: Strings.hBackToMenuMain()(locale) },
    });
  };

  private onLocaleDeletePress = (menu: TMenu, localeToDelete: ELocale) => {
    const { menuArchiveLocale } = this.props;
    return menuArchiveLocale({
      menu,
      localeToArchive: localeToDelete,
    });
  };

  private onSubMenuCreate = async (form: TMenuForm) => {
    const {
      menuAddSubMenu,
      hostData,
    } = this.props;
    return menuAddSubMenu({
      parentMenu: hostData.menu,
      subMenu: form,
    });
  };

  private onMenuPublish = async (menu: TMenu) => {
    const {
      hostData,
      hostSecondaryMenuPublishUpdate,
    } = this.props;
    return hostSecondaryMenuPublishUpdate({
      host: hostData,
      secondaryMenuId: menu[FIELD_ID],
      published: true,
    });
  };

  private onMenuUnPublish = async (menu: TMenu) => {
    const {
      hostData,
      hostSecondaryMenuPublishUpdate,
    } = this.props;
    return hostSecondaryMenuPublishUpdate({
      host: hostData,
      secondaryMenuId: menu[FIELD_ID],
      published: false,
    });
  };

  private onMenuArchive = async (menu: TMenu) => {
    const {
      hostData,
      hostSecondaryMenuArchive,
    } = this.props;
    return hostSecondaryMenuArchive({
      host: hostData,
      secondaryMenuId: menu[FIELD_ID],
    });
  };

  render() {
    const {
      locale,
      hostData,
    } = this.props;
    return (
      <Root>
        <HostNavbarMain
          locale={locale}
        />
        <ScrollableContent>
          <Content>
            <MenuMainView
              locale={locale}
              hostData={hostData}
              onChange={this.onMenuChange}
              onArchive={this.onMenuArchive}
              onPublish={this.onMenuPublish}
              onUnPublish={this.onMenuUnPublish}
              onDuplicate={this.onMenuDuplicate}
              onEditLocalePress={this.onEditLocalePress}
              onLocaleDeletePress={this.onLocaleDeletePress}
              onCreateSubMenu={this.onSubMenuCreate}
            />
          </Content>
        </ScrollableContent>
      </Root>
    );
  }
}

function mapStateToProps(state: TState) {
  return {
    locale: state.app.locale,
    hostData: state.host.hostData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    hostSecondaryMenuPublishUpdate: bindActionCreators(actionHostSecondaryMenuPublishUpdate, dispatch),
    hostSecondaryMenuArchive: bindActionCreators(actionHostSecondaryMenuArchive, dispatch),
    menuAddSubMenu: bindActionCreators(actionMenuAddSubMenu, dispatch),
    menuArchive: bindActionCreators(actionMenuArchive, dispatch),
    menuDuplicate: bindActionCreators(actionMenuDuplicate, dispatch),
    menuUpdate: bindActionCreators(actionMenuUpdate, dispatch),
    menuArchiveLocale: bindActionCreators(actionMenuArchiveLocale, dispatch),
  };
}

export const ScreenHostMenuMain = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withPropCheckMiddleware(ScreenHostMenuMainBase));

const Root = styled(MaxScreenDiv)`
`;

const ScrollableContent = styled(MaxHeightDiv)`
  background-color: ${({ theme }) => theme.t.col.lightGray3};
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
`;

