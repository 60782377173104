import * as React from 'react';
import styled from 'styled-components';
import { BlurChildren } from '../BlurChildren';
import { useElementDimensions } from '../../../../lib-react/src/hooks/useElementDimensions';
import { OverlayTrigger, Popover, PopoverBody } from 'react-bootstrap';
import { useToggle } from '../../../../lib-react/src/hooks/useToggle';

type TBlockAndBlurOverlay = {
  className?: string;
  uniqueId: string;
  shouldBlockChildren: boolean;
  children: React.ReactNode;
  tooltipContent?: React.ReactNode;
  overlay?: React.ReactNode;
  blurDeviation?: number;
  blockCursor: boolean;
  childrenDimensionsCheckFrequencyMs?: number;
};

export const BlockAndBlurOverlay = ({
  className,
  uniqueId,
  shouldBlockChildren,
  children,
  tooltipContent,
  overlay,
  blockCursor,
  blurDeviation,
  childrenDimensionsCheckFrequencyMs,
}: TBlockAndBlurOverlay) => {
  const { elementRef, width, height } = useElementDimensions<HTMLDivElement>(childrenDimensionsCheckFrequencyMs);

  const [popoverVisible, , setVisibleBase, setHidden] = useToggle(false);
  const setVisible = React.useCallback(() => {
    !!tooltipContent && shouldBlockChildren && setVisibleBase();
  }, [setVisibleBase, tooltipContent, shouldBlockChildren]);

  const memoizedPopover = React.useMemo(() => {
    return (
      <Popover id={uniqueId}>
        <PopoverBody>
          {tooltipContent}
        </PopoverBody>
      </Popover>
    );
  }, [uniqueId, tooltipContent]);
  return (
    <Root
      onMouseEnter={setVisible}
      onMouseOver={setVisible}
      onMouseLeave={setHidden}
      onMouseOut={setHidden}
      width={width}
      height={height}
      className={className}
      notAllowedCursor={blockCursor && shouldBlockChildren}>
      <OverlayTrigger
        show={popoverVisible}
        placement="top"
        overlay={memoizedPopover}>
        <OverlayTriggerChildren
          width={width}
          height={height}
          notAllowedCursor={blockCursor && shouldBlockChildren}
        >
          {overlay && shouldBlockChildren && (
            <OverlayMask>
              {overlay}
            </OverlayMask>
          )}
          <BlockedChildrenMask
            shouldBlockChildren={shouldBlockChildren}>
            <StyledBlurChildren
              blurDeviation={shouldBlockChildren ? blurDeviation : 0}>
              <ChildrenContainer ref={elementRef}>
                {children}
              </ChildrenContainer>
            </StyledBlurChildren>
          </BlockedChildrenMask>
        </OverlayTriggerChildren>
      </OverlayTrigger>
    </Root>
  );
};

const Root = styled.div<{ height?: number; width?: number; notAllowedCursor: boolean }>`
  cursor: ${({ notAllowedCursor }) => (notAllowedCursor ? `not-allowed` : 'unset')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

const OverlayTriggerChildren = styled.div<{ height?: number; width?: number; notAllowedCursor: boolean }>`
  cursor: ${({ notAllowedCursor }) => (notAllowedCursor ? `not-allowed` : 'unset')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '100%')};
`;

const AbsoluteChildren = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`;

const OverlayMask = styled(AbsoluteChildren)`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  z-index: 1;
`;

const BlockedChildrenMask = styled(AbsoluteChildren)<{ shouldBlockChildren: boolean }>`
  ${({ shouldBlockChildren }) => (shouldBlockChildren ? 'pointer-events: none;' : '')};
  z-index: 0;
`;

const ChildrenContainer = styled.div`
`;

const StyledBlurChildren = styled(BlurChildren)`
`;
