import * as React from 'react';
import { FullScreenServiceFrame } from './FullScreenServiceFrame';
import { SquareButton } from '../Button/SquareButton';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

export type TFullScreenErrorViewProps = {
  locale: ELocale;
  title?: string;
  message: string;
  actionText?: string;
  actionOnClick: () => void;
};

export const FullScreenErrorView = ({
  locale,
  title,
  message,
  actionText,
  actionOnClick,
}: TFullScreenErrorViewProps) => {
  return (
    <FullScreenServiceFrame
      locale={locale}
      title={title}
      content={(
        <ContentRoot>
          <LoadingH1>
            {message}
          </LoadingH1>
          {actionOnClick && (
            <StyledSquareButton
              title={actionText}
              onClick={actionOnClick}
            />
          )}
        </ContentRoot>
      )}
    />
  );
};

const ContentRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingH1 = styled.span`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
`;

const StyledSquareButton = styled(SquareButton)`
  margin-top: 20px;
`;
