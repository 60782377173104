import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useSyncedDataRef } from '../hooks/useSyncedDataRef';

type TWindow = {
  document: {
    addEventListener: any;
    removeEventListener: any;
  };
  location: {
    href: string;
  };
  history: {
    replaceState: (...params: any) => void;
  };
};

export const WindowContext = React.createContext({
  window: null as any as TWindow,
  windowRef: { current: null as any as TWindow },
});

export type TWindowContextProviderProps = {
  window: TWindow;
};

export const WindowContextProvider = ({
  window,
  children,
}: PropsWithChildren<TWindowContextProviderProps>) => {
  const windowRef = useSyncedDataRef(window);
  return (
    <WindowContext.Provider value={{
      window,
      windowRef,
    }}>
      {children}
    </WindowContext.Provider>
  );
};

