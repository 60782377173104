// import '../wdyr.js'
import 'react-swipeable-list/dist/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from 'react';
import * as serviceWorker from './serviceWorker';
import { App } from './App';
import ReactDOM from 'react-dom';

ReactDOM.render(
  // <React.StrictMode>
  <App/>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
