import * as React from 'react';
import * as fp from 'lodash/fp';
import { FIELD_ID } from '../../../../../core/src/db/DbDefs';
import { FieldInputListItem } from './FieldInputListItem';
import { TSecondaryItemInputRendererProps } from './buildSecondaryItemInputDefaultRenderer';
import { fpRemove } from '../../../../../core/src/lib/HelperFunctions';
import { usePrevious } from '../../../../../lib-react/src/hooks/usePrevious';
import { useSyncedDataRef } from '../../../../../lib-react/src/hooks/useSyncedDataRef';
import styled from 'styled-components';

export type TFieldInputListItem = {
  [FIELD_ID]?: string | undefined;
  mainText: string;
  actionable?: boolean;
  secondaryText?: string;
  hasSecondary?: boolean;
};

export type TFieldInputList<T> = {
  value: T[];
  onChange: (items: T[]) => void;
  mapListItemToValue: (listItem: TFieldInputListItem) => T;
  mapValueToListItem: (value: T) => TFieldInputListItem;
  listItemAction?: React.ReactNode;
  newItem?: T;
  newItemSet?: (value: T) => void;
  newItemReset?: () => void;
  className?: string;
  mapNewValueToListItem?: (value: T) => Omit<TFieldInputListItem, 'actionable'>;
  newItemMainPlaceholder?: string;
  newItemSecondaryPlaceholder?: string;
  renderSecondaryInput?: (props: TSecondaryItemInputRendererProps) => React.ReactNode;
};

function FieldInputListBase<T>({
  value,
  onChange,
  mapListItemToValue,
  mapValueToListItem,
  listItemAction,
  newItem,
  newItemSet,
  newItemReset,
  className,
  renderSecondaryInput,
  newItemMainPlaceholder = '',
  newItemSecondaryPlaceholder = '',
  mapNewValueToListItem = mapValueToListItem,
}: TFieldInputList<T>) {
  const valueRef = useSyncedDataRef(value);
  const mapListItemToValueRef = useSyncedDataRef(mapListItemToValue);

  const onValueChange = React.useCallback((index: number, listItem: TFieldInputListItem) => {
    const val = valueRef.current;
    const itemToChange = mapListItemToValueRef.current(listItem);
    onChange && onChange(fp.set(`[${index}]`, itemToChange, val));
  }, [onChange]);

  const onItemRemove = React.useCallback((index: number, listItem: TFieldInputListItem) => {
    const val = valueRef.current;
    onChange && onChange(fpRemove(val, index));
  }, [onChange]);

  const onNewItemChange = React.useCallback((index: number, listItem: TFieldInputListItem) => {
    newItemSet && newItemSet(mapListItemToValueRef.current(listItem));
  }, [newItemSet]);

  const previousItemCount = usePrevious(value.length);
  const autoFocusNewMainInput = (previousItemCount ?? 0) < value.length;

  const onNewItemSave = React.useCallback((index: number, listItem: TFieldInputListItem) => {
    const val = valueRef.current;
    onValueChange(val.length, listItem);
    newItemReset && newItemReset();
  }, [onValueChange, newItemReset]);

  const listItems = value.map(mapValueToListItem);
  const newItemListItem = newItem != null && mapNewValueToListItem(newItem);
  return (
    <Root className={className}>
      {listItems.map((item: TFieldInputListItem, index) => (
        <FieldInputListItem
          key={index}
          index={index}
          listItem={item}
          onChange={onValueChange}
          onAction={onItemRemove}
          action={listItemAction}
          saveOnBlur={true}
          renderSecondaryInput={renderSecondaryInput}
        />
      ))}
      {!!newItemListItem && (
        <FieldInputListItem
          key={listItems.length}
          index={listItems.length}
          listItem={newItemListItem}
          onChange={onNewItemChange}
          onSave={onNewItemSave}
          mainPlaceholder={newItemMainPlaceholder}
          secondaryPlaceholder={newItemSecondaryPlaceholder}
          saveOnBlur={false}
          renderSecondaryInput={renderSecondaryInput}
          autoFocusMainInput={autoFocusNewMainInput}
        />
      )}
    </Root>
  );
}

export const FieldInputList = React.memo(FieldInputListBase) as typeof FieldInputListBase;

const Root = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

