import * as React from 'react';
import {
  TDimensions,
  TScaler,
  buildScaler,
  useGetDimensions,
} from './Dim';
import { ThemeProvider } from 'styled-components';
import { useSyncedDataRef } from '../../../../lib-react/src/hooks/useSyncedDataRef';

export type TStyledComponentsTheme<T> = {
  t: T;
  dim: TDimensions;
  scaler: TScaler;
};

const StyledComponentsThemeContext = React.createContext({
  theme: ({} as TStyledComponentsTheme<any>),
  setTheme<T = any>(updater: (previousTheme: T) => T) {
  },
});

type TThemeContextProviderProps<T> = {
  initialTheme: T;
  children: React.ReactNode;
};

function useBuildThemeBuilder<T>(dimensions: TDimensions) {
  return React.useCallback((newTheme: T) => {
    return {
      t: newTheme,
      dim: dimensions,
      scaler: buildScaler(dimensions),
    };
  }, [dimensions]);
}

function StyledComponentsThemeProvider<T>({
  initialTheme,
  children,
}: TThemeContextProviderProps<T>) {
  const dimensions = useGetDimensions();
  const buildTheme = useBuildThemeBuilder<T>(dimensions);

  const [theme, setTheme] = React.useState<TStyledComponentsTheme<T>>(buildTheme(initialTheme));
  const setThemeRef = useSyncedDataRef(setTheme);

  const updateTheme = React.useCallback((updater: (previousTheme: T) => T) => {
    setTheme((stateTheme) => buildTheme(updater(stateTheme.t)));
  }, [setTheme, buildTheme]);

  // Keep theme synced
  React.useEffect(() => {
    setThemeRef.current((stateTheme) => buildTheme(stateTheme.t));
  }, [buildTheme]);

  return (
    <StyledComponentsThemeContext.Provider
      value={{
        theme,
        setTheme: updateTheme as any,
      }}>
      <ThemeProvider
        theme={theme as any}>
        {children}
      </ThemeProvider>
    </StyledComponentsThemeContext.Provider>
  );
}

export const StyledComponentsTheme = {
  Provider: StyledComponentsThemeProvider,
  Context: StyledComponentsThemeContext,
};
