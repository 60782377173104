import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { LabeledFieldTextInput } from '../form/LabeledFields';
import { Strings } from '../../../../core/src/locale/Strings';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { useProfileFormHandler } from '../../lib/forms/ProfileForm';
import styled from 'styled-components';
import { ELocale } from '../../../../core/src/locale/Locale';

type TProfileFiscalViewProps = {
  hostData: THost;
  locale: ELocale;
  formHandler: ReturnType<typeof useProfileFormHandler>;
};

export const ProfileFiscalView = ({
  locale,
  formHandler,
}: TProfileFiscalViewProps) => {
  return (
    <Root>
      <ScreenTitleTextTopMargined>
        {Strings.hProfileEditFiscal()(locale)}
      </ScreenTitleTextTopMargined>
      <ScreenTitleSubText>
        {Strings.hProfileEditFiscalInfo()(locale)}
      </ScreenTitleSubText>
      <StyledRow>
        <StyledCol md={12}
          lg={6}>
          <LabeledFieldTextInput
            isRequired
            value={formHandler.fields.denomination.value}
            onChange={formHandler.fields.denomination.setValue}
            error={formHandler.fields.denomination.errorStr}
            label={Strings.hProfileDenomination()(locale)}
            placeholder={Strings.hProfileDenominationPhd()(locale)}
          />
        </StyledCol>
        <StyledCol md={12}
          lg={6}>
          <LabeledFieldTextInput
            isRequired
            value={formHandler.fields.phone.value}
            onChange={formHandler.fields.phone.setValue}
            error={formHandler.fields.phone.errorStr}
            label={Strings.hProfilePhone()(locale)}
            placeholder={Strings.hProfilePhonePhd()(locale)}
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol md={12}
          lg={12}>
          <LabeledFieldTextInput
            isRequired
            value={formHandler.fields.vatId.value}
            onChange={formHandler.fields.vatId.setValue}
            error={formHandler.fields.vatId.errorStr}
            label={Strings.hProfileVatId()(locale)}
            placeholder={Strings.hProfileVatIdPhd()(locale)}
          />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol md={12}
          lg={12}>
          <LabeledFieldTextInput
            isRequired
            value={formHandler.fields.address.value}
            onChange={formHandler.fields.address.setValue}
            error={formHandler.fields.address.errorStr}
            label={Strings.hProfileAddress()(locale)}
            placeholder={Strings.hProfileAddressPhd()(locale)}
          />
        </StyledCol>
      </StyledRow>
    </Root>
  );
};

const Root = styled.div`
`;

const ScreenTitleText = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
`;

const ScreenTitleSubText = styled.p`
  color: ${({ theme }) => theme.t.col.accentLight};
  font-size: 14px;
  text-align: left;
`;

const ScreenTitleTextTopMargined = styled(ScreenTitleText)`
  margin-top: 28px;
`;

const StyledRow = styled(Row)`
`;

const StyledCol = styled(Col)`
  margin-top: 30px;
`;
