import * as React from 'react';
import { hexToRgbaString } from '../../../../core/src/lib/Color';
import { useBindDrawableContentOffset } from '../../hooks/useBindDrawableContentOffset';
import styled from 'styled-components';

type THostNavbarMainProps = {
  title: string;
  centerContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

export const NavbarFrame = ({
  title,
  centerContent,
  rightContent,
}: THostNavbarMainProps) => {
  useBindDrawableContentOffset(navbarFrameHeight);
  return (
    <Root2>
      <Root>
        <LeftContainer>
          <NavbarTitle>
            {title}
          </NavbarTitle>
        </LeftContainer>
        <CenterContainer>
          {centerContent}
        </CenterContainer>
        <RightContainer>
          {rightContent}
        </RightContainer>
      </Root>
    </Root2>
  );
};

const flexTotal = 1;
const flexLeftRightContainers = 0.2;
const flexCenterContainer = flexTotal - (flexLeftRightContainers * 2);
export const navbarFrameHeight = 60;

const Root2 = styled.div`
  background-color: ${({ theme }) => theme.t.col.white};
  border-bottom: 1px solid ${({ theme }) => hexToRgbaString(theme.t.col.accentDark, 0.2)};
  display: flex;
  height: ${navbarFrameHeight}px;
  width: 100vw;
`;

const Root = styled.div`
  align-items: center;
  display: flex;
  flex: ${flexTotal};
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 10px;
`;

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

const LeftContainer = styled(FlexContainer)`
  flex: ${flexLeftRightContainers};
  justify-content: flex-start;
`;

const CenterContainer = styled(FlexContainer)`
  flex: ${flexCenterContainer};
  justify-content: center;
`;

const RightContainer = styled(FlexContainer)`
  flex: ${flexLeftRightContainers};
  justify-content: flex-end;
`;

const NavbarTitle = styled.span`
  color: ${({ theme }) => theme.t.col.accentDark};
  font-size: 16px;
  font-weight: 600;
  text-align: left;
`;
