import * as _ from 'lodash';
import { FIELD_ID } from '../../../../core/src/db/DbDefs';
import { Host } from '../../../../core/src/models/db/host/Host';
import { HostPrintQueueItemCreator } from '../../../../core/src/models/db/hostPrintQueueItem/HostPrintQueueItemCreator';
import { PrintBuilderForGuestSessionAccount } from '../../../../core/src/printer/prints/PrintBuilderForGuestSessionAccount';
import {
  TDispatch,
  TThunkAction,
} from '../../../../lib-react/src/redux/redux';
import { TGuestSession } from '../../../../core/src/models/db/guestSession/GuestSessionTypes';
import { THost } from '../../../../core/src/models/db/host/HostTypes';
import { TOrder } from '../../../../core/src/models/db/order/OrderTypes';
import {
  TOrderItem,
  TOrderItemId,
} from '../../../../core/src/models/db/orderItem/OrderItemTypes';
import { dispatchWithErrorHandler } from '../ReduxErrorHandlers';

export type TActionHostAddMenuItemParams = {
  host: THost;
  guestSession: TGuestSession;
  filterOrderItemIds?: TOrderItemId[];
  numberOfGuests: number;
  discountNum: number;
};

export const actionHostPrintQueuePrintGuestSession = dispatchWithErrorHandler(({
  host,
  guestSession,
  filterOrderItemIds,
  numberOfGuests,
  discountNum,
}: TActionHostAddMenuItemParams): TThunkAction<Promise<void>> => {
  return async (dispatch: TDispatch) => {
    const hostPrintQueueItem = new PrintBuilderForGuestSessionAccount({
      host,
      guestSession,
      orderItemsToPrint: applyOrderItemsFilter(guestSession, filterOrderItemIds),
      destinationStationId: Host.findMainStationOrThrow(host)[FIELD_ID],
      numberOfGuests,
      discountNum,
    }).build();

    const pbp = { hostId: host[FIELD_ID] };

    await HostPrintQueueItemCreator.remoteSaveNewToPath(pbp, hostPrintQueueItem);
  };
});

function applyOrderItemsFilter(guestSessionData: TGuestSession, filterOrderItemIds?: TOrderItemId[]): TOrderItem[] {
  const orderItems = [] as TOrderItem[];

  _.forOwn(guestSessionData.orders, (order: TOrder) => {
    _.forOwn(order.items, (orderItem: TOrderItem) => {
      if (!filterOrderItemIds) {
        orderItems.push(orderItem);
        return;
      }

      if (filterOrderItemIds.includes(orderItem[FIELD_ID])) {
        orderItems.push(orderItem);
      }
    });
  });

  return orderItems;
}

